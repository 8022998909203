// import React, { useState } from 'react'

// import { TextField, Box, Stack } from '@mui/material'
// import { Link, useNavigate } from 'react-router-dom'
// import axios from 'axios';
// export default function ProPresentation() {


//   const [formData, setFormData] = useState({
//     presentation: '',
//     email: ''
//   });

//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');


//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleProUserPresentation = async (e) => {
//     e.preventDefault();
//     try {
//       setLoading(true);
//       setError('');
//       const storedEmail = localStorage.getItem("email");
//       console.log(storedEmail)
//       const requestData = {
//         email: storedEmail,
//         presentation: formData.presentation,
//       };
//       console.log(formData, storedEmail)
//       const response = await axios.post(`${process.env.REACT_APP_API_URL}api/prouser/add-propresentation`, requestData, {
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       console.log(response.status, response.data.success)
//       if (response.status === 200 && response.data.success) {

//         // localStorage.setItem("email", formData.email)
//         navigate('/proaccountingactivity');
//         // console.log(citiesData)
//       } else {
//         console.log(response);
//       }
//     } catch (error) {
//       console.error('Error during API call:', error);
//       if (error.response && error.response.status === 400 && error.response.data.success === false) {
//         setError(error.response.data.msg || 'API call failed');
//         setLoading(false);
//       }
//     }
//   };

//   return (
//     <>
//       <div className='bg-[#F5F5F5] h-[85vh] flex items-center justify-center' style={{
//         background: `linear-gradient(172deg, #64D3B6 60%, white 45%)`,
//         backgroundSize: '100% 50%', backgroundRepeat: 'no-repeat'
//       }}>
//         <div className='bg-[#F5F5F5] w-full py-7 3xl:py-12'>
//           <div className='md:max-w-[1477px] relative w-full h-full   flex items-center justify-center m-auto  '>

//             <div className='max-w-[700px] 3xl:max-w-[700px]  w-full h-full bg-white px-5 sm:px-12 py-2 sm:py-5 3xl:p-12 ' style={{ boxShadow: "0px 4px 10px 1px rgba(39, 41, 91, 0.35)", borderRadius: "33px" }}>
//               <div className=' px-2 py-2 3xl:py-4'>
//                 <h1 className='text-xl sm:text-3xl xl:text-[28px] font-extrabold md:leading-[45px] mt-6 text-primary'>Présentation</h1>
//               </div>
//               <Box
//                 onSubmit={handleProUserPresentation}
//                 component="form" className='mt-5 sm:mt-5 3xl:mt-12'
//                 sx={{
//                   "& .MuiInputLabel-root": { color: '#797979', },
//                   "& fieldset": {
//                     border: '1px solid #eee', borderRadius: '8px',
//                     color: '#797979',
//                     background: 'rgb(39 41 91 / 6%)',
//                   },
//                 }}
//               >
//                 <div className='grid grid-cols-12 items-start mt-2'>
//                   <div className='col-span-12'>
//                     <TextField
//                       hiddenLabel
//                       multiline
//                       minRows={14}
//                       name="presentation"
//                       onChange={handleChange}
//                       value={formData.presentation}
//                       className='w-full'
//                       placeholder='Décrivez l’activité de votre entreprise (compétences, caractéristiques, secteur d’activités...)'
//                     />
//                   </div>
//                 </div>
//                 {/* <Stack alignItems='end' sx={{ mt: 2 }}>
//                             <Link to='/proaccountingactivity' type='submit' className='bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white  mt-4 sm:mt-4 3xl:mt-10 px-10   py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white '>Suivant</Link>
//                             </Stack> */}
//                 <Stack alignItems='end' sx={{ mt: 2 }}>
//                   <button
//                     //   onClick={(e)=>{handleRegistration(e)}}
//                     type='submit'
//                     className='bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white  mt-4 sm:mt-4 3xl:mt-10 px-10   py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white'
//                     disabled={loading}
//                   >
//                     {loading ? 'En cours...' : 'Suivant'}
//                   </button>
//                 </Stack>
//                 {error && <p className="text-red-500 mt-2">{error}</p>}
//               </Box>
//             </div>
//           </div>
//         </div>

//       </div>
//     </>
//   )
// }


import React, { useState } from 'react';
import { TextField, Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
export default function ProPresentation() {
  let params = useParams();
  const [formData, setFormData] = useState({
    user_id: params.id?params.id:"",
    presentation: '',
    email: ''
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleProUserPresentation = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError('');
      const storedEmail = localStorage.getItem("email");
      const requestData = {
        user_id: params.id?params.id:"",
        email: storedEmail,
        presentation: formData.presentation,
      };

      // Validate if the presentation field is empty
      if (!formData.presentation.trim()) {
        setError('Le champ de présentation ne peut pas être vide');
        setLoading(false);
        return;
      }

      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/prouser/add-propresentation`, requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200 && response.data.success) {
        navigate(`/proaccountingactivity/${params.id}`);

      } else {
        console.log(response);
      }
    } catch (error) {
      console.error('Error during API call:', error);
      if (error.response && error.response.status === 400 && error.response.data.success === false) {
        setError(error.response.data.msg || 'API call failed');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className='bg-[#F5F5F5] h-[85vh] flex items-center justify-center' style={{
        background: `linear-gradient(172deg, #64D3B6 60%, white 45%)`,
        backgroundSize: '100% 50%', backgroundRepeat: 'no-repeat'
      }}>
        <div className='bg-[#F5F5F5] w-full py-7 3xl:py-12'>
          <div className='md:max-w-[1477px] relative w-full h-full   flex items-center justify-center m-auto  '>
            <div className='max-w-[700px] 3xl:max-w-[700px]  w-full h-full bg-white px-5 sm:px-12 py-2 sm:py-5 3xl:p-12 ' style={{ boxShadow: "0px 4px 10px 1px rgba(39, 41, 91, 0.35)", borderRadius: "33px" }}>
              <div className=' px-2 py-2 3xl:py-4'>
                <h1 className='text-xl sm:text-3xl xl:text-[28px] font-extrabold md:leading-[45px] mt-6 text-primary'>Présentation</h1>
              </div>
              <Box
                onSubmit={handleProUserPresentation}
                component="form" className='mt-5 sm:mt-5 3xl:mt-12'
                sx={{
                  "& .MuiInputLabel-root": { color: '#797979', },
                  "& fieldset": {
                    border: '1px solid #eee', borderRadius: '8px',
                    color: '#797979',
                    background: 'rgb(39 41 91 / 6%)',
                  },
                }}
              >
                <div className='grid grid-cols-12 items-start mt-2'>
                  <div className='col-span-12'>
                    <TextField
                      hiddenLabel
                      multiline
                      minRows={14}
                      name="presentation"
                      onChange={handleChange}
                      value={formData.presentation}
                      className='w-full'
                      placeholder='Décrivez l’activité de votre entreprise (compétences, caractéristiques, secteur d’activités...)'
                    />
                    {error && <p className="text-red-500 mt-2" style={{color:'red'}}>{error}</p>}
                  </div>
                </div>
                <Stack alignItems='end' sx={{ mt: 2 }}>
                  <button
                    type='submit'
                    className='bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white  mt-4 sm:mt-4 3xl:mt-10 px-10   py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white'
                    disabled={loading}
                  >
                    {loading ? 'En cours...' : 'Suivant'}
                  </button>
                </Stack>
                {/* {error && <p className="text-red-500 mt-2">{error}</p>} */}
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
