/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react'
import moment from "moment";
import frLocale from "moment/locale/fr";
import axios from "axios";
import {  useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { userActions } from "../_actions";


export const changeDateFormatFrench =(date)=>{
    moment.locale('fr', [frLocale])
    
    return moment(date).format('LL');
}

export const changeDateFormatFrenchDynamic =(date,dateformat='LL')=>{
    moment.locale('fr', [frLocale])
    
    return moment(date).format(dateformat);
}
export const convertNumberFrench =(get_number)=>{       
    const number = Number(get_number);
    return number.toLocaleString("fr-FR");
}
export const isValidEmail =(email)=>{ 
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
}

export const isAlphabetic =(inputVal)=>{ 
    const regex = /^[A-Za-z\s]+$/;
    return (typeof inputVal === "string" && inputVal.length>0 && regex.test(inputVal));
}

export const isNumericValue =(value)=>{ 
    return value.length>0 && /^\d*$/.test(value);
}
export const isNumberValue =(value)=>{ 
    const regex = /^[0-9\b]+$/;
    return value.length>0 && regex.test(value);
}

export const getDetailsBySiren =async(sirenNo)=>{
    try {       
        const fetchResponse = await axios.get(`${process.env.REACT_APP_API_URL}api/announce/sirenDetails/${sirenNo}`);       
        if(fetchResponse){
            return fetchResponse.data.data;

        }else{
            return {
                siren:"", denomination:"", forme_juridique:"",capital_social:"",adresse_rcs:"", code_postal:"",ville:"", area:"",
            };
        }
    } catch (e) {
        // return e;
        return {
            siren:"", denomination:"", forme_juridique:"",capital_social:"",adresse_rcs:"", code_postal:"",ville:"", area:"",
        };
    } 
    
}
export const decodedStringValue =(value)=>{ 
    let encodedString = value;
    let div = document.createElement('div');
    div.innerHTML = encodedString;
    let decodedString = div.innerText || div.textContent;
    return decodedString;
}


export const LogoutApp = () => {
    /* const dispatch = useDispatch();
    let navigate = useNavigate();
    dispatch(userActions.logout()); */
    const keysToRemove = ['user_pro', 'email', 'id', 'token'];
  
    keysToRemove.forEach(key => {
    localStorage.removeItem(key);
    });
    setTimeout(() => {
    //   navigate("/");
      window.location.href="/"
    }, 1000);
  }


  export const getMonthsStartingWithCurrent = () => {
    const monthNames = [
      'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
      'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];
  
    const currentMonthIndex = new Date().getMonth();
    const monthsStartingWithCurrent = [
      ...monthNames.slice(currentMonthIndex),
      ...monthNames.slice(0, currentMonthIndex)
    ];
  
    return monthsStartingWithCurrent;
  };

export const isValidSIRET = (siret) => {
    if (siret.length !== 14 || isNaN(siret)) {
      return false;
    }
  
    let sum = 0;
    for (let i = 0; i < 14; i++) {
      let digit = parseInt(siret[i], 10);
  
      // Double every second digit
      if (i % 2 === 0) {
        digit *= 2;
        if (digit > 9) {
          digit -= 9;
        }
      }
      sum += digit;
    }
  
    return sum % 10 === 0;
};

// Basic Regex pattern for French TVA
export const isValidTVA = (tva) => {
    const regex = /^FR[0-9A-Z]{2}[0-9]{9}$/;
    return regex.test(tva);
};

// NAF validation: 4 digits followed by 1 letter (e.g., "1234Z")
export const isValidNAF = (naf) => {
    const nafRegex = /^[0-9]{4}[A-Z]$/;
    return nafRegex.test(naf);
};
  
// Function to validate French IBAN (27 characters starting with 'FR')
export const validateIBAN = (iban) => {
    const ibanRegex = /^FR\d{12}[A-Z0-9]{11}\d{2}$/; // FR IBAN with 27 characters
    return ibanRegex.test(iban.replace(/\s+/g, "")); // Remove spaces before testing
};

// Function to format the IBAN in blocks of 4 characters
export const formatIBAN = (iban) => {
    return iban.replace(/\s+/g, '').replace(/(.{4})/g, '$1 ').trim();
};

export const validateBIC = (bic) => {
    const bicRegex = /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
    return bicRegex.test(bic.replace(/\s+/g, ""));
  };