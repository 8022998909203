import React,{useState,useEffect} from 'react';
import { TextField, Box, FormControl, Select, MenuItem, OutlinedInput, InputAdornment, cardMediaClasses, FormHelperText } from '@mui/material'
import { Link } from 'react-router-dom'
import ToastMessageComp from '../component/ToastMessageComp';
import PageLoaderComp from '../component/pageLoaderComp';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions,userActions } from '../_actions';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { userConstants } from '../_constants';
import { isValidSIRET,isValidTVA,isValidNAF,isNumberValue } from '../_helpers';

export default function MonEntreprise() {
    const alert = useSelector(state => state.alert);
    const currentUser = useSelector(state => state.authentication.user);
    const {UserBusinessDetails} = useSelector(state => state.authentication.user);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [siretError, setSiretError] = useState("");
    const [tvaError, setTvaError] = useState("");
    const [nafError, setNafError] = useState("");
    const [numberError, setNumberError] = useState("");
    
    const [submitted, setSubmitted] = useState(false);
    const [successfull, setSuccessfull] = useState(false);
    const [formData, setFormData] = useState({
        "user_id":currentUser && currentUser.data?currentUser.data.id:"",
        "type":"company",
        "companyArr":{
            "type":"company",
            "entreprise_name":"",
            "adresse":"",
            "statut_juridique":"",
            "userName":"",
            "siret":"",
            "intracommunity_vat":"",
            "naf_code":"",
            "vat":"",
            "created_at":"",
            "updated_at":"",
          
        },
        "documentsArr":{
            "type":"documents",
            "front":"",
            "adresse":"",
            "statut_juridique":"",
            "userName":"",
            "siret":"",
            "intracommunity_vat":"",
            "naf_code":"",
            "vat":"",
            "created_at":"",
            "updated_at":"",
        },
        "paymentsArr":{
            "type":"payments",
            "society_name":"",
            "adresse_bank_acc_holder":"",
            "iban":"",
            "bic":"",
            "created_at":"",
        }
       
    });
    useEffect(() => {
        window.scrollTo(0, 0)        
        if(currentUser && currentUser.data && UserBusinessDetails){
            setFormData({
                "user_id":currentUser.data.id,
                "type":"company",
                "companyArr":UserBusinessDetails.companyArr,
                "documentsArr":UserBusinessDetails.documentsArr,
                "paymentsArr":UserBusinessDetails.paymentsArr
            });
        }   
    }, [])
    
    const handleInputChange =async (e) => {
        // console.log("e",e)
        let {name, value} = e.target;
        if(name === "siret"  && !isValidSIRET(value)){
            setSubmitted(true);         
            setSiretError("Siret invalide!");
        }else{
            setSubmitted(false); 
            setSiretError("");
        }

        if(name === "intracommunity_vat" && value.length>0  && !isValidTVA(value)){
            setSubmitted(true);           
            setTvaError("TVA invalide!");
        }else{
            setSubmitted(false); 
            setTvaError("");
        }
        if(name === "naf_code"  && !isValidNAF(value)){
            setSubmitted(true);       
            setNafError("TVA invalide!");
        }else{
            setSubmitted(false); 
            setNafError("");
        }

        if(name === "vat" && value.length>0  && !isNumberValue(value)){
            setSubmitted(true);           
            setNumberError("Veuillez saisir uniquement un chiffre !!");
        }else{
            setSubmitted(false); 
            setNumberError("");
        }
        // setIsLoading(true)
        setFormData(prevState => ({
            ...prevState,
            companyArr: {
                ...prevState.companyArr,
                [name]: value
            }
        }));
    };
    const handleSubmit = (e) => {
        // handleClose()
        setSubmitted(true); 
        if(formData.companyArr.entreprise_name && formData.companyArr.adresse  && formData.companyArr.statut_juridique  /* && formData.companyArr.userName */  && formData.companyArr.siret && isValidSIRET(formData.companyArr.siret) /*  && formData.companyArr.intracommunity_vat */ && formData.companyArr.naf_code && !isValidNAF(formData.companyArr.naf_code) && formData.companyArr.vat || (formData.companyArr.intracommunity_vat && isValidTVA(formData.companyArr.intracommunity_vat))){
            
            setIsLoading(true);            
            fetch(process.env.REACT_APP_API_URL+`api/prouser/user-business-settings`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData),
            })
            .then((res) => res.json())
            .then((response) => {
                setSuccessfull(true)
                if(response.success){
                    dispatch(alertActions.success(response.msg));
                    let UserBusinessDetails = response.UserBusinessDetails;
                    dispatch({ type: userConstants.USER_BUSINESS_SETTINGS, UserBusinessDetails });
                    if(localStorage.getItem('user_pro')!=null){
                        let getLocalUser = JSON.parse(localStorage.getItem('user_pro'));
                        getLocalUser.UserBusinessDetails = UserBusinessDetails;
                        localStorage.setItem('user_pro', JSON.stringify(getLocalUser));
                    }
                }else {
                    dispatch(alertActions.error(response.msg));
                    // navigate(-1)       
                }
                setTimeout(()=>{
                    setIsLoading(false);
                    setSubmitted(false);
                    setSuccessfull(false)
                    // setOpen(false);
                },1000)
            });
        }
    };
    return (       
        <>
            {/*  {isLoading?
            <PageLoaderComp />
            :""} */}
            
            {successfull?
             <ToastMessageComp  message={alert.message} type={alert.type} />
            :""}
            <h6 className='text-xl font-semibold text-[#414141] mt-8'>Entreprise</h6>

            <Box component="form" className='mt-3 sm:mt-6 3xl:mt-6'
                sx={{
                    "& .MuiInputLabel-root": { color: '#797979', },
                    "& fieldset": {
                        border: '1px solid #eee', borderRadius: '5px',
                        color: '#797979',
                        background: 'rgb(39 41 91 / 6%)',
                    },
                }}
            >
                <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                    <label className='col-span-12 text-base font-medium text-gray mb-1'>Nom de l’entreprise (nom commercial)</label>
                    <div className='col-span-12'>
                        <TextField
                            hiddenLabel
                            id="outlined-size-small"
                            className='w-full outline-secondary focus:outline-secondary'
                            name='entreprise_name'
                            value={formData.companyArr.entreprise_name}                            
                            onChange={handleInputChange}
                            error={submitted && !formData.companyArr.entreprise_name}
                            helperText={submitted && !formData.companyArr.entreprise_name?`Le nom de l'entreprise ne doit pas être vide`:""}
                        />
                    </div>
                </div>
                <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                    <label className='col-span-12 text-base font-medium text-gray mb-1'>Adresse</label>
                    <div className='col-span-12'>
                        <TextField
                            hiddenLabel
                            id="outlined-size-small"
                            className='w-full outline-secondary focus:outline-secondary'
                            name='adresse'
                            value={formData.companyArr.adresse}
                            onChange={handleInputChange}
                            error={submitted && !formData.companyArr.adresse}
                            helperText={submitted && !formData.companyArr.adresse?`L'adresse ne doit pas être vide`:""}
                        />
                    </div>
                </div>
                <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                    <label className='col-span-12 text-base font-medium text-gray mb-1'>Statut juridique</label>
                    <div className='col-span-12'>
                        <FormControl fullWidth  error={submitted && !formData.companyArr.statut_juridique} >

                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name='statut_juridique'
                                value={formData.companyArr.statut_juridique}
                                onChange={handleInputChange}
                               
                            >
                               <MenuItem value={"SAS"}>Société par actions simplifiée (SAS)</MenuItem>
                                <MenuItem value={"SAS à capital variable"}>Société par actions simplifiée à capital variable</MenuItem>
                                <MenuItem value={"SASU"}>Société par actions simplifiée unipersonnelle (SASU)</MenuItem>
                                <MenuItem value={"SASU à capital variable"}>Société par actions simplifiée unipersonnelle à capital variable</MenuItem>
                                <MenuItem value={"SARL"}>Société à responsabilité limitée (SARL)</MenuItem>
                                <MenuItem value={"SARL à capital variable"}>Société à responsabilité limitée à capital variable</MenuItem>
                                <MenuItem value={"SARLU"}>Société à responsabilité limitée à associé unique (SARLU)</MenuItem>
                                <MenuItem value={"SARLU à capitale variable"}>Société à responsabilité limitée à associé unique à capitale variable</MenuItem>
                                <MenuItem value={"SCI"}>Société civile immobilière (SCI)</MenuItem>
                                <MenuItem value={"SCI à capital variable"}>Société civile immobilière à capital variable</MenuItem>
                                <MenuItem value={"SC"}>Société civile</MenuItem>
                                <MenuItem value={"SA"}>Société anonyme (SA)</MenuItem>
                                <MenuItem value={"SA à capital variable"}>Société anonyme à capital variable</MenuItem>
                                <MenuItem value={"SNC"}>Société en nom collectif (SNC)</MenuItem>
                                <MenuItem value={"SNC à capital variable"}>Société en nom collectif à capital variable</MenuItem>
                                <MenuItem value={"SCM"}>Société civile de moyens (SCM)</MenuItem>
                                <MenuItem value={"SCP"}>Société civile professionnelle (SCP)</MenuItem>
                                <MenuItem value={"SCPI"}>Société civile de placement immobilier (SCPI)</MenuItem>
                                <MenuItem value={"SCCV"}>Société civile immobilière de construction vente (SCCV ou SCICV)</MenuItem>
                                <MenuItem value={"SELAFA"}>Société d'exercice libéral sous forme anonyme (SELAFA)</MenuItem>
                                <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                                <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                                <MenuItem value={"SELCA"}>Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
                                <MenuItem value={"SELARL"}>Société d'exercice libéral a responsabilité limitée (SELARL)</MenuItem>
                                <MenuItem value={"SELEURL"}>Société d'exercice libéral ayant un associé Unique (SELEURL)</MenuItem>
                                <MenuItem value={"SELASU"}>Société d'exercice libéral par actions simplifiée unipersonnelle (SELASU)</MenuItem>
                                <MenuItem value={"SCEA"}>Société civile d'exploitation agricole (SCEA)</MenuItem>
                                <MenuItem value={"EARL"}>Exploitation agricole à responsabilité limitée (EARL)</MenuItem>
                                <MenuItem value={"GAEC"}>Groupement agricole d'exploitation en commun (GAEC)</MenuItem>
                                <MenuItem value={"GFA"}>Groupement foncier agricole (GFA)</MenuItem>
                                <MenuItem value={"EIRL"}>Entrepreneur Individuel à Responsabilité Limité</MenuItem>
                                <MenuItem value={"SPFPLAS"}>Société de Participations Financières de Profession Libérale d'Avocats Simplifiée (SPFPLAS)</MenuItem>
                            </Select>
                            {submitted && !formData.companyArr.statut_juridique && (
                                <FormHelperText>Le statut juridique ne doit pas être vide</FormHelperText>
                            )}
                        </FormControl>
                    </div>
                </div>
                <div className='mt-12'>
                    <h6 className='text-[22px] font-semibold text-black'>Forme juridique</h6>
                    <p>Les informations suivantes seront ajoutées au pied de page de toutes vos factures. Veuillez vérifier qu'elles sont conformes aux réglementations en vigueur dans votre pays selon le statut juridique de votre entreprise.</p>
                </div>
                <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                    <label className='col-span-12 text-base font-medium text-gray mb-1'>Nom d’usage</label>
                    <div className='col-span-12'>
                        <TextField
                            hiddenLabel
                            id="outlined-size-small"
                            className='w-full outline-secondary focus:outline-secondary'
                            name='userName'
                            value={formData.companyArr.userName}
                            onChange={handleInputChange}
                            /* error={submitted && !formData.companyArr.userName}
                            helperText={submitted && !formData.companyArr.userName?`le nom d'utilisateur ne doit pas être vide`:""} */
                        />
                    </div>
                </div>
                <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                    <label className='col-span-12 text-base font-medium text-gray mb-1'>Siret</label>
                    <div className='col-span-12'>
                        <TextField
                            hiddenLabel
                            id="outlined-size-small"
                            className='w-full outline-secondary focus:outline-secondary'
                            name='siret'
                            maxLength="14"
                            value={formData.companyArr.siret}
                            onChange={handleInputChange}
                            error={submitted && (!formData.companyArr.siret || siretError) }
                            helperText={submitted && (!formData.companyArr.siret || siretError)?siretError?"Siret invalide":`siret ne doit pas être vide`:""}
                            
                        />
                    </div>
                </div>
                <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                    <label className='col-span-12 text-base font-medium text-gray mb-1'>TVA intracommunautaire</label>
                    <div className='col-span-12'>
                        <TextField
                            hiddenLabel
                            id="outlined-size-small"
                            className='w-full outline-secondary focus:outline-secondary'
                            name='intracommunity_vat'
                            value={formData.companyArr.intracommunity_vat}
                            onChange={handleInputChange}
                            maxLength="13"
                            // error={submitted && !formData.companyArr.intracommunity_vat}
                            // helperText={submitted && !formData.companyArr.intracommunity_vat?`TVA intracommunautaire ne doit pas être vide`:""}
                            error={submitted && tvaError }
                            helperText={submitted && tvaError?"TVA invalide":""}
                            
                        />
                    </div>
                </div>
                <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                    <label className='col-span-12 text-base font-medium text-gray mb-1'>Code NAF</label>
                    <div className='col-span-12'>
                        <TextField
                            hiddenLabel
                            id="outlined-size-small"
                            className='w-full outline-secondary focus:outline-secondary'
                            name='naf_code'
                            value={formData.companyArr.naf_code}
                            onChange={handleInputChange}/* nafError */
                            /* error={submitted && !formData.companyArr.naf_code}
                            helperText={submitted && !formData.companyArr.naf_code?`Le code NAF ne doit pas être vide`:""} */
                            error={submitted && (!formData.companyArr.naf_code || nafError) }
                            helperText={submitted && (!formData.companyArr.naf_code || nafError)?nafError?"NAF invalide":`Le code NAF ne doit pas être vide`:""}
                        />
                    </div>
                </div>
                <div className='mt-12'>
                    <h6 className='text-[22px] font-semibold text-black'>Taxe</h6>
                </div>
                <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                    <label className='col-span-12 text-base font-medium text-gray mb-1'>TVA</label>
                    <div className='col-span-12'>
                        <FormControl variant="outlined" >
                            <OutlinedInput
                                id="TVA"
                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                placeholder='0'
                                name='vat'
                                value={formData.companyArr.vat}
                                onChange={handleInputChange}
                                /* error={submitted && !formData.companyArr.vat}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}  // Accepts only numeric input
                                helperText={submitted && !formData.companyArr.vat?`TVA ne doit pas être vide`:""} */
                                error={submitted && (!formData.companyArr.vat || numberError) }
                               /*  helperText={submitted && (!formData.companyArr.vat || numberError)?numberError?numberError:`TVA ne doit pas être vide`:""} */
                            />
                            {/* Helper text handling */}
                            {(submitted && (!formData.companyArr.vat || numberError)) && (
                                <FormHelperText error>
                                {numberError ? numberError : `TVA ne doit pas être vide`}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </div>
                </div>
               {/*  <Link to='/' type='submit' className='bg-primary text-base xl:text-xl 3xl:text-xl font-bold text-white  mt-4 sm:mt-4 3xl:mt-10 px-10   py-3   rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white '>Enregistrer</Link> */}
                <LoadingButton
                    className='bg-primary text-base xl:text-xl 3xl:text-xl font-bold text-white  mt-4 sm:mt-4 3xl:mt-10 px-10   py-3   rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white capitalize'
                    size="small"
                    onClick={handleSubmit}
                    endIcon={<SendIcon />}
                    loading={isLoading}
                    loadingPosition="end"
                    variant="contained"                    >
                    <span>Enregistrer</span>
                </LoadingButton>
            </Box>
        </>
    )
}
