
// import React, { useState } from 'react';
// import { TextField, Box, Stack } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';

// export default function PartagezVotreProfil() {

//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');

//   const [profileData, setProfileData] = useState({
//     email: '',
//     share_your_profile: [
//       { website: '' },
//       { Youtube: '' },
//       { LinkedIn: '' },
//       { Viadeo: '' },
//     ],
//   });

//   const handleChange = (category, value, index) => {
//     setProfileData((prevProfileData) => ({
//       ...prevProfileData,
//       share_your_profile: prevProfileData.share_your_profile.map((item, i) =>
//         i === index ? { [category]: value } : item
//       ),
//     }));
//   };



import React, { useState } from 'react';
import { TextField, Box, Stack } from '@mui/material';
import { useNavigate,useParams } from 'react-router-dom';
import axios from 'axios';

export default function PartagezVotreProfil() {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [profileData, setProfileData] = useState({
    email: '',
    share_your_profile: [
      { website: '' },
      { Youtube: '' },
      { LinkedIn: '' },
      { Viadeo: '' },
    ],
  });

  const handleChange = (category, value, index) => {
    setProfileData((prevProfileData) => ({
      ...prevProfileData,
      share_your_profile: prevProfileData.share_your_profile.map((item, i) =>
        i === index ? { [category]: value } : item
      ),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all fields are empty
    /* const allFieldsEmpty = profileData.share_your_profile.every(
      (item) => Object.values(item)[0] === ''
    );

    if (allFieldsEmpty) {
      setError('Veuillez remplir au moins un champ.');
      return;
    } */

    try {
      setLoading(true);

      const emaildata = localStorage.getItem('email');

      // Create the request data object
      const requestData = {
        user_id: params.id?params.id:"",
        email: emaildata,
        share_your_profile: profileData.share_your_profile,
      };

      // Use Axios to make the API request
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/prouser/add-prousershareprofile`,
        requestData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        // Handle success, e.g., redirect or show a success message
        navigate(`/prologo/${params.id}`); // Redirect to the next page on success
      } else {
        // Handle error, e.g., show an error message
        setError("Une erreur s'est produite."); // Set the error message
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className='bg-[#F5F5F5] h-[85vh] flex items-center justify-center'>
        <div className='bg-[#F5F5F5] w-full py-7 3xl:py-12'>
          <div className='md:max-w-[1477px] relative w-full h-full   flex items-center justify-center m-auto  '>
            <div
              className='max-w-[700px] 3xl:max-w-[700px]  w-full h-full bg-white px-5 sm:px-12 py-2 sm:py-5 3xl:p-12 '
              style={{ boxShadow: '0px 4px 10px 1px rgba(39, 41, 91, 0.35)', borderRadius: '33px' }}
            >
              <div className=' px-2 py-2 3xl:py-4'>
                <h1 className='text-xl sm:text-3xl xl:text-[28px] font-extrabold md:leading-[45px] mt-6 text-primary'>
                  Partagez votre profil{' '}
                  <small className='font-light text-base'>(facultatif)</small>
                </h1>
              </div>
              <Box
                component='form'
                className='mt-5 sm:mt-5 3xl:mt-12'
                onSubmit={handleSubmit}
                sx={{
                  '& .MuiInputLabel-root': { color: '#797979' },
                  '& fieldset': {
                    border: '1px solid #eee',
                    borderRadius: '8px',
                    color: '#797979',
                    background: 'rgb(39 41 91 / 6%)',
                  },
                }}
              >
                <div className='mt-3 sm:mt-3'>
                  <div className='inline-flex items-center gap-6 w-full'>
                    <p className='min-w-[80px]'>Site web</p>
                    <TextField
                      label='Coller le lien url du site web'
                      variant='outlined'
                      size='small'
                      className='w-2/3 outline-secondary focus:outline-secondary'
                      value={profileData.share_your_profile[0].website || ''}
                      onChange={(e) => handleChange('website', e.target.value, 0)}
                    />
                  </div>
                  <div className='inline-flex items-center gap-6 w-full mt-2'>
                    <p className='min-w-[80px]'>YouTube</p>
                    <TextField
                      label='Coller le lien url YouTube'
                      variant='outlined'
                      size='small'
                      className='w-2/3 outline-secondary focus:outline-secondary'
                      value={profileData.share_your_profile[1].Youtube || ''}
                      onChange={(e) => handleChange('Youtube', e.target.value, 1)}
                    />
                  </div>
                  <div className='inline-flex items-center gap-6 w-full mt-2'>
                    <p className='min-w-[80px]'>LinkedIn</p>
                    <TextField
                      label='Coller le lien url LinkedIn'
                      variant='outlined'
                      size='small'
                      className='w-2/3 outline-secondary focus:outline-secondary'
                      value={profileData.share_your_profile[2].LinkedIn || ''}
                      onChange={(e) => handleChange('LinkedIn', e.target.value, 2)}
                    />
                  </div>
                  <div className='inline-flex items-center gap-6 w-full mt-2'>
                    <p className='min-w-[80px]'>Viadeo</p>
                    <TextField
                      label='Coller le lien url Viadeo'
                      variant='outlined'
                      size='small'
                      className='w-2/3 outline-secondary focus:outline-secondary'
                      value={profileData.share_your_profile[3].Viadeo || ''}
                      onChange={(e) => handleChange('Viadeo', e.target.value, 3)}
                    />
                  </div>
                </div>
                <Stack alignItems='end' sx={{ mt: 2 }}>
                  <button
                    type='submit'
                    className='bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white mt-4 sm:mt-4 3xl:mt-10 px-10 py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white'
                    disabled={loading}
                  >
                    {loading ? 'En cours...' : 'Suivant'}
                  </button>
                </Stack>
                {/* {error && <p className='text-red-500 mt-2' style={{color:'red'}}>{error}</p>} */}
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
