import React from 'react'
import { Link } from 'react-router-dom'

export default function NoPage() {
  return (
    <div>NoPage  

      <Link to="/">Back</Link>
    </div>
  )
}
