import React from 'react'
 
import { ReactComponent as TourIcon } from '../../assets/Home/TourIcon.svg'
import ToutSavoirData from './ToutSavoirData'
export default function ToutSavoir() {
  return (
    <>
      <div className='bg-secondary w-full h-full'>
        <div className='md:max-w-[1477px] relative w-full h-full m-auto  py-10 sm:py-16 lg:py-24 px-6 sm:px-14'>
          <div className='flex items-center justify-start gap-4 w-full m-auto'>
            <TourIcon />
            <h2 className='text-2xl xl:text-3xl font-black text-primary'>Tout savoir sur les annonces légales</h2>
          </div>
          <div className=' mt-10 md:mt-20'>
            <ToutSavoirData />
          </div>
        </div>
      </div>
    </>
  )
}
