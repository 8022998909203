/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import React, { useState,useEffect,useRef } from 'react'
import { Link,useNavigate,useLocation,useHistory ,useParams,useSearchParams,Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

import moment from 'moment'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel'; 
import Typography from '@mui/material/Typography';
import Breadcrumb from '../../../component/Breadcrumb'
import Express from '../FormComponent/Express';
import Procedures from '../FormComponent/Procedures';
import PaymentSideBlock from '../FormComponent/PaymentSideBlock';
import { TransformationSaEnSas} from '../adsFormComp';
import FormSummary from '../FormSummary';
import CompleteAddress from '../CompleteAddress';
import PaymentForm from '../PaymentForm'
import PaymentSecond from '../PaymentSecond'
import Footer from '../../../component/Footer';
import FormSuccess from '../FormSuccess';
import axios from 'axios';
import { announceActions,alertActions } from '../../../_actions';
import ToastMessageComp from '../../../component/ToastMessageComp';
import { announceConstants } from '../../../_constants';
import PageLoaderComp from '../../../component/pageLoaderComp';

import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';
import { isValidEmail,isNumericValue } from '../../../_helpers';
import { Ads_fullname } from '../../../_helpers/ads_fullname';
import { getDetailsBySiren } from '../../../_helpers';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`, {
  locale: 'fr' // Set the locale to French
});

export function TransformationSaEnSasStepperForm() {
 
  const appearance = {
    theme: 'stripe',
  };
 
  const [loading, setLoading] = useState(false);
  const [loadingFullpage, setLoadingFullpage] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [options, setOptions] = useState( {
    clientSecret,
    appearance,
  });

  const [queryParameters] = useSearchParams()
  const childRef = useRef();
  const announceIdRef = useRef("");
  const steps = ['Remplir le formulaire', 'Visualiser le récapitulatif', 'Adresse de facturation', 'Procéder au paiement', 'succès'];
  const alert = useSelector(state => state.alert);
  const dispatch = useDispatch(); 
  const location = useLocation(); 
  let navigate = useNavigate();
  let { ads,adsname,id } = useParams();
  
  const currentUser = useSelector(state => state.authentication.user);
  const announceData = useSelector(state => state.announce.announce);
  const [isCheckedSerenity,setIscheckedSerenity]=useState(false)
  const [is_general_conditions_sale,setis_general_conditions_sale]=useState(false)
  const [general_conditions_sale_msg,setgeneral_conditions_sale_msg]=useState("")
  const [announceDetails,setAnnounceDetails]=useState("")
  const [AdsFullName,setAdsFullName]=useState("")

  const [Les_president, setLes_president] = useState([{
    user_id:currentUser&&currentUser.data?currentUser.data.id:"",
    index:0,
    type_personne:"",
    personne_morale_rcs:"",
    personne_morale_denomination:"",
    personne_morale_forme_juridique:"",
    personne_morale_capital:"",
    civilite_physique:"",
    nom_physique:"",
    prenom_physique:"",
    adresse_physique:"",
    adresse_suite_physique:"",
    resident_etranger:"Non",
    code_postal_physique:"",
    ville_physique:"",
    pays_physique:"",
    ville_physiqueArr:[],
    ville_physique_id:"",
    personne_morale_greffe:"",
    personne_morale_representation_civilite:"",
    personne_morale_representation_nom:"",
    personne_morale_representation_prenom:"",
  }]);
 
  const [ Directeur_general, setDirecteur_general] = useState({
    show_data_direct:"Non",
    user_id:currentUser&&currentUser.data?currentUser.data.id:"",
    index:0,
    type_personne_direct:"",    
    rcs_direct:"",
    denomination_direct:"",
    forme_juridiquedirect:"",
    capital_direct:"",
    civilite_direct_physique:"",
    nom_direct_physique:"",
    prenom_direct_physique:"",
    adresse_direct:"",
    adresse_suite_direct:"",
    code_postal_direct:"",
    ville_direct:"",
    pays_direct:"",
    ville_directArr:[],
    ville_direct_id:"",
    greffe_direct:""  ,
   
  });
  const Directeur_generalRef = useRef(Directeur_general);
  const [CommissaireAuxComptes, setCommissaireAuxComptes] = useState({
    show_data_commissaire:"Non",
    user_id:currentUser&&currentUser.data?currentUser.data.id:"",
    CAC_titulaire:{
      user_id:currentUser&&currentUser.data?currentUser.data.id:"",
      index:0,
      type_personne_titulaire:"",
      civilite_titulaire_physique:"",
      nom_titulaire_physique:"",
      prenom_titulaire_physique:"",
      adresse_titulaire_physique:"",
      adresse_suite_titulaire_physique:"",
      resident_etrangerTitulairephysic:"Non",
      code_postal_titulaire_physique:"",
      ville_titulaire_physique:"",
      rcs_titulaire:"",
      denomination_titulaire:"",
      forme_juridique_titulaire:"",
      capital_titulaire:"",
      adresse_titulaire:"",
      adresse_suite_titulaire:"",
      code_postal_titulaire:"",
      ville_titulaire:"",
      ville_titulaireArr:[],
      ville_id_titulaire:"",
      greffe_titulaire:"",
      pays_titulaire:""
    },
    CAC_suppleant:{
      user_id:currentUser&&currentUser.data?currentUser.data.id:"",
      index:0,
      type_personne_suppleant:"",
      civilite_suppleant_physique:"",
      nom_suppleant_physique:"",
      prenom_suppleant_physique:"",
      adresse_suppleant_physique:"",
      adresse_suite_suppleant_physique:"",
      code_postal_suppleant_physique:"",
      ville_suppleant_physique:"",
      rcs_suppleant:"",
      denomination_suppleant:"",
      forme_juridique_suppleant:"",
      capital_suppleant:"",
      adresse_suppleant:"",
      adresse_suite_suppleant:"",
      code_postal_suppleant:"",
      pays_suppleant:"",
      ville_suppleant:"",
      ville_suppleantArr:[],
      ville_id_suppleant:"",
      greffe_suppleant:"",
      resident_etrangerSuppleantPhysic:"Non"
    }
    
  });
  const [ Transmission_des_actions , setTransmission_des_actions] = useState({
    show_data:"Texte libre",
    user_id:currentUser&&currentUser.data?currentUser.data.id:"",
    texte:"",
       
  });
  const [Admission_assemblees , setAdmission_assemblees] = useState({
    show_data:"Texte libre",
    user_id:currentUser&&currentUser.data?currentUser.data.id:"",
    texte:"",
       
  });
  const [formData,setFormData]= useState({
    "announce_id":id?id:"",title:(ads.split("-")).join(" "),add_type_full:AdsFullName, add_type:adsname?adsname:"",form_type:adsname?adsname:"",rcs_societe:"",denomination_societe:"",forme_juridique_societe:"",capital_societe:"",address_societe:"",address_suite_societe:"",code_postal:"",ville:"",villeArr:[],ville_id:"",greffe:"",type_acte_Transformation:"",type_acte:"",datelagge:"",date_effect:"",Les_president:Les_president,Directeur_general:Directeur_general,CommissaireAuxComptes:CommissaireAuxComptes,Transmission_des_actions:Transmission_des_actions,Admission_assemblees:Admission_assemblees});
    
  const [isSubmitted, setSubmitted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [successful, setSuccessful] = useState(false);
  const [userSelectedAddress, setUserSelectedAddress] = useState({
    "announce_id":id,
    "user_id":currentUser && currentUser.data?currentUser.data.id:"",
    "delivery_address":currentUser && currentUser.user_address?currentUser.user_address[0]:"",
    "shipping_address":currentUser && currentUser.user_address?currentUser.user_address[0]:"",
    "email":currentUser && currentUser.data?currentUser.data.email:"",
    "telephone":currentUser && currentUser.data?currentUser.data.telephone:"",
    "billing_address_type":"account_address",
    "billing_address_denomination":currentUser && currentUser.data?currentUser.data.raisosociale:"",
    "billing_address_civility":currentUser && currentUser.data?currentUser.data.civil:"",
    "billing_address_nom":currentUser && currentUser.data?currentUser.data.firstname:"",
    "billing_address_prenom":currentUser && currentUser.data?currentUser.data.lastname:"",
    "billing_address_address":currentUser && currentUser.data?currentUser.data.adresse:"",
    "billing_address_code_postal":currentUser && currentUser.data?currentUser.data.postalcode:"",
    "billing_address_ville":currentUser && currentUser.data?currentUser.data.ville:"",
    "billing_address_ville_id":currentUser && currentUser.data?currentUser.data.ville_id:"",
    "billing_address_villeArr":currentUser && currentUser.data&& currentUser.data.villeArr?JSON.parse(currentUser.data.villeArr):[],
  });
  
  const [payamentPrice,sePayamentPrice]=useState(0);
  const [paymentData,setPaymentData]=useState("");
 
  useEffect(() => {
    window.scrollTo(0, 0)
    setAdsFullName(Ads_fullname(adsname));
    if(id){
      let announce = {
        "data":[],
        "announce_id":id 
      }
      dispatch({ type: announceConstants.CREATE_SUCCESS, announce});
    }
    if(localStorage.getItem("activeStep")){
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
      setActiveStep(parseInt(localStorage.getItem("activeStep")))
      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped); 
    }
    if(!queryParameters.get("activstep") && id){
      // setActiveStep(1);
      getAnnounceDetails(id);
    }
    
  }, [])
  
  useEffect(() => { 
    if(announceDetails){
      let announceDetailsGet = announceDetails.data;

      let CommissaireAuxComptesGet = announceDetailsGet.votre_annonce_json.CommissaireAuxComptes;
      let Transmission_des_actionsGet = announceDetailsGet.votre_annonce_json.Transmission_des_actions;
      let Admission_assembleesGet = announceDetailsGet.votre_annonce_json.Admission_assemblees;
      let Directeur_generalGet = announceDetailsGet.votre_annonce_json.Directeur_general;
      let Les_presidentGet = announceDetailsGet.votre_annonce_json.Les_president;

      announceDetailsGet.votre_annonce_json.announce_id=id?id:"";
      announceDetailsGet.votre_annonce_json.title=ads?(ads.split("-")).join(" "):"";
      setFormData(announceDetailsGet.votre_annonce_json);
      setDirecteur_general(Directeur_generalGet);
      setLes_president(Les_presidentGet);
      setCommissaireAuxComptes(CommissaireAuxComptesGet);
      setTransmission_des_actions(Transmission_des_actionsGet);
      setAdmission_assemblees(Admission_assembleesGet);
    }
  }, [announceDetails])

  useEffect(() => {   
    if(activeStep ===3 && payamentPrice >0){
      fetch(process.env.REACT_APP_API_URL+`api/create-payment-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ items: [],announce_id: id,price:payamentPrice/* ,paymentData:paymentData */}),
      })
        .then((res) => res.json())
        .then((data) => {
          setClientSecret(data.clientSecret);
          let clientSecret = data.clientSecret
          setOptions({clientSecret,appearance})
        });
    }    
    
  }, [activeStep,payamentPrice])

  useEffect(() => {  
    if(paymentData && paymentData.data){
      sePayamentPrice(parseFloat(parseFloat(paymentData.data.total)+(isCheckedSerenity?118.80:0)).toFixed(2))
    }
    
  }, [isCheckedSerenity,paymentData])
  useEffect(() => {
    Directeur_generalRef.current = Directeur_general;
  
  }, [Directeur_general])
  useEffect(() => {  
    if(queryParameters.get("activstep")  && id && ( (queryParameters.get("payment_intent") && queryParameters.get("payment_intent_client_secret")) || (currentUser && currentUser.UserProDetails && currentUser.UserProDetails.details && currentUser.UserProDetails.details.payment_plan !== null && currentUser.UserProDetails.details.payment_plan.includes("monthly")) )){
      const getDetails = async () => {
        const details_response = await getAnnounceDetails(id);
        if(details_response.success){
          if(parseInt(details_response.data.data.status) === 1 || parseInt(details_response.data.data.status) === 2){
            let data = {
              "announce_id":id,
              "payment_order_id":moment().format("DDMMY")+"_"+id.padStart(8, '0'),
              "invoice_number":moment().format("DDMMY")+"_"+id.padStart(8, '0'),
              "invoice_date":moment().format("DD-MM-Y"),
              "date_payment":moment().format("DD-MM-Y"),
              "payment_intent":queryParameters.get("payment_intent"),
              "payment_price":details_response.data.data.total,
              "payment_intent_client_secret":queryParameters.get("payment_intent_client_secret"),
            }
            const update_response = await updateAnnouncePayment(data);
            
          }
        }
      };    
      getDetails();
    }
    
  }, [queryParameters.get("activstep"),queryParameters.get("payment_intent"),queryParameters.get("payment_intent_client_secret")])

  useEffect(() => {
    const getData = setTimeout(async() => {
      setFormData(formData => ({ ...formData, ["ville"]: "",["villeArr"]: [],["ville_id"]: "",["greffe"]: ""  }));  
      if(formData.code_postal.length>0){
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/checkzipcode/${formData.code_postal}`);        
        if( response.data &&  response.data.data){
          setFormData(formData => ({ ...formData, ["ville"]: response.data.data.city,["villeArr"]: response.data.data.cityArr,["ville_id"]: response.data.data.id,["greffe"]: response.data.data.area  }));
        }
      }      
    }, 1000)
    return () => clearTimeout(getData);
  }, [formData.code_postal])

  
  
  async function handleChangeAddForm(e,index,type="President") {
    let { name, value } = e.target;
    // // console.log("value",value)
    if(type=== "President"){
      let values = [...Les_president];
      if(name == "ville_physique_id"){        
        let cityArrFilter = values[index].ville_physiqueArr.filter((val,i)=>val.id==value);
        values[index]["ville_physique"] = cityArrFilter[0].city;
        values[index]["ville_physique_id"] = value;
        setLes_president(values);
        setFormData(formData => ({ ...formData, ["Les_president"]: values }));

      }else{
        
        values[index][name] = value;
        setLes_president(values);
        setFormData(formData => ({ ...formData, ["Les_president"]: values }));
      }

      if(name ==="code_postal_physique"){
        await getCityFromPostal(name,value,index,type);
      }
      if(name ==="personne_morale_rcs"){
        await getSirenDetailsBySiren(name,value,index,type);
      }
     
      
    }
    if(type === "Directeur_general"){
      let values = Directeur_generalRef.current;   
      if(name =="ville_direct_id"){         
        let cityArrFilter = values.ville_directArr.filter((val,i)=>val.id==value);        
        values["ville_direct"] = cityArrFilter[0].city;
        values["ville_direct_id"] = value;        
        setDirecteur_general(values);
        setFormData(formData => ({ ...formData, ["Directeur_general"]: values }));

      }else{        
                
        values[name] = value;    
        setDirecteur_general(values);       
        setFormData(formData => ({ ...formData, ["Directeur_general"]: values }));
      }
      
      if( name === "code_postal_direct"){
        await getCityFromPostalType(name,value,0,"Directeur_general");
      }
      if(name ==="rcs_direct"){
        await getSirenDetailsBySiren(name,value,index,type);
      }

      /* let values =Directeur_generalRef.current; ;   
      values[name] = value;
      setDirecteur_general(values);
      setFormData(formData => ({ ...formData, ["Directeur_general"]: values }));  */ 
      
    }
    
    if(type === "commissaireAuxComptes"){
      let values = {...CommissaireAuxComptes};
           
      if(name === "show_data_commissaire"){
        values[name] = value;
      }
      setCommissaireAuxComptes(values);
      setFormData(formData => ({ ...formData, ["CommissaireAuxComptes"]: values }));
    }
    if(type === "CAC_titulaire"){
      let values = {...CommissaireAuxComptes};
      if(name == "ville_id_titulaire"){        
        let cityArrFilter = values["CAC_titulaire"].ville_titulaireArr.filter((val,i)=>val.id==value);
        values["CAC_titulaire"]["ville_titulaire"] = cityArrFilter[0].city;
        values["CAC_titulaire"]["ville_id_titulaire"] = value;
        setCommissaireAuxComptes(values);
        setFormData(formData => ({ ...formData, ["CommissaireAuxComptes"]: values }));

      }else{
        
        values["CAC_titulaire"][name] = value;
        setCommissaireAuxComptes(values);
        setFormData(formData => ({ ...formData, ["CommissaireAuxComptes"]: values }));
      }
      if(name === "code_postal_titulaire"){
        await getCityFromPostalType(name,value,0,"CAC_titulaire");
      }
      if(name === "rcs_titulaire"){
        await getSirenDetailsBySiren(name,value,index,type);
      }

      
     /*  values["CAC_titulaire"][name] = value;
      setCommissaireAuxComptes(values);
      setFormData(formData => ({ ...formData, ["CommissaireAuxComptes"]: values })); */
    }
    if(type === "CAC_suppleant"){
      let values = {...CommissaireAuxComptes};

      if(name == "ville_id_suppleant"){        
        let cityArrFilter = values["CAC_suppleant"].ville_suppleantArr.filter((val,i)=>val.id==value);
        values["CAC_suppleant"]["ville_suppleant"] = cityArrFilter[0].city;
        values["CAC_suppleant"]["ville_id_suppleant"] = value;
        setCommissaireAuxComptes(values);
        setFormData(formData => ({ ...formData, ["CommissaireAuxComptes"]: values }));

      }else{        
        values["CAC_suppleant"][name] = value;
        setCommissaireAuxComptes(values);
        setFormData(formData => ({ ...formData, ["CommissaireAuxComptes"]: values }));
      }
      if(name ==="code_postal_suppleant"){
        await getCityFromPostalType(name,value,0,"CAC_suppleant");
      }
      if(name === "rcs_suppleant"){
        await getSirenDetailsBySiren(name,value,index,type);
      }
      /* let values = {...CommissaireAuxComptes};      
      values["CAC_suppleant"][name] = value;
      setCommissaireAuxComptes(values);
      setFormData(formData => ({ ...formData, ["CommissaireAuxComptes"]: values })); */
    } 
    if(type === "Transmission_des_actions"){
      let values = {...Transmission_des_actions};  
      values[name] = value;
      if(name == "show_data"){
        if(value === "Texte libre"){
          values["texte"] = "";
        }else{
          values["texte"] = "Actions librement cessibles entre associés uniquement.";
        }
      }      
      setTransmission_des_actions(values);
      setFormData(formData => ({ ...formData, ["Transmission_des_actions"]: values }));
    }
   
    if(type === "Admission_assemblees"){
      let values = {...Admission_assemblees}; 
      values[name] = value;
      if(name == "show_data"){
        if(value === "Texte libre"){
          values["texte"] = "";
        }else{
          values["texte"] = "Tout Actionnaire est convoqué aux Assemblées. Chaque action donne droit à une voix.";
        }
      }
      setAdmission_assemblees(values);
      setFormData(formData => ({ ...formData, ["Admission_assemblees"]: values }));
    }
    
  }
  async function getSirenDetailsBySiren(name,value,index,type="lagrance"){
   
    const getSiren = setTimeout(async() => {
      
      if(type === "rcs_societe"){        
        setFormData(formData => ({ ...formData, ["denomination_societe"]: "",["forme_juridique_societe"]:"",["capital_societe"]:"",["address_societe"]:"",["code_postal"]:"",["ville"]:"",["ville_id"]:"",["villeArr"]:[],["greffe"]:"" }));   
 
        if(value.length>0){
          let getDetails = await getDetailsBySiren(value);    
          setFormData(formData => ({ ...formData, ["denomination_societe"]:getDetails.denomination,["forme_juridique_societe"]:getDetails.forme_juridique,["capital_societe"]:getDetails.capital_social,["address_societe"]:getDetails.adresse_rcs,["code_postal"]:getDetails.code_postal,["ville"]:getDetails.ville,["ville_id"]:getDetails.ville_id,["villeArr"]:getDetails.cityArr,["greffe"]:getDetails.area }));   
        }
      }
      if(type === "President"){
        let values = [...Les_president];
        // values[index]["personne_morale_rcs"] =value;
        values[index]["personne_morale_denomination"] = "";
        values[index]["personne_morale_forme_juridique"] = "";
        values[index]["personne_morale_capital"] = "";
        values[index]["adresse_physique"] = "";
        values[index]["code_postal_physique"] = "";
        values[index]["ville_physique"] = "";
        values[index]["personne_morale_greffe"] = "";
        setLes_president(values);
        setFormData(formData => ({ ...formData, ["Les_president"]: values }));
        if(value.length>0){
          let getDetails = await getDetailsBySiren(value);    
          let values = [...Les_president];
         
          // values[index]["personne_morale_rcs"] =value;
          values[index]["personne_morale_denomination"] =getDetails.denomination;
          values[index]["personne_morale_forme_juridique"] = getDetails.forme_juridique;
          values[index]["personne_morale_capital"] = getDetails.capital_social;
          values[index]["adresse_physique"] = getDetails.adresse_rcs;
          values[index]["code_postal_physique"] =getDetails.code_postal;
          values[index]["ville_physique"] =getDetails.ville;
          values[index]["personne_morale_greffe"] = getDetails.area;
          setLes_president(values);
          setFormData(formData => ({ ...formData, ["Les_president"]: values })); 
        }

      }
      if(type === "Directeur_general"){
        
        let values = Directeur_generalRef.current; ; 
        // values["rcs_direct"] =value;
        values["denomination_direct"] = "";
        values["forme_juridiquedirect"] = "";
        values["capital_direct"] = "";
        values["adresse_direct"] = "";
        values["code_postal_direct"] = "";
        values["ville_direct"] = "";
        values["ville_direct_id"] = "";
        values["ville_directArr"] =[];
        values["greffe_direct"] = "";
        setDirecteur_general(values);
        setFormData(formData => ({ ...formData, ["Directeur_general"]: values }));     
        if(value.length>0){
          let getDetails = await getDetailsBySiren(value);    
          let values = Directeur_generalRef.current; ; 
          // values["rcs_direct"] =value;     
          values["denomination_direct"] = getDetails.denomination;
          values["forme_juridiquedirect"] = getDetails.forme_juridique;
          values["capital_direct"] = getDetails.capital_social;
          values["adresse_direct"] = getDetails.adresse_rcs;
          values["code_postal_direct"] = getDetails.code_postal;
          values["ville_direct_id"] =getDetails.ville_id;
          values["ville_directArr"] =getDetails.cityArr;
          values["ville_direct"] = getDetails.ville;
          values["greffe_direct"] = getDetails.area;
          setDirecteur_general(values);
          setFormData(formData => ({ ...formData, ["Directeur_general"]: values }));   
        }
      }
      if(type === "CAC_titulaire"){ 
        
        let values = {...CommissaireAuxComptes};             
        // values[index]["rcs_titulaire"] =value;
        values["CAC_titulaire"]["denomination_titulaire"] = "";
        values["CAC_titulaire"]["forme_juridique_titulaire"] = "";
        values["CAC_titulaire"]["capital_titulaire"] = "";
        values["CAC_titulaire"]["adresse_titulaire"] = "";
        values["CAC_titulaire"]["code_postal_titulaire"] = "";
        values["CAC_titulaire"]["ville_titulaire"] = "";
        values["CAC_titulaire"]["ville_titulaireArr"] =[];
        values["CAC_titulaire"]["ville_id_titulaire"] = "";
        values["CAC_titulaire"]["greffe_titulaire"] = "";        
        setCommissaireAuxComptes(values);
        setFormData(formData => ({ ...formData, ["CommissaireAuxComptes"]: values }));
        if(value.length>0){
          let getDetails = await getDetailsBySiren(value);    
          let values = {...CommissaireAuxComptes}          
          // values[index]["rcs_titulaire"] =value;
          values["CAC_titulaire"]["denomination_titulaire"] =getDetails.denomination;
          values["CAC_titulaire"]["forme_juridique_titulaire"] = getDetails.forme_juridique;
          values["CAC_titulaire"]["capital_titulaire"] = getDetails.capital_social;
          values["CAC_titulaire"]["adresse_titulaire"] = getDetails.adresse_rcs;
          values["CAC_titulaire"]["code_postal_titulaire"] =getDetails.code_postal;
          values["CAC_titulaire"]["ville_titulaire"] =getDetails.ville;
          values["CAC_titulaire"]["ville_titulaireArr"] =getDetails.cityArr;
          values["CAC_titulaire"]["ville_id_titulaire"] = getDetails.ville_id;
          values["CAC_titulaire"]["greffe_titulaire"] = getDetails.area;
          setCommissaireAuxComptes(values);
          setFormData(formData => ({ ...formData, ["CommissaireAuxComptes"]: values })); 
        }
      }
      if(type === "CAC_suppleant"){ 
     
        let values = {...CommissaireAuxComptes};             
        // values["CAC_suppleant"]["rcs_suppleant"] =value;
        values["CAC_suppleant"]["denomination_suppleant"] = "";
        values["CAC_suppleant"]["forme_juridique_suppleant"] = "";
        values["CAC_suppleant"]["capital_suppleant"] = "";
        values["CAC_suppleant"]["adresse_suppleant"] = "";
        values["CAC_suppleant"]["code_postal_suppleant"] = "";
        values["CAC_suppleant"]["ville_suppleant"] = "";
        values["CAC_suppleant"]["greffe_suppleant"] = "";     
        values["CAC_suppleant"]["ville_suppleantArr"] =[];
        values["CAC_suppleant"]["ville_id_suppleant"] = "";     
        setCommissaireAuxComptes(values);
        setFormData(formData => ({ ...formData, ["CommissaireAuxComptes"]: values }));
        if(value.length>0){
          let getDetails = await getDetailsBySiren(value);    
          let values ={...CommissaireAuxComptes}         
          // values["CAC_suppleant"]["rcs_suppleant"] =value;
          values["CAC_suppleant"]["denomination_suppleant"] =getDetails.denomination;
          values["CAC_suppleant"]["forme_juridique_suppleant"] = getDetails.forme_juridique;
          values["CAC_suppleant"]["capital_suppleant"] = getDetails.capital_social;
          values["CAC_suppleant"]["adresse_suppleant"] = getDetails.adresse_rcs;
          values["CAC_suppleant"]["code_postal_suppleant"] =getDetails.code_postal;
          values["CAC_suppleant"]["ville_suppleant"] =getDetails.ville;
          values["CAC_suppleant"]["greffe_suppleant"] = getDetails.area;
          values["CAC_suppleant"]["ville_suppleantArr"] =getDetails.cityArr;
          values["CAC_suppleant"]["ville_id_suppleant"] = getDetails.ville_id;
          setCommissaireAuxComptes(values);
          setFormData(formData => ({ ...formData, ["CommissaireAuxComptes"]: values })); 
        }
      }
     
    }, 1000);
    return () => clearTimeout(getSiren);
  }
  async function getCityFromPostal(name,value,index,type){
    const getCity = setTimeout(async() => {
      if(type === "President"){
        let values = [...Les_president];     
        values[index]["ville_physique"] = "";
        values[index]["ville_physiqueArr"] = [];
        values[index]["ville_physique_id"] = "";
        values[index]["personne_morale_greffe"] = "";
        setLes_president(values);
        setFormData(formData => ({ ...formData, ["Les_president"]: values }));     
        if(value.length>0){
          const response = await axios.get(`${process.env.REACT_APP_API_URL}api/checkzipcode/${value}`);
          if( response.data &&  response.data.data){          
            values[index]["ville_physique"] = response.data.data.city;
            values[index]["ville_physiqueArr"] = response.data.data.cityArr;
            values[index]["ville_physique_id"] = response.data.data.id;
            values[index]["personne_morale_greffe"] = response.data.data.area;
            setLes_president(values);
            setFormData(formData => ({ ...formData, ["Les_president"]: values }));
          }
        }  
      }

      
     
          
    }, 1000);
    return () => clearTimeout(getCity);
  }
  async function getCityFromPostalType(name,value,index,type){   
    const getCity = setTimeout(async() => {
      if(type === "CAC_titulaire"){
        let values = {...CommissaireAuxComptes};
        values.CAC_titulaire.ville_titulaire = "";
        values.CAC_titulaire.ville_titulaireArr = [];
        values.CAC_titulaire.ville_id_titulaire = "";
        values.CAC_titulaire.greffe_titulaire = "";
       
        setCommissaireAuxComptes(values);
        setFormData(formData => ({ ...formData, ["CommissaireAuxComptes"]: values }));     
        if(value.length>0){
          const response = await axios.get(`${process.env.REACT_APP_API_URL}api/checkzipcode/${value}`);
          if( response.data &&  response.data.data){  
            values.CAC_titulaire.ville_titulaire = response.data.data.city;
            values.CAC_titulaire.ville_titulaireArr = response.data.data.cityArr;
            values.CAC_titulaire.ville_id_titulaire =response.data.data.id;
            values.CAC_titulaire.greffe_titulaire =response.data.data.area;    
            setCommissaireAuxComptes(values);
            setFormData(formData => ({ ...formData, ["CommissaireAuxComptes"]: values }));
          }
        } 
      }
      if(type === "CAC_suppleant"){
        let values = {...CommissaireAuxComptes};
        values.CAC_suppleant.ville_suppleant = "";
        values.CAC_suppleant.ville_suppleantArr =[];
        values.CAC_suppleant.ville_id_suppleant = "";
        values.CAC_suppleant.greffe_suppleant = "";
        setCommissaireAuxComptes(values);
        setFormData(formData => ({ ...formData, ["CommissaireAuxComptes"]: values }));     
        if(value.length>0){
          const response = await axios.get(`${process.env.REACT_APP_API_URL}api/checkzipcode/${value}`);
          if( response.data &&  response.data.data){  
            values.CAC_suppleant.ville_suppleant = response.data.data.city;
            values.CAC_suppleant.ville_suppleantArr = response.data.data.cityArr;
            values.CAC_suppleant.ville_id_suppleant =response.data.data.id;  
            values.CAC_suppleant.greffe_suppleant =response.data.data.area;  
            setCommissaireAuxComptes(values);
            setFormData(formData => ({ ...formData, ["CommissaireAuxComptes"]: values }));
          }
        }  
      }
      if(type === "Directeur_general"){
        let values = Directeur_generalRef.current; ;
        // values.code_postal_direct =value;
        values.ville_direct = "";
        values.ville_directArr = [];
        values.ville_direct_id = "";
        values.greffe_direct = "";
        setDirecteur_general(values);
        setFormData(formData => ({ ...formData, ["Directeur_general"]: values }));     
        if(value.length>0){
          const response = await axios.get(`${process.env.REACT_APP_API_URL}api/checkzipcode/${value}`);
          if( response.data &&  response.data.data){  
            // values.code_postal_direct =value;
            values.ville_directArr = response.data.data.cityArr;
            values.ville_direct = response.data.data.city;
            values.ville_direct_id =response.data.data.id;
            values.greffe_direct =response.data.data.area;    
            setDirecteur_general(values);
            setFormData(formData => ({ ...formData, ["Directeur_general"]: values }));
          }
        }  
      }
       
    }, 1000);
    return () => clearTimeout(getCity);
  }
 
  async function handleChange(e) {
    // // // console.log("e",e)
    const { name, value } = e.target;  
    if(name == "ville_id"){
      let formDataGet = {...formData};
      let cityArrFilter = formDataGet.villeArr.filter((val,i)=>val.id==value);
      setFormData(formData => ({ ...formData, ["ville"]: cityArrFilter[0].city,["ville_id"]: value }));

    }else{
      setFormData(formData => ({ ...formData, [name]: value }));
    } 
    if(name === "code_postal_nouvelle"){    
      await getCityFromPostalType(name,value); 
    }
    if(name === "rcs_societe"){
      await getSirenDetailsBySiren(name,value,0,"rcs_societe");
    }
    
  }

  function handleChangeDate(e,name) {
    let newDate = moment(e.$d).format('YYYY/MM/DD');
    setFormData(formData => ({ ...formData, [name]: newDate }));
  }
  
  async function submitPaymentDetails() { 
    let valid  = false;
    // setSubmitted(true);
    setLoading(true);
    delete paymentData['data']['billing_address_json'];
    delete paymentData['data']['currentUserDetails'];
    delete paymentData['data']['select_cartdetailJ'];
    delete paymentData['data']['select_cartdetailN'];
    delete paymentData['data']['select_department'];
    delete paymentData['data']['votre_annonce_json'];
    delete paymentData['data']['votre_annonce'];
    delete paymentData['data']['billing_address_json_parse'];
    delete paymentData['data']['user_detail'];
    delete paymentData['data']['votre_html'];
    
    let getResponse =  await dispatch(announceActions.updatePayment({announce_id:id,user_id:currentUser && currentUser.data?currentUser.data.id:"",serenite:isCheckedSerenity,paymentData:paymentData})).then((responseData) => {         
      // // // console.log("responseData",responseData)        
      return Promise.resolve(responseData);   
      
    })
    .catch((err) => {         
      return Promise.reject({
          "sucess": false,
          "msg": "Une erreur s'est produite. Veuillez réessayer!",
          "data": "",
          "insertID": ""
      });      
    });
    // setSuccessful(true);
    // // // console.log("getResponse",getResponse);
    setLoading(false);
    if(getResponse.sucess === true){        
      valid = true
    }else{
      valid = false
    }
    return valid;
  }

  async function submitUserDetails() { 
    
    let valid  = false;
    // setSubmitted(true);
    if(userSelectedAddress.email && isValidEmail(userSelectedAddress.email) && userSelectedAddress.telephone && isNumericValue(userSelectedAddress.telephone)  && ((userSelectedAddress.billing_address_type === "account_address" && userSelectedAddress.billing_address_nom  && userSelectedAddress.billing_address_prenom) || (userSelectedAddress.billing_address_type === "another_address"  && userSelectedAddress.billing_address_nom  && userSelectedAddress.billing_address_prenom   && userSelectedAddress.billing_address_code_postal  && userSelectedAddress.billing_address_ville) || ( userSelectedAddress.billing_address_type === "customer_address" && (userSelectedAddress.billing_address_denomination  ||  userSelectedAddress.billing_address_address  || userSelectedAddress.billing_address_code_postal  || userSelectedAddress.billing_address_ville)) )  ){
      setLoading(true);
      let getResponse =  await dispatch(announceActions.update_user(userSelectedAddress)).then((responseData) => { 
        return Promise.resolve(responseData); 
      })
      .catch((err) => { 
        setLoading(false);        
        return Promise.reject({
            "sucess": false,
            "msg": "Une erreur s'est produite. Veuillez réessayer!",
            "data": "",
            "insertID": ""
        });      
      });
      // setSuccessful(true);
      // // // console.log("getResponse",getResponse);
      setLoading(false); 
      if(getResponse.sucess === true){   
           
        valid = true
      }else{
        valid = false
      }
    }
    
    return valid;
  }
  async function handleSubmit() { 
    let valid  = false;  
    setSuccessful(false);
    setSubmitted(true);
    setSubmitted(true);
    // // // console.log("formData",formData)
    

    if(formData.rcs_societe && formData.denomination_societe && formData.forme_juridique_societe && formData.capital_societe && formData.address_societe  && formData.code_postal  && formData.ville  && formData.greffe && formData.type_acte_Transformation &&  formData.type_acte && formData.datelagge && formData.date_effect  && (formData.Les_president.length >0 && formData.Les_president[0].type_personne) && ((formData.Directeur_general.show_data_direct  === "Non" )|| (formData.Directeur_general.show_data_direct ==="Oui" && formData.Directeur_general.type_personne_direct)) && ((formData.CommissaireAuxComptes.show_data_commissaire  === "Non") || (formData.CommissaireAuxComptes.show_data_commissaire === "Oui" && (formData.CommissaireAuxComptes.CAC_titulaire.type_personne_titulaire) && (formData.CommissaireAuxComptes.CAC_suppleant.type_personne_suppleant) ))) {  
      // // console.log("formData",formData)

      let checkLes_president = await Promise.all(formData.Les_president.map((val,key)=>{
        var status = false;
        if( val.type_personne==="PERSONNE_PHYSIQUE" && val.civilite_physique  && val.nom_physique && val.prenom_physique && val.adresse_physique && ((val.resident_etranger =="Non" && val.code_postal_physique && val.ville_physique)|| (val.resident_etranger =="Oui" && val.pays_physique && val.ville_physique)) ){
          status= true;
        }else if( val.type_personne==="PERSONNE_MORALE" && val.personne_morale_rcs  && val.personne_morale_denomination  && val.personne_morale_forme_juridique  && val.personne_morale_capital && val.adresse_physique && val.code_postal_physique && val.ville_physique && val.personne_morale_greffe   && val.personne_morale_representation_nom && val.personne_morale_representation_prenom ){
          status= true;
        }        
        return status;
      }));  
          
      valid= !checkLes_president.includes(false);

      if(formData.Directeur_general.show_data_direct ==="Oui" && (formData.Directeur_general.type_personne_direct==="PERSONNE_PHYSIQUE" && (!formData.Directeur_general.civilite_direct_physique || !formData.Directeur_general.nom_direct_physique  || !formData.Directeur_general.prenom_direct_physique  || !formData.Directeur_general.adresse_direct  || !formData.Directeur_general.code_postal_direct || !formData.Directeur_general.ville_direct) || (formData.Directeur_general.type_personne_direct==="PERSONNE_MORALE" && (!formData.Directeur_general.rcs_direct || !formData.Directeur_general.denomination_direct  || !formData.Directeur_general.forme_juridiquedirect || !formData.Directeur_general.capital_direct || !formData.Directeur_general.adresse_direct  || !formData.Directeur_general.code_postal_direct || !formData.Directeur_general.ville_direct || !formData.Directeur_general.greffe_direct)) ) ){
        valid= false;        
      }

      if(formData.CommissaireAuxComptes.show_data_commissaire === "Oui" && (formData.CommissaireAuxComptes.CAC_titulaire.type_personne_titulaire && ((formData.CommissaireAuxComptes.CAC_titulaire.type_personne_titulaire === "PERSONNE_PHYSIQUE" && (!formData.CommissaireAuxComptes.CAC_titulaire.civilite_titulaire_physique || !formData.CommissaireAuxComptes.CAC_titulaire.nom_titulaire_physique  || !formData.CommissaireAuxComptes.CAC_titulaire.prenom_titulaire_physique ||!formData.CommissaireAuxComptes.CAC_titulaire.adresse_titulaire  || !formData.CommissaireAuxComptes.CAC_titulaire.code_postal_titulaire || !formData.CommissaireAuxComptes.CAC_titulaire.ville_titulaire)) || (formData.CommissaireAuxComptes.CAC_titulaire.type_personne_titulaire === "PERSONNE_MORALE" && (!formData.CommissaireAuxComptes.CAC_titulaire.rcs_titulaire || !formData.CommissaireAuxComptes.CAC_titulaire.denomination_titulaire || !formData.CommissaireAuxComptes.CAC_titulaire.forme_juridique_titulaire  || !formData.CommissaireAuxComptes.CAC_titulaire.capital_titulaire || !formData.CommissaireAuxComptes.CAC_titulaire.adresse_titulaire || !formData.CommissaireAuxComptes.CAC_titulaire.code_postal_titulaire || !formData.CommissaireAuxComptes.CAC_titulaire.ville_titulaire)))) ){  
            valid= false;
      }
      if(formData.CommissaireAuxComptes.show_data_commissaire === "Oui" && (formData.CommissaireAuxComptes.CAC_suppleant.type_personne_suppleant && ((formData.CommissaireAuxComptes.CAC_suppleant.type_personne_suppleant === "PERSONNE_PHYSIQUE" && (!formData.CommissaireAuxComptes.CAC_suppleant.civilite_suppleant_physique || !formData.CommissaireAuxComptes.CAC_suppleant.nom_suppleant_physique || !formData.CommissaireAuxComptes.CAC_suppleant.prenom_suppleant_physique || !formData.CommissaireAuxComptes.CAC_suppleant.adresse_suppleant  || !formData.CommissaireAuxComptes.CAC_suppleant.code_postal_suppleant || !formData.CommissaireAuxComptes.CAC_suppleant.ville_suppleant)) || (formData.CommissaireAuxComptes.CAC_suppleant.type_personne_suppleant === "PERSONNE_MORALE" && (!formData.CommissaireAuxComptes.CAC_suppleant.rcs_suppleant || !formData.CommissaireAuxComptes.CAC_suppleant.denomination_suppleant  || !formData.CommissaireAuxComptes.CAC_suppleant.forme_juridique_suppleant || !formData.CommissaireAuxComptes.CAC_suppleant.capital_suppleant || !formData.CommissaireAuxComptes.CAC_suppleant.adresse_suppleant  || !formData.CommissaireAuxComptes.CAC_suppleant.code_postal_suppleant || !formData.CommissaireAuxComptes.CAC_suppleant.ville_suppleant)))) ){  
        valid= false;
      }

    } 
    /* // // console.log("valid",valid)
    return false;  */  
    if(valid === true){
      setLoading(true);
      // // // console.log("formData",formData)
      let getResponse =  await dispatch(announceActions.create(formData)).then((responseData) => { 
        announceIdRef.current = responseData.announce_id;         
        // // // console.log("responseData",responseData)        
        return Promise.resolve(responseData);   
        
      })
      .catch((err) => { 
        // // // console.log("responseData err",err) 
        setLoading(false);          
        return Promise.resolve({
            "sucess": false,
            "msg": "Une erreur s'est produite. Veuillez réessayer!",
            "data": "",
            "insertID": ""
        });      
      });
      setSuccessful(true);
      if(getResponse.sucess === true){        
        valid = true
      }else{
        valid = false
      }
    }
    
    return valid;
   
}
  function setPaymentDataFunc(data){
    setPaymentData(data);
  }

  const handlePayment =async () => {    
    if(!currentUser){
      navigate("/login");
    }
  };
  function getStepContent(step) {
    switch (step) {
      case 0: 
      return <TransformationSaEnSas adsname={adsname.toUpperCase()} formData={formData} handleChange={handleChange} handleChangeDate={handleChangeDate} isSubmitted={isSubmitted}  Les_president={Les_president} Directeur_general={Directeur_general}  CommissaireAuxComptes={CommissaireAuxComptes} Transmission_des_actions={Transmission_des_actions} Admission_assemblees={Admission_assemblees}  handleChangeAddForm={handleChangeAddForm}   />;        
      case 1:
        return <FormSummary  handleBack={handleBack} adsname={adsname.toUpperCase()}  />;
      case 2:
        return <CompleteAddress setUserSelectedAddress={setUserSelectedAddress} userSelectedAddress={userSelectedAddress}   adsname={adsname.toUpperCase()}  />;
      case 3:
        return <PaymentForm setPaymentDataFunc={setPaymentDataFunc} isCheckedSerenity={isCheckedSerenity} setIscheckedSerenity={setIscheckedSerenity} setis_general_conditions_sale={setis_general_conditions_sale} is_general_conditions_sale={is_general_conditions_sale} general_conditions_sale_msg={general_conditions_sale_msg} adsname={adsname.toUpperCase()} />;
      case 4:
       /*  return announceDetails&&announceDetails.data&&parseInt(announceDetails.data.status)===3?<FormSuccess announceDetails={announceDetails} />: activeStep===4?<Elements options={options} stripe={stripePromise}><PaymentSecond ref={childRef} payamentPrice={payamentPrice} handlePayment={handlePayment}  adsname={adsname.toUpperCase()} /> </Elements>:<PaymentSecond ref={childRef} payamentPrice={payamentPrice} handlePayment={handlePayment}  adsname={adsname.toUpperCase()} />; */
        return activeStep===4?<Elements options={options} stripe={stripePromise}><PaymentSecond ref={childRef} payamentPrice={payamentPrice} handlePayment={handlePayment} setLoading={setLoading}  adsname={adsname.toUpperCase()} /> </Elements>:<PaymentSecond ref={childRef} payamentPrice={payamentPrice} handlePayment={handlePayment} setLoading={setLoading} adsname={adsname.toUpperCase()} />;

      default:
        return 'Unknown step';
    }
  }
  
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
 

  const handleSaveAnnonce = async () => {
    setSuccessful(false)
    setLoadingFullpage(true);
    localStorage.setItem("activeStep",activeStep);
    if(!currentUser){
      navigate("/login");
      return;
    }
    try {
      const requestData = {
        announce_id: id,
        user_id: currentUser && currentUser.data?currentUser.data.id:"",
      };
      let post_url = `${process.env.REACT_APP_API_URL}api/announce/save_announce`;

      // Use Axios to make the API request
      const response = await axios.post(post_url, requestData, {
        headers: {
            'Content-Type': 'application/json',
        },
      });
      setLoadingFullpage(false);
      setSuccessful(true)
      if(response.data.sucess === true){
        navigate("/userdashboard/annonces-en-attente");
      }else{
        dispatch(alertActions.error("Une erreur s'est produite. Veuillez réessayer!")); 
      }
      
      // return {success:true,data:response.data}
    } catch (error) {
      setLoadingFullpage(false);
      setSuccessful(true)
      dispatch(alertActions.error("Une erreur s'est produite. Veuillez réessayer!"));
      // // console.log(error); 
      // return {success:false,data:""}     
    }
  }
  const handleNext = async (activeStepGet) => {
    // // console.log("activeStepGet",activeStepGet)
    
    let submitResult = true;
    if(!currentUser && activeStepGet !== 0 ){
      navigate("/login");
    }
    if(activeStepGet === 0){
      submitResult = await handleSubmit();
      // // console.log("submitResult",submitResult)
      
    }
    if(activeStepGet === 2){
      submitResult = await submitUserDetails();

    }
    if(activeStepGet === 3){
      setgeneral_conditions_sale_msg("");
      if(!is_general_conditions_sale){
        setgeneral_conditions_sale_msg("Veuillez accepter les conditions générales de vente")
        return false;
      }
      submitResult = await submitPaymentDetails();

    }
    if(submitResult){
      setLoading(false);     
      if(currentUser && currentUser.UserProDetails && currentUser.UserProDetails.details && currentUser.UserProDetails.details.payment_plan && currentUser.UserProDetails.details.payment_plan.includes("monthly") && activeStepGet === 3){
        navigate(location.pathname+"?activstep=5");

      }else{
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);  
        if(location.pathname.split('/').length ===4){
          navigate(location.pathname+"/"+announceIdRef.current);
        }
      }
    }else{
      // let newSkipped = skipped;
      // if (isStepSkipped(activeStep)) {
      //   newSkipped = new Set(newSkipped.values());
      //   newSkipped.delete(activeStep);
      // }
      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
      // setSkipped(newSkipped);
    }
    
  };
  const handleBack = () => {
    if(id || announceData.announce_id){
      // // // console.log("location",location);
      // // // console.log("location.pathname",location.pathname.split('/'));  
      setActiveStep((prevActiveStep) => prevActiveStep - 1);   
      if(location.pathname.split('/').length ===4){
        navigate(location.pathname+"/"+announceData.announce_id);
      }
      

    }else{
      setActiveStep((prevActiveStep) => prevActiveStep - 1);

    }
  };
  

  
async function updateAnnouncePayment(bodyData){
  setLoadingFullpage(true);
  try {
    let getResponse =  await dispatch(announceActions.updateSuccessPayment(bodyData)).then((responseData) => {       
      
      if(responseData.sucess == true){
        setAnnounceDetails(responseData);
        let announce = {
          "data":responseData.data,
          "announce_id":bodyData.announce_id 
        }
        dispatch({ type: announceConstants.CREATE_SUCCESS, announce});
      }
      setLoadingFullpage(false);      
      return Promise.resolve(responseData);   
      
    })
    .catch((err) => {
      setLoadingFullpage(false);           
      return Promise.reject({
          "sucess": false,
          "msg": "Une erreur s'est produite. Veuillez réessayer!",
          "data": "",
          "insertID": ""
      });      
    });
   /* return {success:true,data:response.data} */ 
  } catch (error) {
    // // console.log(error); 
    return {success:false,data:""}     
  }
}
  
async function getAnnounceDetails(announce_id){
  setLoadingFullpage(true);
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}api/announce/announce/${announce_id}`);
    
    if(response.data.sucess === true){
       if(parseInt(response.data.data.status) === 3 || (response.data.data.length<=0)){
       navigate("/");
      }
      setAnnounceDetails(response.data);
    }
    setLoadingFullpage(false);
    return {success:true,data:response.data}
  } catch (error) {
    setLoadingFullpage(false);
    // // console.log(error); 
    return {success:false,data:""}     
  }
}
const handleDetailsBySiren=async(sirenNo)=>{
  let response =  await getDetailsBySiren(sirenNo);
}
  
  return (
    <>
     {loadingFullpage?
      <PageLoaderComp />
      :""}
    
     {successful?
      <ToastMessageComp  message={alert.message} type={alert.type} />
      :""}
      
      <div className='bg-mainBg'>
        <div className='md:max-w-[1477px] relative w-full h-full m-auto py-7 px-4 sm:px-14 myForm'>
          <Breadcrumb Accueil="Accueil" page={(ads.split("-")).join(" ")} />
            
          <div className='relative  bg-mainBg my-4 sm:my-12 '>
            <div className='grid grid-cols-12 gap-5  '>
              <div className='mb-8 col-span-12 2lg:col-span-8'>
                <div className='hidden sm:inline-flex bg-white  mb-6 items-center justify-between w-full max-w-[974px] h-[81px] rounded-md xl:px-12  '>
                  <Stepper activeStep={queryParameters.get("activstep")?queryParameters.get("activstep"):activeStep} className='w-full  '>
                    {steps.map((label, index) => {
                      return (
                        <Step key={label}  >
                          <StepLabel>
                            <div className='inline-flex   items-center justify-between w-full'> 
                              <div className='max-w-[118px] text-black-light font-normal'>{label}</div>
                            </div>
                          </StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </div>
                
                <div className='bg-white max-w-[974px] rounded-xl  ' style={{ border: '0.5px solid #DEE3E2' }}>
                  {queryParameters.get("activstep") && parseInt(queryParameters.get("activstep")) === steps.length ? (
                    <>
                      <Typography sx={{ mt: 2, mb: 1 }}>
                        <FormSuccess announceDetails={announceDetails} loadingFullpage={loadingFullpage} />
                      </Typography>
                      
                    </>
                  ) : (
                    <>
                      <Typography  >{getStepContent(activeStep)}</Typography>
                      {
                       <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>                     
                      <button onClick={handleBack} className={activeStep === 0 ?"hidden":"w-48 h-12 m-4 rounded-md bg-thColor font-extrabold text-base sm:text-lg 3xl:text-xl text-white transition-all duration-300 hover:bg-secondary hover:btnShadow"} >
                      Précédent
                      </button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {/* onClick={() => childRef.current.getAlert()} */}
                      {/* handlePayment() */}
                      <button onClick={handleSaveAnnonce} className={activeStep === 1 ?"w-48 h-12 m-4 rounded-md bg-secondary font-extrabold text-base sm:text-lg 3xl:text-xl text-white transition-all duration-300 hover:bg-thColor hover:btnShadow":"hidden"} >
                        Enregistrer
                      </button>
                      <button disabled={loading?true:false} style={{"opacity":loading?"0.7":""}} onClick={()=>activeStep === steps.length - 1 ? childRef.current.handleSubmitCheckout():handleNext(activeStep)} className='w-48 h-12 m-4 rounded-md bg-thColor font-extrabold text-base sm:text-lg 3xl:text-xl text-white transition-all duration-300 hover:bg-secondary hover:btnShadow'>
                          {activeStep === steps.length - 1 ? `Payer ${payamentPrice} €` : activeStep ===0 ?'Obtenir le tarif':'Etape suivante '} 
                          {
                            loading?                         
                            <CircularProgress 
                            size={24}
                            sx={{
                              color: "white",
                              // marginTop: '-10px',
                              /* position: 'absolute',
                              top: '50%',
                              left: '50%',
                              
                              marginLeft: '-12px', */
                            }} />
                        :""}
                      </button>
                    </Box>
                    }
                    </>
                  )}
                </div>
              </div>
              <div className=' col-span-12 2lg:col-span-4  '>
                <Procedures />
                <Express />
                <PaymentSideBlock />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div >
    </>
  );
}


