import React, { useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Facebook01 from '../assets/Social/Facebook01.svg'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import Footer from '../component/Footer'
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Checkbox, FormControlLabel, FormHelperText } from '@mui/material'
// import { Modal } from '@mui/material'
// import messageSuccess from '../assets/messageSuccess.svg'
import ToastMessageComp from '../component/ToastMessageComp';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions, userActions } from '../_actions';
import SocialLoginComp from '../component/SocialLoginComp'

export default function ForgotPassword() {
  let navigate = useNavigate();
  const location = useLocation();
  const alert = useSelector(state => state.alert);
  const dispatch = useDispatch();

  const [user, setUser] = useState({
    email: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [successful, setSuccessful] = useState(false);

  function handleChange(e) {
    const { name, value } = e.target;

    setUser(user => ({ ...user, [name]: value }));
  }
  async function handleSubmit(e) {
    e.preventDefault();
    setSuccessful(false);
    setSubmitted(true);
    if (user.email) {
      dispatch(userActions.forgot_password(user.email)).then(() => {
        setSuccessful(true);
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      })
        .catch(() => {
          setSuccessful(true);
        });
    }
  }


  return (
    <>
      {successful ?
        <ToastMessageComp message={alert.message} type={alert.type} />
        : ""}
      <form className='mt-5 sm:mt-8 3xl:mt-12' onSubmit={handleSubmit}>
        <div className='bg-[#F5F5F5] w-full py-7 3xl:py-12'>
          <div className='md:max-w-[1477px] relative w-full h-full max-h-[684px] flex items-center justify-center m-auto  '>

            <div className='max-w-[640px] 3xl:max-w-[720px]  w-full h-full bg-white px-5 sm:px-12 py-2 sm:py-5 3xl:p-12 ' style={{ boxShadow: "0px 4px 10px 1px rgba(39, 41, 91, 0.35)", borderRadius: "33px" }}>
              <div className='flex items-center px-2 justify-around gap-14 py-2 3xl:py-4'>

                <h3 className='text-2xl sm:text-3xl 3xl:text-4xl font-semibold border-b-[6px] border-primary py-4 text-primary '>Mot de passe oublié</h3>

              </div>

              <div className='my-4 block'>
                <TextField
                  // disableUnderline
                  sx={{
                    "& .MuiInputLabel-root": { color: '#797979', },
                    "& fieldset": { border: '1px solid #eee' },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '8px',
                      color: '#797979',
                      background: 'rgb(39 41 91 / 6%)',
                    }
                  }
                  }
                  className='w-full'
                  label="Email" variant="outlined" type="email" error={submitted && !user.email ? true : false} name="email" value={user.email} onChange={handleChange} />


              </div>

              <div className='flex gap-4 sm:gap-auto justify-between  sm:items-center sm:flex-row flex-col mb-5 mt-8 sm:mt-0'>
                {/*  <div className='flex items-center'>
                    <label className='flex items-center gap-2'>
                      <input className=" w-5 h-5 rounded-2xl block border-black-light" type="checkbox"  />
                      <span className="text-base text-black-light font-medium">
                        Rester connecté
                      </span>
                    </label>
                  </div> */}
                <span className="text-base text-black-light font-medium">
                  Retour connexion?
                </span>
                <div>
                  <Link className='text-black-light text-base font-medium underline hover:text-secondary' to="/login">Se connecter</Link>
                </div>
              </div>

              <button type='submit' className='bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white  mt-4 sm:mt-4
                3xl:mt-10 px-6 w-full py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white'>Mot de passe oublié</button>


            </div>
          </div>
        </div>
      </form>
      {/* <Modal
        className='z-50 max-w-[600px] bg-white 3xl:max-w-[656px]w-full max-h-[350px] 3xl:max-h-[372px] m-auto flex justify-center items-center px-5'
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className='bg-white w-full h-full p-4 rounded-[33px] flex justify-center items-center '>
          <div className='bg-white'>
            <img src={messageSuccess} alt="messageSuccess" className='text-center m-auto' />
            <h4 className='font-semibold text-xl 3xl:text-2xl text-primary py-4 text-center'>Confirmez votre adresse e-mail</h4>
            <p className='group font-normal tetx-base text-primary mt-2 3xl:mt-4'>Un e-mail vient de vous être envoyé sur <Link to="mailto: hugo.delpierre@gmail.com" className='font-semibold gropu-hover:text-secondary'>hugo.delpierre@gmail.com</Link> </p>
            <p className='font-normal tetx-base text-primary'>Consultez votre messagerie et vérifiez votre dossier “spam”.</p>
            <p className='font-normal tetx-base text-primary'>Vous pourrez confirmer votre adresse e-mail en cliquant sur le bouton.</p>

            <p className='text-base font-normal text-black-light mt-6 flex flex-col justify-center items-center sm:flex-row'>Vous n’avez pas reçu d’e-mail ? <Link to="" className='underline'> Contactez l’équipe Legals</Link></p>
          </div>
        </div>
      </Modal> */}
      <Footer />
    </>
  )
}
