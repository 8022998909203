import React from 'react'

import { TextField, Box, Stack, Typography } from '@mui/material'
 
import { Link } from 'react-router-dom'
import FridLogo from '../assets/FridLogo.png'
export default function Registration() {
  return (
    <>
      <div className='w-[600px] mx-auto'>
        <div className='flex flex-col sm:flex-row items-center gap-4 mb-4'>
          <img src={FridLogo} alt="FridLogo" />
          <div>
            <h4 className='font-bold text-lg 3xl:text-xl text-black'>Fiduciaire de Paris</h4>
            <h6 className='text-base font-semibold text-black'>Cabinet comptable</h6>
            <p className='text-base font-light text-black'>Expert-comptable -  Commissaire aux comptes</p>
            <div className='flex items-center gap-4'>
              <p className='text-[12px] font-normal text-black'>N° Ordre des Experts-Comptables </p>
              <p className='text-[12px] font-normal text-black'>14-01305000-01</p>
            </div>
            <div className='flex items-center gap-4'>
              <p className='text-[12px] font-normal text-black'>N° Compagnie des Commissaires aux Comptes </p>
              <p className='text-[12px] font-normal text-black'>66005840</p>
            </div>
          </div>
        </div>

        <Typography variant='h5' sx={{ fontWeight: '600', color: '#414141' }}>Informations personnelles</Typography>
        <Typography variant='body1' sx={{ fontWeight: '300', color: '#414141' }}>Pour des raisons de transparence, vos données seront envoyées au professionnel. Vos données ne seront pas utilisées sans votre consentement.</Typography>
        <Box component="form" className='mt-5 sm:mt-8 3xl:mt-12'
          sx={{
            "& .MuiInputLabel-root": { color: '#797979', },
            "& fieldset": {
              border: '1px solid #eee', borderRadius: '5px',
              color: '#797979',
              background: 'rgb(39 41 91 / 6%)',
            },
          }}
        >
          <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
            <label className='col-span-12 text-base font-medium text-gray mb-1'>Société</label>
            <div className='col-span-12'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full outline-secondary focus:outline-secondary'
              />
            </div>
          </div>
          <div className='grid grid-cols-12 sm:gap-8 items-center '>
            <div className='col-span-12 sm:col-span-6 sm:grid sm:grid-cols-12 items-cente mt-3 sm:mt-6'>
              <label className='col-span-12 text-base font-medium text-gray mb-1'>Prénom*</label>
              <div className='col-span-12'>
                <TextField
                  hiddenLabel
                  id="outlined-size-small"
                  size="small"
                  className='w-full outline-secondary focus:outline-secondary'
                />
              </div>
            </div>
            <div className='col-span-12 sm:col-span-6 grid grid-cols-12 items-center mt-3 sm:mt-6'>
              <label className='col-span-12 text-base font-medium text-gray mb-1'>Nom* </label>
              <div className='col-span-12'>
                <TextField
                  hiddenLabel
                  id="outlined-size-small"
                  size="small"
                  className='w-full outline-secondary focus:outline-secondary'
                />
              </div>
            </div>
          </div>
          <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
            <label className='col-span-12 text-base font-medium text-gray mb-1'>Adresse*</label>
            <div className='col-span-12'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full outline-secondary focus:outline-secondary'
              />
            </div>
          </div>

          <div className='grid grid-cols-12 sm:gap-8 items-center '>
            <div className='col-span-12 sm:col-span-4 sm:grid sm:grid-cols-12 items-cente mt-3 sm:mt-6'>
              <label className='col-span-12 text-base font-medium text-gray mb-1'>Code postal*</label>
              <div className='col-span-12'>
                <TextField
                  hiddenLabel
                  id="outlined-size-small"
                  size="small"
                  className='w-full outline-secondary focus:outline-secondary'
                />
              </div>
            </div>
            <div className='col-span-12 sm:col-span-8 grid grid-cols-12 items-center mt-3 sm:mt-6'>
              <label className='col-span-12 text-base font-medium text-gray mb-1'>Ville*</label>
              <div className='col-span-12'>
                <TextField
                  hiddenLabel
                  id="outlined-size-small"
                  size="small"
                  className='w-full outline-secondary focus:outline-secondary'
                />
              </div>
            </div>
          </div>
          <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
            <label className='col-span-12 text-base font-medium text-gray mb-1'>Téléphone</label>
            <div className='col-span-12'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full outline-secondary focus:outline-secondary'
              />
            </div>
          </div>
          <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
            <label className='col-span-12 text-base font-medium text-gray mb-1'>E-mail*</label>
            <div className='col-span-12'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full outline-secondary focus:outline-secondary'
              />
            </div>
          </div>
          <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
            <label className='col-span-12 text-base font-medium text-gray mb-1'>Votre message</label>
            <div className='col-span-12'>
              <TextField

                hiddenLabel
                multiline
                minRows={6}
                className='w-full'
                placeholder='Votre message'
              />
            </div>
          </div>
          <div className='flex items-center  mb-5 mt-8 sm:mt-8'>
            <div className='flex items-center'>
              <label className='flex gap-4'>
                <input className=" w-5 h-5 rounded-4xl block border-black-light" type="checkbox" />
                <span className="text-base  font-normal text-primary">
                  J’accèpte les <Link to="/conditions-generales-vente" className='text-primary font-semibold underline'>conditions générales d’utilisation.</Link>
                </span>
              </label>
            </div>
          </div>

          <Stack alignItems='center' sx={{ mt: 2 }}>
            <Link to='/' type='submit' className='bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white  mt-4 sm:mt-4 3xl:mt-10 px-10   py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white '>Envoyer</Link>
          </Stack>
          <p><small> *Champs obligatoires</small></p>
        </Box>
      </div>
    </>
  )
}
