import { authHeader } from '../_helpers';
import axios from 'axios';
export const userService = {
    login,
    logout,
    register,
    getAll,
    getById,
    update,
    addAddress,
    forgot_password,
    delete: _delete
};
// function logout() {
//     // remove user from local storage to log user out
//     localStorage.removeItem('user');
//     localStorage.removeItem('email');
//     localStorage.removeItem('id');
//     localStorage.removeItem('token');
// }

function logout() {
    try {
      const keysToRemove = ['user_pro', 'email', 'id', 'token'];
  
      keysToRemove.forEach(key => {
        localStorage.removeItem(key);
      });
  
      // Optionally: Add logic to invalidate the user's token on the server side
      // e.g., send a request to a server endpoint to log the user out
  
      // Optionally: Redirect the user to the login page or perform other post-logout actions
    } catch (error) {
      console.error('Error during logout:', error);
      // Optionally: Handle errors gracefully, e.g., show a user-friendly error message
    }
  }
  
async function getAll() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/user`, {
            headers: authHeader()
        });
        const details = await handleResponse(response);
        console.log(details)
        return details;
    } catch (error) {
        // Handle errors, e.g., log them or throw a custom error
        console.error('Error in getAll:', error);
        throw error;
    }
}

async function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const response = await fetch(`/users/${id}`, requestOptions);
    return handleResponse(response);
}

async function addAddress(formData) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };

    const response = await fetch(process.env.REACT_APP_API_URL + `api/user_address`, requestOptions);
    const get_user_address = await handleResponse(response);
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    if (get_user_address.sucess === true) {
        let userData = localStorage.getItem('user_pro');
        let userDataParse = JSON.parse(userData);
        userDataParse.user_address = get_user_address.user_address;
        localStorage.setItem('user_pro', JSON.stringify(userDataParse));
    }

    return get_user_address;
}
async function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    const response = await fetch(process.env.REACT_APP_API_URL + `api/register`, requestOptions);

    if (user.signup_type === "google" || user.signup_type === "facebook") {
        const user = await handleResponse(response);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        if (user.sucess === true) {
            localStorage.setItem('user_pro', JSON.stringify(user));
        }
        return user;
    } else {
        return handleResponse(response);
    }
}

async function update(user) {
    delete user["email"];
    let formData = new FormData();
    // console.log("handleSubmit user update",user)
    Object.keys(user).forEach(fieldName => {
        console.log("fieldName", fieldName)
        if (fieldName === "villeArr") {
            formData.append(fieldName, JSON.stringify(user[fieldName]));
        } else {
            formData.append(fieldName, user[fieldName]);
        }

    });

    const response = await axios.put(process.env.REACT_APP_API_URL + `api/user/${user.id}`, formData, authHeader());

    const user_data = response.data;
    if (localStorage.getItem('user_pro')) {
        let getData = JSON.parse(localStorage.getItem('user_pro'));
        getData.data = user_data.data;
        localStorage.setItem('user_pro', JSON.stringify(getData));
    } else {
        localStorage.setItem('user_pro', JSON.stringify(user_data));
    }
    return user_data;
}

// prefixed function name with underscore because delete is a reserved word in javascript
async function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    const response = await fetch(`/users/${id}`, requestOptions);
    return handleResponse(response);
}

async function forgot_password(email) {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}api/forgotpassword`, {
            email: email,
            forgot_type:"prouser",
        });
        
        const user = await handleResponse(response); // Assuming the user data is in the response data
        return user;
    } catch (error) {
        // Handle errors here
        console.error('Error in forgotPassword:', error.response.data);
        throw error.response.data.msg; // Rethrow the error to be caught by the calling code
    }
}

async function login(userdata) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}api/login`,
            { email: userdata.email, password: userdata.password },
            { headers: { 'Content-Type': 'application/json' } }
        );

        const user = await handleResponse(response);

        // store user details and jwt token in local storage to keep user logged in between page refreshes
        if (user.sucess === true) {
            localStorage.setItem('user_pro', JSON.stringify(user));
            // console.log(object)
            localStorage.setItem('token', user.token)
        }

        if (userdata.remember_password === true) {
            localStorage.setItem('remember_email', userdata.email);
            localStorage.setItem('remember_password', userdata.password);
            localStorage.setItem('is_remember', userdata.remember_password);
        } else {
            localStorage.removeItem('remember_email');
            localStorage.removeItem('remember_password');
            localStorage.removeItem('is_remember');
        }

        return user;
    } catch (error) {
        console.error("Error:", error.response.data.msg);
        // handle error appropriately, e.g., show a message to the user
        return { success: false, message: error.response.data.msg };
    }
}

async function handleResponse(response) {
    console.log(response);

    if (!response) {
        console.log(response)
        console.log(response.status)
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            logout();
            // eslint-disable-next-line no-restricted-globals
            location.reload(true);
        }

        const data = await response;
        console.log(data)
        const error = (data && data.msg) || response.statusText;
        throw error;
    }
    const sudata = await response.data;
    console.log(sudata)
    return sudata;
}

