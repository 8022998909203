import React from 'react'
import { Link } from 'react-router-dom'

export default function Procedures() {
  return (
    <div className='h-max bg-white rounded-xl w-full py-8 px-4 sm:p-8' style={{ boxShadow: '0px 4px 4px 2px rgba(53, 53, 53, 0.1)' }}>
      <div>
        <h3 className='text-lg sm:text-xl font-extrabold text-primary mb-2'>Besoin d’aide dans vos démarches&nbsp;?</h3>

        <div className='mt-6 flex gap-3 py-3'>
          <svg width="45" height="45" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 0C15.6462 0 13.8462 1.8 13.8462 4.15385V12.4615C13.8462 14.8154 15.6462 16.6154 18 16.6154H26.3077L31.8462 22.1538V16.6154C34.2 16.6154 36 14.8154 36 12.4615V4.15385C36 1.8 34.2 0 31.8462 0H18ZM23.7988 4.15385H26.1775L28.5148 12.4615H26.4378L25.8743 10.3846H23.7974L23.2795 12.4615H21.4615L23.7988 4.15385ZM24.9231 5.53846C24.7846 6.09231 24.6295 6.768 24.4897 7.18338L24.102 9H25.7455L25.3551 7.182C25.0795 6.768 24.9231 6.09231 24.9231 5.53846ZM4.15385 13.8462C1.8 13.8462 0 15.6462 0 18V26.3077C0 28.6615 1.8 30.4615 4.15385 30.4615V36L9.69231 30.4615H18C20.3538 30.4615 22.1538 28.6615 22.1538 26.3077V18H18C15.3692 18 13.284 16.2 12.5917 13.8462H4.15385ZM10.5148 17.8698C12.8686 17.8698 13.9763 19.8083 13.9763 22.0237C13.9763 23.9622 13.3103 25.1917 12.2026 25.7455C12.7565 26.0225 13.4128 26.1692 14.1051 26.3077L13.5872 27.6923C12.618 27.4154 11.6128 26.9834 10.6435 26.5666C10.5051 26.4282 10.2628 26.4378 10.1243 26.4378C8.46277 26.2994 6.92308 24.9231 6.92308 22.1538C6.92308 19.8 8.29939 17.8698 10.5148 17.8698ZM10.5148 19.3846C9.40708 19.3846 8.86985 20.6308 8.86985 22.1538C8.86985 23.8154 9.40708 24.9231 10.5148 24.9231C11.6225 24.9231 12.2012 23.6769 12.2012 22.1538C12.2012 20.6308 11.6225 19.3846 10.5148 19.3846Z" fill="#1CBE93" />
          </svg>
          <div className=''>
            <h6 className='text-base font-semibold text-primary'>Consultez notre FAQ</h6>
            <p className='text-sm font-light text-primary mb-4'>Retrouvez les réponses aux questions les plus fréquentes posées par nos clients.</p>
            <Link to="/faq" className='py-2 px-4 rounded-full bg-primary font-bold text-sm text-white transition-all duration-300 hover:bg-secondary hover:btnShadow'>Consulter la FAQ</Link>
          </div>
        </div>

        <div className='mt-6 flex gap-3 py-3'>
          <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.1673 7.1945V18.729C29.1674 19.905 28.7174 21.0365 27.9098 21.8913C27.1021 22.7462 25.998 23.2595 24.8238 23.3261L24.5632 23.3332H5.43815C4.26212 23.3332 3.13064 22.8833 2.27582 22.0756C1.421 21.268 0.907649 20.1638 0.841068 18.9897L0.833984 18.729V7.1945L14.5077 14.3572C14.6598 14.4368 14.8289 14.4785 15.0007 14.4785C15.1724 14.4785 15.3415 14.4368 15.4937 14.3572L29.1673 7.1945ZM5.43815 0.666504H24.5632C25.7044 0.666366 26.805 1.09008 27.6515 1.85547C28.4981 2.62085 29.0302 3.67335 29.1447 4.80884L15.0007 12.218L0.856651 4.80884C0.966476 3.71845 1.46164 2.70299 2.25313 1.94499C3.04462 1.187 4.08053 0.736185 5.17465 0.673587L5.43815 0.666504H24.5632H5.43815Z" fill="#1CBE93" />
          </svg>
          <div className=''>
            <h6 className='text-base font-semibold text-primary'>Envoyez-nous un Email</h6>
            <p className='text-sm font-light text-primary mb-4'>Nos services s’efforceront de vous répondre au plus vite.</p>
            <Link to="mailto:contact@busyplace.fr" className='py-2 px-4 rounded-full bg-primary font-bold text-sm text-white transition-all duration-300 hover:bg-secondary hover:btnShadow'>Contactez-nous</Link>
          </div>
        </div>

        <div className='mt-6 flex gap-3 py-3'>
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.0007 0.833496C7.18065 0.833496 0.833984 7.18016 0.833984 15.0002C0.833984 22.8202 7.18065 29.1668 15.0007 29.1668C22.8207 29.1668 29.1673 22.8202 29.1673 15.0002C29.1673 7.18016 22.8207 0.833496 15.0007 0.833496ZM15.0007 6.50016C17.7348 6.50016 19.959 8.72433 19.959 11.4585C19.959 14.1927 17.7348 16.4168 15.0007 16.4168C12.2665 16.4168 10.0423 14.1927 10.0423 11.4585C10.0423 8.72433 12.2665 6.50016 15.0007 6.50016ZM15.0007 26.3335C12.1248 26.3335 8.72482 25.1718 6.30232 22.2535C8.78376 20.3066 11.8466 19.2485 15.0007 19.2485C18.1547 19.2485 21.2175 20.3066 23.699 22.2535C21.2765 25.1718 17.8765 26.3335 15.0007 26.3335Z" fill="#1CBE93" />
          </svg>

          <div className=''>
            <h6 className='text-base font-semibold text-primary'>Contactez un conseiller</h6>
            <p className='text-sm font-light text-primary mb-4'>Du lundi au vendredi de 10h à 12h et de 14h à 18h</p>
            <Link to="tel:01 53 65 16 66" className='py-2 px-4 rounded-full bg-primary font-bold text-sm text-white transition-all duration-300 hover:bg-secondary hover:btnShadow'>Appelez-nous</Link>
          </div>
        </div>
      </div>
    </div>
  )
}
