import React, { useState, useEffect } from 'react'
import Layout from '../../Dashboard/Layout';
import { useLocation,useNavigate,useParams, Navigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import demouser from '../../../src/assets/AnnuaireBg.jpg'
import axios from 'axios';
import PageLoaderComp from '../../component/pageLoaderComp';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Typography,Input ,InputAdornment,Divider   } from '@mui/material';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { frFR } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';
import 'dayjs/locale/fr.js';
import moment from "moment";
import ToastMessageComp from '../../component/ToastMessageComp';
import { alertActions } from '../../_actions';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';

export default function Messagerie() {
  const alert = useSelector(state => state.alert);
  const currentUser = useSelector(state => state.authentication.user);  
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [successfull, setSuccessfull] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [isOpenQuote, setIsOpenQuote] = useState(false);
  const [userTva, setUserTva] = useState(currentUser && currentUser.UserBusinessDetails && currentUser.UserBusinessDetails.companyArr.vat ?parseFloat(currentUser.UserBusinessDetails.companyArr.vat)/100:0);
  const [messagesData, setMessagesData] = useState({
    "directory_id":currentUser?.UserProDetails?.details?.id|| "",
    "society":"",
    "prenom":"",
    "nom":"",
    "address":"",
    "code_postal":"",
    "ville":"",
    "ville_id":"",
    "villeArr":[],
    "telephone":"",
    "email":"",
    
});
  const [singleMessagesData, setSingleMessagesData] = useState([]);
  const [quoteFormData, setQuoteFormData] = useState({
      quote_arr:[{
        prestation:"",
        quantity:0,
        price_ht:0,
        sub_total_ht:0,
      }],    
      total_ht:0,
      total_tva:0,
      tva_percent:currentUser && currentUser.UserBusinessDetails&& currentUser.UserBusinessDetails.companyArr.vat?parseFloat(currentUser.UserBusinessDetails.companyArr.vat):0,
      total_ttc:0,
      remise:0,
      remiseIsChecked:false,
      total_remise_ht:0,
      invoice_number:`FA-${moment(new Date()).format("DD-MM-YYYY")}-${"1".padStart(2, '0')}`,
      devis_number:`DEV-${moment(new Date()).format("DD-MM-YYYY")}-${"1".padStart(2, '0')}`, /* moment().format("DDMMY")+"_"+id.padStart(8, '0'), */
      devis_date:moment(new Date()).format("YYYY-MM-DD"),
      devis_validity_date:moment(new Date()).format("YYYY-MM-DD"),
      "create_type_db":"quote",
      "create_type":"message",
      "create_user_type":"user"
      
  });
 
  
  useEffect(() => {
    getAllMessages();
   
  }, []);
  useEffect(() => {
  }, [quoteFormData]);
  useEffect(() => {
    if (!currentUser) {
      return <Navigate to="/" />;
    }    
  }, [currentUser]);

  const getAllMessages = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}api/announce/get_messages/${currentUser.UserProDetails.user_profile?.job_title}/${currentUser.UserProDetails.details.id}`);
      
      setMessagesData(response.data.data);  
      if(response.data.data.length>0){
        setSingleMessagesData(response.data.data[0].messageArr);
        setQuoteFormData(prevState => ({
          ...prevState,
          devis_number: `DEV-${moment(new Date()).format("DD-MM-YYYY")}-${(response.data.last_id_quote).toString().padStart(2, '0')}`           
        }));
      }  
      setLoading(false)
    } catch (error) {
      console.error('Error during API call:', error);
      setLoading(false);
      if (error.response && error.response.status === 400 && error.response.data.success === false) {
          console.error(error.response.data.msg || 'API call failed');
          setLoading(false);
      }
      if (error.response && error.response.status === 401 && error.response.data.success === false) {
          // console.error(error.response.data.msg || 'API call failed');
          setLoading(false);
        
      }
    }  
  };
  const handleChange=async(evt, index)=>{
    let {name,value} = evt.target;
    const updatedQuoteArr = quoteFormData.quote_arr.map((item, idx) => {            
      if (idx === index) {
      let sub_total_ht = parseFloat(item.quantity) * parseFloat(item.price_ht);
      if(name === "quantity"){
          sub_total_ht = value.length>0 && item.price_ht?parseFloat(item.price_ht) * parseFloat(value):0
      }else if(name === "price_ht"){
          sub_total_ht = value.length>0&& item.quantity?parseFloat(item.quantity) * parseFloat(value):0
      }
      const updatedItem = {
          ...item,
          [name]: value,
          sub_total_ht: sub_total_ht
      };
      return updatedItem;
      }
      return item;
  });
  setQuoteFormData(prevState => ({
    ...prevState,
    quote_arr: updatedQuoteArr,
    total_ht: (updatedQuoteArr.reduce((acc, curr) => acc + Number(curr.sub_total_ht), 0)).toFixed(2),
    total_tva: (updatedQuoteArr.reduce((acc, curr) => acc + Number(curr.sub_total_ht) * userTva, 0)).toFixed(2),
    total_ttc: (updatedQuoteArr.reduce((acc, curr) => acc + Number(curr.sub_total_ht) * 1.2, 0)).toFixed(2),
    tva_percent:currentUser && currentUser.UserBusinessDetails&& currentUser.UserBusinessDetails.companyArr.vat?parseFloat(currentUser.UserBusinessDetails.companyArr.vat):0,
      
  }));
    
  }
  const handleRemove=async(evt, type,indexToRemove)=>{
    setQuoteFormData(prevState => ({
        ...prevState,
        quote_arr: prevState.quote_arr.filter((item, index) => index !== indexToRemove),     
        total_ht: ((prevState.quote_arr.filter((item, index) => index !== indexToRemove)).reduce((acc, curr) => acc + Number(curr.sub_total_ht), 0)).toFixed(2),
        total_tva: ((prevState.quote_arr.filter((item, index) => index !== indexToRemove)).reduce((acc, curr) => acc + Number(curr.sub_total_ht) * userTva, 0)).toFixed(2),
        total_ttc: ((prevState.quote_arr.filter((item, index) => index !== indexToRemove)).reduce((acc, curr) => acc + Number(curr.sub_total_ht) * 1.2, 0)).toFixed(2),
        tva_percent:currentUser && currentUser.UserBusinessDetails&& currentUser.UserBusinessDetails.companyArr.vat?parseFloat(currentUser.UserBusinessDetails.companyArr.vat):0,
    }));
  }
  const handleAdd=async(evt, type)=>{
    setQuoteFormData(prevState => ({
        ...prevState,
        quote_arr: [...prevState.quote_arr,[{ prestation:"", quantity:"", price_ht:"",sub_total_ht:""}]]
    }));
  }
  const handleSendMessage = () => {
    /* if (newMessage.trim() !== '') {
      setMessages([...messages, { text: newMessage, user: 'current' }]);
      setNewMessage('');
    } */
  };
  const handleSave=async(e,saveType)=>{
    e.preventDefault();
    setIsLoading(true);
    try {
      // Create the request data object
      const requestData = {
        messagesData: singleMessagesData.length>0?singleMessagesData[0]:null,
        quoteFormData: quoteFormData,
        saveType:saveType,
        quoteId:"",
        isEditing:false,
        invoice_save_type:"",
        "create_type_db":"quote",
        "create_type":"message",
        "create_user_type":"user"
      };

      // Use Axios to make the API request
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/prouser/add-quote`, requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setSuccessfull(true)
      if (response.status === 200) {
        dispatch(alertActions.success("Devis envoyé avec succès")); 
        // Handle success, e.g., redirect or show a success message
        // navigate(`/openinghours/${params.id}`); // Redirect to the next page on success        
      } else {
        dispatch(alertActions.error("Une erreur s'est produite. Veuillez réessayer!")); 
        // Handle error, e.g., show an error message
        // setError("Une erreur s'est produite. Veuillez réessayer plus tard.");
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <>
      {
        loading?<PageLoaderComp />:""
      }
       {successfull?
        <ToastMessageComp  message={alert.message} type={alert.type} />
        :""}
      <Layout>
        <div className='mb-8 col-span-12 xl:col-span-9 w-full h-full'>
          {/* message page */}
          <div  className={isOpenQuote?`hidden`:`xl:max-w-[1126px] w-full h-full bg-white md:rounded-[20px]`}>
            <div className='mainHead min-h-[80px] sm:min-h-[120px] flex sm:flex-row flex-col sm:items-center p-3 sm:p-10 sm:justify-between'>
              <div>
              <h1 className="text-lg sm:text-xl md:text-lg xl:text-xl 2xl:text-xl font-extrabold text-white sm:mt-0 mt-4">
              Messagerie</h1>
              </div>
              <div className='w-full sm:w-auto flex justify-end'>
                {/* {/ Your search component /} */}
              </div>
            </div>
            <div className={messagesData.length<=0 && singleMessagesData.length<=0?"w-full text-center font-medium text-xl pt-10 pb-5  items-center h-20":"hidden"}>Aucun message trouvé !</div>
            <div className={messagesData.length<=0 && singleMessagesData.length<=0?"hidden ":"flex flex-col sm:flex-row h-auto sm:h-[651px] "} >              
              {/* {/ User List Section /} */}
              <div className={` w-full sm:w-2/5 h-auto sm:h-full overflow-y-auto p-4 bg-gray-200 border-r border-gray-300`} >
                <h4 className="text-xl font-semibold text-primary mt-4 sm:mt-10">Conversations</h4>
                <div className="mt-4  overflow-auto">
                  {
                    messagesData.length>0&&messagesData.map((msg,msgInd)=>{
                      let msgActivestyle={ }
                      if(msgInd === 0){
                        msgActivestyle={ backgroundColor: "rgba(39, 41, 91, 0.05)" }
                      }
                      
                      return (
                        <div key={msgInd} className="flex items-center space-x-8 mb-4 mt-4 p-2 cursor-pointer" style={msgActivestyle} onClick={(e)=>setSingleMessagesData(msg.messageArr)}>
                          <img src={demouser} alt="User" className="w-10 h-10 rounded-full" />
                          <div className="flex flex-row space-x-8 ">
                            <p className="font-semibold text-dark capitalize">{msg.messageArr[0].nom} {msg.messageArr[0].prenom}</p>
                            {/* <p className="text-gray-300">03/12</p> */}
                          </div>
                        </div>
                      )
                    })
                  }
                  
                </div>
              </div>
              {/* {/ Chat Section /} */}
              <div  className={` w-full sm:w-3/5 flex flex-col h-auto sm:h-[615px] bg-gray-100 p-4 relative overflow-auto border-t border-gray-300 overflow-y-auto border-r border-gray-300`}  >
                <div className='flex flex-col-reverse flex-grow o  bg-gray-200 p-4 rounded-lg '>
                  {
                      singleMessagesData.length>0&&singleMessagesData.map((msg,msgInd)=>{
                        let msgActivestyle={ }
                        if(msgInd === 0){
                          msgActivestyle={ backgroundColor: "rgba(39, 41, 91, 0.05)" }
                        }                        
                        return (
                          <div key={msgInd} className={`flex items-start ${msg.user === 'sender' ? 'justify-end' : 'justify-start'}`} >
                            <div className='p-4 mr-auto justify-end'>
                            <p className='text-xs text-black-500 justify-end' >{"time"}</p>
                            <p
                              className='p-2 rounded-lg'
                              style={{
                                color: "#414141",
                                backgroundColor: "rgba(28, 190, 147, 0.05)",
                              }}

                            >
                              {msg.message}
                            </p>
                          </div>
                        </div>
                        )
                      })
                    }
                 
                  <div className="flex-grow"></div>
                </div>
                <div className='flex items-center justify-between  bg-white sticky bottom-0 left-0 right-0'>
                  <textarea
                    style={{ height: '6em', overflowY: 'auto' }}
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Message..."
                  />
                  <button type="button" className="text-white p-2 ml-4 mt-16 font-semibold bg-primary hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
                    Envoyer
                  </button>
                  {/* <button
                    className='bg-primary font-thin text-white ml-4 px-10 py-2 rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white'
                    style={{
                      borderRadius: '5px',
                    }}
                    disabled
                  >
                    Envoyer
                  </button> */}
                </div>
              </div>

              {/* {/ Profile Section /} */}
              <div className="w-full sm:w-1/3 h-auto sm:h-full p-4 bg-gray-200">
                <div className='mt-4 flex flex-col items-center h-full'>

                  <div className="flex items-center space-x-6 mb-4 mt-4">
                    <img src={demouser} alt="User" className="w-10 h-10 rounded-full" />
                    <div className="flex flex-row  space-x-8 ">
                      <p className='capitalize'> {singleMessagesData.length>0?singleMessagesData[0].nom:""} {singleMessagesData.length>0?singleMessagesData[0].prenom:""} </p>
                    </div>
                  </div>
                  <p className='p-2'>Vous pouvez envoyer un devis à votre interlocuteur.</p>
                    <button onClick={()=>setIsOpenQuote(true)} className="text-gray-900 font-semibold bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2" >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      stroke="#27295B"
                      className="w-6 h-6 mr-2 "
                    >
                      <circle cx="12" cy="12" r="11" strokeWidth="2" fill="white" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v8m-4-4h8" />
                    </svg>
                    Créer un devis
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* QUOTE PAGE */}
          <div  className={isOpenQuote?`xl:max-w-[1126px] w-full h-full bg-white md:rounded-[20px]`:`xl:max-w-[1126px] w-full h-full bg-white md:rounded-[20px] hidden`}>
            <div className='mainHead min-h-[80px] sm:min-h-[120px] flex sm:flex-row flex-col sm:items-center p-3 sm:p-10 sm:justify-between'>
              <div>
                <h1 className='text-2xl 2xl:text-[25px] font-black text-white sm:mt-0 mt-4'>Devis</h1>
              </div>
              <div className='w-full sm:w-auto flex justify-end'>
                {/* {/ Your search component /} */}
                <Button variant="outlined" className="border-white text-white font-semibold  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2" onClick={()=>setIsOpenQuote(false)}>Retour à la conversation</Button>
              </div>
            </div>
            <div className="h-auto sm:h-[651px] ">
              <div className="w-full  h-auto overflow-y-auto p-4 rounded">
                <div className="border border-gray-500 p-5 rounded-2xl" style={{borderColor: '#797979'}}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Box component="section" sx={{ p: 2, border: '0px', width: '100%' }} className={"flex "}>                        
                      <Stack
                        direction="column"
                        justifyContent="space-between"
                        alignItems="start"
                        spacing={2}
                        
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="start"
                          spacing={2}
                          
                        >
                          <Typography variant="subtitle1" component="subtitle1" className={"font-bold flex items-center"}>
                            DEVIS n°
                            <Button variant="outlined" className={"font-bold rounded-full border-gray text-black ml-5 "}>{quoteFormData.devis_number}</Button>
                          </Typography>
                          
                        </Stack>
                        <Stack
                            direction="column"
                            justifyContent="space-between"
                            alignItems="start"
                            spacing={2}
                          >
                              <Typography variant="body1" className="text-sm m-0 flex items-center" sx={{color: '#737373'}} >Date du devis : 
                              {/* {quoteFormData.devis_date} */}
                              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}  className={"ml-3 mb-3"}>
                                <DatePicker
                                    className='ml-3'
                                    sx={{
                                        "& .MuiInputLabel-root": { color: '#797979', },
                                        "& fieldset": { border: '1px solid #eee' },
                                    }}
                                    disablePast={true}
                                    slotProps={{ textField: { size: 'small' } }}
                                    value={dayjs(quoteFormData.devis_date)}
                                    /* onChange={(date) => {
                                      setQuoteFormData(prevState => ({
                                        ...prevState,
                                        devis_date: date.$d
                                      }));
                                    }} */
                                    onChange={(newValue) => {
                                      setQuoteFormData(prevState => ({
                                          ...prevState,
                                          devis_date: newValue ? newValue.toISOString() : null
                                      }));
                                    }}
                                    renderInput={(params) => <TextField {...params} fullWidth variant='outlined' margin='normal' size='small' />}
                                    inputFormat="DD-MM-YYYY"
                                />
                                </LocalizationProvider>
                              </Typography>
                            <Typography variant="body1"  className="text-sm  mt-3 flex items-center"  sx={{color: '#737373'}}>Date de validité du devis : 
                            {/* {quoteFormData.devis_validity_date} */}
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}  className={"ml-3 mb-3"}>
                                <DatePicker
                                    className='ml-3'
                                    disablePast={true}
                                    sx={{
                                        "& .MuiInputLabel-root": { color: '#797979', },
                                        "& fieldset": { border: '1px solid #eee' },
                                    }}
                                    slotProps={{ textField: { size: 'small' } }}
                                    value={dayjs(quoteFormData.devis_validity_date)}
                                    onChange={(newValue) => {
                                      setQuoteFormData(prevState => ({
                                          ...prevState,
                                          devis_validity_date: newValue ? newValue.toISOString() : null
                                      }));
                                    }}
                                    /* onChange={(date) => {
                                      setQuoteFormData(prevState => ({
                                        ...prevState,
                                        devis_date: date.$d
                                      }));
                                    }} */
                                    renderInput={(params) => <TextField {...params} fullWidth variant='outlined' margin='normal' size='small' />}
                                    inputFormat="DD-MM-YYYY"
                                />
                                </LocalizationProvider>
                            </Typography>    
                        </Stack>
                      </Stack> 
                    </Box>
                    <Box component="section" sx={{ 
                      backgroundColor: '#f6f6f6',
                      borderRadius: '10px',
                      p: 3,
                      width: '50%'

                      }}>
                    <Typography variant="body1" sx={{color: '#2a2a2a'}} >{singleMessagesData.length>0&&singleMessagesData[0].society}</Typography >
                    <Typography variant="body1" className='text-xs capitalize'   sx={{color: '#737373'}} >{singleMessagesData.length>0&&singleMessagesData[0].nom} {singleMessagesData.length>0&&singleMessagesData[0].prenom} <br /> {singleMessagesData.length>0&&singleMessagesData[0].address} <br />{singleMessagesData.length>0&&singleMessagesData[0].code_postal}  {singleMessagesData.length>0&&singleMessagesData[0].ville} </Typography >

                    </Box>
                  </Stack>
                  <Divider className='mt-2 mb-2' />
                  <Grid container spacing={2} className='min-h-[300px] overflow-y-scroll'>
                    {
                      quoteFormData.quote_arr.map((prest,index)=>{
                      return(
                        <Grid item xs={12}>                              
                          <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <Typography variant="body1" sx={{color: '#2a2a2a'}} className='mb-3 mt-3' >Prestations</Typography >                           
                              
                                  <TextField className={"w-full rounded-full mb-3"} size="small" id="outlined-basic"   variant="outlined" placeholder='Description de la prestation' value={prest.prestation} name='prestation' onInput={(e)=>handleChange(e,index)} />
                                                            
                              </Grid>
                              <Grid item xs={7} >
                                <Grid container spacing={2} className="flex justify-end items-center">
                                  <Grid item xs={4}>
                                    <Typography variant="body1" className='mb-3  mt-3' sx={{color: '#2a2a2a'}} >Quantité</Typography >
                                    <TextField  type="number" className={"w-full rounded-full"} size="small" id="outlined-basic"   variant="outlined" value={prest.quantity} name='quantity' onInput={(e)=>handleChange(e,index)}  />
                                    
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Typography variant="body1" className='mb-3  mt-3' sx={{color: '#2a2a2a'}} >Prix unit. HT</Typography >
                                    <TextField  type="number"  size="small" sx={{borderRadius: '100px'}}   variant="outlined"  InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>, }} value={prest.price_ht} name='price_ht' onInput={(e)=>handleChange(e,index)} />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Typography variant="body1" className='mb-3  mt-3' sx={{color: '#2a2a2a'}} >Total HT</Typography >
                                    <TextField  type="text"  size="small" sx={{borderRadius: '100px'}}   variant="outlined"  InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment>, readOnly: true, }} value={prest.sub_total_ht} name='sub_total_ht' onInput={(e)=>handleChange(e,index)} />
                                  </Grid>
                                  
                                </Grid>                              
                              </Grid>
                              <Grid item xs={1} className="mt-7 flex justify-end items-center" >
                                <RemoveCircleOutlineOutlinedIcon sx={{ fontSize: 40 }} className={index===0?`hidden`:`ml-2 cursor-pointer`} onClick={(e)=>handleRemove(e,"prestation",index)} />
                              </Grid>
                          </Grid>
                        </Grid>
                      )})
                    }                   
                    <Typography variant="body1" sx={{color: '#2a2a2a'}} className='pl-5 pr-5 mb-1 mt-1 cursor-pointer' onClick={(e)=>handleAdd(e,"prestation")}><ControlPointOutlinedIcon  sx={{ fontSize: 40 }} /> 
                      Ajouter une prestation 
                    </Typography>
                  </Grid>
                  <Divider className='mt-2 mb-2' />
                  <Grid container spacing={2} >
                    <Grid item xs={6}>
                      
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={2} alignItems="center" className="mb-3 flex justify-end items-center">
                          <Grid item xs={12} className="flex justify-end items-center ">
                            <Typography variant="body1" sx={{ color: '#2a2a2a', mr: 2 }}>
                            Total HT:
                            </Typography>
                            <TextField
                              className='w-[150px] '
                              size="small"
                              sx={{ borderRadius: '100px' }}
                              variant="outlined"
                              InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                readOnly: true,
                              }}
                              name={"total_ht"}
                              value={quoteFormData.total_ht}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} alignItems="center" className="mb-3  flex justify-end items-center">
                          <Grid item xs={12} className="flex justify-end items-center">
                            <Typography variant="body1" sx={{ color: '#2a2a2a', mr: 2 }}>
                              Total TVA ({currentUser && currentUser.UserBusinessDetails&& currentUser.UserBusinessDetails.companyArr.vat?parseFloat(currentUser.UserBusinessDetails.companyArr.vat):0}%)
                            </Typography>
                            <TextField
                            className='w-[150px] '
                              size="small"
                              sx={{ borderRadius: '100px' }}
                              variant="outlined"
                              InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                readOnly: true,
                              }}
                              name={"total_tva"}
                              value={quoteFormData.total_tva}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} alignItems="center" className="mb-3  flex justify-end items-center">
                          <Grid item xs={12} className="flex justify-end items-center">
                            <Typography variant="body1" sx={{ color: '#2a2a2a', mr: 2 }}>
                              Total TTC
                            </Typography>
                            <TextField
                            className='w-[150px] '
                              size="small"
                              sx={{ borderRadius: '100px' }}
                              variant="outlined"
                              InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                readOnly: true,
                              }}
                              name={"total_ttc"}
                              value={quoteFormData.total_ttc}
                            />
                          </Grid>
                        </Grid>
                      </Grid>                    
                  </Grid>
                </div>
                  
              </div>
              
            </div>
            {/* footer part */}
            <Grid container spacing={2} className='mt-8 p-5' >
              <Grid item xs={6} className="mb-3 flex justify-start items-center">
                <Button variant="outlined" className='mr-3' startIcon={<SearchIcon />} sx={{ borderColor: '#27295B', color: '#27295B','&:hover': {borderColor: '#27295B',  backgroundColor: '#27295B', color: '#FFFFFF' } }}  >Aperçu</Button>           
                {/* <Button onClick={(e)=>handleSave(e,"save")} variant="outlined" startIcon={<SaveIcon />}  sx={{ borderColor: '#27295B', color: '#27295B','&:hover': {borderColor: '#27295B',  backgroundColor: '#27295B', color: '#FFFFFF' } }} >Enregistrer sans envoyer</Button>   */} 
                <LoadingButton
                    onClick={(e)=>handleSave(e,"save")} variant="outlined"   sx={{ borderColor: '#27295B', color: '#27295B','&:hover': {borderColor: '#27295B',  backgroundColor: '#27295B', color: '#FFFFFF' } }}
                    className=' capitalize'
                    size="small"
                    // onClick={handleSubmit}
                    endIcon={<SaveIcon />}
                    loading={isLoading}
                    loadingPosition="end"                  >
                    <span>Enregistrer sans envoyer</span>
                </LoadingButton>        
              </Grid>
              <Grid item xs={6}  className="mb-3 flex justify-end items-center">
                  <Button onClick={()=>setIsOpenQuote(false)} variant="outlined" className='mr-3' sx={{ borderColor: '#27295B', color: '#27295B','&:hover': {borderColor: '#27295B',  backgroundColor: '#27295B', color: '#FFFFFF' } }}>Annuler</Button>           
                 {/*  <Button onClick={(e)=>handleSave(e,"send")} variant="contained" sx={{backgroundColor: '#27295B', borderColor: '#27295B', color: '#FFFFFF','&:hover': {borderColor: '#27295B',  backgroundColor: '#27295B', color: '#FFFFFF' } }}>Envoyer</Button>   */} 
                  <LoadingButton
                    onClick={(e)=>handleSave(e,"send")} variant="outlined"  sx={{ borderColor: '#27295B', color: '#27295B','&:hover': {borderColor: '#27295B',  backgroundColor: '#27295B', color: '#FFFFFF' } }}
                    className=' capitalize'
                    size="small"
                    // onClick={handleSubmit}
                    endIcon={<SaveIcon />}
                    loading={isLoading}
                    loadingPosition="end"                  >
                    <span>Envoyer</span>
                </LoadingButton>        
                  
                </Grid>
              
            </Grid>
          </div>
          
        </div>
      </Layout>
    </>
  );
}
