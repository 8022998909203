export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user_pro'));
    let admin = JSON.parse(localStorage.getItem('admin'));
    if (user && user.token) {
        return {
            "Content-Type":"application/json",
            "Accept":"*", 
            'Authorization': 'Bearer ' + user.token ,
        };
    }else  if (admin && admin.token) {
        return {
            "Content-Type":"application/json",
            "Accept":"*", 
            'Authorization': 'Bearer ' + admin.token 
        };
    } else {
        return {
            "Content-Type":"application/json",
            "Accept":"*",             
        };
    }
}