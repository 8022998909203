

/* eslint-disable no-unused-vars */
import { announceConstants } from '../_constants';
import { announceService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';


export const announceActions = {
    getAllAnnounce,
    getAll,
    create,
    update,
    updatePayment,
    updateSuccessPayment,
    update_user
};


function getAllAnnounce() {
    return async dispatch => { // Make the function async
        dispatch(request());

        try {
            const data = await announceService.getAllAnnounce(); // Await the result
            dispatch(success(data));
            return data; // Return the data
        } catch (error) {
            dispatch(failure(error.toString()));
            throw error; // Propagate the error
        }
    };

    function request() { return { type: announceConstants.GETALL_REQUEST } }
    function success(devis) { return { type: announceConstants.GETALL_SUCCESS, devis } }
    function failure(error) { return { type: announceConstants.GETALL_FAILURE, error } }
}

function getAll(user_id,type) {
    return dispatch => {
        dispatch(request());
        return announceService.getAll(user_id,type)
            .then(
                orders => {
                    let data = {
                        order:[],
                        invoice:[],
                        devis:[],
                    }
                   
                    if(type === "invoice"){
                        data.invoice = orders;
                        
                    }else if(type === "order"){
                        data.order = orders;
                       
                    }else if(type === "devis"){
                        data.devis = orders;
                    }                   
                    dispatch(success(data));
                    return Promise.resolve(orders);
                },
                error =>{
                    dispatch(failure(error.toString()));
                    return Promise.reject(); 
                } 
            );
    };

    function request() { return { type: announceConstants.GETALL_REQUEST } }
    function success(orders) { return { type: announceConstants.GETALL_SUCCESS, orders } }
    function failure(error) { return { type: announceConstants.GETALL_FAILURE, error } }
}

function create(formData) {
    return dispatch => {
        dispatch(request());
        return announceService.create(formData)
            .then(
                announce => {  
                    if(announce.sucess === true){
                        dispatch(success(announce));
                        dispatch(alertActions.success(announce.msg));
                        return Promise.resolve(announce);
                    }else{
                        
                        dispatch(failure("Une erreur s'est produite. Veuillez réessayer!"));
                        dispatch(alertActions.error("Une erreur s'est produite. Veuillez réessayer!")); 
                        return Promise.reject(announce); 
                    }
                    
                },
                error =>{
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString())); 
                    return Promise.reject(); 
                } 
            );
    };

    function request() { return { type: announceConstants.CREATE_REQUEST } }
    function success(announce) { return { type: announceConstants.CREATE_SUCCESS, announce } }
    function failure(error) { return { type: announceConstants.CREATE_FAILURE, error } }
}

function update(formData) {
    return dispatch => {
        dispatch(request());
        return announceService.update(formData)
            .then(
                announce => {
                    if(announce.sucess){
                        dispatch(success(announce));
                        return Promise.resolve(announce);
                    }else{
                        dispatch(failure(announce.msg));
                        return Promise.reject(); 
                    } 
                },
                error =>{
                    dispatch(failure(error.toString()));
                    return Promise.reject(); 
                } 
            );
    };

    function request() { return { type: announceConstants.UPDATE_REQUEST } }
    function success(announce) { return { type: announceConstants.UPDATE_SUCCESS, announce } }
    function failure(error) { return { type: announceConstants.UPDATE_FAILURE, error } }
}
function updatePayment(formData) {
    return dispatch => {
        dispatch(request());
        return announceService.updatePayment(formData)
            .then(
                announce => {                   
                                   
                    if(announce.sucess){
                        dispatch(success(announce));
                        return Promise.resolve(announce);
                    }else{
                        dispatch(failure(announce.msg));
                        return Promise.reject(); 
                    } 
                },
                error =>{
                    dispatch(failure(error.toString()));
                    return Promise.reject(); 
                } 
            );
    };

    function request() { return { type: announceConstants.UPDATE_REQUEST } }
    function success(announce) { return { type: announceConstants.UPDATE_SUCCESS, announce } }
    function failure(error) { return { type: announceConstants.UPDATE_FAILURE, error } }
}
function updateSuccessPayment(formData) {
    return dispatch => {
        dispatch(request());
        return announceService.updateSuccessPayment(formData)
            .then(
                announce => {                   
                                   
                    if(announce.sucess){
                        dispatch(success(announce));
                        return Promise.resolve(announce);
                    }else{
                        dispatch(failure(announce.msg));
                        return Promise.reject(); 
                    } 
                },
                error =>{
                    dispatch(failure(error.toString()));
                    return Promise.reject(); 
                } 
            );
    };

    function request() { return { type: announceConstants.UPDATE_REQUEST } }
    function success(announce) { return { type: announceConstants.UPDATE_SUCCESS, announce } }
    function failure(error) { return { type: announceConstants.UPDATE_FAILURE, error } }
}

function update_user(formData) {
    return dispatch => {
        dispatch(request());
        return announceService.update_user(formData)
            .then(
                announce => {                   
                    if(announce.sucess){
                        dispatch(success(announce));
                        return Promise.resolve(announce);
                    }else{
                        dispatch(failure(announce.msg));
                        return Promise.reject(); 
                    }               
                    
                   
                },
                error =>{
                    dispatch(failure(error.toString()));
                    return Promise.reject(); 
                } 
            );
    };

    function request() { return { type: announceConstants.UPDATE_REQUEST } }
    function success(announce) { return { type: announceConstants.UPDATE_SUCCESS, announce } }
    function failure(error) { return { type: announceConstants.UPDATE_FAILURE, error } }
}
