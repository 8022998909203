
import React, { useState } from 'react';
import { Box, Stack, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useNavigate,useParams } from 'react-router-dom';
import axios from 'axios';

export default function ProAccess() {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [selectedAccess, setSelectedAccess] = useState(['Accès pour personne handicapée']);

  const handleCheckboxChange = (access) => {
    setSelectedAccess((prevSelectedAccess) => {
      if (prevSelectedAccess.includes(access)) {
        // If the access is already selected, remove it
        return prevSelectedAccess.filter((item) => item !== access);
      } else {
        // If the access is not selected, add it
        return [...prevSelectedAccess, access];
      }
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const emaildata = localStorage.getItem('email');

      // Create the request data object
      const requestData = {
        user_id: params.id?params.id:"",
        email: emaildata,
        your_access: selectedAccess,
      };

      // Use Axios to make the API request
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/prouser/add-prouseraccess`, requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });


      if (response.status === 200) {
        // Handle success, e.g., redirect or show a success message
        navigate(`/partagezvotreprofil/${params.id}`); // Redirect to the next page on success
      } else {
        // Handle error, e.g., show an error message
        setError('Une erreur s\'est produite.'); // Set the error message
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className='bg-[#F5F5F5] h-[85vh] flex items-center justify-center'>
        <div className='bg-[#F5F5F5] w-full py-7 3xl:py-12'>
          <div className='md:max-w-[1477px] relative w-full h-full   flex items-center justify-center m-auto  '>
            <div className='max-w-[700px] 3xl:max-w-[700px]  w-full h-full bg-white px-5 sm:px-12 py-2 sm:py-5 3xl:p-12 ' style={{ boxShadow: "0px 4px 10px 1px rgba(39, 41, 91, 0.35)", borderRadius: "33px" }}>
              <div className=' px-2 py-2 3xl:py-4'>
                <h1 className='text-xl sm:text-3xl xl:text-[28px] font-extrabold md:leading-[45px] mt-6 text-primary'>Vos accès <small className='font-light text-base'>(facultatif)</small></h1>
              </div>
              <Box
                component="form"
                className='mt-5 sm:mt-5 3xl:mt-12'
                onSubmit={handleSubmit}
                sx={{
                  "& .MuiInputLabel-root": { color: '#797979', },
                  "& fieldset": {
                    border: '1px solid #eee', borderRadius: '8px',
                    color: '#797979',
                    background: 'rgb(39 41 91 / 6%)',
                  },
                }}
              >
                <div className='  mt-3 sm:mt-3'>
                  <FormGroup className='w-full'>
                    <FormControlLabel
                      className='w-full'
                      control={<Checkbox checked={selectedAccess.includes('Accès pour personne handicapée')} onChange={() => handleCheckboxChange('Accès pour personne handicapée')} />}
                      label="Accès pour personne handicapée"
                    />
                    <FormControlLabel
                      // required
                      className='w-full'
                      control={<Checkbox checked={selectedAccess.includes('Accès ascenseur')} onChange={() => handleCheckboxChange('Accès ascenseur')} />}
                      label="Accès ascenseur"
                    />
                    <FormControlLabel
                      // required
                      className='w-full'
                      control={<Checkbox checked={selectedAccess.includes('Accès parking')} onChange={() => handleCheckboxChange('Accès parking')} />}
                      label="Accès parking"
                    />
                  </FormGroup>
                </div>
                {/* <Stack alignItems='end' sx={{ mt: 2 }}>
                  <button
                    type="submit"
                    className='bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white mt-4 sm:mt-4 3xl:mt-10 px-10   py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white '
                  >
                    Suivant
                  </button>
                </Stack> */}
                <Stack alignItems='end' sx={{ mt: 2 }}>
                  <button
                    type='submit'
                    className='bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white mt-4 sm:mt-4 3xl:mt-10 px-10 py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white'
                    disabled={loading}
                  >
                    {loading ? 'En cours...' : 'Suivant'}
                  </button>
                </Stack>
                {/* {error && <p className="text-red-500 mt-2">{error}</p>} */}
              </Box>
            </div>
          </div >
        </div >
      </div>
    </>
  );
}
