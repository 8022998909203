import React from 'react'
import NosFormulaires from '../HomePage/NosFormulaires'
import CommentPublier from '../HomePage/CommentPublier'
import ToutSavoir from '../HomePage/ToutSavoir'
import LesFormulaires from '../HomePage/LesFormulaires'
import Actualites from '../HomePage/Actualites'
import Footer from '../../component/Footer'
import Banner from '../HomePage/Banner'
import { Helmet } from 'react-helmet'
export default function Hero() {
    return (
        <>
            {/* <Helmet>
                <title>Publier une annonce légale, c'est à la fois simple, rapide et pas cher ! busyplace</title>
                <meta name="description" content="Publiez vos annonces légales facilement avec BusyPlace. Profitez d'un service en ligne rapide, conforme aux exigences légales, et recevez votre attestation officielle immédiatement. Idéal pour entreprises et particuliers en France." />
                <link rel="canonical" href={`${process.env.REACT_APP_WEB_URL}/nos-formulaires`} />
            </Helmet>
            <Banner /> */}
            {/* <div className='bg-thColor' > */}
            <div  >
                <NosFormulaires />
            </div>
            <CommentPublier />
            <ToutSavoir />
            <LesFormulaires />
            <Actualites />
            <Footer />
        </>
    )
}
