/* eslint-disable array-callback-return */
import React, {useState, useEffect } from 'react' 
import moment from 'moment';
import dayjs from 'dayjs';
import { BsFillQuestionCircleFill, BsPlusCircle } from 'react-icons/bs'
import { MdEuroSymbol } from 'react-icons/md'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/fr.js';
import { frFR } from '@mui/x-date-pickers/locales';
import Tooltip from '@mui/material/Tooltip';
import { FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField ,Divider} from '@mui/material';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';

export  function Selafa(props) {
 
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const StyledTooltip = ({ title, children, ...props }) => (
    <Tooltip
      {...props}
      title={title}
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            background: '#DEE3E2',
            width: '129px',
            padding: '10px',
            color: '#27295B',
            fontSize: '14px',
            fontWeight: '400'
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );

  return (
    <>
      <div className='  ' >
        <div className='py-6 px-3 sm:px-6' style={{ borderBottom: '0.5px solid #DEE3E2' }}>
          <h1 className='text-lg sm:text-2xl font-black text-primary'>Annonce légale : Création de {props.adsname}</h1>
        </div>
        <div className='py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Les statuts</h2>

          <div className='grid grid-cols-12 items-center mt-2 sm:mt-6'>
            <label className='col-span-12 sm:col-span-3 my-2 sm:mb-0 text-base font-normal text-black-light'>Type d’acte</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Sélectionnez"
                  id="type_acte" name="type_acte"
                  value={props.formData.type_acte} onChange={props.handleChange}
                  error={!props.formData.type_acte && props.isSubmitted?true:false}
                >
                  <MenuItem value={"ACTE_SSP"}>Acte sous seing privé (acte rédigé par les parties concernées)</MenuItem>
                  <MenuItem value={"ACTE_AUTHENTIQUE"}>Acte authentique (acte rédigé par un notaire)</MenuItem>

                </Select>
              </FormControl>
            </div>
          </div>
          <div  className={props.formData.type_acte==="ACTE_AUTHENTIQUE"?"nature_acte 3522 notaire":"nature_acte 3522 notaire hidden"} id="notaire" >
            <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Notaire *</label>
              <div className='col-span-12 sm:col-span-9 md:col-span-7 inline-flex items-center gap-2'>
                <TextField
                  hiddenLabel
                  id="outlined-size-small"
                  size="small"
                  className='w-full outline-secondary focus:outline-secondary' name="nom_famille" 
                  value={props.formData.nom_famille} onChange={props.handleChange}
                  error={!props.formData.nom_famille && props.isSubmitted?true:false}
                  
                  />
                
              </div>
            </div>
            <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Officiant à *</label>
              <div className='col-span-12 sm:col-span-9 md:col-span-7 inline-flex items-center gap-2'>
                <TextField
                  hiddenLabel
                  id="outlined-size-small"
                  size="small"
                  className='w-full outline-secondary focus:outline-secondary' name="officiant_to" 
                  value={props.formData.officiant_to} onChange={props.handleChange}
                  error={!props.formData.officiant_to  && props.isSubmitted?true:false}
                  />                
              </div>
            </div>
          </div>
          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Date de l’acte</label>
            <div className='col-span-12 sm:col-span-9 mr-7 md:mr-0 md:col-span-7 lg:col-span-4'>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}  >
                <DemoContainer components={['DatePicker']}  >
                  {
                    props.formData.date_acte?
                    <DatePicker value={dayjs(props.formData.date_acte)}   name="date_acte"   onChange={props.handleChangeDate} format={"DD/MM/YYYY"}                   
                    />
                    :
                    <DatePicker  name="date_acte"   onChange={props.handleChangeDate}  format={"DD/MM/YYYY"}                  
                    />
                  }
                  
                </DemoContainer>
              </LocalizationProvider>
            </div>

          </div>
        </div>


        <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>La société</h2>

          <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full outline-secondary focus:outline-secondary'
                name="denomination" 
                value={props.formData.denomination} 
                onChange={props.handleChange}  
              />
              <StyledTooltip title="Indiquez le nom de la Société tel que mentionné dans les statuts.">
                <div className='p-0 m-0 w-auto'>
                  <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                </div>
              </StyledTooltip>
            </div>
          </div>

          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Sigle</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="sigle"
                value={props.formData.sigle} onChange={props.handleChange} 
              />
              <StyledTooltip title="Sigle à renseigner uniquement si indiqué dans les statuts.">
                <div className='p-0 m-0 w-auto'>
                  <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                </div>
              </StyledTooltip>
            </div>
          </div>

          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom commercial</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="enseigne_commerciale"
                value={props.formData.enseigne_commerciale} onChange={props.handleChange} 
              />
              <StyledTooltip title="Nom sous lequel l'activité de votre société sera connue du public.">
                <div className='p-0 m-0 w-auto'>
                  <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                </div>
              </StyledTooltip>
            </div>
          </div>

          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-8'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Sélectionnez"
                  id="forme_juridique" name="forme_juridique"
                  value={props.formData.forme_juridique} onChange={props.handleChange}  error={!props.formData.forme_juridique  && props.isSubmitted?true:false}
                >
                  <MenuItem value={"SELAFA"}>Société d'exercice libéral sous forme anonyme (SELAFA)</MenuItem>
                 
                 
                </Select>
              </FormControl>
            </div>
          </div>

          <div className='grid grid-cols-12 items-start mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Objet</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 inline-flex items-center gap-2'>
              <TextField
                
                hiddenLabel
                multiline
                minRows={4}
                className='w-full'
                id="comment" name="objet"
                value={props.formData.objet} onChange={props.handleChange} 
              />
              <StyledTooltip title="Indiquez les éléments principaux de votre activité tel que déclarés dans les statuts.">
                <div className='p-0 m-0 w-auto'>
                  <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                </div>
              </StyledTooltip>
            </div>
          </div>

          <div  className={"grid grid-cols-12 items-center mt-6" } >
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>
              
              <FormControl size="small" className='w-full'>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  name="capital"
                  value={props.formData.capital} onChange={props.handleChange}  
                  error={!props.formData.capital  && props.isSubmitted?true:false}
                />
              </FormControl>

              <StyledTooltip title="Montant du capital mentionné dans les statuts.">
                <div className='p-0 m-0 w-auto'>
                  <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                </div>
              </StyledTooltip>

            </div>
          </div>
          
          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Durée</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>

              <FormControl size="small" className='w-full'>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  endAdornment={<InputAdornment position="end" className='font-medium text-primary'><span className='font-medium text-primary'> ans</span></InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  name="duree"
                  value={props.formData.duree} 
                  onChange={props.handleChange}  
                />
              </FormControl>

              <StyledTooltip title="La durée maximale est de 99 ans. Elle pourra être prorogée une ou plusieurs fois.">
                <div className='p-0 m-0 w-auto'>
                  <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer  hover:fill-secondary' />
                </div>
              </StyledTooltip>

            </div>
          </div>
        </div>

        <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Adresse du siège social</h2>

          <div className='grid grid-cols-12 mt-3 sm:mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="adresse" 
                value={props.formData.adresse} onChange={props.handleChange}  error={!props.formData.adresse  && props.isSubmitted?true:false}
              />
            </div>
          </div>

          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Complément adresse</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="adresse_suite"
                value={props.formData.adresse_suite} onChange={props.handleChange}  
              />
            </div>
          </div>

          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="code_postal"  maxLength="5"

                value={props.formData.code_postal} onChange={props.handleChange}  error={!props.formData.code_postal  && props.isSubmitted?true:false}
              />
            </div>
          </div>
          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              {/* <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="ville"
                value={props.formData.ville} 
                onChange={props.handleChange}  
                error={!props.formData.ville  && props.isSubmitted?true:false}
              /> */}
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Sélectionnez"
                  name="ville_id"
                  value={props.formData.ville_id}                   
                  onChange={props.handleChange}  
                  error={!props.formData.ville  && props.isSubmitted?true:false}
                  
                >
                  {
                    props.formData.villeArr.map((cityVal,cityIndex)=>{
                      return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                    })
                  }

                </Select>
              </FormControl>
            </div>
          </div>
          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="greffe" 
                value={props.formData.greffe} onChange={props.handleChange} 
              />
            </div>
          </div>
        </div>
        
        {/* Les président */}
        <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Président du conseil d'administration</h2>
          {props.Les_president.map((val,key)=>{
            return <div key={val.index} index={val.index}  >   
              <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Personne</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-9 md:mr-10 inline-flex items-center gap-2'>
                  <FormControl size="small" className='w-full'>
                    <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id={val.index}
                      label="Sélectionnez"
                      name="type_personne_Associes"
                      value={val.type_personne_Associes} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Associes")}}  
                      error={!val.type_personne_Associes  && props.isSubmitted?true:false}
                      index={val.index}
                    >
                      <MenuItem value={"PERSONNE_PHYSIQUE"}>Personne physique</MenuItem>
                      <MenuItem value={"PERSONNE_MORALE"}>Personne morale</MenuItem>

                    </Select>
                  </FormControl>
                  <StyledTooltip title="La personne physique désigne un individu. La personne morale, elle, est une entité juridique.">
                    <div className='p-0 m-0 w-auto'>
                      <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer  hover:fill-secondary' />
                    </div>
                  </StyledTooltip>
                  {/* {val.index >0?
                  <div className='p-0 m-0 w-auto'>
                      <RemoveCircleRoundedIcon fontSize='large' className='text-3xl  cursor-pointer  fill-secondary' onClick={(e)=>{props.removeForm(val.index,"Associes")}} />
                  </div>
                  :""} */}
                </div>
                
              </div>
              <div className={val.type_personne_Associes === ""?"hidden":""}>
                <div className={val.type_personne_Associes === "PERSONNE_MORALE"?"":"hidden"}>      
                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>N° SIREN *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="siren_Associes"
                        value={val.siren_Associes} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Associes")}}   
                        error={!val.siren_Associes  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="denomination_Associes"
                        value={val.denomination_Associes} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Associes")}}  
                        error={!val.denomination_Associes  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="forme_juridiqueAssocies"
                          value={val.forme_juridiqueAssocies} 
                          onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Associes")}}    
                          error={!val.forme_juridiqueAssocies  && props.isSubmitted?true:false}
                          index={val.index}
                        >
                          <MenuItem value={"SAS"}>Société par actions simplifiée (SAS)</MenuItem>
                          <MenuItem value={"SAS à capital variable"}>Société par actions simplifiée à capital variable</MenuItem>
                          <MenuItem value={"SASU"}>Société par actions simplifiée unipersonnelle (SASU)</MenuItem>
                          <MenuItem value={"SASU à capital variable"}>Société par actions simplifiée unipersonnelle à capital variable</MenuItem>
                          <MenuItem value={"SARL"}>Société à responsabilité limitée (SARL)</MenuItem>
                          <MenuItem value={"SARL à capital variable"}>Société à responsabilité limitée à capital variable</MenuItem>
                          <MenuItem value={"SARLU"}>Société à responsabilité limitée à associé unique (SARLU)</MenuItem>
                          <MenuItem value={"SARLU à capitale variable"}>Société à responsabilité limitée à associé unique à capitale variable</MenuItem>
                          <MenuItem value={"SCI"}>Société civile immobilière (SCI)</MenuItem>
                          <MenuItem value={"SCI à capital variable"}>Société civile immobilière à capital variable</MenuItem>
                          <MenuItem value={"SC"}>Société civile</MenuItem>
                          <MenuItem value={"SA"}>Société anonyme (SA)</MenuItem>
                          <MenuItem value={"SA à capital variable"}>Société anonyme à capital variable</MenuItem>
                          <MenuItem value={"SNC"}>Société en nom collectif (SNC)</MenuItem>
                          <MenuItem value={"SNC à capital variable"}>Société en nom collectif à capital variable</MenuItem>
                          <MenuItem value={"SCM"}>Société civile de moyens (SCM)</MenuItem>
                          <MenuItem value={"SCP"}>Société civile professionnelle (SCP)</MenuItem>
                          <MenuItem value={"SCPI"}>Société civile de placement immobilier (SCPI)</MenuItem>
                          <MenuItem value={"SCCV"}>Société civile immobilière de construction vente (SCCV ou SCICV)</MenuItem>
                          <MenuItem value={"SELAFA"}>Société d'exercice libéral sous forme anonyme (SELAFA)</MenuItem>
                          <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                          <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                          <MenuItem value={"SELCA"}>Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
                          <MenuItem value={"SELARL"}>Société d'exercice libéral a responsabilité limitée (SELARL)</MenuItem>
                          <MenuItem value={"SELEURL"}>Société d'exercice libéral ayant un associé Unique (SELEURL)</MenuItem>
                          <MenuItem value={"SELASU"}>Société d'exercice libéral par actions simplifiée unipersonnelle (SELASU)</MenuItem>
                          <MenuItem value={"SCEA"}>Société civile d'exploitation agricole (SCEA)</MenuItem>
                          <MenuItem value={"EARL"}>Exploitation agricole à responsabilité limitée (EARL)</MenuItem>
                          <MenuItem value={"GAEC"}>Groupement agricole d'exploitation en commun (GAEC)</MenuItem>
                          <MenuItem value={"GFA"}>Groupement foncier agricole (GFA)</MenuItem>
                          <MenuItem value={"EIRL"}>Entrepreneur Individuel à Responsabilité Limité</MenuItem>
                          <MenuItem value={"SPFPLAS"}>Société de Participations Financières de Profession Libérale d'Avocats Simplifiée (SPFPLAS)</MenuItem>
                        </Select>
                      </FormControl>
                      
                    </div>
                  </div>
                  <div  className={"grid grid-cols-12 items-center mt-6"} >
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>
                      
                      <FormControl size="small" className='w-full'>
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            'aria-label': 'weight',
                          }}
                          name="capital_Associes"
                          value={val.capital_Associes} 
                          onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Associes")}}   
                          error={!val.capital_Associes  && props.isSubmitted?true:false}
                          index={val.index}
                        />
                      </FormControl>

                      <StyledTooltip title="Montant du capital mentionné dans les statuts.">
                        <div className='p-0 m-0 w-auto'>
                          <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                        </div>
                      </StyledTooltip>

                    </div>
                  </div>
                </div> 
                <div className={val.type_personne_Associes === "PERSONNE_PHYSIQUE"?"":"hidden"}>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="civilite_Associes"
                          value={val.civilite_Associes} 
                          onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Associes")}}   
                          error={!val.civilite_Associes  && props.isSubmitted?true:false}
                          index={val.index}
                          
                        >
                          <MenuItem value={"Mme"}>Madame</MenuItem>
                          <MenuItem value={"M."}>Monsieur</MenuItem>

                        </Select>
                      </FormControl>
                      
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="nomAssocies"
                        value={val.nomAssocies} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Associes")}}   
                        error={!val.nomAssocies  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="prenomAssocies" 
                        value={val.prenomAssocies} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Associes")}}  
                        error={!val.prenomAssocies  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>
                </div>
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="adresseAssocies" 
                      value={val.adresseAssocies} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Associes")}}   
                      error={!val.adresseAssocies  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                  </div>
                </div>
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Complément adresse</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="adresse_suiteAssocies"
                      value={val.adresse_suiteAssocies} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Associes")}}  
                      index={val.index}
                    />
                  </div>
                </div>
                <div className={val.type_personne_Associes === "PERSONNE_PHYSIQUE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Résidant à l’étranger</label>
                  
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <FormControl size="small" className='w-full'>
                      <InputLabel id="resident_etrangerAssociesphysic-label">Sélectionnez</InputLabel>
                      <Select
                        labelId="demo-resident_etrangerAssociesphysic-label"
                        id="resident_etrangerAssociesphysic"
                        label="resident_etrangerAssociesphysic"
                        name="resident_etrangerAssociesphysic"
                        value={`${val.resident_etrangerAssociesphysic}`}                         
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Associes")}}   
                        index={val.index}
                      >
                        <MenuItem value={"Non"} >Non</MenuItem>
                        <MenuItem value={"Oui"} >Oui</MenuItem>

                      </Select>
                    </FormControl>
                  </div>
                </div>  

                <div className={(val.type_personne_Associes === "PERSONNE_PHYSIQUE" && val.resident_etrangerAssociesphysic=="Non")|| val.type_personne_Associes === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":"hidden grid-cols-12 mt-6 items-center"} >
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="code_postalAssocies"
                      value={val.code_postalAssocies} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Associes")}}  
                      error={!val.code_postalAssocies  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                  </div>
                </div>

                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    {/* <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="villeAssocies"
                      value={val.villeAssocies} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Associes")}}  
                      error={!val.villeAssocies  && props.isSubmitted?true:false}
                      index={val.index}
                    /> */}
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className={((val.type_personne_Associes === "PERSONNE_PHYSIQUE"&& val.resident_etrangerAssociesphysic==="Oui"  ))?"w-full":"hidden w-full"}
                      name="villeAssocies"
                      value={val.villeAssocies} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Associes")}}  
                      error={!val.villeAssocies  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                    <FormControl size="small" className={((val.type_personne_Associes === "PERSONNE_PHYSIQUE"&& val.resident_etrangerAssociesphysic==="Oui"  ))?"hidden w-full":" w-full"} >
                      <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Sélectionnez"
                        name="ville_id_Associes"
                        value={val.ville_id_Associes} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Associes")}}  
                        error={!val.villeAssocies  && props.isSubmitted?true:false}
                        index={val.index}
                      >
                        {
                          val.villeAssociesArr.map((cityVal,cityIndex)=>{
                            return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                          })
                        }

                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className={val.type_personne_Associes === "PERSONNE_PHYSIQUE" && val.resident_etrangerAssociesphysic=="Oui"?"grid grid-cols-12 mt-6 items-center":"hidden grid-cols-12 mt-6 items-center"}>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Pays *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="paysAssocies"
                      value={val.paysAssocies} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Associes")}}  
                      error={!val.paysAssocies  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                  </div>
                </div>
                <div className={val.type_personne_Associes === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe </label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="greffeAssocies"
                      value={val.greffeAssocies} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Associes")}}   
                      // error={!val.greffelagrance  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                  </div>
                </div>
                <div className={val.type_personne_Associes === "PERSONNE_MORALE"?"":"hidden"}>
                  <div className='px-0 group flex items-center gap-2 text-secondary mt-12 '>                   
                    <span className='text-base font-normal'>Représentée par</span>
                  </div>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité </label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="civilite_Associes"
                          value={val.civilite_Associes} 
                          onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Associes")}}                            
                          index={val.index}
                          
                        >
                          <MenuItem value={"Mme"}>Madame</MenuItem>
                          <MenuItem value={"M."}>Monsieur</MenuItem>

                        </Select>
                      </FormControl>
                      
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="nomAssocies" 
                        value={val.nomAssocies} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Associes")}}  
                        error={!val.nomAssocies  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="prenomAssocies" 
                        value={val.prenomAssocies} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Associes")}}  
                        error={!val.prenomAssocies  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Divider className='mb-6 mt-6' variant="middle" />
            </div> 
          })}  
          {/* <div className='px-0 group flex items-center gap-2 text-primary hover:text-secondary mt-12 cursor-pointer' onClick={(e)=>{props.addForm(e,"Associes")}} >
            <BsPlusCircle className='text-3xl ' />
            <span className='text-base font-normal'>Ajouter un associés</span>
          </div> */}

        </div>
        {/* Directeur général (éventuellement) */}
        <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Directeur général (éventuellement)</h2>
          <div className={"grid grid-cols-12 items-start mt-6"} >
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Saisir données *</label>
            
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="show_data-label">Sélectionnez</InputLabel>
                <Select
                  labelId="show_data-label"
                  id="show_data"
                  label="Sélectionnez"
                  name="show_data"
                  value={`${props.Directeur_general.show_data}`}                         
                  onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}  
                  error={!props.Directeur_general.show_data  && props.isSubmitted?true:false} 
                >
                  <MenuItem value={"Non"} >Non</MenuItem>
                  <MenuItem value={"Oui"} >Oui</MenuItem>

                </Select>
              </FormControl>
            </div>
          </div>  
          <div className={props.Directeur_general.show_data === "Oui"?"":"hidden"}>
            <div className='Directeur_general'>
              <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Personne</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-9 md:mr-10 inline-flex items-center gap-2'>
                  <FormControl size="small" className='w-full'>
                    <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      // id={val.index}
                      label="Sélectionnez"
                      name="type_personne_titulaire"
                      value={props.Directeur_general.type_personne_titulaire} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}  
                      error={!props.Directeur_general.type_personne_titulaire  && props.isSubmitted?true:false}
                    >
                      <MenuItem value={"PERSONNE_PHYSIQUE"}>Personne physique</MenuItem>
                      <MenuItem value={"PERSONNE_MORALE"}>Personne morale</MenuItem>

                    </Select>
                  </FormControl>
                  <StyledTooltip title="La personne physique désigne un individu. La personne morale, elle, est une entité juridique.">
                    <div className='p-0 m-0 w-auto'>
                      <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer  hover:fill-secondary' />
                    </div>
                  </StyledTooltip>                  
                </div>                
              </div>
              <div className={props.Directeur_general.type_personne_titulaire === ""?"hidden":""}>
                <div className={props.Directeur_general.type_personne_titulaire === "PERSONNE_MORALE"?"":"hidden"}>      
                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>N° SIREN *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="rcs_titulaire"
                        value={props.Directeur_general.rcs_titulaire} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}   
                        error={!props.Directeur_general.rcs_titulaire  && props.isSubmitted?true:false}
                       
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="denomination_titulaire"
                        value={props.Directeur_general.denomination_titulaire} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}  
                        error={!props.Directeur_general.denomination_titulaire  && props.isSubmitted?true:false}
                        
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="forme_juridique_titulaire"
                          value={props.Directeur_general.forme_juridique_titulaire} 
                          onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}    
                          error={!props.Directeur_general.forme_juridique_titulaire  && props.isSubmitted?true:false}
                         
                        >
                          <MenuItem value={"SAS"}>Société par actions simplifiée (SAS)</MenuItem>
                          <MenuItem value={"SAS à capital variable"}>Société par actions simplifiée à capital variable</MenuItem>
                          <MenuItem value={"SASU"}>Société par actions simplifiée unipersonnelle (SASU)</MenuItem>
                          <MenuItem value={"SASU à capital variable"}>Société par actions simplifiée unipersonnelle à capital variable</MenuItem>
                          <MenuItem value={"SARL"}>Société à responsabilité limitée (SARL)</MenuItem>
                          <MenuItem value={"SARL à capital variable"}>Société à responsabilité limitée à capital variable</MenuItem>
                          <MenuItem value={"SARLU"}>Société à responsabilité limitée à associé unique (SARLU)</MenuItem>
                          <MenuItem value={"SARLU à capitale variable"}>Société à responsabilité limitée à associé unique à capitale variable</MenuItem>
                          <MenuItem value={"SCI"}>Société civile immobilière (SCI)</MenuItem>
                          <MenuItem value={"SCI à capital variable"}>Société civile immobilière à capital variable</MenuItem>
                          <MenuItem value={"SC"}>Société civile</MenuItem>
                          <MenuItem value={"SA"}>Société anonyme (SA)</MenuItem>
                          <MenuItem value={"SA à capital variable"}>Société anonyme à capital variable</MenuItem>
                          <MenuItem value={"SNC"}>Société en nom collectif (SNC)</MenuItem>
                          <MenuItem value={"SNC à capital variable"}>Société en nom collectif à capital variable</MenuItem>
                          <MenuItem value={"SCM"}>Société civile de moyens (SCM)</MenuItem>
                          <MenuItem value={"SCP"}>Société civile professionnelle (SCP)</MenuItem>
                          <MenuItem value={"SCPI"}>Société civile de placement immobilier (SCPI)</MenuItem>
                          <MenuItem value={"SCCV"}>Société civile immobilière de construction vente (SCCV ou SCICV)</MenuItem>
                          <MenuItem value={"SELAFA"}>Société d'exercice libéral sous forme anonyme (SELAFA)</MenuItem>
                          <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                          <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                          <MenuItem value={"SELCA"}>Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
                          <MenuItem value={"SELARL"}>Société d'exercice libéral a responsabilité limitée (SELARL)</MenuItem>
                          <MenuItem value={"SELEURL"}>Société d'exercice libéral ayant un associé Unique (SELEURL)</MenuItem>
                          <MenuItem value={"SELASU"}>Société d'exercice libéral par actions simplifiée unipersonnelle (SELASU)</MenuItem>
                          <MenuItem value={"SCEA"}>Société civile d'exploitation agricole (SCEA)</MenuItem>
                          <MenuItem value={"EARL"}>Exploitation agricole à responsabilité limitée (EARL)</MenuItem>
                          <MenuItem value={"GAEC"}>Groupement agricole d'exploitation en commun (GAEC)</MenuItem>
                          <MenuItem value={"GFA"}>Groupement foncier agricole (GFA)</MenuItem>
                          <MenuItem value={"EIRL"}>Entrepreneur Individuel à Responsabilité Limité</MenuItem>
                          <MenuItem value={"SPFPLAS"}>Société de Participations Financières de Profession Libérale d'Avocats Simplifiée (SPFPLAS)</MenuItem>
                        </Select>
                      </FormControl>
                      
                    </div>
                  </div>
                  <div  className={"grid grid-cols-12 items-center mt-6"} >
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>
                      
                      <FormControl size="small" className='w-full'>
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            'aria-label': 'weight',
                          }}
                          name="capital_titulaire"
                          value={props.Directeur_general.capital_titulaire} 
                          onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}   
                          error={!props.Directeur_general.capital_titulaire  && props.isSubmitted?true:false}
                          
                        />
                      </FormControl>

                      <StyledTooltip title="Montant du capital mentionné dans les statuts.">
                        <div className='p-0 m-0 w-auto'>
                          <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                        </div>
                      </StyledTooltip>

                    </div>
                  </div>
                </div> 
                <div className={props.Directeur_general.type_personne_titulaire === "PERSONNE_PHYSIQUE"?"":"hidden"}>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="civilite_titulaire_physique"
                          value={props.Directeur_general.civilite_titulaire_physique} 
                          onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}   
                          error={!props.Directeur_general.civilite_titulaire_physique  && props.isSubmitted?true:false}
                        >
                          <MenuItem value={"Mme"}>Madame</MenuItem>
                          <MenuItem value={"M."}>Monsieur</MenuItem>

                        </Select>
                      </FormControl>                      
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="nom_titulaire_physique"
                        value={props.Directeur_general.nom_titulaire_physique} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}   
                        error={!props.Directeur_general.nom_titulaire_physique  && props.isSubmitted?true:false}
                        
                      />
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="prenom_titulaire_physique" 
                        value={props.Directeur_general.prenom_titulaire_physique} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}  
                        error={!props.Directeur_general.prenom_titulaire_physique  && props.isSubmitted?true:false}
                      />
                    </div>
                  </div>
                </div>
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="adresse_titulaire" 
                      value={props.Directeur_general.adresse_titulaire} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}   
                      error={!props.Directeur_general.adresse_titulaire  && props.isSubmitted?true:false}
                      
                    />
                  </div>
                </div>
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Complément adresse</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="adresse_suite_titulaire"
                      value={props.Directeur_general.adresse_suite_titulaire} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}  
                      
                    />
                  </div>
                </div>
                

                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="code_postal_titulaire"
                      value={props.Directeur_general.code_postal_titulaire} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}  
                      error={!props.Directeur_general.code_postal_titulaire  && props.isSubmitted?true:false}
                     
                    />
                  </div>
                </div>

                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    {/* <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="ville_titulaire"
                      value={props.Directeur_general.ville_titulaire} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}  
                      error={!props.Directeur_general.ville_titulaire  && props.isSubmitted?true:false}
                      
                    /> */}
                    <FormControl size="small" className={((props.Directeur_general.type_personne_titulaire === "PERSONNE_PHYSIQUE" ))?" w-full":" w-full"} >
                      <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Sélectionnez"
                        name="ville_id_titulaire"
                        value={props.Directeur_general.ville_id_titulaire} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}  
                        error={!props.Directeur_general.ville_titulaire  && props.isSubmitted?true:false}                        
                        
                      >
                        {
                          props.Directeur_general.ville_titulaireArr.map((cityVal,cityIndex)=>{
                            return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                          })
                        }

                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className={props.Directeur_general.type_personne_titulaire === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe </label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="greffe_titulaire"
                      value={props.Directeur_general.greffe_titulaire} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}} 
                      
                    />
                  </div>
                </div>
                
              </div>
            </div>
          
          </div>
        </div>
        {/* Directeur général délégué (éventuellement) */}
       
        <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          {props.Directeur_general_delegue.map((val,key)=>{
            return (<div className='mt-4'>
              <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Directeur général délégué (éventuellement) 
              {key >0?
              <div className='p-0 m-0 w-auto'>
                  <RemoveCircleRoundedIcon fontSize='large' className='text-3xl  cursor-pointer  fill-secondary' onClick={(e)=>{props.removeForm(key,"Directeur_general_delegue")}} />
              </div>
              :""}
              </h2>
              
              <div className={"grid grid-cols-12 items-start mt-6"} >
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Saisir données *</label>
                
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <FormControl size="small" className='w-full'>
                    <InputLabel id="show_data-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="show_data-label"
                      id="show_data"
                      label="Sélectionnez"
                      name="show_data"
                      value={`${val.show_data}`}                         
                      onChange={(e)=>{props.handleChangeAddForm(e,key,"Directeur_general_delegue")}}  
                      error={!val.show_data  && props.isSubmitted?true:false} 
                    >
                      <MenuItem value={"Non"} >Non</MenuItem>
                      <MenuItem value={"Oui"} >Oui</MenuItem>

                    </Select>
                  </FormControl>
                </div>
              </div>  
              <div className={val.show_data === "Oui"?"":"hidden"}>
                <div className='Directeur_general'>
                  <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Personne</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-9 md:mr-10 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          // id={val.index}
                          label="Sélectionnez"
                          name="type_personne_titulaire"
                          value={val.type_personne_titulaire} 
                          onChange={(e)=>{props.handleChangeAddForm(e,key,"Directeur_general_delegue")}}  
                          error={!val.type_personne_titulaire  && props.isSubmitted?true:false}
                        >
                          <MenuItem value={"PERSONNE_PHYSIQUE"}>Personne physique</MenuItem>
                          <MenuItem value={"PERSONNE_MORALE"}>Personne morale</MenuItem>

                        </Select>
                      </FormControl>
                      <StyledTooltip title="La personne physique désigne un individu. La personne morale, elle, est une entité juridique.">
                        <div className='p-0 m-0 w-auto'>
                          <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer  hover:fill-secondary' />
                        </div>
                      </StyledTooltip>                  
                    </div>                
                  </div>
                  <div className={val.type_personne_titulaire === ""?"hidden":""}>
                    <div className={val.type_personne_titulaire === "PERSONNE_MORALE"?"":"hidden"}>      
                      <div className='grid grid-cols-12 mt-6 items-center'>
                        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>N° SIREN *</label>
                        <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                          <TextField
                            hiddenLabel
                            id="outlined-size-small"
                            size="small"
                            className='w-full'
                            name="rcs_titulaire"
                            value={val.rcs_titulaire} 
                            onChange={(e)=>{props.handleChangeAddForm(e,key,"Directeur_general_delegue")}}   
                            error={!val.rcs_titulaire  && props.isSubmitted?true:false}
                          
                          />
                        </div>
                      </div>
                      <div className='grid grid-cols-12 mt-6 items-center'>
                        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination *</label>
                        <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                          <TextField
                            hiddenLabel
                            id="outlined-size-small"
                            size="small"
                            className='w-full'
                            name="denomination_titulaire"
                            value={val.denomination_titulaire} 
                            onChange={(e)=>{props.handleChangeAddForm(e,key,"Directeur_general_delegue")}}  
                            error={!val.denomination_titulaire  && props.isSubmitted?true:false}
                            
                          />
                        </div>
                      </div>
                      <div className='grid grid-cols-12 items-start mt-6'>
                        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique *</label>
                        <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                          <FormControl size="small" className='w-full'>
                            <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Sélectionnez"
                              name="forme_juridique_titulaire"
                              value={val.forme_juridique_titulaire} 
                              onChange={(e)=>{props.handleChangeAddForm(e,key,"Directeur_general_delegue")}}    
                              error={!val.forme_juridique_titulaire  && props.isSubmitted?true:false}
                            
                            >
                              <MenuItem value={"SAS"}>Société par actions simplifiée (SAS)</MenuItem>
                              <MenuItem value={"SAS à capital variable"}>Société par actions simplifiée à capital variable</MenuItem>
                              <MenuItem value={"SASU"}>Société par actions simplifiée unipersonnelle (SASU)</MenuItem>
                              <MenuItem value={"SASU à capital variable"}>Société par actions simplifiée unipersonnelle à capital variable</MenuItem>
                              <MenuItem value={"SARL"}>Société à responsabilité limitée (SARL)</MenuItem>
                              <MenuItem value={"SARL à capital variable"}>Société à responsabilité limitée à capital variable</MenuItem>
                              <MenuItem value={"SARLU"}>Société à responsabilité limitée à associé unique (SARLU)</MenuItem>
                              <MenuItem value={"SARLU à capitale variable"}>Société à responsabilité limitée à associé unique à capitale variable</MenuItem>
                              <MenuItem value={"SCI"}>Société civile immobilière (SCI)</MenuItem>
                              <MenuItem value={"SCI à capital variable"}>Société civile immobilière à capital variable</MenuItem>
                              <MenuItem value={"SC"}>Société civile</MenuItem>
                              <MenuItem value={"SA"}>Société anonyme (SA)</MenuItem>
                              <MenuItem value={"SA à capital variable"}>Société anonyme à capital variable</MenuItem>
                              <MenuItem value={"SNC"}>Société en nom collectif (SNC)</MenuItem>
                              <MenuItem value={"SNC à capital variable"}>Société en nom collectif à capital variable</MenuItem>
                              <MenuItem value={"SCM"}>Société civile de moyens (SCM)</MenuItem>
                              <MenuItem value={"SCP"}>Société civile professionnelle (SCP)</MenuItem>
                              <MenuItem value={"SCPI"}>Société civile de placement immobilier (SCPI)</MenuItem>
                              <MenuItem value={"SCCV"}>Société civile immobilière de construction vente (SCCV ou SCICV)</MenuItem>
                              <MenuItem value={"SELAFA"}>Société d'exercice libéral sous forme anonyme (SELAFA)</MenuItem>
                              <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                              <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                              <MenuItem value={"SELCA"}>Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
                              <MenuItem value={"SELARL"}>Société d'exercice libéral a responsabilité limitée (SELARL)</MenuItem>
                              <MenuItem value={"SELEURL"}>Société d'exercice libéral ayant un associé Unique (SELEURL)</MenuItem>
                              <MenuItem value={"SELASU"}>Société d'exercice libéral par actions simplifiée unipersonnelle (SELASU)</MenuItem>
                              <MenuItem value={"SCEA"}>Société civile d'exploitation agricole (SCEA)</MenuItem>
                              <MenuItem value={"EARL"}>Exploitation agricole à responsabilité limitée (EARL)</MenuItem>
                              <MenuItem value={"GAEC"}>Groupement agricole d'exploitation en commun (GAEC)</MenuItem>
                              <MenuItem value={"GFA"}>Groupement foncier agricole (GFA)</MenuItem>
                              <MenuItem value={"EIRL"}>Entrepreneur Individuel à Responsabilité Limité</MenuItem>
                              <MenuItem value={"SPFPLAS"}>Société de Participations Financières de Profession Libérale d'Avocats Simplifiée (SPFPLAS)</MenuItem>
                            </Select>
                          </FormControl>
                          
                        </div>
                      </div>
                      <div  className={"grid grid-cols-12 items-center mt-6"} >
                        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital *</label>
                        <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>
                          
                          <FormControl size="small" className='w-full'>
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                              aria-describedby="outlined-weight-helper-text"
                              inputProps={{
                                'aria-label': 'weight',
                              }}
                              name="capital_titulaire"
                              value={val.capital_titulaire} 
                              onChange={(e)=>{props.handleChangeAddForm(e,key,"Directeur_general_delegue")}}   
                              error={!val.capital_titulaire  && props.isSubmitted?true:false}
                              
                            />
                          </FormControl>

                          <StyledTooltip title="Montant du capital mentionné dans les statuts.">
                            <div className='p-0 m-0 w-auto'>
                              <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                            </div>
                          </StyledTooltip>

                        </div>
                      </div>
                    </div> 
                    <div className={val.type_personne_titulaire === "PERSONNE_PHYSIQUE"?"":"hidden"}>
                      <div className='grid grid-cols-12 items-start mt-6'>
                        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité *</label>
                        <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                          <FormControl size="small" className='w-full'>
                            <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Sélectionnez"
                              name="civilite_titulaire_physique"
                              value={val.civilite_titulaire_physique} 
                              onChange={(e)=>{props.handleChangeAddForm(e,key,"Directeur_general_delegue")}}   
                              error={!val.civilite_titulaire_physique  && props.isSubmitted?true:false}
                            >
                              <MenuItem value={"Mme"}>Madame</MenuItem>
                              <MenuItem value={"M."}>Monsieur</MenuItem>

                            </Select>
                          </FormControl>                      
                        </div>
                      </div>

                      <div className='grid grid-cols-12 mt-6 items-center'>
                        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                        <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                          <TextField
                            hiddenLabel
                            id="outlined-size-small"
                            size="small"
                            className='w-full'
                            name="nom_titulaire_physique"
                            value={val.nom_titulaire_physique} 
                            onChange={(e)=>{props.handleChangeAddForm(e,key,"Directeur_general_delegue")}}   
                            error={!val.nom_titulaire_physique  && props.isSubmitted?true:false}
                            
                          />
                        </div>
                      </div>

                      <div className='grid grid-cols-12 mt-6 items-center'>
                        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                        <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                          <TextField
                            hiddenLabel
                            id="outlined-size-small"
                            size="small"
                            className='w-full'
                            name="prenom_titulaire_physique" 
                            value={val.prenom_titulaire_physique} 
                            onChange={(e)=>{props.handleChangeAddForm(e,key,"Directeur_general_delegue")}}  
                            error={!val.prenom_titulaire_physique  && props.isSubmitted?true:false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='grid grid-cols-12 mt-6 items-center'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                          name="adresse_titulaire" 
                          value={val.adresse_titulaire} 
                          onChange={(e)=>{props.handleChangeAddForm(e,key,"Directeur_general_delegue")}}   
                          error={!val.adresse_titulaire  && props.isSubmitted?true:false}
                          
                        />
                      </div>
                    </div>
                    <div className='grid grid-cols-12 mt-6 items-center'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Complément adresse</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                          name="adresse_suite_titulaire"
                          value={val.adresse_suite_titulaire} 
                          onChange={(e)=>{props.handleChangeAddForm(e,key,"Directeur_general_delegue")}}  
                          
                        />
                      </div>
                    </div>
                    

                    <div className='grid grid-cols-12 mt-6 items-center'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                          name="code_postal_titulaire"
                          value={val.code_postal_titulaire} 
                          onChange={(e)=>{props.handleChangeAddForm(e,key,"Directeur_general_delegue")}}  
                          error={!val.code_postal_titulaire  && props.isSubmitted?true:false}
                        
                        />
                      </div>
                    </div>

                    <div className='grid grid-cols-12 mt-6 items-center'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                        {/* <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                          name="ville_titulaire"
                          value={val.ville_titulaire} 
                          onChange={(e)=>{props.handleChangeAddForm(e,key,"Directeur_general_delegue")}}  
                          error={!val.ville_titulaire  && props.isSubmitted?true:false}
                          
                        /> */}
                        <FormControl size="small"  className={((val.type_personne_titulaire === "PERSONNE_PHYSIQUE" ))?" w-full":" w-full"}
                          name="ville_titulaire" >
                          <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Sélectionnez"                              
                            name="ville_id_titulaire"
                            value={val.ville_id_titulaire} 
                            onChange={(e)=>{props.handleChangeAddForm(e,key,"Directeur_general_delegue")}}  
                            error={!val.ville_titulaire  && props.isSubmitted?true:false}                     
                            
                          >
                            {
                              val.ville_titulaireArr.map((cityVal,cityIndex)=>{
                                return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                              })
                            }

                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className={val.type_personne_titulaire === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe </label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                          name="greffe_titulaire"
                          value={val.greffe_titulaire} 
                          onChange={(e)=>{props.handleChangeAddForm(e,key,"Directeur_general_delegue")}} 
                          
                        />
                      </div>
                    </div>
                    
                  </div>
                </div>
              
              </div>
            </div>)
          })}
          <div className='px-0 group flex items-center gap-2 text-primary hover:text-secondary mt-2 cursor-pointer '  onClick={(e)=>{ props.addForm(e,"Directeur_general_delegue")}}>
            <BsPlusCircle className='text-3xl ' />
            <span className='text-base font-normal'>Ajouter</span>
          </div>
          
        </div>
       {/* Administrateurs (trois minimum) */}
       
       <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          {props.Administrateurs.map((val,key)=>{
            return (<div className='mt-4'>
              <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Administrateurs (trois minimum) 
              {key >0?
              <div className='p-0 m-0 w-auto'>
                  <RemoveCircleRoundedIcon fontSize='large' className='text-3xl  cursor-pointer  fill-secondary' onClick={(e)=>{props.removeForm(key,"Administrateurs")}} />
              </div>
              :""}
              </h2>
              
              <div className={"grid grid-cols-12 items-start mt-6"} >
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Saisir données *</label>
                
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <FormControl size="small" className='w-full'>
                    <InputLabel id="show_data-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="show_data-label"
                      id="show_data"
                      label="Sélectionnez"
                      name="show_data"
                      value={`${val.show_data}`}                         
                      onChange={(e)=>{props.handleChangeAddForm(e,key,"Administrateurs")}}  
                      error={!val.show_data  && props.isSubmitted?true:false} 
                    >
                      <MenuItem value={"Non"} >Non</MenuItem>
                      <MenuItem value={"Oui"} >Oui</MenuItem>

                    </Select>
                  </FormControl>
                </div>
              </div>  
              <div className={val.show_data === "Oui"?"":"hidden"}>
                <div className='Directeur_general'>
                  <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Personne</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-9 md:mr-10 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          // id={val.index}
                          label="Sélectionnez"
                          name="type_personne_titulaire"
                          value={val.type_personne_titulaire} 
                          onChange={(e)=>{props.handleChangeAddForm(e,key,"Administrateurs")}}  
                          error={!val.type_personne_titulaire  && props.isSubmitted?true:false}
                        >
                          <MenuItem value={"PERSONNE_PHYSIQUE"}>Personne physique</MenuItem>
                          <MenuItem value={"PERSONNE_MORALE"}>Personne morale</MenuItem>

                        </Select>
                      </FormControl>
                      <StyledTooltip title="La personne physique désigne un individu. La personne morale, elle, est une entité juridique.">
                        <div className='p-0 m-0 w-auto'>
                          <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer  hover:fill-secondary' />
                        </div>
                      </StyledTooltip>                  
                    </div>                
                  </div>
                  <div className={val.type_personne_titulaire === ""?"hidden":""}>
                    <div className={val.type_personne_titulaire === "PERSONNE_MORALE"?"":"hidden"}>      
                      <div className='grid grid-cols-12 mt-6 items-center'>
                        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>N° SIREN *</label>
                        <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                          <TextField
                            hiddenLabel
                            id="outlined-size-small"
                            size="small"
                            className='w-full'
                            name="rcs_titulaire"
                            value={val.rcs_titulaire} 
                            onChange={(e)=>{props.handleChangeAddForm(e,key,"Administrateurs")}}   
                            error={!val.rcs_titulaire  && props.isSubmitted?true:false}
                          
                          />
                        </div>
                      </div>
                      <div className='grid grid-cols-12 mt-6 items-center'>
                        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination *</label>
                        <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                          <TextField
                            hiddenLabel
                            id="outlined-size-small"
                            size="small"
                            className='w-full'
                            name="denomination_titulaire"
                            value={val.denomination_titulaire} 
                            onChange={(e)=>{props.handleChangeAddForm(e,key,"Administrateurs")}}  
                            error={!val.denomination_titulaire  && props.isSubmitted?true:false}
                            
                          />
                        </div>
                      </div>
                      <div className='grid grid-cols-12 items-start mt-6'>
                        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique *</label>
                        <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                          <FormControl size="small" className='w-full'>
                            <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Sélectionnez"
                              name="forme_juridique_titulaire"
                              value={val.forme_juridique_titulaire} 
                              onChange={(e)=>{props.handleChangeAddForm(e,key,"Administrateurs")}}    
                              error={!val.forme_juridique_titulaire  && props.isSubmitted?true:false}
                            
                            >
                              <MenuItem value={"SAS"}>Société par actions simplifiée (SAS)</MenuItem>
                              <MenuItem value={"SAS à capital variable"}>Société par actions simplifiée à capital variable</MenuItem>
                              <MenuItem value={"SASU"}>Société par actions simplifiée unipersonnelle (SASU)</MenuItem>
                              <MenuItem value={"SASU à capital variable"}>Société par actions simplifiée unipersonnelle à capital variable</MenuItem>
                              <MenuItem value={"SARL"}>Société à responsabilité limitée (SARL)</MenuItem>
                              <MenuItem value={"SARL à capital variable"}>Société à responsabilité limitée à capital variable</MenuItem>
                              <MenuItem value={"SARLU"}>Société à responsabilité limitée à associé unique (SARLU)</MenuItem>
                              <MenuItem value={"SARLU à capitale variable"}>Société à responsabilité limitée à associé unique à capitale variable</MenuItem>
                              <MenuItem value={"SCI"}>Société civile immobilière (SCI)</MenuItem>
                              <MenuItem value={"SCI à capital variable"}>Société civile immobilière à capital variable</MenuItem>
                              <MenuItem value={"SC"}>Société civile</MenuItem>
                              <MenuItem value={"SA"}>Société anonyme (SA)</MenuItem>
                              <MenuItem value={"SA à capital variable"}>Société anonyme à capital variable</MenuItem>
                              <MenuItem value={"SNC"}>Société en nom collectif (SNC)</MenuItem>
                              <MenuItem value={"SNC à capital variable"}>Société en nom collectif à capital variable</MenuItem>
                              <MenuItem value={"SCM"}>Société civile de moyens (SCM)</MenuItem>
                              <MenuItem value={"SCP"}>Société civile professionnelle (SCP)</MenuItem>
                              <MenuItem value={"SCPI"}>Société civile de placement immobilier (SCPI)</MenuItem>
                              <MenuItem value={"SCCV"}>Société civile immobilière de construction vente (SCCV ou SCICV)</MenuItem>
                              <MenuItem value={"SELAFA"}>Société d'exercice libéral sous forme anonyme (SELAFA)</MenuItem>
                              <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                              <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                              <MenuItem value={"SELCA"}>Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
                              <MenuItem value={"SELARL"}>Société d'exercice libéral a responsabilité limitée (SELARL)</MenuItem>
                              <MenuItem value={"SELEURL"}>Société d'exercice libéral ayant un associé Unique (SELEURL)</MenuItem>
                              <MenuItem value={"SELASU"}>Société d'exercice libéral par actions simplifiée unipersonnelle (SELASU)</MenuItem>
                              <MenuItem value={"SCEA"}>Société civile d'exploitation agricole (SCEA)</MenuItem>
                              <MenuItem value={"EARL"}>Exploitation agricole à responsabilité limitée (EARL)</MenuItem>
                              <MenuItem value={"GAEC"}>Groupement agricole d'exploitation en commun (GAEC)</MenuItem>
                              <MenuItem value={"GFA"}>Groupement foncier agricole (GFA)</MenuItem>
                              <MenuItem value={"EIRL"}>Entrepreneur Individuel à Responsabilité Limité</MenuItem>
                              <MenuItem value={"SPFPLAS"}>Société de Participations Financières de Profession Libérale d'Avocats Simplifiée (SPFPLAS)</MenuItem>
                            </Select>
                          </FormControl>
                          
                        </div>
                      </div>
                      <div  className={"grid grid-cols-12 items-center mt-6"} >
                        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital *</label>
                        <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>
                          
                          <FormControl size="small" className='w-full'>
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                              aria-describedby="outlined-weight-helper-text"
                              inputProps={{
                                'aria-label': 'weight',
                              }}
                              name="capital_titulaire"
                              value={val.capital_titulaire} 
                              onChange={(e)=>{props.handleChangeAddForm(e,key,"Administrateurs")}}   
                              error={!val.capital_titulaire  && props.isSubmitted?true:false}
                              
                            />
                          </FormControl>

                          <StyledTooltip title="Montant du capital mentionné dans les statuts.">
                            <div className='p-0 m-0 w-auto'>
                              <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                            </div>
                          </StyledTooltip>

                        </div>
                      </div>
                    </div> 
                    <div className={val.type_personne_titulaire === "PERSONNE_PHYSIQUE"?"":"hidden"}>
                      <div className='grid grid-cols-12 items-start mt-6'>
                        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité *</label>
                        <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                          <FormControl size="small" className='w-full'>
                            <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Sélectionnez"
                              name="civilite_titulaire_physique"
                              value={val.civilite_titulaire_physique} 
                              onChange={(e)=>{props.handleChangeAddForm(e,key,"Administrateurs")}}   
                              error={!val.civilite_titulaire_physique  && props.isSubmitted?true:false}
                            >
                              <MenuItem value={"Mme"}>Madame</MenuItem>
                              <MenuItem value={"M."}>Monsieur</MenuItem>

                            </Select>
                          </FormControl>                      
                        </div>
                      </div>

                      <div className='grid grid-cols-12 mt-6 items-center'>
                        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                        <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                          <TextField
                            hiddenLabel
                            id="outlined-size-small"
                            size="small"
                            className='w-full'
                            name="nom_titulaire_physique"
                            value={val.nom_titulaire_physique} 
                            onChange={(e)=>{props.handleChangeAddForm(e,key,"Administrateurs")}}   
                            error={!val.nom_titulaire_physique  && props.isSubmitted?true:false}
                            
                          />
                        </div>
                      </div>

                      <div className='grid grid-cols-12 mt-6 items-center'>
                        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                        <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                          <TextField
                            hiddenLabel
                            id="outlined-size-small"
                            size="small"
                            className='w-full'
                            name="prenom_titulaire_physique" 
                            value={val.prenom_titulaire_physique} 
                            onChange={(e)=>{props.handleChangeAddForm(e,key,"Administrateurs")}}  
                            error={!val.prenom_titulaire_physique  && props.isSubmitted?true:false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='grid grid-cols-12 mt-6 items-center'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                          name="adresse_titulaire" 
                          value={val.adresse_titulaire} 
                          onChange={(e)=>{props.handleChangeAddForm(e,key,"Administrateurs")}}   
                          error={!val.adresse_titulaire  && props.isSubmitted?true:false}
                          
                        />
                      </div>
                    </div>
                    <div className='grid grid-cols-12 mt-6 items-center'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Complément adresse</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                          name="adresse_suite_titulaire"
                          value={val.adresse_suite_titulaire} 
                          onChange={(e)=>{props.handleChangeAddForm(e,key,"Administrateurs")}}  
                          
                        />
                      </div>
                    </div>
                    

                    <div className='grid grid-cols-12 mt-6 items-center'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                          name="code_postal_titulaire"
                          value={val.code_postal_titulaire} 
                          onChange={(e)=>{props.handleChangeAddForm(e,key,"Administrateurs")}}  
                          error={!val.code_postal_titulaire  && props.isSubmitted?true:false}
                        
                        />
                      </div>
                    </div>

                    <div className='grid grid-cols-12 mt-6 items-center'>
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                        {/* <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                          name="ville_titulaire"
                          value={val.ville_titulaire} 
                          onChange={(e)=>{props.handleChangeAddForm(e,key,"Administrateurs")}}  
                          error={!val.ville_titulaire  && props.isSubmitted?true:false}
                          
                        /> */}
                        <FormControl size="small"  className={((val.type_personne_titulaire === "PERSONNE_PHYSIQUE" ))?" w-full":" w-full"}
                          name="ville_titulaire" >
                          <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Sélectionnez"                              
                            name="ville_id_titulaire"
                            value={val.ville_id_titulaire} 
                            onChange={(e)=>{props.handleChangeAddForm(e,key,"Administrateurs")}}  
                            error={!val.ville_titulaire  && props.isSubmitted?true:false}                     
                            
                          >
                            {
                              val.ville_titulaireArr.map((cityVal,cityIndex)=>{
                                return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                              })
                            }

                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className={val.type_personne_titulaire === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
                      <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe </label>
                      <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          className='w-full'
                          name="greffe_titulaire"
                          value={val.greffe_titulaire} 
                          onChange={(e)=>{props.handleChangeAddForm(e,key,"Administrateurs")}} 
                          
                        />
                      </div>
                    </div>
                    
                  </div>
                </div>
              
              </div>
            </div>)
          })}
          <div className='px-0 group flex items-center gap-2 text-primary hover:text-secondary mt-2 cursor-pointer '  onClick={(e)=>{ props.addForm(e,"Administrateurs")}}>
            <BsPlusCircle className='text-3xl ' />
            <span className='text-base font-normal'>Ajouter</span>
          </div>
          
        </div>
        
        {/* Commissaire aux comptes */}
        <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Commissaire aux comptes</h2>
          <div className={"grid grid-cols-12 items-start mt-6"} >
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Saisir données *</label>
            
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="show_data-label">Sélectionnez</InputLabel>
                <Select
                  labelId="show_data-label"
                  id="show_data"
                  label="Sélectionnez"
                  name="show_data"
                  value={`${props.CommissaireAuxComptes.show_data}`}                         
                  onChange={(e)=>{props.handleChangeAddForm(e,0,"commissaireAuxComptes")}}  
                  error={!props.CommissaireAuxComptes.show_data  && props.isSubmitted?true:false} 
                >
                  <MenuItem value={"Non"} >Non</MenuItem>
                  <MenuItem value={"Oui"} >Oui</MenuItem>

                </Select>
              </FormControl>
            </div>
          </div>  
          <div className={props.CommissaireAuxComptes.show_data === "Oui"?"":"hidden"}>
            <div className='titulaire'>
              <div className='px-0 group flex items-center gap-2 text-secondary mt-12 '>                   
                <span className='text-base font-normal'>CAC titulaire</span>
              </div>
              <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Personne</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-9 md:mr-10 inline-flex items-center gap-2'>
                  <FormControl size="small" className='w-full'>
                    <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      // id={val.index}
                      label="Sélectionnez"
                      name="type_personne_titulaire"
                      value={props.CommissaireAuxComptes.CAC_titulaire.type_personne_titulaire} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}  
                      error={!props.CommissaireAuxComptes.CAC_titulaire.type_personne_titulaire  && props.isSubmitted?true:false}
                    >
                      <MenuItem value={"PERSONNE_PHYSIQUE"}>Personne physique</MenuItem>
                      <MenuItem value={"PERSONNE_MORALE"}>Personne morale</MenuItem>

                    </Select>
                  </FormControl>
                  <StyledTooltip title="La personne physique désigne un individu. La personne morale, elle, est une entité juridique.">
                    <div className='p-0 m-0 w-auto'>
                      <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer  hover:fill-secondary' />
                    </div>
                  </StyledTooltip>                  
                </div>                
              </div>
              <div className={props.CommissaireAuxComptes.CAC_titulaire.type_personne_titulaire === ""?"hidden":""}>
                <div className={props.CommissaireAuxComptes.CAC_titulaire.type_personne_titulaire === "PERSONNE_MORALE"?"":"hidden"}>      
                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>N° SIREN *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="rcs_titulaire"
                        value={props.CommissaireAuxComptes.CAC_titulaire.rcs_titulaire} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}   
                        error={!props.CommissaireAuxComptes.CAC_titulaire.rcs_titulaire  && props.isSubmitted?true:false}
                       
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="denomination_titulaire"
                        value={props.CommissaireAuxComptes.CAC_titulaire.denomination_titulaire} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}  
                        error={!props.CommissaireAuxComptes.CAC_titulaire.denomination_titulaire  && props.isSubmitted?true:false}
                        
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="forme_juridique_titulaire"
                          value={props.CommissaireAuxComptes.CAC_titulaire.forme_juridique_titulaire} 
                          onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}    
                          error={!props.CommissaireAuxComptes.CAC_titulaire.forme_juridique_titulaire  && props.isSubmitted?true:false}
                         
                        >
                          <MenuItem value={"SAS"}>Société par actions simplifiée (SAS)</MenuItem>
                          <MenuItem value={"SAS à capital variable"}>Société par actions simplifiée à capital variable</MenuItem>
                          <MenuItem value={"SASU"}>Société par actions simplifiée unipersonnelle (SASU)</MenuItem>
                          <MenuItem value={"SASU à capital variable"}>Société par actions simplifiée unipersonnelle à capital variable</MenuItem>
                          <MenuItem value={"SARL"}>Société à responsabilité limitée (SARL)</MenuItem>
                          <MenuItem value={"SARL à capital variable"}>Société à responsabilité limitée à capital variable</MenuItem>
                          <MenuItem value={"SARLU"}>Société à responsabilité limitée à associé unique (SARLU)</MenuItem>
                          <MenuItem value={"SARLU à capitale variable"}>Société à responsabilité limitée à associé unique à capitale variable</MenuItem>
                          <MenuItem value={"SCI"}>Société civile immobilière (SCI)</MenuItem>
                          <MenuItem value={"SCI à capital variable"}>Société civile immobilière à capital variable</MenuItem>
                          <MenuItem value={"SC"}>Société civile</MenuItem>
                          <MenuItem value={"SA"}>Société anonyme (SA)</MenuItem>
                          <MenuItem value={"SA à capital variable"}>Société anonyme à capital variable</MenuItem>
                          <MenuItem value={"SNC"}>Société en nom collectif (SNC)</MenuItem>
                          <MenuItem value={"SNC à capital variable"}>Société en nom collectif à capital variable</MenuItem>
                          <MenuItem value={"SCM"}>Société civile de moyens (SCM)</MenuItem>
                          <MenuItem value={"SCP"}>Société civile professionnelle (SCP)</MenuItem>
                          <MenuItem value={"SCPI"}>Société civile de placement immobilier (SCPI)</MenuItem>
                          <MenuItem value={"SCCV"}>Société civile immobilière de construction vente (SCCV ou SCICV)</MenuItem>
                          <MenuItem value={"SELAFA"}>Société d'exercice libéral sous forme anonyme (SELAFA)</MenuItem>
                          <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                          <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                          <MenuItem value={"SELCA"}>Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
                          <MenuItem value={"SELARL"}>Société d'exercice libéral a responsabilité limitée (SELARL)</MenuItem>
                          <MenuItem value={"SELEURL"}>Société d'exercice libéral ayant un associé Unique (SELEURL)</MenuItem>
                          <MenuItem value={"SELASU"}>Société d'exercice libéral par actions simplifiée unipersonnelle (SELASU)</MenuItem>
                          <MenuItem value={"SCEA"}>Société civile d'exploitation agricole (SCEA)</MenuItem>
                          <MenuItem value={"EARL"}>Exploitation agricole à responsabilité limitée (EARL)</MenuItem>
                          <MenuItem value={"GAEC"}>Groupement agricole d'exploitation en commun (GAEC)</MenuItem>
                          <MenuItem value={"GFA"}>Groupement foncier agricole (GFA)</MenuItem>
                          <MenuItem value={"EIRL"}>Entrepreneur Individuel à Responsabilité Limité</MenuItem>
                          <MenuItem value={"SPFPLAS"}>Société de Participations Financières de Profession Libérale d'Avocats Simplifiée (SPFPLAS)</MenuItem>
                        </Select>
                      </FormControl>
                      
                    </div>
                  </div>
                  <div  className={"grid grid-cols-12 items-center mt-6"} >
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>
                      
                      <FormControl size="small" className='w-full'>
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            'aria-label': 'weight',
                          }}
                          name="capital_titulaire"
                          value={props.CommissaireAuxComptes.CAC_titulaire.capital_titulaire} 
                          onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}   
                          error={!props.CommissaireAuxComptes.CAC_titulaire.capital_titulaire  && props.isSubmitted?true:false}
                          
                        />
                      </FormControl>

                      <StyledTooltip title="Montant du capital mentionné dans les statuts.">
                        <div className='p-0 m-0 w-auto'>
                          <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                        </div>
                      </StyledTooltip>

                    </div>
                  </div>
                </div> 
                <div className={props.CommissaireAuxComptes.CAC_titulaire.type_personne_titulaire === "PERSONNE_PHYSIQUE"?"":"hidden"}>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="civilite_titulaire_physique"
                          value={props.CommissaireAuxComptes.CAC_titulaire.civilite_titulaire_physique} 
                          onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}   
                          error={!props.CommissaireAuxComptes.CAC_titulaire.civilite_titulaire_physique  && props.isSubmitted?true:false}
                         
                          
                        >
                          <MenuItem value={"Mme"}>Madame</MenuItem>
                          <MenuItem value={"M."}>Monsieur</MenuItem>

                        </Select>
                      </FormControl>                      
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="nom_titulaire_physique"
                        value={props.CommissaireAuxComptes.CAC_titulaire.nom_titulaire_physique} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}   
                        error={!props.CommissaireAuxComptes.CAC_titulaire.nom_titulaire_physique  && props.isSubmitted?true:false}
                        
                      />
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="prenom_titulaire_physique" 
                        value={props.CommissaireAuxComptes.CAC_titulaire.prenom_titulaire_physique} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}  
                        error={!props.CommissaireAuxComptes.CAC_titulaire.prenom_titulaire_physique  && props.isSubmitted?true:false}
                        
                      />
                    </div>
                  </div>
                </div>
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="adresse_titulaire" 
                      value={props.CommissaireAuxComptes.CAC_titulaire.adresse_titulaire} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}   
                      error={!props.CommissaireAuxComptes.CAC_titulaire.adresse_titulaire  && props.isSubmitted?true:false}
                      
                    />
                  </div>
                </div>
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Complément adresse</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="adresse_suite_titulaire"
                      value={props.CommissaireAuxComptes.CAC_titulaire.adresse_suite_titulaire} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}  
                      
                    />
                  </div>
                </div>

                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="code_postal_titulaire"
                      value={props.CommissaireAuxComptes.CAC_titulaire.code_postal_titulaire} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}  
                      error={!props.CommissaireAuxComptes.CAC_titulaire.code_postal_titulaire  && props.isSubmitted?true:false}
                     
                    />
                  </div>
                </div>

                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    {/* <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="ville_titulaire"
                      value={props.CommissaireAuxComptes.CAC_titulaire.ville_titulaire} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}  
                      error={!props.CommissaireAuxComptes.CAC_titulaire.ville_titulaire  && props.isSubmitted?true:false}
                      
                    /> */}
                    <FormControl size="small" className={" w-full"} >
                      <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Sélectionnez"
                        name="ville_id_titulaire"
                        value={props.CommissaireAuxComptes.CAC_titulaire.ville_id_titulaire} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}  
                        error={!props.CommissaireAuxComptes.CAC_titulaire.ville_titulaire  && props.isSubmitted?true:false}
                       
                      >
                        {
                          props.CommissaireAuxComptes.CAC_titulaire.ville_titulaireArr.map((cityVal,cityIndex)=>{
                            return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                          })
                        }

                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className={props.CommissaireAuxComptes.CAC_titulaire.type_personne_titulaire === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe </label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="greffe_titulaire"
                      value={props.CommissaireAuxComptes.CAC_titulaire.greffe_titulaire} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}} 
                      
                    />
                  </div>
                </div>
                
              </div>
            </div>
            <div className='suppleant'>
              <div className='px-0 group flex items-center gap-2 text-secondary mt-12 '>                   
                <span className='text-base font-normal'>CAC suppléant</span>
              </div>
              <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Personne</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-9 md:mr-10 inline-flex items-center gap-2'>
                  <FormControl size="small" className='w-full'>
                    <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      // id={val.index}
                      label="Sélectionnez"
                      name="type_personne_suppleant"
                      value={props.CommissaireAuxComptes.CAC_suppleant.type_personne_suppleant} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}  
                      error={!props.CommissaireAuxComptes.CAC_suppleant.type_personne_suppleant  && props.isSubmitted?true:false}
                    >
                      <MenuItem value={"PERSONNE_PHYSIQUE"}>Personne physique</MenuItem>
                      <MenuItem value={"PERSONNE_MORALE"}>Personne morale</MenuItem>

                    </Select>
                  </FormControl>
                  <StyledTooltip title="La personne physique désigne un individu. La personne morale, elle, est une entité juridique.">
                    <div className='p-0 m-0 w-auto'>
                      <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer  hover:fill-secondary' />
                    </div>
                  </StyledTooltip>                  
                </div>                
              </div>
              <div className={props.CommissaireAuxComptes.CAC_suppleant.type_personne_suppleant === ""?"hidden":""}>
                <div className={props.CommissaireAuxComptes.CAC_suppleant.type_personne_suppleant === "PERSONNE_MORALE"?"":"hidden"}>      
                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>N° SIREN *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="rcs_suppleant"
                        value={props.CommissaireAuxComptes.CAC_suppleant.rcs_suppleant} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}   
                        error={!props.CommissaireAuxComptes.CAC_suppleant.rcs_suppleant  && props.isSubmitted?true:false}
                       
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="denomination_suppleant"
                        value={props.CommissaireAuxComptes.CAC_suppleant.denomination_suppleant} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}  
                        error={!props.CommissaireAuxComptes.CAC_suppleant.denomination_suppleant  && props.isSubmitted?true:false}
                        
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="forme_juridique_suppleant"
                          value={props.CommissaireAuxComptes.CAC_suppleant.forme_juridique_suppleant} 
                          onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}    
                          error={!props.CommissaireAuxComptes.CAC_suppleant.forme_juridique_suppleant  && props.isSubmitted?true:false}
                         
                        >
                          <MenuItem value={"SAS"}>Société par actions simplifiée (SAS)</MenuItem>
                          <MenuItem value={"SAS à capital variable"}>Société par actions simplifiée à capital variable</MenuItem>
                          <MenuItem value={"SASU"}>Société par actions simplifiée unipersonnelle (SASU)</MenuItem>
                          <MenuItem value={"SASU à capital variable"}>Société par actions simplifiée unipersonnelle à capital variable</MenuItem>
                          <MenuItem value={"SARL"}>Société à responsabilité limitée (SARL)</MenuItem>
                          <MenuItem value={"SARL à capital variable"}>Société à responsabilité limitée à capital variable</MenuItem>
                          <MenuItem value={"SARLU"}>Société à responsabilité limitée à associé unique (SARLU)</MenuItem>
                          <MenuItem value={"SARLU à capitale variable"}>Société à responsabilité limitée à associé unique à capitale variable</MenuItem>
                          <MenuItem value={"SCI"}>Société civile immobilière (SCI)</MenuItem>
                          <MenuItem value={"SCI à capital variable"}>Société civile immobilière à capital variable</MenuItem>
                          <MenuItem value={"SC"}>Société civile</MenuItem>
                          <MenuItem value={"SA"}>Société anonyme (SA)</MenuItem>
                          <MenuItem value={"SA à capital variable"}>Société anonyme à capital variable</MenuItem>
                          <MenuItem value={"SNC"}>Société en nom collectif (SNC)</MenuItem>
                          <MenuItem value={"SNC à capital variable"}>Société en nom collectif à capital variable</MenuItem>
                          <MenuItem value={"SCM"}>Société civile de moyens (SCM)</MenuItem>
                          <MenuItem value={"SCP"}>Société civile professionnelle (SCP)</MenuItem>
                          <MenuItem value={"SCPI"}>Société civile de placement immobilier (SCPI)</MenuItem>
                          <MenuItem value={"SCCV"}>Société civile immobilière de construction vente (SCCV ou SCICV)</MenuItem>
                          <MenuItem value={"SELAFA"}>Société d'exercice libéral sous forme anonyme (SELAFA)</MenuItem>
                          <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                          <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                          <MenuItem value={"SELCA"}>Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
                          <MenuItem value={"SELARL"}>Société d'exercice libéral a responsabilité limitée (SELARL)</MenuItem>
                          <MenuItem value={"SELEURL"}>Société d'exercice libéral ayant un associé Unique (SELEURL)</MenuItem>
                          <MenuItem value={"SELASU"}>Société d'exercice libéral par actions simplifiée unipersonnelle (SELASU)</MenuItem>
                          <MenuItem value={"SCEA"}>Société civile d'exploitation agricole (SCEA)</MenuItem>
                          <MenuItem value={"EARL"}>Exploitation agricole à responsabilité limitée (EARL)</MenuItem>
                          <MenuItem value={"GAEC"}>Groupement agricole d'exploitation en commun (GAEC)</MenuItem>
                          <MenuItem value={"GFA"}>Groupement foncier agricole (GFA)</MenuItem>
                          <MenuItem value={"EIRL"}>Entrepreneur Individuel à Responsabilité Limité</MenuItem>
                          <MenuItem value={"SPFPLAS"}>Société de Participations Financières de Profession Libérale d'Avocats Simplifiée (SPFPLAS)</MenuItem>
                        </Select>
                      </FormControl>
                      
                    </div>
                  </div>
                  <div  className={"grid grid-cols-12 items-center mt-6"} >
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>
                      
                      <FormControl size="small" className='w-full'>
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            'aria-label': 'weight',
                          }}
                          name="capital_suppleant"
                          value={props.CommissaireAuxComptes.CAC_suppleant.capital_suppleant} 
                          onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}   
                          error={!props.CommissaireAuxComptes.CAC_suppleant.capital_suppleant  && props.isSubmitted?true:false}
                          
                        />
                      </FormControl>

                      <StyledTooltip title="Montant du capital mentionné dans les statuts.">
                        <div className='p-0 m-0 w-auto'>
                          <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                        </div>
                      </StyledTooltip>

                    </div>
                  </div>
                </div> 
                <div className={props.CommissaireAuxComptes.CAC_suppleant.type_personne_suppleant === "PERSONNE_PHYSIQUE"?"":"hidden"}>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="civilite_suppleant_physique"
                          value={props.CommissaireAuxComptes.CAC_suppleant.civilite_suppleant_physique} 
                          onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}   
                          error={!props.CommissaireAuxComptes.CAC_suppleant.civilite_suppleant_physique  && props.isSubmitted?true:false}
                         
                          
                        >
                          <MenuItem value={"Mme"}>Madame</MenuItem>
                          <MenuItem value={"M."}>Monsieur</MenuItem>

                        </Select>
                      </FormControl>                      
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="nom_suppleant_physique"
                        value={props.CommissaireAuxComptes.CAC_suppleant.nom_suppleant_physique} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}   
                        error={!props.CommissaireAuxComptes.CAC_suppleant.nom_suppleant_physique  && props.isSubmitted?true:false}
                        
                      />
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="prenom_suppleant_physique" 
                        value={props.CommissaireAuxComptes.CAC_suppleant.prenom_suppleant_physique} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}  
                        error={!props.CommissaireAuxComptes.CAC_suppleant.prenom_suppleant_physique  && props.isSubmitted?true:false}
                        
                      />
                    </div>
                  </div>
                </div>
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="adresse_suppleant" 
                      value={props.CommissaireAuxComptes.CAC_suppleant.adresse_suppleant} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}   
                      error={!props.CommissaireAuxComptes.CAC_suppleant.adresse_suppleant  && props.isSubmitted?true:false}
                      
                    />
                  </div>
                </div>
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Complément adresse</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="adresse_suite_suppleant"
                      value={props.CommissaireAuxComptes.CAC_suppleant.adresse_suite_suppleant} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}  
                      
                    />
                  </div>
                </div>
                

                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="code_postal_suppleant"
                      value={props.CommissaireAuxComptes.CAC_suppleant.code_postal_suppleant} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}  
                      error={!props.CommissaireAuxComptes.CAC_suppleant.code_postal_suppleant  && props.isSubmitted?true:false}
                     
                    />
                  </div>
                </div>

                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    {/* <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="ville_suppleant"
                      value={props.CommissaireAuxComptes.CAC_suppleant.ville_suppleant} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}  
                      error={!props.CommissaireAuxComptes.CAC_suppleant.ville_suppleant  && props.isSubmitted?true:false}
                      
                    /> */}
                     <FormControl size="small" className={" w-full"} >
                      <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Sélectionnez"
                        name="ville_id_suppleant"
                        value={props.CommissaireAuxComptes.CAC_suppleant.ville_id_suppleant} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}  
                        error={!props.CommissaireAuxComptes.CAC_suppleant.ville_suppleant  && props.isSubmitted?true:false}
                      >
                        {
                          props.CommissaireAuxComptes.CAC_suppleant.ville_suppleantArr.map((cityVal,cityIndex)=>{
                            return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                          })
                        }

                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className={props.CommissaireAuxComptes.CAC_suppleant.type_personne_suppleant === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe </label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="greffe_suppleant"
                      value={props.CommissaireAuxComptes.CAC_suppleant.greffe_suppleant} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}   
                     
                      
                    />
                  </div>
                </div>
                
              </div>
            </div>
            
          </div>
          {/* Transmission des actions */}
          <div className={"grid grid-cols-12 items-start mt-6"} >
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'><h2 className='text-lg sm:text-[22px] font-bold text-primary'>Transmission des actions</h2></label>
            
            <div className='col-span-12 sm:col-span-9 md:col-span-8 mr-7 inline-flex items-center gap-2'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="show_data-label">Sélectionnez</InputLabel>
                <Select
                  labelId="show_data-label"
                  id="show_data"
                  label="Sélectionnez"
                  name="show_data"
                  value={`${props.Transmission_des_actions.show_data}`}                         
                  onChange={(e)=>{props.handleChangeAddForm(e,0,"Transmission_des_actions")}}  
                  error={!props.Transmission_des_actions.show_data  && props.isSubmitted?true:false} 
                >
                  <MenuItem value={"Texte libre"} >Texte libre</MenuItem>
                  <MenuItem value={"Actions librement cessibles entre associés uniquement."} >Texte usuel</MenuItem>

                </Select>
              </FormControl>
            </div>
           
          </div>
          <div className={"grid grid-cols-12 items-start mt-6"} >
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'><h2 className='text-lg sm:text-[22px] font-bold text-primary'> </h2></label>
            
            <div className='col-span-12 sm:col-span-9 md:col-span-8 mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                rows="6"
                multiline={true}
                disabled={false}
                helperText="Attention, La mention des Clauses d'Agréments est recommandée, elle est souvent requise par les greffes"
                name="texte"
                placeholder="(Reprendre les clauses figurant dans les statuts, par exemple : Les actions sont librement cessibles entre actionnaires; toutes autres cessions et transmissions sont soumises à l'agrément du conseil d'administration."
                value={props.Transmission_des_actions.texte} 
                onChange={(e)=>{props.handleChangeAddForm(e,0,"Transmission_des_actions")}}                  
              />
            </div>
          </div>
           {/* Admission aux assemblées et droits de votes */}
           <div className={"grid grid-cols-12 items-start mt-6"} >
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'><h2 className='text-lg sm:text-[22px] font-bold text-primary'>Admission aux assemblées et droits de votes</h2></label>
            
            <div className='col-span-12 sm:col-span-9 md:col-span-8 mr-7 inline-flex items-center gap-2'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="show_data-label">Sélectionnez</InputLabel>
                <Select
                  labelId="show_data-label"
                  id="show_data"
                  label="Sélectionnez"
                  name="show_data"
                  value={`${props.Admission_assemblees.show_data}`}                         
                  onChange={(e)=>{props.handleChangeAddForm(e,0,"Admission_assemblees")}}  
                  error={!props.Admission_assemblees.show_data  && props.isSubmitted?true:false} 
                >
                  <MenuItem value={"Texte libre"} >Texte libre</MenuItem>
                  <MenuItem value={"Tout Actionnaire est convoqué aux Assemblées. Chaque action donne droit à une voix."} >Texte usuel</MenuItem>

                </Select>
              </FormControl>
            </div>
           
          </div>
          <div className={"grid grid-cols-12 items-start mt-6"} >
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'><h2 className='text-lg sm:text-[22px] font-bold text-primary'> </h2></label>
            
            <div className='col-span-12 sm:col-span-9 md:col-span-8 mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                rows="6"
                multiline={true}
                disabled={false}
                helperText="Attention, La mention des Admissions aux Assemblées est recommandée, elle est souvent requise par les greffes."
                name="texte"
                value={props.Admission_assemblees.texte} 
                onChange={(e)=>{props.handleChangeAddForm(e,0,"Admission_assemblees")}}                  
              /> 
            </div>
          </div>                  
        </div>
      </div>
    </>
  )
}
