import { adminConstants } from '../_constants';
let adminData = JSON.parse(localStorage.getItem('admin'));
const initialState = adminData ? { loggedIn: true, admin:adminData ,journal:""} : {};

export function administrator(state =initialState, action) {
    switch (action.type) {
        case adminConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                admin: action.admin
            };
        case adminConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                admin: action.admin
            };
        case adminConstants.LOGIN_FAILURE:
            return {};
        case adminConstants.ADDRESS_REQUEST:
            return {
                ...state,
                admin: {...state.admin}
            };
        case adminConstants.ADDRESS_SUCCESS:
            return {
                ...state,
                admin: {...state.admin}
            };
        case adminConstants.ADDRESS_FAILURE:
            return {};
        case adminConstants.JOURNAL_CREATE_REQUEST:
            return {
                ...state,
                journal: state.journal
            };
        case adminConstants.JOURNAL_CREATE_SUCCESS:
            return {
                ...state,
                journal: state.journal
            };
        case adminConstants.JOURNAL_CREATE_FAILURE:
            return {
                ...state,
                journal: ""
            };
        case adminConstants.JOURNAL_UPDATE_REQUEST:
            return {
                ...state,
                journal: state.journal
            };
        case adminConstants.JOURNAL_UPDATE_SUCCESS:
            return {
                ...state,
                journal: state.journal
            };
        case adminConstants.JOURNAL_UPDATE_FAILURE:
            return {
                ...state,
                journal: ""
            };
        case adminConstants.LOGOUT:
            return {};
        default:
            return state
    }
}