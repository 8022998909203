/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React,{useState,useEffect} from 'react'
import axios from 'axios'
import { Link,useNavigate,useLocation,useParams } from 'react-router-dom'
import Facebook01 from '../assets/Social/Facebook01.svg'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import Footer from '../component/Footer'
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField,Checkbox,FormControlLabel,FormHelperText } from '@mui/material'
// import { Modal } from '@mui/material'
// import messageSuccess from '../assets/messageSuccess.svg'
import ToastMessageComp from '../component/ToastMessageComp';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions,userActions } from '../_actions';
import SocialLoginComp from '../component/SocialLoginComp'

export default function ResetPassword() {
    let { email,token } = useParams();
    let navigate = useNavigate();
    const location = useLocation();
    const alert = useSelector(state => state.alert);
    const dispatch = useDispatch(); 
  
    const [user, setUser] = useState({
        password: '',
        confirm_password: '',
    });

    const [submitted, setSubmitted] = useState(false);
    const [successful, setSuccessful] = useState(false);    
    const [showPassword, setShowPassword] = useState(false);
    useEffect(()=>{
        verifyEmail();
    },[])
    async function verifyEmail() {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}api/resetpasswordcheck/${email}/${token}`);
          
          if(response.data.sucess === false){
            navigate("/");  
          }
        } catch (error) {
          console.error(error);
          navigate("/");  
        }
    }

    function handleChange(e) {
        const { name, value } = e.target;
         
        setUser(user => ({ ...user, [name]: value }));
    }
    async function handleSubmit(e) {
        e.preventDefault();
        setSuccessful(false);
        setSubmitted(true);    
        if (user.password && user.confirm_password && (user.password === user.confirm_password)) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}api/resetpassword/${email}/${token}`,user);
                
                if(response.data.sucess === true){
                    dispatch(alertActions.success(response.data.msg)); 
                    setSuccessful(true);
                    setTimeout(() => {                    
                        navigate("/"); 
                    }, 1000);
                }else{
                    dispatch(alertActions.error(response.data.msg)); 
                    setSuccessful(true);
                }
               
               
            } catch (error) {
                console.error(error);
                dispatch(alertActions.error("Une erreur s'est produite. Veuillez réessayer!")); 
                setSuccessful(true);
                setTimeout(() => {                    
                    // navigate("/"); 
                }, 1000);
            }
        }
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

return (
    <>
      {successful?
      <ToastMessageComp  message={alert.message} type={alert.type} />
      :""}
      <form className='mt-5 sm:mt-8 3xl:mt-12' onSubmit={handleSubmit}>
        <div className='bg-[#F5F5F5] w-full py-7 3xl:py-12'>
          <div className='md:max-w-[1477px] relative w-full h-full max-h-[684px] flex items-center justify-center m-auto  '>

            <div className='max-w-[640px] 3xl:max-w-[720px]  w-full h-full bg-white px-5 sm:px-12 py-2 sm:py-5 3xl:p-12 ' style={{ boxShadow: "0px 4px 10px 1px rgba(39, 41, 91, 0.35)", borderRadius: "33px" }}>
                
                <div className='flex items-center px-2 justify-around gap-14 py-2 3xl:py-4'>
                    <h3 className='text-2xl sm:text-3xl 3xl:text-4xl font-semibold border-b-[6px] border-primary py-4 text-primary '>Réinitialisez mot de passe</h3>
                </div>
              
                <div className='my-4 block'>
                    <FormControl className='w-full' variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password" sx={{ color: submitted && !user.password ? '#d32f2f' :  '#797979', }} >nouveau mot de passe</InputLabel>
                      <OutlinedInput
                        error={submitted && !user.password ? true : false} name="password" value={user.password} onChange={handleChange}
                        type={showPassword ? 'text' : 'password'}
                        sx={{
                          "& .MuiInputLabel-root": { color:  submitted && !user.password ? '#d32f2f' :  '#797979', },
                          "& fieldset": { border: '1px solid #eee' },
                          background: 'rgb(39 41 91 / 6%)',
                          color:  submitted && !user.password ? '#d32f2f' :  '#797979',
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <AiFillEye fill='#CFCFCF' /> : <AiFillEyeInvisible fill='#CFCFCF' />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Mot de passe"
                      />
                    </FormControl>
                </div>
                <div className='my-4 block'>
                    <FormControl className='w-full' variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password" sx={{ color: (submitted && !user.confirm_password ) || (user.password !== user.confirm_password) ? '#d32f2f' :  '#797979', }} >Confirmer le nouveau mot de passe</InputLabel>
                      <OutlinedInput
                        error={(submitted && !user.confirm_password) || (user.password !== user.confirm_password) ? true : false} name="confirm_password" value={user.confirm_password} onChange={handleChange}
                        type={showPassword ? 'text' : 'password'}
                        sx={{
                          "& .MuiInputLabel-root": { color:  (submitted && !user.confirm_password) || (user.password !== user.confirm_password) ? '#d32f2f' :  '#797979', },
                          "& fieldset": { border: '1px solid #eee' },
                          background: 'rgb(39 41 91 / 6%)',
                          color:  (submitted && !user.confirm_password) || (user.password !== user.confirm_password) ? '#d32f2f' :  '#797979',
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <AiFillEye fill='#CFCFCF' /> : <AiFillEyeInvisible fill='#CFCFCF' />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Mot de passe"
                      />
                    </FormControl>
                </div>
            
                <button type='submit' className='bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white  mt-4 sm:mt-4
                3xl:mt-10 px-6 w-full py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white'>Réinitialisez</button>
                
              
            </div>
          </div>
        </div>
      </form>
      {/* <Modal
        className='z-50 max-w-[600px] bg-white 3xl:max-w-[656px]w-full max-h-[350px] 3xl:max-h-[372px] m-auto flex justify-center items-center px-5'
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className='bg-white w-full h-full p-4 rounded-[33px] flex justify-center items-center '>
          <div className='bg-white'>
            <img src={messageSuccess} alt="messageSuccess" className='text-center m-auto' />
            <h4 className='font-semibold text-xl 3xl:text-2xl text-primary py-4 text-center'>Confirmez votre adresse e-mail</h4>
            <p className='group font-normal tetx-base text-primary mt-2 3xl:mt-4'>Un e-mail vient de vous être envoyé sur <Link to="mailto: hugo.delpierre@gmail.com" className='font-semibold gropu-hover:text-secondary'>hugo.delpierre@gmail.com</Link> </p>
            <p className='font-normal tetx-base text-primary'>Consultez votre messagerie et vérifiez votre dossier “spam”.</p>
            <p className='font-normal tetx-base text-primary'>Vous pourrez confirmer votre adresse e-mail en cliquant sur le bouton.</p>

            <p className='text-base font-normal text-black-light mt-6 flex flex-col justify-center items-center sm:flex-row'>Vous n’avez pas reçu d’e-mail ? <Link to="" className='underline'> Contactez l’équipe Legals</Link></p>
          </div>
        </div>
      </Modal> */}
      <Footer />
    </>
  )
}
