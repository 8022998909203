
import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Box, Stack, FormControlLabel, Radio,Checkbox } from '@mui/material';
import { useNavigate,useParams } from 'react-router-dom';

export default function OpeningHours() {

    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const HorairesData = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

    const [openingHours, setOpeningHours] = useState({
        email: '',
        opening_hours: {
            Lundi: [{ type: 'Matin',value: '9:00 - 18:00',checked:true },{ type: 'Après-midi', 'value': '9:00 - 18:00',checked:true }],
            Mardi: [{ type: 'Matin',value: '9:00 - 18:00',checked:true },{ type: 'Après-midi', 'value': '9:00 - 18:00',checked:true }],
            Mercredi: [{ type: 'Matin',value: '9:00 - 18:00',checked:true },{ type: 'Après-midi', 'value': '9:00 - 18:00',checked:true }],
            Jeudi: [{ type: 'Matin',value: '9:00 - 18:00',checked:true },{ type: 'Après-midi', 'value': '9:00 - 18:00',checked:true }],
            Vendredi: [{ type: 'Matin',value: '9:00 - 18:00',checked:true },{ type: 'Après-midi', 'value': '9:00 - 18:00',checked:true }],
            Samedi: [{ type: 'Matin',value: '9:00 - 18:00',checked:true },{ type: 'Après-midi', 'value': '9:00 - 18:00',checked:true }],           
        },
    });

    const toggleChecked = (day, type,checkedValue) => {
        setOpeningHours(prev => ({
            ...prev,
            opening_hours: {
                ...prev.opening_hours,
                [day]: prev.opening_hours[day].map((session, idx) => {
                    console.log("session",session);
                    console.log("type",type);
                    console.log("session.type === type",session.type === type);
                    return session.type === type ? { ...session, checked: checkedValue } : session
                    }
                    
                )
            }
        }));
    };

    const updateValue = (day, type, newValue) => {
        setOpeningHours(prev => ({
            ...prev,
            opening_hours: {
                ...prev.opening_hours,
                [day]: prev.opening_hours[day].map((session, idx) => 
                    session.type === type ? { ...session, value: newValue } : session
                )
            }
        }));
    };

    const handleTimeChange = (e,day, type) => {
        let {name,value} = e.target;
        
        if(name==="matin_checkbox" || name==="apres_checkbox" ){
            toggleChecked(day,type,e.target.checked)
        }else{
            updateValue(day,type,e.target.value)
        }
        
    };
    const checkAtLeastOneSession = () => {
        return Object.values(openingHours.opening_hours).some(day => 
            day.some(session => session.checked)
        );
    };
    const validateAllChecked = () => {
        const days = Object.values(openingHours.opening_hours); // get all days' sessions
         const allChecked = days.every(day => day.every(session => session.checked));
         return allChecked;
        /* if (!allChecked) {
            alert("Not all sessions are checked. Please check all sessions before submitting.");
            return false;
        }
        
        alert("All sessions are checked. Proceeding with submission.");
        return true; */
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Check if all fields are empty
       /*  if (!validateAllChecked()) {
            setError('Veuillez remplir tous les champs.');
            return;
        } */
        
        try {
            setLoading(true);
    
            const emaildata = localStorage.getItem('email');
    
            // Create the request data object
            const requestData = {
                user_id: params.id?params.id:"",
                email: emaildata,
                opening_hours: openingHours.opening_hours,
            };
    
            // Use Axios to make the API request
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}api/prouser/add-prouseropeninghours`,
                requestData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
    
            if (response.status === 200) {
                // Handle success, e.g., redirect or show a success message
                navigate(`/proaccess/${params.id}`); // Redirect to the next page on success
            } else {
                // Handle error, e.g., show an error message
                setError("Une erreur s'est produite."); // Set the error message
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };
    
    

    return (
        <>
            <div className='bg-[#F5F5F5] w-full py-7 3xl:py-12' style={{
                background: `linear-gradient(172deg, #64D3B6 60%, white 45%)`,
                backgroundSize: '100% 50%', backgroundRepeat: 'no-repeat'
            }}>
                <div className='md:max-w-[1477px] relative w-full h-full   flex items-center justify-center m-auto  '>
                    <div className='max-w-[700px] 3xl:max-w-[700px]  w-full h-full bg-white px-5 sm:px-12 py-2 sm:py-5 3xl:p-12 ' style={{ boxShadow: "0px 4px 10px 1px rgba(39, 41, 91, 0.35)", borderRadius: "33px" }}>
                        <div className=' px-2 py-2 3xl:py-4'>
                            <h1 className='text-xl sm:text-3xl xl:text-[28px] font-extrabold md:leading-[45px] mt-6 text-primary'>Vos horaires d’ouverture</h1>
                        </div>
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            className='mt-5 sm:mt-5 3xl:mt-12'
                            sx={{
                                "& .MuiInputLabel-root": { color: '#797979', },
                                "& fieldset": {
                                    border: '1px solid #eee', borderRadius: '8px',
                                    color: '#797979',
                                    background: 'rgb(39 41 91 / 6%)',
                                },
                            }}
                        >
                            { HorairesData.map((item, index) =>{

                                let mngHours = openingHours.opening_hours[item][0].value;
                                let eveHours = openingHours.opening_hours[item][1].value;
                                let checkedStatusMrng = openingHours.opening_hours[item][0].checked;
                                let checkedStatusEve = openingHours.opening_hours[item][1].checked;
                                
                                return (
                                <div key={index} className="sm:gap-4 my-6 sm:mb-10">
                                    <div>
                                        <label className="col-span-12 text-base font-bold text-gray mb-1">{item}</label>
                                    </div>
                                    <div className="flex flex-col sm:flex-row sm:items-center justify-between sm:gap-4">
                                        <div className="flex sm:items-center gap-4">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkedStatusMrng}
                                                        onChange={(e) => handleTimeChange(e,item, 'Matin')}
                                                        name={`matin_checkbox`}
                                                        color="primary"
                                                    />
                                                }
                                                label="Matin"
                                                className="text-sm font-normal text-gray"
                                            />
                                            <TextField
                                                // label="00:00 - 00:00"
                                                variant="outlined"
                                                size="small"
                                                className="outline-secondary focus:outline-secondary"
                                                value={mngHours}
                                                onChange={(e) => handleTimeChange(e,item, 'Matin')}
                                                name='matin_text'
                                            />
                                        </div>
                                        <div className="flex sm:items-center gap-4">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkedStatusEve}
                                                        onChange={(e) => handleTimeChange(e,item, 'Après-midi')}
                                                        name={`apres_checkbox`}
                                                        color="primary"
                                                    />
                                                }
                                                label="Après-midi"
                                                className="text-sm font-normal text-gray word-nowrap whitespace-nowrap"
                                            />
                                            <TextField
                                                // label="00:00 - 00:00"
                                                variant="outlined"
                                                size="small"
                                                className="outline-secondary focus:outline-secondary"
                                                value={eveHours}
                                                onChange={(e) => handleTimeChange(e,item, 'Après-midi')}
                                                name='apres_text'
                                            />
                                        </div>
                                    </div>
                                </div>
                                )})
                            }

                            <Stack alignItems='end' sx={{ mt: 2 }}>
                                <button
                                    type='submit'
                                    className='bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white mt-4 sm:mt-4 3xl:mt-10 px-10 py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white'
                                    disabled={loading}
                                >
                                    {loading ? 'En cours...' : 'Suivant'}
                                </button>
                            </Stack>
                            {/* {error && <p className="text-red-500 mt-2" style={{color:'red'}}>{error}</p>} */}
                        </Box>
                    </div>
                </div>
            </div>
        </>
    );
}
