import React, { useState,useEffect } from 'react'
import { Link,useNavigate,useLocation,useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'
import itinérantLogo from '../../assets/itinérantLogo.png'
import lesEchosLogo from '../../assets/lesEchosLogo.png'
import axios from 'axios'
import { changeDateFormatFrenchDynamic } from '../../_helpers';
import PageLoaderComp from '../../component/pageLoaderComp';
import { alertActions } from '../../_actions';
import ToastMessageComp from '../../component/ToastMessageComp';

export default function FormSummary(props) {
  const alert = useSelector(state => state.alert);
  const dispatch = useDispatch(); 
  let navigate = useNavigate();
  let location = useLocation();

  let { ads,adsname,id } = useParams();
  const currentUser = useSelector(state => state.authentication.user);
  const announceData = useSelector(state => state.announce.announce);
  const [cartData,setCartData]=useState("")
  const [pageLoading,setPageLoading]=useState(false)
  const [showToast,setShowToast]=useState(false)
  const [currentUrl, setCurrentUrl] = useState('');
  
  useEffect(() => {
    window.scrollTo(0, 0)
    if(announceData)
    {
      if(!id)
      {
        navigate(`${location.pathname}/${announceData.announce_id}`, { replace: true });
      }
      getCartDetails(announceData.announce_id);
    }
    // Update currentUrl state with the current URL when the component mounts
    setCurrentUrl(location.pathname);    
    localStorage.setItem("announceCurrentUrl",location.pathname);
    localStorage.setItem("activeStep",1);
  }, [])
  /*  useEffect(() => {
    if(!currentUser){
      localStorage.setItem("announceCurrentUrl",currentUrl);
    }
  }, [currentUrl]) */

  const getCartDetails=async(announce_id)=>{
    let user_id = "";
    if(currentUser && currentUser.data){
      user_id = currentUser.data.id;
    }
    try 
    {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}api/announce/cart/${announce_id}?user_id=${user_id}`);
      
      if(response.data.sucess === true){
        setCartData(response.data);
      }
    } catch (error) {
      // // console.log(error);
       
    }
  }
  const change_general_announce=async(e,announce_id,journal_id)=>{
    setPageLoading(true)
    setShowToast(false)
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}api/announce/change_general_announce/${journal_id}/${announce_id}`);
      // console.log("response.data",response.data)
      setTimeout(() => {
        setPageLoading(false)
        setShowToast(true)
      }, 1000);
      if(response.data.sucess === true){
        dispatch(alertActions.success(response.data.msg)); 
        setTimeout(() => {
          setCartData(response.data);
        }, 1000); 
        
      }else{
        dispatch(alertActions.error(response.data.msg));
      }
    } catch (error) {
      setTimeout(() => {
        setPageLoading(false)
        setShowToast(true)
      }, 1000);
      dispatch(alertActions.error("Une erreur s'est produite. Veuillez réessayer!")); 
      // // console.log(error);
       
    }
  }
  
  return (
    <>
      {
      pageLoading?
      <PageLoaderComp />
      :""}
      {
      showToast?
      <ToastMessageComp  message={alert.message} type={alert.type} />
      :""}
      <div>
        <div className='py-6 px-3 sm:px-6' >
          <h1 className='text-lg sm:text-2xl font-black text-primary'>Annonce légale - dossier n° 1066233</h1>
          <p className='font-normal text-sm text-gray max-w-[414px] mt-1'>La présentation de votre annonce peut varier selon la composition graphique du journal.</p>
        </div>
        <div className='my-12 max-w-[414px] m-auto'>
          <div className=' rounded-[14px] p-5 sm:mx-0 mx-2' style={{ 'boxShadow': '0px 4px 4px rgba(0, 0, 0, 0.25)' }} dangerouslySetInnerHTML={{__html:cartData&& cartData.data && cartData.data.votre_html? cartData.data.votre_html:""}}>
            {/* {cartData&& cartData.data && cartData.data.votre_html? cartData.data.votre_html:""} */}
          </div>       

          {/* <div className=' rounded-[14px] p-5 sm:mx-0 mx-2' style={{ 'boxShadow': '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
            <h6 className='text-base font-normal text-gray'>{cartData&& cartData.data && cartData.data.votre_annonce_json?cartData.data.votre_annonce_json.type_acte==='ACTE_AUTHENTIQUE'? `Par Acte authentique du ${cartData.data.votre_annonce_json.date_acte} recu par me ${cartData.data.votre_annonce_json.nom_famille} officiant a ${cartData.data.votre_annonce_json.officiant_to} il a été constitué une ${cartData.data.votre_annonce_json.forme_juridique} dénommée`:`Par  Acte SSP  du ${cartData.data.votre_annonce_json.date_acte} il a été constitué une ${cartData.data.votre_annonce_json.forme_juridique} dénommée `:"" }:</h6>
            <h3 className='text-lg font-bold text-primary text-center my-4'>{cartData&& cartData.data && cartData.data.votre_annonce_json?cartData.data.votre_annonce_json.enseigne_commerciale:""}</h3>

            <h5 className='text-base font-medium text-primary  '>Sigle &nbsp;: <span className='font-normal text-[15px] text-gray'>{cartData&& cartData.data && cartData.data.votre_annonce_json?cartData.data.votre_annonce_json.sigle:""}</span></h5>

            <h5 className='text-base font-medium text-primary  '>Nom commercial&nbsp;: <span className='font-normal text-[15px] text-gray'>{cartData&& cartData.data && cartData.data.votre_annonce_json?cartData.data.votre_annonce_json.enseigne_commerciale:""}</span></h5>

            <h5 className='text-base font-medium text-primary  '>Siège social&nbsp;: <span className='font-normal text-[15px] text-gray'>{cartData&& cartData.data && cartData.data.votre_annonce_json?`${cartData.data.votre_annonce_json.adresse} ${cartData.data.votre_annonce_json.adresse_suite} ${cartData.data.votre_annonce_json.code_postal} ${cartData.data.votre_annonce_json.ville} `:""}</span></h5>
            

            <h5 className='text-base font-medium text-primary  '>Capital&nbsp;: <span className='font-normal text-[15px] text-gray'>{cartData&& cartData.data && cartData.data.votre_annonce_json?cartData.data.votre_annonce_json.capital?cartData.data.votre_annonce_json.capital:cartData.data.votre_annonce_json.capital_initial:0} €</span></h5>

            <h5 className='text-base font-medium text-primary  '>Objet&nbsp;: <span className='font-normal text-[15px] text-gray'>{cartData&& cartData.data && cartData.data.votre_annonce_json?cartData.data.votre_annonce_json.objet:""}</span></h5>

            {cartData&& cartData.data && cartData.data.votre_annonce_json && ((cartData.data.votre_annonce_json.La_gerance).length>0)?  
              cartData.data.votre_annonce_json.La_gerance.map((val,key)=>{
                return <h5 key={key} className='text-base font-medium text-primary  '>Gérant&nbsp;: <span className='font-normal text-[15px] text-gray'> {val.type_personne_lagrance==='PERSONNE_PHYSIQUE'? `${val.civilite_lagrance} ${val.nomlagrance} ${val.prenomlagrance} ${val.adresselagrance} ${val.adresse_suitelagrance} ${val.code_postallagrance} ${val.villelagrance}`:val.type_personne_lagrance==='PERSONNE_MORALE'? ` ${val.denomination_lagrance} ${val.forme_juridiquelagrance} au capital de ${val.capital_lagrance} &euro; sise ${val.adresselagrance} ${val.adresse_suitelagrance} ${val.code_postallagrance} ${val.villelagrance} ${val.siren_lagrance} RCS ${val.greffelagrance} repr&#xE9;sent&#xE9;e par ${val.civilite_lagrance} ${val.nomlagrance} ${val.prenomlagrance}`:""}</span></h5>
              })          
             
            : ""}
            {cartData&& cartData.data && cartData.data.votre_annonce_json?
              <h5 className='text-base font-medium text-primary  '>Durée&nbsp;: <span className='font-normal text-[15px] text-gray'>
              {cartData.data.votre_annonce_json.duree} ans &#xE0; compter de l'immatriculation au RCS de {cartData.data.votre_annonce_json.greffe}</span></h5>
            :""}

          </div> */}
          <button type='button' onClick={props.handleBack} className='mt-5 mx-auto block text-center text-lg font-medium outline-thColor text-thColor transition-all duration-300 border border-thColor w-3/4 sm:w-full py-3 rounded-md
          hover:bg-secondary hover:text-white hover:border-secondary'>
            Modifier l’annonce
          </button>
        </div>
      </div>
      {
        cartData && cartData.data && cartData.data.select_cartdetailJ?          
      <div>
        <div className='py-6 px-3 sm:px-6' >
          <h1 className='text-lg sm:text-2xl font-black text-primary mb-3 sm:mb-10'>Publication</h1>
          <h5 className='font-bold text-base text-primary  flex items-center'>Journal sélectionné du {cartData.data.select_department.department_num} : {cartData.data.select_cartdetailJ.journal_empowered_name} {cartData.data.select_cartdetailJ.image? <img className='ml-2' style={{"width":"80px"}} src={`${cartData.data.select_cartdetailJ.image}`} alt={cartData.data.select_cartdetailJ.image} />:""}</h5>
          <h5 className='font-bold text-base text-primary '>Parution du journal : {cartData&& cartData.data?changeDateFormatFrenchDynamic(cartData.data.date_parution,"dddd D MMMM YYYY "):""}{/* jeudi 14 juillet 2022 */}</h5>
          <h5 className='font-bold text-base text-primary '>Attestation immédiate : {cartData && cartData.data && cartData.data.select_cartdetailJ?cartData.data.select_cartdetailJ.attestation:""} </h5>
         
        </div>
        <div className='py-4 px-3 sm:px-6 sm:flex items-end mb-4'>
        {
              cartData && cartData.data &&((cartData.data.journalempowered).length>0)?
              <p className='text-base font-normal text-gray'>Autres journaux du {cartData && cartData.data?cartData.data.select_department.department_num:""} où nous pouvons publier</p>
        :""}
        </div>          
        {
          cartData && cartData.data &&((cartData.data.journalempowered).length>0)?
              cartData.data.journalempowered.map((val,key)=>{
                  return <>
                  <div className='py-4 px-3 sm:px-6 sm:flex items-end mb-4'>
                    <div>
                    <p className='text-base font-normal text-gray flex items-center gap-2'>Journal du {val.journal_empowered_name}{val.image? <img className='ml-2' style={{"width":"80px"}} src={`${val.image}`} alt={val.image} />:""} </p><p className='text-base font-normal text-gray'>Parution du journal : {changeDateFormatFrenchDynamic(val.date_parution,"dddd D MMMM YYYY ")}</p><p className='text-base font-normal text-gray'>Possibilité attestation immédiate : {val.attestation}</p>
                    </div>
                    <div className='ml-3'>
                      <button className='mt-5 text-center text-base font-normal outline-thColor text-thColor transition-all duration-300 border border-thColor w-full py-2 rounded-md px-4
                    hover:bg-secondary hover:text-white hover:border-secondary' onClick={(e)=>change_general_announce(e,cartData.data.id,val.id)}>
                        Choisir ce journal
                      </button>
                    </div>   
                  </div>               
                  </>
              })              
          :""
        }
        </div>      
      :""}
      <div>
        <div className='pt-3 sm:py-6 px-3 sm:px-6' >
          <h1 className='text-lg sm:text-2xl font-black text-primary mb-4 sm:mb-10'>Tarif de l’annonce</h1>
        </div>
        <div className=' px-3 sm:px-6 '>
          <div className='flex justify-between items-center'>
            <p className='text-base font-normal text-gray'>Annonce {cartData && cartData.data && cartData.data.select_cartdetailN?cartData.data.select_cartdetailN.newspaper_name:""}</p>
            <p className='text-base font-normal text-gray'> {cartData && cartData.data ?cartData.data.news_price:0} €</p>
          </div>
          {/* <div className='flex justify-between items-center'>
            <p className='text-base font-normal text-gray my-1'>Attestation immédiate</p>
            <p className='text-base font-normal text-gray my-1'>0,00 €</p>
          </div> */}
          <div className='flex justify-between items-center'>
            <p className='text-base font-normal text-gray my-1'>Remise accordée: {cartData && cartData.data ?cartData.data.discountPer+"%":"0%"}</p>
            <p className='text-base font-normal text-gray my-1'>-{cartData && cartData.data ?cartData.data.discountAmount:0} €</p>
          </div>
          
        </div>
        <div className='py-4 px-3 sm:px-6 flex items-start justify-end sm:mb-4'> 
          <div className='text-right'> 
            <div className='h-[1px] bg-gray-light my-2'></div>
            <div className='flex justify-between flex-col'>
              <p className='text-base font-normal text-gray my-1 flex justify-between'>
                <span className='font-medium'>Total HT &nbsp;&nbsp;&nbsp;&nbsp;</span> {cartData && cartData.data ?cartData.data.subtotal:0} €</p>
              <p className='text-base font-normal text-gray my-1 flex justify-between'><span className='font-medium'>TVA (20 %) &nbsp;&nbsp;&nbsp;&nbsp;</span> {cartData && cartData.data ?(parseFloat(cartData.data.tax)).toFixed(2):0} €</p>
              <p className='text-lg sm:text-xl font-extrabold text-primary my-2 flex justify-between'><span className='font-extrabold'>Total TTC &nbsp;&nbsp;&nbsp;&nbsp;</span> {cartData && cartData.data ?parseFloat(cartData.data.total_price).toFixed(2):0} €</p>
            </div>
          </div>
        </div>
      </div>
      
      <p className='text-sm font-light text-gray my-4 max-w-[981px] p-3 sm:p-6 '>* Les tarifs proposés sont les tarifs que peut proposer la plateforme <Link to="" className="hover:text-secondary"> {process.env.REACT_APP_WEB_URL}</Link> , ils sont fonctions des prix moyens constatés sur le marché, et ne sont en aucun cas des tarifs obtenus auprés des journaux en direct.</p>
    </>
  )
}


