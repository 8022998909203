export const orderConstants = {
    GETALL_REQUEST: 'ORDERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'ORDERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'ORDERS_GETALL_FAILURE',

    DELETE_REQUEST: 'ORDERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'ORDERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'ORDERS_DELETE_FAILURE' ,
    
    UPDATE_REQUEST: 'ORDERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ORDERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ORDERS_UPDATE_FAILURE' 
};
