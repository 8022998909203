import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export default function CreateAddressPopup(props) {
  const theme = useTheme();

  return (
    <div>
      <form>
        <Dialog open={props.addresopen} onClose={props.handleClose}>
          <DialogTitle>Add Address</DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}
            <TextField
              autoFocus
              margin="dense"
              id="full_name"
              name="full_name"
              label="Full Name"
              type="text"
              fullWidth
              variant="standard"
              onChange={props.handleChangeAddress}
              value={props.addressData.full_name}
              error={!props.addressData.full_name && props.isSubmitted ? true : false}
            />
            <TextField
              autoFocus
              margin="dense"
              id="phone_number"
              name="phone_number"
              label="Phone number"
              type="number"
              fullWidth
              variant="standard"
              onChange={props.handleChangeAddress}
              value={props.addressData.phone_number}
              error={!props.addressData.phone_number && props.isSubmitted ? true : false}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxlength: "10" }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="address_title"
              name="address_title"
              label="Title"
              type="text"
              fullWidth
              variant="standard"
              onChange={props.handleChangeAddress}
              value={props.addressData.address_title}
              error={!props.addressData.address_title && props.isSubmitted ? true : false}
            />
            <TextField
              autoFocus
              margin="dense"
              id="address"
              name="address"
              label="Address"
              type="text"
              fullWidth
              variant="standard"
              onChange={props.handleChangeAddress}
              value={props.addressData.address}
              error={!props.addressData.address && props.isSubmitted ? true : false}
            />
            <TextField
              autoFocus
              margin="dense"
              id="address_additional"
              name="address_additional"
              label="Address additional"
              type="text"
              fullWidth
              variant="standard"
              onChange={props.handleChangeAddress}
              value={props.addressData.address_additional}
            // error={!props.addressData.address_additional && props.isSubmitted?true:false}
            />
            <TextField
              autoFocus
              margin="dense"
              id="postal_code"
              name="postal_code"
              label="Postal code"
              type="number"
              fullWidth
              variant="standard"
              onChange={props.handleChangeAddress}
              value={props.addressData.postal_code}
              error={!props.addressData.postal_code && props.isSubmitted ? true : false}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxlength: "6" }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="city"
              name="city"
              label="Ville"
              type="text"
              fullWidth
              variant="standard"
              onChange={props.handleChangeAddress}
              value={props.addressData.city}
              error={!props.addressData.city && props.isSubmitted ? true : false}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose}>Cancel</Button>
            <Button onClick={props.handleSubmitAddress}>Add</Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}