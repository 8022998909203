/* eslint-disable array-callback-return */
import React, {useState, useEffect } from 'react' 
import moment from 'moment';
import dayjs from 'dayjs';
import { BsFillQuestionCircleFill, BsPlusCircle } from 'react-icons/bs'
import { MdEuroSymbol } from 'react-icons/md'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/fr.js';
import { frFR } from '@mui/x-date-pickers/locales';
import Tooltip from '@mui/material/Tooltip';
import { FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField ,Divider} from '@mui/material';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';

export  function Sc(props) {
  
  /* const [formData,setFormData]= useState({
    type_acte:"",nom_famille:"",officiant_to:"",date_acte:"",denomination:"",sigle:"",enseigne_commerciale:"",forme_juridique:"",objet:"",capital:"",capital_initial:"",capital_min:"",capital_max:"",duree:"",adresse:"",adresse_suite:"",code_postal:"",ville:"",greffe:"",La_gerance:[]
  }); */
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

 

  const StyledTooltip = ({ title, children, ...props }) => (
    <Tooltip
      {...props}
      title={title}
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            background: '#DEE3E2',
            width: '129px',
            padding: '10px',
            color: '#27295B',
            fontSize: '14px',
            fontWeight: '400'
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );

  return (
    <>
      <div className='  ' >
        <div className='py-6 px-3 sm:px-6' style={{ borderBottom: '0.5px solid #DEE3E2' }}>
          <h1 className='text-lg sm:text-2xl font-black text-primary'>Annonce légale : Création de {props.adsname}</h1>
        </div>
        <div className='py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Les statuts</h2>

          <div className='grid grid-cols-12 items-center mt-2 sm:mt-6'>
            <label className='col-span-12 sm:col-span-3 my-2 sm:mb-0 text-base font-normal text-black-light'>Type d’acte</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Sélectionnez"
                  id="type_acte" name="type_acte"
                  value={props.formData.type_acte} onChange={props.handleChange}
                  error={!props.formData.type_acte && props.isSubmitted?true:false}
                >
                  <MenuItem value={"ACTE_SSP"}>Acte sous seing privé (acte rédigé par les parties concernées)</MenuItem>
                  <MenuItem value={"ACTE_AUTHENTIQUE"}>Acte authentique (acte rédigé par un notaire)</MenuItem>

                </Select>
              </FormControl>
            </div>
          </div>
          <div  className={props.formData.type_acte==="ACTE_AUTHENTIQUE"?"nature_acte 3522 notaire":"nature_acte 3522 notaire hidden"} id="notaire" >
            <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Notaire *</label>
              <div className='col-span-12 sm:col-span-9 md:col-span-7 inline-flex items-center gap-2'>
                <TextField
                  hiddenLabel
                  id="outlined-size-small"
                  size="small"
                  className='w-full outline-secondary focus:outline-secondary' name="nom_famille" 
                  value={props.formData.nom_famille} onChange={props.handleChange}
                  error={!props.formData.nom_famille && props.isSubmitted?true:false}
                  
                  />
                
              </div>
            </div>
            <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Officiant à *</label>
              <div className='col-span-12 sm:col-span-9 md:col-span-7 inline-flex items-center gap-2'>
                <TextField
                  hiddenLabel
                  id="outlined-size-small"
                  size="small"
                  className='w-full outline-secondary focus:outline-secondary' name="officiant_to" 
                  value={props.formData.officiant_to} onChange={props.handleChange}
                  error={!props.formData.officiant_to  && props.isSubmitted?true:false}
                  />                
              </div>
            </div>
          </div>
          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Date de l’acte</label>
            <div className='col-span-12 sm:col-span-9 mr-7 md:mr-0 md:col-span-7 lg:col-span-4'>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}  >
                <DemoContainer components={['DatePicker']}  >
                  {
                    props.formData.date_acte?
                    <DatePicker value={dayjs(props.formData.date_acte)}   name="date_acte"   onChange={props.handleChangeDate} format={"DD/MM/YYYY"}                   
                    />
                    :
                    <DatePicker  name="date_acte"   onChange={props.handleChangeDate} format={"DD/MM/YYYY"}                   
                    />
                  }
                  
                </DemoContainer>
              </LocalizationProvider>
            </div>

          </div>
        </div>


        <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>La société</h2>

          <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full outline-secondary focus:outline-secondary'
                name="denomination" 
                value={props.formData.denomination} 
                onChange={props.handleChange}  
              />
              <StyledTooltip title="Indiquez le nom de la Société tel que mentionné dans les statuts.">
                <div className='p-0 m-0 w-auto'>
                  <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                </div>
              </StyledTooltip>
            </div>
          </div>

          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Sigle</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="sigle"
                value={props.formData.sigle} onChange={props.handleChange} 
              />
              <StyledTooltip title="Sigle à renseigner uniquement si indiqué dans les statuts.">
                <div className='p-0 m-0 w-auto'>
                  <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                </div>
              </StyledTooltip>
            </div>
          </div>

          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom commercial</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="enseigne_commerciale"
                value={props.formData.enseigne_commerciale} onChange={props.handleChange} 
              />
              <StyledTooltip title="Nom sous lequel l'activité de votre société sera connue du public.">
                <div className='p-0 m-0 w-auto'>
                  <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                </div>
              </StyledTooltip>
            </div>
          </div>

          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-8'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Sélectionnez"
                  id="forme_juridique" name="forme_juridique"
                  value={props.formData.forme_juridique} onChange={props.handleChange}  error={!props.formData.forme_juridique  && props.isSubmitted?true:false}
                >
                  <MenuItem value={"SC"}>Société civile</MenuItem>
                  <MenuItem value={"SCI"}>Société civile à capital variable</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div className='grid grid-cols-12 items-start mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Objet</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 inline-flex items-center gap-2'>
              <TextField
                
                hiddenLabel
                multiline
                minRows={4}
                className='w-full'
                id="comment" name="objet"
                value={props.formData.objet} onChange={props.handleChange} 
              />
              <StyledTooltip title="Indiquez les éléments principaux de votre activité tel que déclarés dans les statuts.">
                <div className='p-0 m-0 w-auto'>
                  <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                </div>
              </StyledTooltip>
            </div>
          </div>

          <div  className={"grid grid-cols-12 items-center mt-6" } >
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>
              
              <FormControl size="small" className='w-full'>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  name="capital"
                  value={props.formData.capital} onChange={props.handleChange}  
                  error={!props.formData.capital  && props.isSubmitted?true:false}
                />
              </FormControl>

              <StyledTooltip title="Montant du capital mentionné dans les statuts.">
                <div className='p-0 m-0 w-auto'>
                  <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                </div>
              </StyledTooltip>

            </div>
          </div>
          
          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Durée</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>

              <FormControl size="small" className='w-full'>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  endAdornment={<InputAdornment position="end" className='font-medium text-primary'><span className='font-medium text-primary'> ans</span></InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  name="duree"
                  value={props.formData.duree} 
                  onChange={props.handleChange}  
                />
              </FormControl>

              <StyledTooltip title="La durée maximale est de 99 ans. Elle pourra être prorogée une ou plusieurs fois.">
                <div className='p-0 m-0 w-auto'>
                  <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer  hover:fill-secondary' />
                </div>
              </StyledTooltip>

            </div>
          </div>
        </div>

        <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Adresse du siège social</h2>

          <div className='grid grid-cols-12 mt-3 sm:mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="adresse" 
                value={props.formData.adresse} onChange={props.handleChange}  error={!props.formData.adresse  && props.isSubmitted?true:false}
              />
            </div>
          </div>

          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Complément adresse</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="adresse_suite"
                value={props.formData.adresse_suite} onChange={props.handleChange}  
              />
            </div>
          </div>

          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="code_postal" onInput={(e) => e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')} maxLength="5"

                value={props.formData.code_postal} onChange={props.handleChange}  error={!props.formData.code_postal  && props.isSubmitted?true:false}
              />
            </div>
          </div>
          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              {/* <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="ville"
                value={props.formData.ville} onChange={props.handleChange}  error={!props.formData.ville  && props.isSubmitted?true:false}
              /> */}
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Sélectionnez"
                  name="ville_id"
                  value={props.formData.ville_id}                   
                  onChange={props.handleChange}  
                  error={!props.formData.ville  && props.isSubmitted?true:false}
                  
                >
                  {
                    props.formData.villeArr.map((cityVal,cityIndex)=>{
                      return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                    })
                  }

                </Select>
              </FormControl>
            </div>
          </div>
          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="greffe" 
                value={props.formData.greffe} onChange={props.handleChange} 
              />
            </div>
          </div>
        </div>

        <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>La gérance</h2>
          {props.La_gerance.map((val,key)=>{
            return <div key={val.index} index={val.index}  >   
              <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Personne</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-9 md:mr-10 inline-flex items-center gap-2'>
                  <FormControl size="small" className='w-full'>
                    <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id={val.index}
                      label="Sélectionnez"
                      name="type_personne_lagrance"
                      value={val.type_personne_lagrance} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index)}}  
                      error={!val.type_personne_lagrance  && props.isSubmitted?true:false}
                      index={val.index}
                    >
                      <MenuItem value={"PERSONNE_PHYSIQUE"}>Personne physique</MenuItem>
                      <MenuItem value={"PERSONNE_MORALE"}>Personne morale</MenuItem>

                    </Select>
                  </FormControl>
                  <StyledTooltip title="La personne physique désigne un individu. La personne morale, elle, est une entité juridique.">
                    <div className='p-0 m-0 w-auto'>
                      <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer  hover:fill-secondary' />
                    </div>
                  </StyledTooltip>
                  {val.index >0?
                  <div className='p-0 m-0 w-auto'>
                      <RemoveCircleRoundedIcon fontSize='large' className='text-3xl  cursor-pointer  fill-secondary' onClick={(e)=>{props.removeForm(val.index)}} />
                  </div>
                  :""}
                </div>
                
              </div>
              <div className={val.type_personne_lagrance === ""?"hidden":""}>
                <div className={val.type_personne_lagrance === "PERSONNE_MORALE"?"":"hidden"}>      
                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>N° SIREN *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="siren_lagrance"
                        value={val.siren_lagrance} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index)}}   
                        error={!val.siren_lagrance  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="denomination_lagrance"
                        value={val.denomination_lagrance} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index)}}  
                        error={!val.denomination_lagrance  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="forme_juridiquelagrance"
                          value={val.forme_juridiquelagrance} 
                          onChange={(e)=>{props.handleChangeAddForm(e,val.index)}}    
                          error={!val.forme_juridiquelagrance  && props.isSubmitted?true:false}
                          index={val.index}
                        >
                          <MenuItem value={"SAS"}>Société par actions simplifiée (SAS)</MenuItem>
                          <MenuItem value={"SAS à capital variable"}>Société par actions simplifiée à capital variable</MenuItem>
                          <MenuItem value={"SASU"}>Société par actions simplifiée unipersonnelle (SASU)</MenuItem>
                          <MenuItem value={"SASU à capital variable"}>Société par actions simplifiée unipersonnelle à capital variable</MenuItem>
                          <MenuItem value={"SARL"}>Société à responsabilité limitée (SARL)</MenuItem>
                          <MenuItem value={"SARL à capital variable"}>Société à responsabilité limitée à capital variable</MenuItem>
                          <MenuItem value={"SARLU"}>Société à responsabilité limitée à associé unique (SARLU)</MenuItem>
                          <MenuItem value={"SARLU à capitale variable"}>Société à responsabilité limitée à associé unique à capitale variable</MenuItem>
                          <MenuItem value={"SCI"}>Société civile immobilière (SCI)</MenuItem>
                          <MenuItem value={"SCI à capital variable"}>Société civile immobilière à capital variable</MenuItem>
                          <MenuItem value={"SC"}>Société civile</MenuItem>
                          <MenuItem value={"SA"}>Société anonyme (SA)</MenuItem>
                          <MenuItem value={"SA à capital variable"}>Société anonyme à capital variable</MenuItem>
                          <MenuItem value={"SNC"}>Société en nom collectif (SNC)</MenuItem>
                          <MenuItem value={"SNC à capital variable"}>Société en nom collectif à capital variable</MenuItem>
                          <MenuItem value={"SCM"}>Société civile de moyens (SCM)</MenuItem>
                          <MenuItem value={"SCP"}>Société civile professionnelle (SCP)</MenuItem>
                          <MenuItem value={"SCPI"}>Société civile de placement immobilier (SCPI)</MenuItem>
                          <MenuItem value={"SCCV"}>Société civile immobilière de construction vente (SCCV ou SCICV)</MenuItem>
                          <MenuItem value={"SELAFA"}>Société d'exercice libéral sous forme anonyme (SELAFA)</MenuItem>
                          <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                          <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                          <MenuItem value={"SELCA"}>Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
                          <MenuItem value={"SELARL"}>Société d'exercice libéral a responsabilité limitée (SELARL)</MenuItem>
                          <MenuItem value={"SELEURL"}>Société d'exercice libéral ayant un associé Unique (SELEURL)</MenuItem>
                          <MenuItem value={"SELASU"}>Société d'exercice libéral par actions simplifiée unipersonnelle (SELASU)</MenuItem>
                          <MenuItem value={"SCEA"}>Société civile d'exploitation agricole (SCEA)</MenuItem>
                          <MenuItem value={"EARL"}>Exploitation agricole à responsabilité limitée (EARL)</MenuItem>
                          <MenuItem value={"GAEC"}>Groupement agricole d'exploitation en commun (GAEC)</MenuItem>
                          <MenuItem value={"GFA"}>Groupement foncier agricole (GFA)</MenuItem>
                          <MenuItem value={"EIRL"}>Entrepreneur Individuel à Responsabilité Limité</MenuItem>
                          <MenuItem value={"SPFPLAS"}>Société de Participations Financières de Profession Libérale d'Avocats Simplifiée (SPFPLAS)</MenuItem>
                        </Select>
                      </FormControl>
                      
                    </div>
                  </div>
                  <div  className={"grid grid-cols-12 items-center mt-6"} >
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>
                      
                      <FormControl size="small" className='w-full'>
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            'aria-label': 'weight',
                          }}
                          name="capital_lagrance"
                          value={val.capital_lagrance} 
                          onChange={(e)=>{props.handleChangeAddForm(e,val.index)}}   
                          error={!val.capital_lagrance  && props.isSubmitted?true:false}
                          index={val.index}
                        />
                      </FormControl>

                      <StyledTooltip title="Montant du capital mentionné dans les statuts.">
                        <div className='p-0 m-0 w-auto'>
                          <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                        </div>
                      </StyledTooltip>

                    </div>
                  </div>
                </div> 
                <div className={val.type_personne_lagrance === "PERSONNE_PHYSIQUE"?"":"hidden"}>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="civilite_lagrance"
                          value={val.civilite_lagrance} 
                          onChange={(e)=>{props.handleChangeAddForm(e,val.index)}}   
                          error={!val.civilite_lagrance  && props.isSubmitted?true:false}
                          index={val.index}
                          
                        >
                          <MenuItem value={"Mme"}>Madame</MenuItem>
                          <MenuItem value={"M."}>Monsieur</MenuItem>

                        </Select>
                      </FormControl>
                      
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="nomlagrance"
                        value={val.nomlagrance} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index)}}   
                        error={!val.nomlagrance  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="prenomlagrance" 
                        value={val.prenomlagrance} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index)}}  
                        error={!val.prenomlagrance  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>
                </div>
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="adresselagrance" 
                      value={val.adresselagrance} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index)}}   
                      error={!val.adresselagrance  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                  </div>
                </div>
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Complément adresse</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="adresse_suitelagrance"
                      value={val.adresse_suitelagrance} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index)}}  
                      index={val.index}
                    />
                  </div>
                </div>
                <div className={val.type_personne_lagrance === "PERSONNE_PHYSIQUE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Résidant à l’étranger</label>
                  
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <FormControl size="small" className='w-full'>
                      <InputLabel id="resident_etrangerlagrancephysic-label">Sélectionnez</InputLabel>
                      <Select
                        labelId="demo-resident_etrangerlagrancephysic-label"
                        id="resident_etrangerlagrancephysic"
                        label="resident_etrangerlagrancephysic"
                        name="resident_etrangerlagrancephysic"
                        value={`${val.resident_etrangerlagrancephysic}`}                         
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index)}}   
                        index={val.index}
                      >
                        <MenuItem value={"Non"} >Non</MenuItem>
                        <MenuItem value={"Oui"} >Oui</MenuItem>

                      </Select>
                    </FormControl>
                  </div>
                </div> 
                <div className={(val.type_personne_lagrance === "PERSONNE_PHYSIQUE" && val.resident_etrangerlagrancephysic=="Non")|| val.type_personne_lagrance === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":"hidden grid-cols-12 mt-6 items-center"}>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="code_postallagrance"
                      value={val.code_postallagrance} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index)}}  
                      error={!val.code_postallagrance  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                  </div>
                </div>

                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    {/* <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="villelagrance"
                      value={val.villelagrance} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index)}}  
                      error={!val.villelagrance  && props.isSubmitted?true:false}
                      index={val.index}
                    /> */}
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className={((val.type_personne_lagrance === "PERSONNE_PHYSIQUE"&& val.resident_etrangerlagrancephysic==="Oui"  ))?"w-full":"hidden w-full"}
                      name="villelagrance"
                      value={val.villelagrance} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"lagrance")}}  
                      error={!val.villelagrance  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                    <FormControl size="small" className={((val.type_personne_lagrance === "PERSONNE_PHYSIQUE"&& val.resident_etrangerlagrancephysic==="Oui"  ))?"hidden w-full":" w-full"} >
                      <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Sélectionnez"
                        name="ville_id_lagrance"
                        value={val.ville_id_lagrance} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"lagrance")}} 
                        error={!val.villelagrance  && props.isSubmitted?true:false}
                        index={val.index}
                      >
                        {
                          val.villelagranceArr.map((cityVal,cityIndex)=>{
                            return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                          })
                        }

                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className={val.type_personne_lagrance === "PERSONNE_PHYSIQUE" && val.resident_etrangerlagrancephysic=="Oui"?"grid grid-cols-12 mt-6 items-center":"hidden grid-cols-12 mt-6 items-center"}>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Pays *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="paysAssocies"
                      value={val.paysAssocies} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Associes")}}  
                      error={!val.paysAssocies  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                  </div>
                </div>
                <div className={val.type_personne_lagrance === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe </label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="greffelagrance"
                      value={val.greffelagrance} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index)}}   
                      // error={!val.greffelagrance  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                  </div>
                </div>
                <div className={val.type_personne_lagrance === "PERSONNE_MORALE"?"":"hidden"}>
                  <div className='px-0 group flex items-center gap-2 text-secondary mt-12 '>                   
                    <span className='text-base font-normal'>Représentée par</span>
                  </div>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité </label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="civilite_lagrance"
                          value={val.civilite_lagrance} 
                          onChange={(e)=>{props.handleChangeAddForm(e,val.index)}}                            
                          index={val.index}
                          
                        >
                          <MenuItem value={"Mme"}>Madame</MenuItem>
                          <MenuItem value={"M."}>Monsieur</MenuItem>

                        </Select>
                      </FormControl>
                      
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="nomlagrance" 
                        value={val.nomlagrance} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index)}}  
                        error={!val.nomlagrance  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="prenomlagrance" 
                        value={val.prenomlagrance} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index)}}  
                        error={!val.prenomlagrance  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Divider className='mb-6 mt-6' variant="middle" />
            </div> 
          })}  
          <div className='px-0 group flex items-center gap-2 text-primary hover:text-secondary mt-12 cursor-pointer ' onClick={(e)=> props.addForm(e,"lagrance")}>
            <BsPlusCircle className='text-3xl ' />
            <span className='text-base font-normal'>Ajouter un gérant</span>
          </div>
        

        {/* <div className='p-5 flex gap-4 justify-end w-full'>
          <button className='w-48 h-12 rounded-md text-thColor font-extrabold text-lg 3xl:text-xl border-2 border-thColor  transition-all duration-300 hover:border-secondary hover:text-white hover:btnShadow  hover:bg-secondary'>Autre annonce</button>
          <button className='w-48 h-12 rounded-md bg-thColor font-extrabold text-lg 3xl:text-xl text-white transition-all duration-300 hover:bg-secondary hover:btnShadow'>Obtenir le tarif</button>
        </div> */}
        </div>
        <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Cession des parts sociales</h2>
          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Cessions de parts sociales*</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-9 mr-8'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Sélectionnez"
                  id="Cession_parts" 
                  name="Cession_parts"
                  value={props.formData.Cession_parts}
                  onChange={props.handleChange}  
                  error={!props.formData.Cession_parts  && props.isSubmitted?true:false}
                >
                  <MenuItem value={"Cessions libres"}>Cessions libres</MenuItem>
                  <MenuItem value={"Cessions soumises à agrément"}>Cessions soumises à agrément</MenuItem>
                  <MenuItem value={"Cession libres entre associés soumises à agrément dans les autres cas"}>Cession libres entre associés soumises à agrément dans les autres cas</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
