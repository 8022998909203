import React from 'react'

import Footer from '../component/Footer'
import PersonalInformation from './pro/PersonalInformation'
import Login from './Login'

export default function Home() {
  return (
    <>
      <div className='w-full h-full bg-white '>
        {/* <PersonalInformation /> */}
        <Login />
      </div>
      <Footer />
    </>
  )
}
