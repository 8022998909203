import { useState, CSSProperties } from "react";
import PulseLoader from "react-spinners/PulseLoader";

const override = {
  position: "absolute",
  top: "50%",
  left: "50%",
  fontSize: "50px",
  color: "white",
  transform: "translate(-50%,-50%)",
};
const sweetLoading = {
  position: "fixed",
  width: "100vw",
  height: "100vh",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  backgroundColor: "rgba(0,0,0,0.5)",
  zIndex: "99",
  cursor: "pointer",
};


export default function PageLoaderComp(props) {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#602db4");
  // let [color, setColor] = useState("#1cbe93");

  return (
    <>
      <div className="sweet-loading" style={sweetLoading}>
        <PulseLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </>
  )
}
