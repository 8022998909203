import React, { useState } from "react";

import { Link } from "react-router-dom";

import { Menu } from "@headlessui/react";

import { Badge } from "@mui/material";
import NotificationSvg from "../assets/NotificationSvg.svg";
import UserIcon from "../assets/useIcon.svg";
import { ReactComponent as Messagerie } from "../Dashboard/Icons/Messagerie.svg";
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import PageLoaderComp from "./pageLoaderComp";
import { userConstants } from "../_constants";

export default function Notification() {
  const currentUserData = useSelector((state) => state.authentication.user);
  const [loadingFullpage, setLoadingFullpage] = useState(false);
  const dispatch = useDispatch();

  const UpdateNotifications =async (e,notification_type)=>{
    
    let user_id = currentUserData.data.id;
    let user_notifications_data_get = currentUserData.user_notifications_data;
    if(user_notifications_data_get.length>0 && (user_notifications_data_get.findIndex((noti)=>noti.notification_type===notification_type) >=0)){
      setLoadingFullpage(true)
      try {
          // Create the request data object
          const requestData = {
              user_id: user_id,
              notification_type: notification_type,
          };
          let post_url = `${process.env.REACT_APP_API_URL}api/notification/${user_id}`;
          
          // Use Axios to make the API request
          const response = await axios.put(post_url, requestData, {
            headers: {
                'Content-Type': 'application/json',
            },
          });
          if(response.data.sucess){
            let user_notifications_data = response.data.user_notifications_data;
            dispatch({ type: userConstants.NOTIFICATIONS, user_notifications_data });
            let getLocalUserDetails = localStorage.getItem("user_pro");
            if(getLocalUserDetails){
              let getLocalUserDetailsParse = JSON.parse(getLocalUserDetails);
              getLocalUserDetailsParse.user_notifications_data = user_notifications_data;
              localStorage.setItem("user_pro",JSON.stringify(getLocalUserDetailsParse));
            }
          }
          setLoadingFullpage(false)
          
      } catch (error) {
          console.error('Error:', error);
          setLoadingFullpage(false)
      } 
    }
    
  }

  return (
    <>
     {loadingFullpage?
      <PageLoaderComp />
      :""}
         <ul>
      <li className="relative group">
        <Menu>
          <Menu.Button className="text-sm sm:text-base font-medium text-primary bg-transparent flex items-center gap-2 hover:text-secondary">
            <Badge badgeContent={currentUserData?.user_notifications_data.length} color="primary" className="badgeTops">
              <img src={NotificationSvg} alt="alert" />
            </Badge>
          </Menu.Button>

          <Menu.Items className="DropItems absolute  right-0 top-[65px] origin-top-right py-4 text-center w-[350px]  px-4  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transform opacity-100 scale-100">
            {
               currentUserData&& currentUserData.user_notifications_data&& currentUserData.user_notifications_data.length>0? 
               currentUserData.user_notifications_data.map((val,index)=>{
                  return (
                    <Menu.Item className="group flex justify-left w-full items-center  px-2 py-3 text-base font-medium text-primary hover:text-secondary [&:not(:first-child)]:border-t" style={{borderColor: '#eae8e8'}}>
                      <Link to={val.notification_type==="message"?"/prouser/messagerie":val.notification_type==="balance_discount"?"/dashboard":val.notification_type==="published_ads"?"/prouser/annoncespublies":val.notification_type==="pending_ads"?"/prouser/annoncesenattente":"/dashboard"} className="bg-blue-500 flex gap-2" onClick={(e)=>UpdateNotifications(e,`${val.notification_type}`)}>
                        <span>
                          <Messagerie />
                        </span>
                        <span>
                          {val.notification_type==="message"?`Messages reçus`:""} 
                          {val.notification_type==="balance_discount"?`Solde de remise`:"Mon tableau de bord"} 
                          {val.notification_type==="published_ads"?`Nombre d'annonces publiées`:""} 
                          {val.notification_type==="pending_ads"?`Annonces en attente`:""} 
                          
                          {/* Mon tableau de bord */}
                        </span>  
                        {/*  */}
                      </Link> 
                    </Menu.Item>
                  )                    
               })
            :
              <Menu.Item className="group flex justify-left w-full items-center  px-2 py-3 text-base font-medium text-primary hover:text-secondary [&:not(:first-child)]:border-t" style={{borderColor: '#eae8e8'}}>
                <Link to={"/dashboard"} className="bg-blue-500 flex gap-2" onClick={(e)=>UpdateNotifications(e,"balance_discount")}>
                  
                  <span>aucune notification</span>  
                </Link> 
              </Menu.Item>
            }
            {/* <Menu.Item className="group flex justify-left w-full items-center  px-2 py-3 text-base font-medium text-primary hover:text-secondary [&:not(:first-child)]:border-t" style={{borderColor: '#eae8e8'}}>
              <Link to={"/dashboard"} className="bg-blue-500 flex gap-2"  onClick={(e)=>UpdateNotifications(e,"balance_discount")}>
                <span>
                  <Messagerie />
                </span>
                <span> Solde des remises accordées</span>  
              </Link> 
            </Menu.Item>
            <Menu.Item className="group flex justify-left w-full items-center  px-2 py-3 text-base font-medium text-primary hover:text-secondary [&:not(:first-child)]:border-t" style={{borderColor: '#eae8e8'}}>
              <Link to={"/prouser/annoncespublies"} className="bg-blue-500 flex gap-2"  onClick={(e)=>UpdateNotifications(e,"annoncespublies")}>
                <span>
                  <Messagerie />
                </span>
                <span> Nb d’annonces publiées de bord</span>  
              </Link> 
            </Menu.Item> */}

            
          </Menu.Items>
        </Menu>
      </li>
    </ul>
    </>
 
  );
}
