import React, { useEffect } from 'react';
import PropTypes from 'prop-types';


const ToastNotification = ({ message, type, onClose }) => {
  const notificationStyle = {
    position: 'fixed',
    top: '10px',
    right: '10px',
    padding: '15px',
    backgroundColor: type === 'success' ? '#4CAF50' : '#f44336',
    color: '#fff',
    borderRadius: '5px',
    zIndex: '1000',
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      onClose(); // Call the onClose function after 4 seconds
    }, 4000);

    return () => {
      clearTimeout(timerId); // Clear the timeout when the component unmounts or onClose is called
    };
  }, [onClose]);

  return <div style={notificationStyle}>{message}</div>;
};

ToastNotification.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ToastNotification;
