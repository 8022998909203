import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box, Checkbox, Divider, Grid, InputAdornment, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { frFR } from '@mui/x-date-pickers/locales';
import { LoadingButton } from '@mui/lab';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';

export default function InvoiceComp(props) {
  return (
    <>
        <div  className={!props.isOpenQuote && props.isOpenInvoice?`xl:max-w-[1126px] w-full h-full bg-white md:rounded-[20px]`:`xl:max-w-[1126px] w-full h-full bg-white md:rounded-[20px] hidden`}>
            <div className='mainHead min-h-[80px] sm:min-h-[120px] flex sm:flex-row flex-col sm:items-center p-3 sm:p-10 sm:justify-between'>
            <div>
                <h1 className='text-2xl 2xl:text-[25px] font-black text-white sm:mt-0 mt-4'>Facture<br />DEVIS n° {props.invoiceFormData.devis_number}</h1>
            </div>
            <div className='w-full sm:w-auto flex justify-end'>
                {/* {/ Your search component /} */}
                {/* <Button variant="outlined" className="border-white text-white font-semibold  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2" onClick={()=>props.setIsOpenInvoice(false)}>Retour à la conversation</Button> */}
            </div>
            </div>
            <div className="h-auto sm:h-[651px] ">
                <div className="w-full  h-auto overflow-y-auto p-4 rounded">
                    <div className="border border-gray-500 p-5 rounded-2xl" style={{borderColor: '#797979'}}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                        >
                            <Box component="section" sx={{ p: 2, border: '0px', width: '100%' }} className={"flex "}>                        
                            <Stack
                                direction="column"
                                justifyContent="space-between"
                                alignItems="start"
                                spacing={2}
                                
                            >
                                <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="start"
                                spacing={2}
                                
                                >
                                <Typography variant="subtitle1" component="subtitle1" className={"font-bold flex items-center"}>
                                    FACTURE n°
                                    <Button variant="outlined" className={"font-bold rounded-full border-gray text-black ml-5 "}>{props.invoiceFormData.invoice_number}</Button>
                                </Typography>
                                
                                </Stack>
                                <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="start"
                                    spacing={2}
                                >
                                    <Typography variant="body1" className="text-sm m-0 flex items-center" sx={{color: '#737373'}} >Date du devis : 
                                    {/* {invoiceFormData.devis_date} */}
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}  className={"ml-3 mb-3"}>
                                        <DatePicker
                                            className='ml-3'
                                            sx={{
                                                "& .MuiInputLabel-root": { color: '#797979', },
                                                "& fieldset": { border: '1px solid #eee' },
                                            }}
                                            disablePast={props.isEditing?false:true}
                                            slotProps={{ textField: { size: 'small' } }}
                                            value={dayjs(props.invoiceFormData.devis_date)}
                                            /* onChange={(date) => {
                                            setInvoiceFormData(prevState => ({
                                                ...prevState,
                                                devis_date: date.$d
                                            }));
                                            }} */
                                            onChange={(newValue) => {
                                                props.setInvoiceFormData(prevState => ({
                                                ...prevState,
                                                devis_date: newValue ? newValue.toISOString() : null
                                            }));
                                            }}
                                            renderInput={(params) => <TextField {...params} fullWidth variant='outlined' margin='normal' size='small' />}
                                            inputFormat="DD-MM-YYYY"
                                        />
                                        </LocalizationProvider>
                                    </Typography>
                                    <Typography variant="body1"  className="text-sm  mt-3 flex items-center"  sx={{color: '#737373'}}>Date de validité du devis : 
                                    {/* {invoiceFormData.devis_validity_date} */}
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}  className={"ml-3 mb-3"}>
                                        <DatePicker
                                            className='ml-3'
                                            disablePast={props.isEditing?false:true}
                                            sx={{
                                                "& .MuiInputLabel-root": { color: '#797979', },
                                                "& fieldset": { border: '1px solid #eee' },
                                            }}
                                            slotProps={{ textField: { size: 'small' } }}
                                            value={dayjs(props.invoiceFormData.devis_validity_date)}
                                            onChange={(newValue) => {
                                                props.setInvoiceFormData(prevState => ({
                                                ...prevState,
                                                devis_validity_date: newValue ? newValue.toISOString() : null
                                            }));
                                            }}
                                            /* onChange={(date) => {
                                            setInvoiceFormData(prevState => ({
                                                ...prevState,
                                                devis_date: date.$d
                                            }));
                                            }} */
                                            renderInput={(params) => <TextField {...params} fullWidth variant='outlined' margin='normal' size='small' />}
                                            inputFormat="DD-MM-YYYY"
                                        />
                                        </LocalizationProvider>
                                    </Typography>    
                                </Stack>
                            </Stack> 
                            </Box>
                            <Box component="section" sx={{ 
                            backgroundColor: '#f6f6f6',
                            borderRadius: '10px',
                            p: 3,
                            width: '50%'

                            }}>
                            <Typography variant="body1" sx={{color: '#2a2a2a'}} >{props.messagesData?.society}</Typography >
                            <Typography variant="body1" className='text-xs capitalize'   sx={{color: '#737373'}} >{props.messagesData.nom} {props.messagesData.prenom} <br /> {props.messagesData.address} <br />{props.messagesData.code_postal}  {props.messagesData.ville} </Typography >

                            </Box>
                        </Stack>
                        <Divider className='mt-2 mb-2' />
                        <Grid container spacing={2} className='h-auto mb-5'>
                            <Grid item xs={12}> 
                                <Typography variant="h6" sx={{color: '#2a2a2a'}} className='mb-3 mt-3' >Informations personnelles du client</Typography >                              
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <Typography variant="body1" sx={{color: '#2a2a2a'}} className='mb-3 mt-3' >Société</Typography >                           
                                    
                                        <TextField className={"w-full rounded-full mb-3"} size="small" id="outlined-basic"   variant="outlined" placeholder='Société'  name='society' value={props.messagesData.society} onInput={(e)=>props.handleChangeUser(e)} />
                                                                    
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body1" className='mb-3  mt-3' sx={{color: '#2a2a2a'}} >Prénom</Typography >
                                        <TextField   className={"w-full rounded-full"} size="small" id="outlined-basic"   variant="outlined"  name='prenom' value={props.messagesData.prenom} onInput={(e)=>props.handleChangeUser(e)}  />
                                        
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body1" className='mb-3  mt-3' sx={{color: '#2a2a2a'}} >Nom</Typography >
                                        <TextField   className={"w-full rounded-full"} size="small" id="outlined-basic"   variant="outlined"  name='nom' value={props.messagesData.nom} onInput={(e)=>props.handleChangeUser(e)}  />                                            
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body1" className='mb-3  mt-3' sx={{color: '#2a2a2a'}} >Address</Typography >
                                        <TextField  type="text"  size="small" sx={{borderRadius: '100px'}}   variant="outlined"   name='address' value={props.messagesData.address} onInput={(e)=>props.handleChangeUser(e)} />
                                    </Grid>                                
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <Typography variant="body1" className='mb-3  mt-3' sx={{color: '#2a2a2a'}} >Code postal</Typography >
                                        <TextField  type="text"  size="small" sx={{borderRadius: '100px'}}   variant="outlined"   name='code_postal' value={props.messagesData.code_postal} onInput={(e)=>props.handleChangeUser(e)} />
                                    </Grid>
                                    <Grid item xs={3} >
                                        <Typography variant="body1" className='mb-3  mt-3' sx={{color: '#2a2a2a'}} >Ville</Typography >
                                        <Select
                                            size="small"
                                            className='w-full'
                                            helperText=""
                                            // error={(submitted && !formData.ville_id) ?true:false}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Ville"
                                            name='ville_id'
                                            value={props.messagesData.ville_id} 
                                            onChange={props.handleChangeUser}
                                            >
                                            {
                                                props.messagesData.villeArr.map((cityVal,cityIndex)=>{
                                                    return(<MenuItem key={cityIndex} value={cityVal.id}>{cityVal.city}</MenuItem>)
                                                })
                                            }
                                        </Select>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body1" className='mb-3  mt-3' sx={{color: '#2a2a2a'}} >Téléphone</Typography >
                                        <TextField  type="text"  size="small" sx={{borderRadius: '100px'}}   variant="outlined"   name='telephone' value={props.messagesData.telephone} onInput={(e)=>props.handleChangeUser(e)} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body1" className='mb-3  mt-3' sx={{color: '#2a2a2a'}} >E-mail</Typography >
                                        <TextField  type="text"  size="small" sx={{borderRadius: '100px'}}   variant="outlined"  name='email' value={props.messagesData.email} onInput={(e)=>props.handleChangeUser(e)} />
                                    </Grid>
                                </Grid>
                            </Grid> 
                        </Grid>
                        <Divider className='mt-2 mb-2' />
                        <Grid container spacing={2} className='min-h-[300px] overflow-y-scroll'>
                            {
                            props.invoiceFormData.quote_arr.map((prest,index)=>{
                            return(
                                <Grid item xs={12} key={index}>                              
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography variant="body1" sx={{color: '#2a2a2a'}} className='mb-3 mt-3' >Prestations</Typography >                           
                                    
                                        <TextField className={"w-full rounded-full mb-3"} size="small" id="outlined-basic"   variant="outlined" placeholder='Description de la prestation' value={prest.prestation} name='prestation' onInput={(e)=>props.handleChange(e,index)} />
                                                                    
                                    </Grid>
                                    <Grid item xs={7} >
                                        <Grid container spacing={2} className="flex justify-end items-center">
                                            <Grid item xs={4}>
                                                <Typography variant="body1" className='mb-3  mt-3' sx={{color: '#2a2a2a'}} >Quantité</Typography >
                                                <TextField  type="number" className={"w-full rounded-full"} size="small" id="outlined-basic"   variant="outlined" value={prest.quantity} name='quantity' onInput={(e)=>props.handleChange(e,index)}  />
                                                
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body1" className='mb-3  mt-3' sx={{color: '#2a2a2a'}} >Prix unit. HT</Typography >
                                                <TextField  type="number"  size="small" sx={{borderRadius: '100px'}}   variant="outlined"  InputProps={{
                                            endAdornment: <InputAdornment position="end">€</InputAdornment>, }} value={prest.price_ht} name='price_ht' onInput={(e)=>props.handleChange(e,index)} />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body1" className='mb-3  mt-3' sx={{color: '#2a2a2a'}} >Total HT</Typography >
                                                <TextField  type="text"  size="small" sx={{borderRadius: '100px'}}   variant="outlined"  InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment>, readOnly: true, }} value={prest.sub_total_ht} name='sub_total_ht' onInput={(e)=>props.handleChange(e,index)} />
                                            </Grid>
                                        
                                        </Grid>                              
                                    </Grid>
                                    <Grid item xs={1} className="mt-7 flex justify-end items-center" >
                                        <RemoveCircleOutlineOutlinedIcon sx={{ fontSize: 40 }} className={index===0?`hidden`:`ml-2 cursor-pointer`} onClick={(e)=>props.handleRemove(e,"prestation",index)} />
                                    </Grid>
                                </Grid>
                                </Grid>
                            )})
                            }                   
                            <Typography variant="body1" sx={{color: '#2a2a2a'}} className='pl-5 pr-5 mb-1 mt-1 cursor-pointer' onClick={(e)=>props.handleAdd(e,"prestation")}><ControlPointOutlinedIcon  sx={{ fontSize: 40 }} /> 
                            Ajouter une prestation 
                            </Typography>
                        </Grid>
                        <Divider className='mt-2 mb-2' />
                        <Grid container spacing={2} >
                            <Grid item xs={6}>
                            
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={2} alignItems="center" className="mb-3 flex justify-end items-center">
                                    <Grid item xs={12} className="flex justify-end items-center ">
                                        <Typography variant="body1" sx={{ color: '#2a2a2a', mr: 2 }}>
                                        Total HT:
                                        </Typography>
                                        <TextField
                                        className='w-[150px] '
                                        size="small"
                                        sx={{ borderRadius: '100px' }}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                            readOnly: true,
                                        }}
                                        name={"total_ht"}
                                        value={props.invoiceFormData.total_ht}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} alignItems="center"  className="mb-3  flex justify-end items-center">
                                    <Grid item xs={12} className="flex justify-end items-center">
                                        <Typography variant="body1" sx={{ color: '#2a2a2a', mr: 2 }}>
                                         <Checkbox  checked={props.invoiceFormData.remiseIsChecked} onChange={props.handleChecked} /> Remise
                                        </Typography>
                                        <TextField
                                        className='w-[150px] '
                                        size="small"
                                        sx={{ borderRadius: '100px' }}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            readOnly: true,
                                        }}
                                        name={"remise"}
                                        value={parseFloat(props.invoiceFormData.remise)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} alignItems="center" className={props.invoiceFormData.remiseIsChecked?"mb-3  flex justify-end items-center":"hidden"} >
                                    <Grid item xs={12} className="flex justify-end items-center">
                                        <Typography variant="body1" sx={{ color: '#2a2a2a', mr: 2 }}>
                                        Tolal Remise HT
                                        </Typography>
                                        <TextField
                                        className='w-[150px] '
                                        size="small"
                                        sx={{ borderRadius: '100px' }}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                            readOnly: true,
                                        }}
                                        name={"total_remise_ht"}
                                        value={parseFloat(props.invoiceFormData.total_remise_ht)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} alignItems="center" className="mb-3  flex justify-end items-center">
                                    <Grid item xs={12} className="flex justify-end items-center">
                                        <Typography variant="body1" sx={{ color: '#2a2a2a', mr: 2 }}>
                                        Total TVA (20%)
                                        </Typography>
                                        <TextField
                                        className='w-[150px] '
                                        size="small"
                                        sx={{ borderRadius: '100px' }}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                            readOnly: true,
                                        }}
                                        name={"total_tva"}
                                        value={parseFloat(props.invoiceFormData.total_tva).toFixed(2)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} alignItems="center" className="mb-3  flex justify-end items-center">
                                    <Grid item xs={12} className="flex justify-end items-center">
                                        <Typography variant="body1" sx={{ color: '#2a2a2a', mr: 2 }}>
                                        Total TTC
                                        </Typography>
                                        <TextField
                                        className='w-[150px] '
                                        size="small"
                                        sx={{ borderRadius: '100px' }}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                            readOnly: true,
                                        }}
                                        name={"total_ttc"}
                                        value={props.invoiceFormData.total_ttc}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>                    
                        </Grid>
                        {/* footer part */}
                        <Grid container spacing={2} className='mt-8 p-5' >
                            <Grid item xs={6} className="mb-3 flex justify-start items-center">
                                <a target="_blank" download={false} rel="noopener noreferrer" alt={props.invoiceFormData.invoice_number+".pdf"} href={process.env.REACT_APP_AWS_URL_STATIC_BUSYPLACE + 'assets/uploads/invoices_pro/' + props.invoiceFormData.invoice_number+".pdf"} >
                                <Button variant="outlined" className='mr-3 capitalize' startIcon={<SearchIcon />} sx={{ borderColor: '#27295B', color: '#27295B','&:hover': {borderColor: '#27295B',  backgroundColor: '#27295B', color: '#FFFFFF' } }}  >Aperçu</Button>    
                                </a>       
                                {/* <Button onClick={(e)=>handleSave(e,"save")} variant="outlined" startIcon={<SaveIcon />}  sx={{ borderColor: '#27295B', color: '#27295B','&:hover': {borderColor: '#27295B',  backgroundColor: '#27295B', color: '#FFFFFF' } }} >Enregistrer sans envoyer</Button>  */}       
                                <LoadingButton  onClick={(e)=>props.handleSave(e,"save","save")} variant="outlined"   sx={{ borderColor: '#27295B', color: '#27295B','&:hover': {borderColor: '#27295B',  backgroundColor: '#27295B', color: '#FFFFFF' } }}  className=' capitalize'  
                                    // onClick={handleSubmit}
                                    endIcon={<SaveIcon />}
                                    loading={props.isLoading}
                                    loadingPosition="end" >
                                        <span>Enregistrer sans envoyer</span>
                                </LoadingButton>   
                            </Grid>
                            <Grid item xs={6}  className="mb-3 flex justify-end items-center">
                                <Button onClick={()=>props.setIsOpenInvoice(false)} variant="outlined" className='mr-3 capitalize'  sx={{ borderColor: '#27295B', color: '#27295B','&:hover': {borderColor: '#27295B',  backgroundColor: '#27295B', color: '#FFFFFF' } }}>Annuler</Button>           
                                {/* <Button onClick={(e)=>handleSave(e,"send")} variant="contained" sx={{backgroundColor: '#27295B', borderColor: '#27295B', color: '#FFFFFF','&:hover': {borderColor: '#27295B',  backgroundColor: '#27295B', color: '#FFFFFF' } }}>Envoyer</Button> */}  
                                <LoadingButton
                                    onClick={(e)=>props.handleSave(e,"send","send")} variant="outlined"   sx={{ borderColor: '#27295B', color: '#27295B','&:hover': {borderColor: '#27295B',  backgroundColor: '#27295B', color: '#FFFFFF' } }}
                                    className=' capitalize'
                                    endIcon={<SaveIcon />}
                                    loading={props.isLoadingSend}
                                    loadingPosition="end"                  >
                                    <span>Générer et envoyer</span>
                                </LoadingButton>         
                                
                            </Grid>                    
                        </Grid>
                    </div>                        
                </div>                    
            </div>                    
        </div>
    </>
  );
}