// import React from 'react';
// import logo from '../../assets/Social/illustration header.svg'

// import { TiTick } from "react-icons/ti";
// const Banner = () => {

//     const tickColor = "#1CBE93";
//     return (
//         <div style={{ fontFamily: "Montserrat", algnContient: "center" }}>
//             <main className="grid lg:grid-cols-2 place-items-center pt-16 pb-8 md:pt-8">
//                 <div className="py-6 md:order-1 hidden md:block" style={{ marginRight: "50px" }}>
//                     <img
//                         src={logo}
//                         alt="Astronaut in the air"
//                         loading="eager"
//                         format="avif"
//                     />
//                 </div>
//                 <div style={{ marginLeft: "65px" }}>
//                     <h3 style={{ color: "#27295B", fontWeight: "700" }} className='text-2xl '>
//                         La plateforme nationale de publication <br /> des <span className="text-green-500" style={{ color: "#1CBE93" }}> annonces légales</span>
//                     </h3>
//                     <p className="text-lg mt-4 text-slate-600 max-w-xl flex items-center" style={{ color: "#27295B" }}>
//                         <TiTick className="mr-2" style={{ color: tickColor }} />Publiez vos mentions légales en 3 clics
//                     </p>
//                     <p className="text-lg mt-4 text-slate-600 max-w-xl flex items-center" style={{ color: "#27295B" }}>
//                         <TiTick className="mr-2" style={{ color: tickColor }} />Simplifiez la rédaction et la publication de vos annonces légales
//                     </p>
//                     <p className="text-lg mt-4 text-slate-600 max-w-xl flex items-center" style={{ color: "#27295B" }}>
//                         <TiTick className="mr-2" style={{ color: tickColor }} />Recevez votre attestation officielle immédiatement
//                     </p>
//                     <div className="mt-6 flex flex-col sm:flex-row gap-3">
//                         <button
//                             type="button"
//                             className="group hidden md:flex text-base font-medium text-white bg-primary flex-col justify-center leading-[18px] items-center px-1 text-center whitespace-nowrap rounded-full w-[254px] h-[51px] transition-all duration-300 hover:bg-secondary"
//                         >
//                             Publier une annonce
//                         </button>
//                     </div>
//                 </div>
//             </main>
//         </div>
//     );
// };

// export default Banner;


import React from 'react'
import heroImg from '../../assets/Home/herobg.png'

import { ReactComponent as CheckHero } from '../../assets/Home/CheckHero.svg'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';
export default function Banner() {
  return (
    <>
      <Helmet>
        <title>Annonces légales instantanées pas cher partout en France I Busyplace</title>
        <meta name="description" content="Annonce légale au prix le plus juste ; publiez toutes vos annonces légales simplement et au meilleur prix. Publication immédiate apres paiement sur tous les départements de France et DOM TOM." />
        <link rel="canonical" href={`${process.env.REACT_APP_WEB_URL}/`} />
      </Helmet>
      <div className='md:max-w-[1477px]  w-full h-full m-auto py-8 sm:py-16 lg:py-24'>
        <div className='grid grid-cols-12 items-center justify-between px-6 sm:px-14 '>
          <div className='col-span-12 lg:col-span-7 order-last lg:order-first'>
            <div className='w-max-[740px] flex gap-5 sm:gap-10 flex-col'>
              <h1 className='text-xl sm:text-3xl xl:text-[35px] font-extrabold md:leading-[45px] mt-6 text-primary'> La plateforme nationale de publication des <span className='text-secondary font-black'>annonces légales</span> </h1>
              <ul className='flex flex-col gap-0 sm:gap-4'>
                <li className='flex items-center gap-3'>
                  <CheckHero /> Publiez vos annonces légales en 3 clics</li>
                <li className='flex items-center gap-3'>
                  <CheckHero /> Simplifiez la rédaction et la publication de vos annonces légales</li>
                <li className='flex items-center gap-3'>
                  <CheckHero /> Recevez votre attestation officielle immédiatement</li>
              </ul>
              {/* <Link to="/annuaire-des-experts-comptable-selection" className='btnShadow flex items-center justify-center text-white rounded-[25.5px] font-semibold text-base sm:text-xl py-2 sm:py-3 sm:h-[51px] sm:w-[254px] transition-all duration-300 mt-3 bg-primary hover:bg-secondary'>Publier une annonce</Link> */}
              <Link to="/nos-formulairess" className='btnShadow flex items-center justify-center text-white rounded-[25.5px] font-semibold text-base sm:text-xl py-2 sm:py-3 sm:h-[51px] sm:w-[254px] transition-all duration-300 mt-3 bg-primary hover:bg-secondary'>Publier une annonce</Link>
            </div>
          </div>
          <div className='order-first lg:order-last col-span-12 lg:col-span-5'>
            <img src={heroImg} alt="Hero Images" className='w-full h-full m-auto' />
          </div>
        </div>
      </div>
    </>
  )
}
