import React, { useState } from 'react'
import LogoWhite from '../assets/BusyWhite.png'


import { ReactComponent as Instagrm } from '../assets/Social/instagrm.svg'
import { ReactComponent as Facebook } from '../assets/Social/Facebook.svg'
import { ReactComponent as Twitter } from '../assets/Social/twitter.svg'
import { Link } from 'react-router-dom'
export default function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  return (
    <>
      <div className='relative w-full bg-primary h-full m-auto '>
        <div className='md:max-w-[1477px] m-auto px-6 sm:px-14'>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-0 '>
            <div className='md:col-span-2 mt-7 lg:mt-14'>
              <img src={LogoWhite} alt="logo" style={{ width: '250px', height: 'fit-content' }} />
              <p className='text-base text-white font-normal max-w-[402px] mt-5'>Parce que notre priorité est de vous simplifier la vie et de rendre votre expérience d'entrepreneur inoubliable, nous avons mis en place des solutions complètes, performantes et à disposition toute notre expertise de professionnels pour vous accompagner et vous assister sereinement tout au long de la durée de vie de votre entreprise.</p>
              {/* <div className='flex flex-row gap-4 items-center mt-6'>
                <Link to=""><Facebook /></Link>
                <Link to=""> <Instagrm /></Link>
                <Link to=""><Twitter /></Link>
              </div> */}
            </div>
            <div className='mt-7 lg:mt-32'>
              <h3 className='font-black text-lg sm:text-xl text-white'>Qui sommes-nous ?</h3>
              <div className='flex flex-col  items-start sm:mt-3 '>
                <Link className='text-base leading-9 font-normal text-white transition-all duration-300 hover:text-secondary' target="_blank" to="https://blog.legals.fr/">Actualités</Link>
                <Link className='text-base leading-9 font-normal text-white transition-all duration-300  hover:text-secondary' to="/mentions-legales">Mentions légales</Link>
                <Link className='text-base leading-9 font-normal text-white transition-all duration-300 hover:text-secondary' to="/conditions-generales-vente">Conditions d’utilisation</Link>
                <Link className='text-base leading-9 font-normal text-white transition-all duration-300 hover:text-secondary' to="/faq">FAQ</Link>
              </div>
            </div>
            <div className='mt-7 lg:mt-32'>
              <h3 className='font-black text-lg sm:text-xl text-white'>Nous contacter</h3>
              <div className='flex flex-col  items-start sm:mt-3 '>
                <p className='text-base leading-9 font-normal text-white' to="/">Busyplace  - 128 Rue La Boetie, 75008 Paris</p>
                <Link className='text-base leading-9 font-normal text-white transition-all duration-300 hover:text-secondary' to="mailto:contact@busyplace.fr">contact@busyplace.fr</Link>
                <Link className='text-base leading-9 font-normal text-white transition-all duration-300 hover:text-secondary' to="tel:01 53 65 16 66">01 53 65 16 66</Link>
              </div>
            </div>
            <div>
            </div>
          </div>
      
           
            <div className='mt-7 '>
              <hr className='bg-white border border-white' />
              <div className='flex flex-col-reverse sm:flex-row justify-between py-5 gap-5'>

                <p className='text-base font-light text-white'>© Copyright {currentYear} - busyplace.fr - Tous droits réservés</p>

                <div className='flex flex-row gap-4 items-center '>
                  <a href="https://www.facebook.com/busyplace.france" target="_blank" rel="noopener noreferrer" aria-label="Follow us on Facebook"><Facebook /></a>
                  <a href="https://www.instagram.com/busyplace_fr/" target="_blank" rel="noopener noreferrer" aria-label="Follow us on Instagram"><Instagrm /></a>
                  {/* Ensure you have a correct URL before using Twitter link */}
                  <a href="#" target="_blank" rel="noopener noreferrer" aria-label="Follow us on Twitter"><Twitter /></a>
                </div>
              </div>

            </div> 
        </div>
      </div>
    </>
  )
}
