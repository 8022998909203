import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation, Navigate } from 'react-router-dom'

import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import Footer from '../component/Footer'
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Checkbox, FormControlLabel } from '@mui/material'

import ToastMessageComp from '../component/ToastMessageComp';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions, userActions } from '../_actions';
import SocialLoginComp from '../component/SocialLoginComp'
import { Helmet } from 'react-helmet';

export default function Login() {
  let navigate = useNavigate();
  const location = useLocation();
  const alert = useSelector(state => state.alert);
  const dispatch = useDispatch();
  let remember_email = localStorage.getItem('remember_email');
  let remember_password = localStorage.getItem('remember_password');
  let is_remember = localStorage.getItem('is_remember');

  const [user, setUser] = useState({
    email: "",
    password: "",
    login_type: 'normal',
    remember_password: false,
  });
  const [submitted, setSubmitted] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  useEffect(() => {

    const getUser = JSON.parse(localStorage.getItem('user_pro'));
    if (getUser) {
      // Redirect to the dashboard
      // You can use the 'Navigate' component or any other method to redirect
      // Example using Navigate from react-router-dom
      // Make sure to import 'Navigate' from 'react-router-dom'
      // return <Navigate to="/dashboard" />;
      // navigate("/dashboard");
    }
  }, []); // The empty dependency array makes this effect run only once when the component mounts

  useEffect(() => {

    setUser({
      email: remember_email ? remember_email : "",
      password: remember_password ? remember_password : "",
      login_type: 'normal',
      remember_password: is_remember ? Boolean(is_remember) : false,
    })

  }, [])

  function handleChange(e) {
    const { name, value } = e.target;


    if (e.target.name === "remember_password") {
      setUser(user => ({ ...user, [name]: e.target.checked }));
    } else {
      setUser(user => ({ ...user, [name]: value }));
    }
  }
  async function handleSubmit(e) {
    e.preventDefault();
    setSuccessful(false);
    setSubmitted(true);
    // const { from } = location.state || { from: { pathname: "/" } };
    if (user.email && user.password) {
      dispatch(userActions.login(user)).then((response) => {
        setSuccessful(true);
        if (response.sucess === true) {
          // window.location.reload();
          let lastUrl = localStorage.getItem("announceCurrentUrl");
          // localStorage.setItem("email", formData.email);
          // navigate(`/registrationform/${response.data.id}`);
          // pro_user_type ==="personal"
          // pro_user_type ==="socity"
          // pro_user_type ==="presentation"
          // pro_user_type ==="language"
          // pro_user_type ==="accounting"
          // pro_user_type ==="openinghours"
          // pro_user_type ==="useraccess"
          // pro_user_type ==="sharedprofileLink"
          // pro_user_type ==="logo"
          /* if(response.data.pro_user_type === "personal"){
            console.log("response.data.pro_user_type ======",response.data.pro_user_type)
            navigate(`/registrationform/${response.data.id}`);
          }else if(response.data.pro_user_type === "socity"){
            navigate(`/propresentation/${response.data.id}`);
          }else if(response.data.pro_user_type === "socity"){
            navigate(`/propresentation/${response.data.id}`);
          }
          return; */

          localStorage.setItem("id", response.data.id)
          localStorage.setItem("email", response.data.email)
          localStorage.setItem("is_login", true)
          dispatch(alertActions.success(response.msg));
          setTimeout(() => {
            if(lastUrl){
              
              navigate(lastUrl);
            }else{
              // navigate(-1)  
              navigate("/dashboard");
            } 
            
            // window.location.reload();
          }, 1000);


        } else {
          dispatch(alertActions.error(response.msg));
          // navigate(-1)       
        }

      })
        .catch(() => {
          setSuccessful(true);

        });
    }
  }

  const currentUser = useSelector(state => state.authentication.user);
  
  //  if (currentUser && !(successful)) {
  //     return <Navigate to="/dashboard" />;
  //   } 




  return (
    <>
      <Helmet>
        <title>identification ou création de compte sur Busyplace</title>

        <meta name="description" content="Se connecter sur Busyplace" />
        <meta property="og:title" content="identification ou création de compte sur Busyplace" />
        <meta property="og:description" content="Se connecter sur Busyplace" />
        <meta name="twitter:title" content="identification ou création de compte sur Busyplace" />
        <meta name="twitter:description" content="Se connecter sur Busyplace" />
        <link rel="canonical" href={`${process.env.REACT_APP_WEB_URL}/login`} />
      </Helmet>
      {successful ?
        <ToastMessageComp message={alert.message} type={alert.type} />
        : ""}

      <form onSubmit={handleSubmit}>
        <div className='bg-[#F5F5F5] w-full py-7 3xl:py-12'>
          <div className='md:max-w-[1477px] relative w-full h-full max-h-[684px] flex items-center justify-center m-auto  '>

            <div className='max-w-[640px] 3xl:max-w-[720px]  w-full h-full bg-white px-5 sm:px-12 py-2 sm:py-5 3xl:p-12 ' style={{ boxShadow: "0px 4px 10px 1px rgba(39, 41, 91, 0.35)", borderRadius: "33px" }}>
              <div className='flex items-center px-2 justify-around gap-14 py-2 3xl:py-4 text-center'>
                <h3 className='text-2xl sm:text-3xl 3xl:text-4xl font-semibold  py-4 text-primary '>Se connecter <p className='font-medium text-sm'>Compte pro</p></h3>


                {/* <Link to="/" className='text-2xl sm:text-3xl 3xl:text-4xl border-b-[6px] border-white text-[#797979]  font-light hover:text-secondary hover:border-b-[6px] hover:border-secondary py-4'>Inscription</Link> */}

              </div>

              <div className='my-4 block'>
                <TextField
                  // disableUnderline
                  sx={{
                    "& .MuiInputLabel-root": { color: '#797979', },
                    "& fieldset": { border: '1px solid #eee' },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '8px',
                      color: '#797979',
                      background: 'rgb(39 41 91 / 6%)',
                    }
                  }
                  }
                  className='w-full'
                  label="Email" variant="outlined" type="email" error={submitted && !user.email ? true : false} name="email" value={user.email} onChange={handleChange} required />


              </div>

              <div className='my-4 block'>
                <div className='my-4 block'>
                  <FormControl className='w-full' variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password" sx={{ color: submitted && !user.password ? '#d32f2f' : '#797979', }} >Mot de passe</InputLabel>
                    <OutlinedInput
                      error={submitted && !user.password ? true : false} name="password" value={user.password} onChange={handleChange}
                      type={showPassword ? 'text' : 'password'}
                      sx={{
                        "& .MuiInputLabel-root": { color: submitted && !user.password ? '#d32f2f' : '#797979', },
                        "& fieldset": { border: '1px solid #eee' },
                        background: 'rgb(39 41 91 / 6%)',
                        color: submitted && !user.password ? '#d32f2f' : '#797979',
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <AiFillEye fill='#CFCFCF' /> : <AiFillEyeInvisible fill='#CFCFCF' />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Mot de passe"
                    />
                  </FormControl>
                </div>
              </div>

              <div className='flex gap-4 sm:gap-auto justify-between  sm:items-center sm:flex-row flex-col mb-5 mt-8 sm:mt-0'>

                <FormControlLabel control={<Checkbox onChange={handleChange} name="remember_password" checked={user.remember_password} />} label={<span className="text-base text-black-light font-medium">
                  Rester connecté
                </span>} />
                <div>
                  <Link className='text-black-light text-base font-medium underline hover:text-secondary' to="/forgot-password">Mot de passe oublié ?</Link>
                </div>
              </div>


              <button type='submit' className='bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white  mt-4 sm:mt-4
                3xl:mt-10 px-6 w-full py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white'>Se connecter</button>


              <div className='mt-8 3xl:mt-8 text-center'>
                <div className=' items-center m-auto w-full text-center'>
                  {/* <div className='h-[0.5px] bg-black-light'> </div> */}
                  <p className=' sm:col-auto font-light text-base text-[#27295B] '>Vous n’avez pas de compte pro ?  <Link to="/personalInformation" className='font-medium'>S’inscrire </Link></p>
                  {/* <div className='h-[0.5px] bg-black-light'> </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      {/* <Footer /> */}
    </>
  )
}
