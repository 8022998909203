import { userConstants } from '../_constants';
let user = JSON.parse(localStorage.getItem('user_pro'));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };
        case userConstants.USER_BUSINESS_SETTINGS:
            return {
                ...state,
                user: {...state.user,["UserBusinessDetails"]:action.UserBusinessDetails}
            };
        case userConstants.NOTIFICATIONS:
            return {
                ...state,
                user: {...state.user,["user_notifications_data"]:action.user_notifications_data}
            };
        case userConstants.USER_PRO_DETAILS:
            return {
                ...state,
                user: {...state.user,["UserProDetails"]:action.UserProDetails,["data"]:action.UserProDetails.details}
            };
        case userConstants.LOGIN_FAILURE:
            return {};
        case userConstants.ADDRESS_REQUEST:
            return {
                ...state,
                user: {...state.user,["user_address"]:action.user_address}
            };
        case userConstants.ADDRESS_SUCCESS:
            return {
                ...state,
                user: {...state.user,["user_address"]:action.user_address}
            };
        case userConstants.ADDRESS_FAILURE:
            return {};
        case userConstants.LOGOUT:
            return {};
        default:
            return state
    }
}