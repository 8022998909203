import React from "react";
import { Navigate } from "react-router-dom";

function Protected({ children }) {
  const getUser = JSON.parse(localStorage.getItem('user_pro'));
  let checkAuth = false;
  if (getUser) {
    checkAuth = true;
  }
  // const checkAuth = true;
  return checkAuth === true ? children : <Navigate to="/" replace />;
}

export default Protected;

export function CheckLoggedin({ children }) {
  const getUser = JSON.parse(localStorage.getItem('user_pro'));
  let checkAuth = false;
  if (getUser) {
    checkAuth = true;
  }
  
  return checkAuth === true ?  <Navigate to="/dashboard" replace /> :children;
}


