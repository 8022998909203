import React, { useState, useEffect, useRef } from "react";
import Footer from "../../component/Footer";
import Breadcrumb from "./Breadcrumb";
import NeedHelp from "./NeedHelp";
// import { TbPointFilled } from "react-icons/tb";
import { Link } from "react-router-dom";
import axios from "axios";
import { decodedStringValue } from "../../_helpers";
import { Helmet } from 'react-helmet';
import VerifiedIcon from '@mui/icons-material/Verified';


export default function AnnuaireDesAvocats() {
  const [breadcrumbsData, setBreadcrumbsData] = useState({
    "breadCrumbs": [
      { "pagename": "Annuaires", "path": "/" }
    ],
    "main": { "pagename": "Avocats", "path": "/" }
  });
  /* =====================|Get Default Values|================== */
  const searchRef = useRef(null);
  const [search, setSearch] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [checkList, setCheckList] = useState(false);
  const [paginateData, setPaginateData] = useState({
    "current": 1,
    "total_pages": 0,
    "total_results": 0,
    "size": 100
  });
  // Search Input data
  function getInputSearchData(input) {
    setSearchKey(input);
    if (input) {
      /* let data = JSON.stringify({
        "query": input,
        "group": {
          "field": "cbcp"
        }
       
      }); */
      let data = { search: input, size: 10, currentPage: 1 };
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        // url: 'https://enterprise-search.kifwat.net/api/as/v1/engines/directory-avocats/search',
        url: process.env.REACT_APP_API_URL + `api/admin/search_directive_avocats`,
        headers: {
          // 'Authorization': `Bearer ${process.env.REACT_APP_ELK_SEARCH_API_KEY}`,
          // 'Accept': '*/*', 
          // "Cache-Control":"no-cache",
          'Content-Type': 'application/json',
        },
        data: data
      };
      axios.request(config)
        .then((response) => {

          if ((response.data.data).length > 0) {
            let resultList = response.data.data.filter((val, i) => val._source.cbVille && val._source.cbCp)
            // console.log("resultList", resultList)
            setSearch(resultList);
            setCheckList(true);
          } else {
            setSearch(response.data.data);
          }

        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          console.error(error);
          setCheckList(false);
        });


    } else {
      setCheckList(false);
    }
  }
  // ========================================

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [search, checkList]);

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setCheckList(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Annuaire des Avocats en France | Trouvez un Avocat sur BusyPlace</title>
        <meta name="description" content="À la recherche d'un avocat ? Découvrez notre annuaire complet d'avocats en France sur BusyPlace. Trouvez facilement des professionnels qualifiés par spécialité et région pour répondre à tous vos besoins juridiques." />
        {/* <link rel="canonical" href={`${process.env.REACT_APP_WEB_URL}/avocats`} /> */}
      </Helmet>
      <div className='w-full h-full AnnuaireBg  pb-6 py-2 md:py-0 md:min-h-[359px]' >
        <div className='md:max-w-[1477px] relative w-full h-full m-auto py-0 md:py-8 px-4 sm:px-14'>
          <div className='flex items-center justify-start sm:gap-4 w-full m-auto'>
            <Breadcrumb page="Avocats" breadcrumbsData={breadcrumbsData} />
          </div>
          <div className='max-w-[1277px] m-auto mt-8 md:mt-16 flex justify-center items-center flex-col gap-4'>
            <h1 className=' text-2xl text-center lg:text-3xl 3xl:text-[35px] font-black text-primary'>L’annuaire des Avocats</h1>
            <div
              className=" max-w-[727px]"
              style={{
                position: "relative",
                width: '100%',
                margin: '0 auto'
              }}
            >
              <form
                id="searchForm"
                style={{ zIndex: "9" }}
                className="bg-white border-0  mx-auto px-5 py-4 input-box-shadow rounded flex justify-between w-full"
              >
                <input
                  onInput={(e) => getInputSearchData(e.target.value)}
                  type="text"
                  className=" w-full outline-none text-[#797979] text-base font-light"
                  placeholder="Nom de la ville ou du département"
                />
                <Link>
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M33 33L25.6807 25.6677M29.7368 15.8684C29.7368 19.5466 28.2757 23.074 25.6749 25.6749C23.074 28.2757 19.5466 29.7368 15.8684 29.7368C12.1903 29.7368 8.6628 28.2757 6.06197 25.6749C3.46113 23.074 2 19.5466 2 15.8684C2 12.1903 3.46113 8.6628 6.06197 6.06197C8.6628 3.46113 12.1903 2 15.8684 2C19.5466 2 23.074 3.46113 25.6749 6.06197C28.2757 8.6628 29.7368 12.1903 29.7368 15.8684V15.8684Z"
                      stroke="#27295B"
                      strokeWidth="3"
                      strokeLinecap="round"
                    />
                  </svg>
                </Link>
              </form>
              <div
                style={{ display: checkList ? "block" : "none" }}
                className="searchListBox"
                ref={searchRef}
              >
                <ul>
                  {search.length === 0 ? (
                    <div
                      className="containertype"
                      style={{ textAlign: "left", paddingTop: "9px 19px" }}
                    >
                      <span>No result found!</span>
                      {/* <div className="dottype"></div>
                      <div className="dottype"></div>
                      <div className="dottype"></div> */}
                    </div>
                  ) : (
                    ""
                  )}
                  {search.map((listView) => {
                    let list = listView._source;
                    // console.log("list", list)
                    return (
                      <li>
                        <Link
                          className="ctmref"
                          to={"/fiche-avocats/" + decodedStringValue(list.slug) + "/" + list.avocats_id}
                        // to={`/annuaire-des-avocats-selection/${list.cbville}/${list.cbcp}`}
                        >
                          {decodedStringValue(list.cbRaisonSociale)} ({decodedStringValue(list.cbVille)}, {list.cbCp})
                          {
                          list.partner_status ==="approved"?
                          <span style={{ color: "green", marginLeft: "8px" }}><VerifiedIcon color="success" /></span>
                          : list.partner_status ==="gold" ?<span style={{ color: "green", marginLeft: "8px" }}><VerifiedIcon style={{"color":"gold"}}  /></span>:""
                         }
                        </Link>
                      </li>
                    );
                  })}
                  <li className={search.length > 0 ? "" : "hidden"} style={{ "fontWeight": "700" }}>
                    <Link
                      onClick={(e) => {
                        setCheckList(false)
                        setPaginateData({
                          "current": 1,
                          "total_pages": 0,
                          "total_results": 0,
                          "size": 100
                        })
                      }}
                      className="ctmref"
                      // to={`/directory_search_result/avocats/${searchKey}`}
                      to={`/annuaire-des-avocats-selection/${"avocats"}/${searchKey}`}
                    >
                      {"Voir tous les résultats de la recherche"}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <form className="bg-white border-0 max-w-[727px] px-5 py-4 input-box-shadow rounded-full flex justify-between w-full">
              <input type="text" className=" w-full outline-none text-[#797979] text-base font-light" placeholder="Nom de la ville ou du département" />
              <Link to='/fiche-Avocats'>
                <svg width="28" height="28" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M33 33L25.6807 25.6677M29.7368 15.8684C29.7368 19.5466 28.2757 23.074 25.6749 25.6749C23.074 28.2757 19.5466 29.7368 15.8684 29.7368C12.1903 29.7368 8.6628 28.2757 6.06197 25.6749C3.46113 23.074 2 19.5466 2 15.8684C2 12.1903 3.46113 8.6628 6.06197 6.06197C8.6628 3.46113 12.1903 2 15.8684 2C19.5466 2 23.074 3.46113 25.6749 6.06197C28.2757 8.6628 29.7368 12.1903 29.7368 15.8684V15.8684Z" stroke="#27295B" strokeWidth="3" strokeLinecap="round" />
                </svg>
              </Link>
            </form> */}
          </div>
        </div>

      </div >
      <div className='w-full h-full min-h-[359px]  px-4 sm:px-14' >
        <div className='max-w-[1057px] relative w-full h-full m-auto mt-8 sm:mt-20'>
          <h2 className='font-semibold text-xl md:text-3xl text-primary'>Pourquoi faut-il consulter un avocat lors de la création de son entreprise ?</h2>
          <h6 className='text-[17px] font-semibold text-primary mt-5'>Quel avocat pour une entreprise ?</h6>
          <p className='text-[17px] font-normal text-primary '>Un avocat en droit des sociétés est un expert du droit privé, spécifiquement des affaires relatives aux activités et à l'existence générale d'une entreprise. Il intervient dans les affaires juridiques concernant son fonctionnement. Son champ de compétences comprend les formalités administratives et contractuelles.</p>

          <p className='text-[17px] font-normal text-primary mt-3'>Il vous assistera dans la création de votre entreprise. Son rôle est de vous assister et de vous apporter une aide juridique, en présence de litiges mais aussi en amont pour les prévenir. Seul l'avocat peut vous représenter et vous assister en justice.</p>
        </div>
      </div>
      <NeedHelp />
      <Footer />
    </>
  )
}
