import React from 'react'
import Profil from '../pages/pro/Profil'
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
export default function Profile() {

  const currentUser = useSelector(state => state.authentication.user);
  /* if (!currentUser) {
    return <Navigate to="/" />;
  } */
  
  return (
    <div>
      <Profil/>
    </div>
  )
}
