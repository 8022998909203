
import React, { useEffect, useState } from 'react'
import Compétences from "../../assets/Compétences.svg"
import matries from "../../assets/matries.svg"
import { Box, Container, Paper, TextField } from '@mui/material'
import axios from 'axios';
import CompanyModal from './Dialog-Box/CompanyModal'
import PresentationModal from './Dialog-Box/PresentationModal'
import ProfessionModal from './Dialog-Box/ProfessionModal'
import SkillsModal from './Dialog-Box/SkillsModal'
import ActivityareaModal from './Dialog-Box/ActivityareaModal'
import LanguagesModal from './Dialog-Box/LanguagesModal'
import CertificationModal from './Dialog-Box/CertificationModal'
import AccessModal from './Dialog-Box/AccessModal'
import ProfilesharingModal from './Dialog-Box/ProfilesharingModal'
import LogoModal from './Dialog-Box/LogoModal'
import OpeningHoursModal from './Dialog-Box/OpeningHoursModal';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import no_image from '../../assets/no_image.png';

function isJson(str) {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
}
export default function Profil() {

    const navigate = useNavigate()
    const currentUser = useSelector(state => state.authentication.user);
    const [profileData, setProfileData] = useState([]);
    const [societyProfileData, setSocietyProfileData] = useState([]);
    const [loading, setLoading] = useState(true);
    const handlegetProUserProfile = async () => {
        try {
            setLoading(true);
            const storedEmail = localStorage.getItem("email");
            const user_id = localStorage.getItem("id");
            const response = await axios.get(`${process.env.REACT_APP_API_URL}api/prouser/get-prouserprofile/${user_id}`);

            if (response.status === 200 && response.data.success) {              
                localStorage.setItem('id', response?.data?.id)
                setProfileData(response?.data?.data?.prouser_profile)
                setSocietyProfileData(response?.data?.data?.prouser_society)
                setLoading(false)
            } else {
                setLoading(false)
                navigate('/login')
            }
        } catch (error) {
            console.error('Error during API call:', error);
            
            if (error.response && error.response.status === 400 && error.response.data.success === false) {
                console.error(error.response.data.msg || 'API call failed');
                setLoading(false);
            }
            if (error.response && error.response.status === 401 && error.response.data.success === false) {
                // console.error(error.response.data.msg || 'API call failed');
                setLoading(false);
                navigate('/login')
            }
        }
    };

    useEffect(() => {
        // handlegetProUserProfile();
        if(currentUser && currentUser.UserProDetails){
            setProfileData(currentUser?.UserProDetails)
            if(currentUser.UserProDetails.society){
                setSocietyProfileData(currentUser?.UserProDetails?.society)
            }
        }
    }, [currentUser]);

    return (
        <>
        <div className='bg-mainBg'>
            <React.Fragment >
                <div className='w-full min-h-[200px] ' style={{ background: 'linear-gradient(90.63deg, #27295B 0%, #1CBE93 100%)' }}>
                    <Container maxWidth='lg'>
                        <div className='md:max-w-[1477px] relative w-full h-full  m-auto  py-7  flex flex-col justify-center px-6 sm:px-14'>
                            <div className='flex flex-col sm:flex-row items-center gap-4'>
                                {
                                    
                                    profileData&&profileData.details && profileData.details.profile_image?
                                    
                                    <img src={profileData?.details?.profile_image} 

                                    alt={profileData?.details?.profile_image} style={{ width: '100px', height: '100px' }} />
                                    :
                                    <svg width="33" height="33" className='w-[100px] h-[100px] sm:w-auto sm-h-auto' viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.2949 0.5C7.46292 0.5 0.294922 7.668 0.294922 16.5C0.294922 25.332 7.46292 32.5 16.2949 32.5C25.1269 32.5 32.2949 25.332 32.2949 16.5C32.2949 7.668 25.1269 0.5 16.2949 0.5ZM16.2949 6.9C19.3829 6.9 21.8949 9.412 21.8949 12.5C21.8949 15.588 19.3829 18.1 16.2949 18.1C13.2069 18.1 10.6949 15.588 10.6949 12.5C10.6949 9.412 13.2069 6.9 16.2949 6.9ZM16.2949 29.3C13.0469 29.3 9.20692 27.988 6.47092 24.692C9.27349 22.4932 12.7327 21.2981 16.2949 21.2981C19.8571 21.2981 23.3164 22.4932 26.1189 24.692C23.3829 27.988 19.5429 29.3 16.2949 29.3Z" fill="#1CBE93" />
                                    </svg>

                                }
                                <div>
                                    <h4 className='font-bold text-lg 3xl:text-xl text-white'>{profileData?.society?.company_name} </h4>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <Container maxWidth='lg'>
                    <div className='md:max-w-[1477px] relative w-full h-full  m-auto mt-[-50px]  px-0 sm:px-14'>
                        <div className=' flex w-full'>
                            <div className='w-full'>
                                <Paper elevation={2} className='bg-white rounded-lg p-6 w-full '>
                                    <div className='flex gap-2  w-100 justify-between'>
                                        <div className='flex gap-2  '>

                                            <p className='text-lg font-bold text-primary'>{profileData?.society?.company_name}</p>
                                        </div>
                                        <div>
                                            <CompanyModal currentUser={currentUser}/>
                                            {/* <HiOutlinePencilAlt size={20} /> */}
                                        </div>
                                    </div>
                                    <p className='text-base text-primary font-light'>
                                       {profileData?.society?.address} {profileData?.society?.comp_address}
                                    </p>
                                    <p className='text-base text-primary font-light mt-3'>
                                        {profileData?.society?.telephone}
                                    </p>

                                    <p className='text-base text-primary font-light'> {profileData?.details?.email}</p>
                                    <p className='text-base text-primary font-light'> Dirigeant :
                                        {/* {profileData?.society?.first_last_name_manager} */}
                                        {/* {profileData?.society?.first_last_name_manager?(JSON.parse(profileData.society.first_last_name_manager)).join(", "):""} */}
                                        {profileData?.society?.first_last_name_manager && isJson(profileData?.society?.first_last_name_manager)?JSON.parse(profileData?.society?.first_last_name_manager).map((dt, index) => (
                                            <span>{dt.name} {" "}</span>
                                        )):profileData?.society?.first_last_name_manager
                                        }
                                    </p>
                                </Paper>

                                <Paper elevation={2} className='bg-white rounded-lg p-6  mt-4'>
                                    <div className='flex gap-2  w-100 justify-between'>
                                        <div className='flex gap-2  '>
                                            <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M24.1991 17.137V2.49795C24.1991 2.12819 24.0523 1.77357 23.7908 1.51211C23.5293 1.25065 23.1747 1.10376 22.805 1.10376H3.28628C2.91652 1.10376 2.5619 1.25065 2.30044 1.51211C2.03898 1.77357 1.89209 2.12819 1.89209 2.49795V27.5934C1.89209 27.9631 2.03898 28.3178 2.30044 28.5792C2.5619 28.8407 2.91652 28.9876 3.28628 28.9876H10.9543M8.16595 6.68052H17.9253M8.16595 12.2573H17.9253M8.16595 17.8341H12.3485" stroke="#27295B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M23.5023 28.9873L21.0869 26.5719M21.0869 26.5719C21.4172 26.2497 21.6804 25.8652 21.861 25.4406C22.0416 25.0159 22.136 24.5597 22.1389 24.0983C22.1418 23.6368 22.0531 23.1794 21.8778 22.7526C21.7025 22.3257 21.4443 21.9379 21.118 21.6116C20.7916 21.2854 20.4038 21.0272 19.9769 20.852C19.55 20.6768 19.0926 20.5881 18.6312 20.591C18.1698 20.594 17.7135 20.6885 17.2889 20.8692C16.8643 21.0498 16.4798 21.313 16.1577 21.6434C15.5089 22.2981 15.1458 23.1832 15.148 24.105C15.1501 25.0267 15.5173 25.9101 16.1692 26.5618C16.821 27.2135 17.7045 27.5804 18.6263 27.5823C19.5481 27.5842 20.433 27.2209 21.0876 26.5719H21.0869Z" stroke="#27295B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p className='text-lg font-bold text-primary'>Présentation</p>
                                        </div>
                                        <div>
                                            {/* <HiOutlinePencilAlt size={20} /> */}
                                            <PresentationModal />
                                        </div>
                                    </div>
                                    <p className='text-base text-primary font-light mt-4'>{profileData?.user_profile?.presentation}
                                    </p>

                                </Paper>
                                <Paper elevation={2} className='bg-white rounded-lg p-6 mt-4'>
                                    <div className='flex gap-2  w-100 justify-between'>
                                        <div className='flex gap-2  '>
                                            <img src={matries} alt="matries" />
                                            <p className='text-lg font-bold text-primary'>Métiers</p>
                                        </div>
                                        <div  >
                                            {/* <HiOutlinePencilAlt size={20} /> */}
                                            <ProfessionModal />
                                        </div>
                                    </div>
                                    <div className='mt-4 flex gap-2 items-center flex-wrap'>
                                        <div className='rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit' style={{ background: 'rgba(222, 227, 226, 0.5)' }}>{profileData?.user_profile?.job_title==="accountant"?"Experts comptables":profileData?.user_profile?.job_title}</div>
                                    </div>
                                </Paper>
                                <Paper elevation={2} className='bg-white rounded-lg p-6 mt-4'>
                                    <div className='flex gap-2  w-100 justify-between'>
                                        <div>
                                            <div className='flex gap-2  '>
                                                <img src={Compétences} alt="" />
                                                <p className='text-lg font-bold text-primary'>{/* {profileData?.user_profile?.presentation=="avocats"?"Compétences":`Domaines`} */}Compétences</p>
                                            </div>
                                            <div className='mt-4 flex gap-2 items-center flex-wrap'>
                                                {profileData?.user_profile?.skills?(JSON.parse(profileData.user_profile.skills)).join(", "):""}
                                               {/*  {profileData?.user_profile?.skills&&JSON.parse(profileData.user_profile.skills).map((dt, index) => (
                                                    <div key={index} className='rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit' style={{ background: 'rgba(222, 227, 226, 0.5)' }}>
                                                        {dt}
                                                    </div>
                                                ))
                                                } */}
                                            </div>
                                        </div>
                                        <div  >
                                            {/* <HiOutlinePencilAlt size={20} /> */}
                                            <SkillsModal />
                                        </div>
                                    </div>
                                </Paper>
                                <Paper elevation={2} className='bg-white rounded-lg p-6 mt-4'>
                                    <div className='flex gap-2  w-100 justify-between'>
                                        <div className='flex gap-2 '>
                                            <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.92198 19.3005V21.4256H7.79483L0.796875 28.4236L2.29932 29.926L9.29728 22.9281V27.8009H11.4224V19.3005H2.92198Z" fill="#27295B" />
                                                <path d="M14.6099 27.8009V25.6758C16.9615 25.673 19.2298 24.805 20.9826 23.2374C22.7354 21.6698 23.8501 19.5121 24.1144 17.1754H15.6725C15.109 17.1748 14.5688 16.9508 14.1704 16.5524C13.772 16.1539 13.5479 15.6137 13.5474 15.0503V6.60835C11.2107 6.87264 9.053 7.98741 7.48538 9.74021C5.91775 11.493 5.04982 13.7613 5.04698 16.1129H2.92188C2.92525 13.014 4.15775 10.0431 6.34895 7.85188C8.54016 5.66068 11.5111 4.42818 14.6099 4.4248C14.8917 4.4248 15.162 4.53675 15.3613 4.73602C15.5605 4.93529 15.6725 5.20555 15.6725 5.48735V15.0503H25.2354C25.5172 15.0503 25.7875 15.1623 25.9868 15.3615C26.186 15.5608 26.298 15.8311 26.298 16.1129C26.2946 19.2117 25.0621 22.1826 22.8709 24.3738C20.6797 26.565 17.7088 27.7975 14.6099 27.8009Z" fill="#27295B" />
                                                <path d="M29.4923 12.9254H19.9176C19.6389 12.9252 19.3628 12.8701 19.1054 12.7632C18.8479 12.6563 18.614 12.4997 18.4172 12.3023C18.2203 12.1049 18.0642 11.8707 17.958 11.6129C17.8517 11.3552 17.7973 11.079 17.7979 10.8003V1.23731C17.799 1.09629 17.8279 0.956887 17.883 0.827086C17.9382 0.697286 18.0184 0.579649 18.1191 0.480925C18.2198 0.382201 18.339 0.304335 18.4698 0.251795C18.6007 0.199255 18.7406 0.173076 18.8817 0.174759C21.9731 0.18373 24.9354 1.41579 27.1214 3.6018C29.3074 5.7878 30.5395 8.75009 30.5485 11.8416C30.5507 11.9822 30.5251 12.1219 30.4732 12.2526C30.4212 12.3833 30.344 12.5024 30.2458 12.6032C30.1477 12.7039 30.0305 12.7842 29.9012 12.8395C29.7719 12.8948 29.6329 12.924 29.4923 12.9254ZM19.923 10.8003H28.3649C28.1207 8.64549 27.1528 6.63728 25.6194 5.10386C24.0859 3.57045 22.0777 2.60253 19.923 2.3583V10.8003Z" fill="#27295B" />
                                            </svg>

                                            <p className='text-lg font-bold text-primary'>Secteurs d’activité</p>
                                        </div>
                                        <div>
                                            {/* <HiOutlinePencilAlt size={20} /> */}
                                            <ActivityareaModal />
                                        </div>
                                    </div>

                                    <div className='mt-4 flex gap-2 items-center flex-wrap'>
                                        {/* {profileData?.user_profile?.sectors} */}
                                        {profileData?.user_profile?.sectors?(JSON.parse(profileData.user_profile.sectors)).join(", "):""}
                                       {/*  {profileData?.user_profile?.sectors&&JSON.parse(profileData?.user_profile?.sectors).map((dt, index) => (
                                            <div key={index} className='rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit' style={{ background: 'rgba(222, 227, 226, 0.5)' }}>
                                                {dt}
                                            </div>
                                        ))
                                        } */}
                                    </div>

                                </Paper>

                                <Paper elevation={2} className='bg-white rounded-lg p-6 mt-4'>
                                    <div className='flex gap-2  w-100 justify-between'>
                                        <div className='flex gap-2 '>
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M8 22.58L10 20.58V23.42L7.7 25.7L6 25V20H3L2 19V3L3 2H27L28 3V12H26V4H4V18H7L8 19V22.58ZM20.58 26L24.3 29.7L26 29V26H29L30 25V15L29 14H13L12 15V25L13 26H20.58ZM21 24H14V16H28V24H25L24 25V26.58L21.7 24.3L21 24Z" fill="#27295B" />
                                            </svg>
                                            <p className='text-lg font-bold text-primary'>Langues</p>
                                        </div>
                                        <div>
                                            {/* <HiOutlinePencilAlt size={20} /> */}
                                            <LanguagesModal />
                                        </div>
                                    </div>

                                    <div className='mt-4 flex gap-2 items-center flex-wrap'>
                                        {/* {profileData?.user_profile?.languages} */}
                                        {profileData?.user_profile?.languages?(JSON.parse(profileData.user_profile.languages)).join(", "):""}
                                        {/* {profileData?.user_profile?.languages&& JSON.parse(profileData?.user_profile?.languages).map((dt, index) => (
                                            <div key={index} className='rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit'>
                                                {dt}
                                            </div>
                                        ))
                                        } */}
                                    </div>

                                </Paper>

                                <Paper elevation={2} className='bg-white rounded-lg p-6 mt-4'>
                                    <div className='flex gap-2  w-100 justify-between'>
                                        <div className='flex gap-2 '>
                                            <svg width="31" height="27" viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M30 16.4762V8.7381L15 1L0 8.7381L15 16.4762L22.8 12.9048V21.2381C22.8 23.619 19.2 26 15 26C10.8 26 7.2 23.619 7.2 21.2381V12.9048" stroke="#27295B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                            <p className='text-lg font-bold text-primary'>Certifications</p>
                                        </div>
                                        <div>
                                            {/* <HiOutlinePencilAlt size={20} /> */}
                                            <CertificationModal />
                                        </div>
                                    </div>

                                    <div className='mt-4 flex gap-2 items-center flex-wrap'>
                                        {/* {profileData?.user_profile?.certifications} */}
                                        {/* {profileData?.user_profile?.certifications?(JSON.parse(profileData.user_profile.certifications)).join(", "):""} */}
                                        {profileData?.user_profile?.certifications&&JSON.parse(profileData?.user_profile?.certifications).map((dt, index) => (
                                            <div key={index} className='rounded-[30px] text-[15px] font-normal text-gray py-1  max-w-fit'>
                                                N° {dt.name} {dt.number}{","}
                                            </div>
                                        ))
                                        }
                                    </div>
                                </Paper>

                                <Paper elevation={2} className='bg-white rounded-lg  mt-4'>
                                    <div className='bg-white rounded-md p-6   '>
                                        <div className='flex gap-2  w-100 justify-between'>
                                            <div className='flex gap-2 mb-7'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                                    <g clip-path="url(#clip0_1823_3724)">
                                                        <path d="M14.3691 1.71956C14.0799 1.69821 13.79 1.68752 13.5 1.6875V0C13.8317 0.000161709 14.1632 0.0125451 14.4939 0.037125L14.3691 1.71956ZM17.7508 2.47894C17.2099 2.2702 16.6543 2.10156 16.0886 1.97437L16.4582 0.327375C17.1045 0.4725 17.7407 0.664875 18.3583 0.9045L17.7508 2.47894ZM20.0627 3.67706C19.8214 3.51616 19.5743 3.36418 19.3219 3.22144L20.1538 1.75331C20.7308 2.08024 21.283 2.44911 21.8059 2.85694L20.7681 4.18837C20.5393 4.00989 20.304 3.8399 20.0627 3.67875V3.67706ZM23.1576 6.69769C22.8238 6.22346 22.4555 5.77449 22.0556 5.35444L23.2774 4.19006C23.733 4.671 24.1549 5.18569 24.5379 5.72569L23.1576 6.69769ZM24.4131 8.97919C24.3023 8.71165 24.1818 8.44823 24.0519 8.18944L25.5589 7.43006C25.8569 8.02252 26.1107 8.63619 26.3182 9.26606L24.7151 9.79425C24.6244 9.51896 24.5237 9.24709 24.4131 8.97919ZM25.3074 13.2098C25.2938 12.6298 25.2374 12.0517 25.1387 11.4801L26.8009 11.1932C26.9139 11.8446 26.9798 12.5061 26.9966 13.1676L25.3091 13.2098H25.3074ZM25.0864 15.8051C25.1421 15.5183 25.1876 15.2331 25.2231 14.9445L26.8987 15.1521C26.8177 15.8104 26.688 16.4619 26.5106 17.1011L24.8839 16.6506C24.9615 16.3721 25.029 16.0903 25.0864 15.8051ZM23.4799 19.8197C23.7904 19.3303 24.0637 18.8173 24.3 18.2874L25.8424 18.9709C25.5724 19.5784 25.2602 20.1623 24.9058 20.7225L23.4799 19.8197ZM21.8531 21.8531C22.059 21.6472 22.2564 21.4346 22.4438 21.2153L23.7229 22.3172C23.5063 22.5683 23.2805 22.8115 23.0462 23.0462L21.8531 21.8531Z" fill="#27295B" />
                                                        <path d="M13.4994 1.6875C11.5569 1.68764 9.64439 2.16683 7.9313 3.08262C6.21821 3.99841 4.7574 5.32255 3.67826 6.93773C2.59912 8.55292 1.93496 10.4093 1.74462 12.3425C1.55427 14.2756 1.8436 16.2259 2.58699 18.0206C3.33037 19.8152 4.50487 21.3988 6.00645 22.6311C7.50803 23.8635 9.29034 24.7065 11.1955 25.0855C13.1007 25.4645 15.07 25.3678 16.9289 24.804C18.7877 24.2402 20.4789 23.2266 21.8525 21.8531L23.0456 23.0462C21.4758 24.6169 19.5428 25.7761 17.4179 26.4212C15.2929 27.0662 13.0417 27.1772 10.8636 26.7443C8.68558 26.3114 6.64792 25.3479 4.93121 23.9393C3.21451 22.5306 1.87175 20.7203 1.02193 18.6686C0.1721 16.617 -0.158561 14.3874 0.0592408 12.1775C0.277043 9.96749 1.03658 7.84536 2.27056 5.99909C3.50454 4.15283 5.17487 2.63944 7.13353 1.593C9.0922 0.546573 11.2787 -0.000588486 13.4994 4.74961e-07V1.6875Z" fill="#27295B" />
                                                        <path d="M12.6562 5.0625C12.88 5.0625 13.0946 5.1514 13.2529 5.30963C13.4111 5.46786 13.5 5.68247 13.5 5.90625V14.6981L18.981 17.8301C19.1697 17.9439 19.3063 18.127 19.3618 18.3402C19.4172 18.5535 19.3871 18.7799 19.2777 18.9712C19.1684 19.1625 18.9886 19.3034 18.7768 19.3639C18.5649 19.4244 18.3378 19.3996 18.144 19.2949L12.2377 15.9199C12.1086 15.8461 12.0013 15.7395 11.9266 15.6109C11.8519 15.4823 11.8125 15.3362 11.8125 15.1875V5.90625C11.8125 5.68247 11.9014 5.46786 12.0596 5.30963C12.2179 5.1514 12.4325 5.0625 12.6562 5.0625Z" fill="#27295B" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1823_3724">
                                                            <rect width="27" height="27" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <p className='text-lg font-bold text-primary'>Horaires d’ouverture</p>
                                            </div>
                                            <div>
                                                {/* <HiOutlinePencilAlt size={20} /> */}
                                                <OpeningHoursModal />
                                            </div>
                                        </div>
                                        
                                        {profileData?.opening_hours?.opening_hours&&Object.entries(JSON.parse(profileData?.opening_hours?.opening_hours)).map(([day, times]) => {
                                           
                                            return (
                                            <div className='flex flex-col sm:flex-row sm:items-center sm:gap-4 my-2'>
                                                <p className='text-base font-light text-black min-w-[100px]'>{day} </p>
                                                {Object.entries(times).map(([period, hours], subIndex) => (
                                                    <p key={subIndex} className='text-base font-light text-black'>{`${hours.type} : ${hours.value}`}</p>
                                                ))}
                                            </div>)
                                            })}
                                    </div>

                                </Paper>
                                <Paper elevation={2} className='bg-white rounded-lg p-6 mt-4'>
                                    <div className='flex gap-2  w-100 justify-between'>
                                        <div className='flex gap-2 '>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                                                <path d="M16.4996 24.75L10.2193 15.8812C9.19129 14.6675 8.52929 13.1868 8.31037 11.6113C8.09145 10.0359 8.32461 8.43077 8.9827 6.98275C9.64078 5.53474 10.6967 4.30349 12.0275 3.4324C13.3583 2.56131 14.9092 2.08624 16.4996 2.0625C18.7041 2.08429 20.8102 2.97886 22.3565 4.5503C23.9028 6.12174 24.7633 8.24195 24.7496 10.4466C24.7504 12.3698 24.0957 14.2358 22.8933 15.7369L16.4996 24.75ZM16.4996 4.125C14.8412 4.14404 13.2581 4.82045 12.098 6.0057C10.9379 7.19094 10.2956 8.78813 10.3121 10.4466C10.319 11.9603 10.8636 13.4222 11.8486 14.5716L16.4996 21.1613L21.2743 14.4375C22.1819 13.3046 22.6798 11.8981 22.6871 10.4466C22.7036 8.78813 22.0613 7.19094 20.9011 6.0057C19.741 4.82045 18.158 4.14404 16.4996 4.125Z" fill="#27295B" />
                                                <path d="M16.5 11.3438C17.6391 11.3438 18.5625 10.4203 18.5625 9.28125C18.5625 8.14216 17.6391 7.21875 16.5 7.21875C15.3609 7.21875 14.4375 8.14216 14.4375 9.28125C14.4375 10.4203 15.3609 11.3438 16.5 11.3438Z" fill="#27295B" />
                                                <path d="M28.875 12.375H26.8125V14.4375H28.875V28.875H4.125V14.4375H6.1875V12.375H4.125C3.57799 12.375 3.05339 12.5923 2.66659 12.9791C2.2798 13.3659 2.0625 13.8905 2.0625 14.4375V28.875C2.0625 29.422 2.2798 29.9466 2.66659 30.3334C3.05339 30.7202 3.57799 30.9375 4.125 30.9375H28.875C29.422 30.9375 29.9466 30.7202 30.3334 30.3334C30.7202 29.9466 30.9375 29.422 30.9375 28.875V14.4375C30.9375 13.8905 30.7202 13.3659 30.3334 12.9791C29.9466 12.5923 29.422 12.375 28.875 12.375Z" fill="#27295B" />
                                            </svg>
                                            <p className='text-lg font-bold text-primary'>Accès</p>
                                        </div>
                                        <div>
                                            {/* <HiOutlinePencilAlt size={20} /> */}
                                            <AccessModal />
                                        </div>
                                    </div>
                                    <div className='mt-4 flex gap-2 items-center flex-wrap'>
                                        <div key={0} className='rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit'>
                                                {profileData.user_other_profile&&profileData.user_other_profile.your_access&&(JSON.parse(profileData.user_other_profile.your_access)).join(", ")}
                                            </div>
                                        {/* {profileData?.user_other_profile?.your_access} */}
                                        {/* {JSON.parse(profileData?.user_other_profile?.your_access).map((dt, index) => (
                                            <div key={index} className='rounded-[30px] text-[15px] font-normal text-gray py-1 px-3 max-w-fit'>
                                                {dt}
                                            </div>
                                        ))
                                        } */}
                                    </div>
                                </Paper>
                                <Paper elevation={2} className='bg-white rounded-lg p-6 mt-4'>
                                    <div className='flex gap-2  w-100 justify-between'>
                                        <div className='flex gap-2 '>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="29" viewBox="0 0 26 29" fill="none">
                                                <path d="M21 9C23.2091 9 25 7.20914 25 5C25 2.79086 23.2091 1 21 1C18.7909 1 17 2.79086 17 5C17 7.20914 18.7909 9 21 9Z" stroke="#27295B" strokeWidth="1.5" />
                                                <path d="M21 27.6667C23.2091 27.6667 25 25.8758 25 23.6667C25 21.4575 23.2091 19.6667 21 19.6667C18.7909 19.6667 17 21.4575 17 23.6667C17 25.8758 18.7909 27.6667 21 27.6667Z" stroke="#27295B" strokeWidth="1.5" />
                                                <path d="M5 18.3333C7.20914 18.3333 9 16.5425 9 14.3333C9 12.1242 7.20914 10.3333 5 10.3333C2.79086 10.3333 1 12.1242 1 14.3333C1 16.5425 2.79086 18.3333 5 18.3333Z" stroke="#27295B" strokeWidth="1.5" />
                                                <path d="M17.5443 7.01599L8.45898 12.316M17.5443 21.6507L8.45898 16.3507" stroke="#27295B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p className='text-lg font-bold text-primary'>Partage du profil</p>
                                        </div>
                                        <div>
                                            {/* <HiOutlinePencilAlt size={20} /> */}
                                            <ProfilesharingModal />
                                        </div>
                                    </div>
                                    <div className='mt-4 flex gap-2 items-center flex-wrap'>
                                        <Box component="form" className='mt-5 sm:mt-5 3xl:mt-12'
                                            sx={{
                                                "& .MuiInputLabel-root": { color: '#797979', },
                                                "& fieldset": {
                                                    border: '1px solid #eee', borderRadius: '8px',
                                                    color: '#797979',
                                                    background: 'rgb(39 41 91 / 6%)',
                                                },
                                            }}
                                        >
                                            {profileData?.user_other_profile?.share_your_profile&&JSON.parse(profileData?.user_other_profile?.share_your_profile).map((dt, index) => (
                                                <div key={index} className='inline-flex items-center gap-6 w-full mb-5'>
                                                    <p className='min-w-[80px]'>{Object.keys(dt)[0]}</p>
                                                    <TextField
                                                        placeholder={`Coller le lien url de ${Object.keys(dt)[0]}`}
                                                        variant='outlined'
                                                        size='small'
                                                        disabled
                                                        value={Object.values(dt)[0]}
                                                        className='w-2/3 outline-secondary focus:outline-secondary'
                                                    />
                                                </div>
                                            ))}
                                        </Box>
                                    </div>
                                </Paper>
                                <Paper elevation={2} className='bg-white rounded-lg p-6 mt-4 mb-8'>
                                    <div className='flex gap-2  w-100 justify-between'>
                                        <div className='flex gap-2 '>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26" fill="none">
                                                <rect x="0.5" y="0.5" width="26" height="25" rx="4.5" stroke="#27295B" />
                                                <path d="M12.128 17V8.6H13.688V15.68H18.08V17H12.128Z" fill="#27295B" />
                                            </svg>
                                            <p className='text-lg font-bold text-primary'>Logo</p>
                                        </div>
                                        <div>
                                            {/* <HiOutlinePencilAlt size={20} /> */}
                                            <LogoModal />
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                    {
                                        profileData.details && profileData.details.profile_image?
                                        <img src={profileData?.details?.profile_image} 

                                        alt={`${profileData?.details?.profile_image}`} style={{ width: '100px', height: '100px' }} />
                                        :
                                        <svg width="33" height="33" className='w-[100px] h-[100px] sm:w-auto sm-h-auto' viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.2949 0.5C7.46292 0.5 0.294922 7.668 0.294922 16.5C0.294922 25.332 7.46292 32.5 16.2949 32.5C25.1269 32.5 32.2949 25.332 32.2949 16.5C32.2949 7.668 25.1269 0.5 16.2949 0.5ZM16.2949 6.9C19.3829 6.9 21.8949 9.412 21.8949 12.5C21.8949 15.588 19.3829 18.1 16.2949 18.1C13.2069 18.1 10.6949 15.588 10.6949 12.5C10.6949 9.412 13.2069 6.9 16.2949 6.9ZM16.2949 29.3C13.0469 29.3 9.20692 27.988 6.47092 24.692C9.27349 22.4932 12.7327 21.2981 16.2949 21.2981C19.8571 21.2981 23.3164 22.4932 26.1189 24.692C23.3829 27.988 19.5429 29.3 16.2949 29.3Z" fill="#1CBE93" />
                                        </svg>

                                    }
                                        
                                    </div>
                                </Paper>
                            </div>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
    </div>

        </>
    )
}
