import React,  { useState, useEffect } from 'react';
import { FormControl, TextField, FormControlLabel, Radio } from '@mui/material'
import { BsPlusCircle } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import Footer from '../../component/Footer'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from "react-router-dom";
import { useLocation,useParams  } from 'react-router-dom';
import axios from "axios";
import no_image from "../../assets/no_image.png";
import PageLoaderComp from '../../component/pageLoaderComp';
import ClaimProAccountantComp from '../../component/claimProAccountantComp';
import ClaimProNotairesComp from '../../component/claimProNotairesComp';
import ClaimProAvocatsComp from '../../component/claimProAvocatsComp';


export default function FormulaireInscriptionPro() {
  const {type } = useParams();  
  return (
    <>
      {type==="accountant"?
      <ClaimProAccountantComp />
      :""}
      {type==="avocats"?
      <ClaimProAvocatsComp />
      :""}
      {type==="notaires"?
      <ClaimProNotairesComp />
      :""}
    </>
  )
}
