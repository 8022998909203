import React from 'react'
import { Link } from 'react-router-dom'

export default function Breadcrumb({ Accueil, page }) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1">
        <li className="inline-flex items-baseline">
          <Link to="/" className="inline-flex gap-2 text-[12px]  sm:text-sm font-medium text-primary hover:text-secondary ">
            <svg width="19" height="16" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.9101 6.8853L8.52849 0.153321C8.47534 0.104718 8.41221 0.0661594 8.34271 0.0398506C8.27322 0.0135419 8.19872 0 8.12348 0C8.04824 0 7.97374 0.0135419 7.90425 0.0398506C7.83475 0.0661594 7.77162 0.104718 7.71847 0.153321L0.33691 6.8853C0.121861 7.08157 0 7.34816 0 7.62621C0 8.20356 0.514326 8.67297 1.14693 8.67297H1.92469V13.4766C1.92469 13.7661 2.18096 14 2.49815 14H6.97655V10.3363H8.98368V14H13.7488C14.066 14 14.3223 13.7661 14.3223 13.4766V8.67297H15.1C15.4047 8.67297 15.6968 8.56339 15.9118 8.36548C16.3581 7.95659 16.3581 7.29419 15.9101 6.8853Z" fill="#27295B" />
            </svg>
            {Accueil}
          </Link>
        </li>
        <li aria-current="page">
          <div className="flex items-center">
            <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
            <span className="ml-1 text-[12px] sm:text-sm   text-primary font-semibold md:ml-2 ">{page} </span>
          </div>
        </li>
      </ol>
    </nav>
  )
}
