import { authHeader } from '../_helpers';

export const orderService = { 
    getAll,
    getById
};

async function getAll(user_id,type) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const response = await fetch(process.env.REACT_APP_API_URL+`api/userdevisOrder/${user_id}/${type}`, requestOptions);
    return handleResponse(response);
}

async function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const response = await fetch(`/users/${id}`, requestOptions);
    return handleResponse(response);
}

function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        
        if (!response.ok) {
            // if (response.status === 401) {
            //     // auto logout if 401 response returned from api
            //     logout();
            //     // eslint-disable-next-line no-restricted-globals
            //     location.reload(true);
            // }

            const error = (data && data.msg) || response.statusText;
            return Promise.reject(error);
        }

        return data.data;
    });
}