import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_GL_TOKEN;


const Mapbox = ({latitude=48.864716,longitude=2.349014,geoJson={}}) => {
    // console.log("geoJson",geoJson)
    const mapContainerRef = useRef(null);
    const navigate = useNavigate();
     // States
     const [lng, setLng] = useState(longitude);
     const [lat, setLat] = useState(latitude);
     const [zoom, setZoom] = useState(8);
     const [hoveredFeatureId, setHoveredFeatureId] = useState(null);
     const [hoveredProvinceFeatureId, setHoveredProvinceFeatureId] =
       useState(null);
     const [markers, setMarkers] = useState([]);
     const [selectedRegion, setSelectedRegion] = useState(null);
     const [selectedRegionName, setSelectedRegionName] = useState(null);
     const [selectedRegionZoom, setSelectedRegionZoom] = useState(null);
   
     // check click steps
     const [checkRegionStep, setCheckRegionStep] = useState(false);
     const [checkProvinceStep, setCheckProvinceStep] = useState(false);
     const [checkCommuneStep, setCheckCommuneStep] = useState(false);
   
     const [popup, setPopup] = useState(null);
   
     // Initialize map when component mounts
     const [map, setMap] = useState(null);

     useEffect(() => {
        setLng(longitude);
        setLat(latitude);

        // getLatLngFromAddress();
        
       let selected_region = null;
      //  var center = [2, 46];
        var bounds = [
          [-8.225, 41.333], // Southwest coordinates 1)min lng 2) min lat
          [9.55, 51.2] // Northeast coordinates 1)max lng 2) max lat
        ];
        const map = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: "mapbox://styles/kifwatimmo/clger8d0m008o01n2asql0cx9",
          center: [longitude, latitude],
          zoom: zoom,
          // zoom: 8,
          maxBounds: bounds
        });
        

        /* const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            marker: {
            color: 'orange'
            },
            mapboxgl: mapboxgl
        });

        console.log("geocoder",geocoder)
          
          map.addControl(geocoder); */
   
       // Add navigation control (the +/- zoom buttons)
        map.addControl(new mapboxgl.NavigationControl(), "top-right");

        // Create a default Marker and add it to the map.
        /* const marker1 = new mapboxgl.Marker()
        .setLngLat([longitude, latitude])
        .addTo(map); */
        markers.forEach(function (marker) {
            marker.remove();
        });
        setMarkers([]);
        /* var dot = document.createElement("div");
        dot.className = "marker"; */

        /* const marker = new mapboxgl.Marker({color:"black",draggable:true})
        .setLngLat([longitude, latitude])
        .addTo(map);
        setMarkers([...markers, marker]); */

       // ==============| On load map |================
       map.on("load", () => {
            // Add a new source from our GeoJSON data and
            // set the 'cluster' option to true. GL-JS will
            // add the point_count property to your source data.
            
            map.addSource('earthquakes', {
                type: 'geojson',
                // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
                // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
                // data: 'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
                data:geoJson,
                cluster: true,
                clusterMaxZoom: 14, // Max zoom to cluster points on
                clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
            });
                
            map.addLayer({
                id: 'clusters',
                type: 'circle',
                source: 'earthquakes',
                filter: ['has', 'point_count'],
                paint: {
                // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
                // with three steps to implement three types of circles:
                //   * Blue, 20px circles when point count is less than 100
                //   * Yellow, 30px circles when point count is between 100 and 750
                //   * Pink, 40px circles when point count is greater than or equal to 750
                'circle-color': [
                'step',
                ['get', 'point_count'],
                '#51bbd6',
                100,
                '#f1f075',
                750,
                '#f28cb1'
                ],
                'circle-radius': [
                'step',
                ['get', 'point_count'],
                20,
                100,
                30,
                750,
                40
                ]
                }
                });
                
                map.addLayer({
                id: 'cluster-count',
                type: 'symbol',
                source: 'earthquakes',
                filter: ['has', 'point_count'],
                layout: {
                'text-field': ['get', 'point_count_abbreviated'],
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12
                }
                });
                
                map.addLayer({
                id: 'unclustered-point',
                type: 'circle',
                source: 'earthquakes',
                filter: ['!', ['has', 'point_count']],
                paint: {
                'circle-color': '#11b4da',
                'circle-radius': 4,
                'circle-stroke-width': 1,
                'circle-stroke-color': '#fff'
                }
                });
                
                // inspect a cluster on click
                map.on('click', 'clusters', (e) => {
                const features = map.queryRenderedFeatures(e.point, {
                layers: ['clusters']
                });
                const clusterId = features[0].properties.cluster_id;
                map.getSource('earthquakes').getClusterExpansionZoom(
                clusterId,
                (err, zoom) => {
                if (err) return;
                
                map.easeTo({
                  center: features[0].geometry.coordinates,
                  zoom: zoom
                  });
                  }
                  );
                });
                
                // When a click event occurs on a feature in
                // the unclustered-point layer, open a popup at
                // the location of the feature, with
                // description HTML from its properties.
                map.on('click', 'unclustered-point', (e) => {
                const coordinates = e.features[0].geometry.coordinates.slice();
                const mag = e.features[0].properties.mag;
                const tsunami =
                e.features[0].properties.tsunami === 1 ? 'yes' : 'no';
                
                // Ensure that if the map is zoomed out such that
                // multiple copies of the feature are visible, the
                // popup appears over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                  coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }
                
                new mapboxgl.Popup()
                .setLngLat(coordinates)
                .setHTML(
                `magnitude: ${mag}<br>Was there a tsunami?: ${tsunami}`
                )
                .addTo(map);
                });
                
                map.on('mouseenter', 'clusters', () => {
                    map.getCanvas().style.cursor = 'pointer';
                });
                map.on('mouseleave', 'clusters', () => {
                    map.getCanvas().style.cursor = '';
                });
            // Add a custom source for the country boundaries
            setMap(map);

           
       })
       
       // Clean up on unmount
       return () => map.remove();
       // , selectedRegionName, mapStep
     }, [longitude, latitude,geoJson]);
   // }, [provinces.length]);
   
    
   
     return (
       <div className="directoryMapBox w-full">
         <div
           style={{ width: "100%", height: "100vh" }}
           className="map-container"
           ref={mapContainerRef}
         />
         {/* Overlay */}
         {/* <div className="map-overlay top">
           <div className="map-overlay-inner">
             <fieldset>
               <label style={{ textAlign: "center" }}>Prix au m²</label>
               <div className="maincolorbar">
                 <div
                   className="colorbar"
                   style={{ background: "rgb(20, 188, 1)" }}
                 ></div>
                 <div
                   className="colorbar"
                   style={{ background: "rgb(118, 227, 2)" }}
                 ></div>
                 <div
                   className="colorbar"
                   style={{ background: "rgb(171, 227, 2)" }}
                 ></div>
                 <div
                   className="colorbar"
                   style={{ background: "rgb(222, 255, 5)" }}
                 ></div>
                 <div
                   className="colorbar"
                   style={{ background: "rgb(255, 248, 50)" }}
                 ></div>
                 <div
                   className="colorbar"
                   style={{ background: "rgb(255, 232, 47)" }}
                 ></div>
                 <div
                   className="colorbar"
                   style={{ background: "rgb(255, 213, 2)" }}
                 ></div>
                 <div
                   className="colorbar"
                   style={{ background: "rgb(255, 165, 31)" }}
                 ></div>
                 <div
                   className="colorbar"
                   style={{ background: "rgb(255, 122, 20)" }}
                 ></div>
                 <div
                   className="colorbar"
                   style={{ background: "rgb(255, 9, 0)" }}
                 ></div>
                 <div
                   className="colorbar"
                   style={{ background: "rgb(200, 0, 0)" }}
                 ></div>
               </div>
             </fieldset>
             <fieldset style={{ display: "flex" }}>
               <label style={{ textAlign: "left", width: "50%" }}>
                 {prices.minPrice} MAD/m²
               </label>
               <label style={{ textAlign: "right", width: "50%" }}>
                 {prices.maxPrice} MAD/m²
               </label>
             </fieldset>
           </div>
         </div> */}
       </div>
     );
   };
 
  export default Mapbox;

  export const getLatLngFromAddress=async(address)=>{
    /* const response = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?country=FR&access_token=pk.eyJ1Ijoia2lmd2F0aW1tbyIsImEiOiJjbGt1MHZuN3kwN293M3Fsb21yOHEyNzluIn0.Gytke4xmRxiZtgXogsOb4g`);  
     */
    const responseGet = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?country=FR&access_token=pk.eyJ1Ijoia2lmd2F0aW1tbyIsImEiOiJjbGt1MHZuN3kwN293M3Fsb21yOHEyNzluIn0.Gytke4xmRxiZtgXogsOb4g`);
    const responseAddress = await responseGet.json();
    return responseAddress;    
    /* if( response.data &&  response.data.data){
      setFormData(formData => ({ ...formData, ["ville"]: response.data.data.city,["villeArr"]:response.data.data.cityArr,["ville_id"]: response.data.data.id }));
    } */
  }
  