/* eslint-disable no-unused-vars */
import { orderConstants } from '../_constants';
import { orderService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';


export const orderActions = {
    getAll,
};

function getAll(user_id,type) {
    return dispatch => {
        dispatch(request());
        return orderService.getAll(user_id,type)
            .then(
                orders => {
                    let data = {
                        order:[],
                        invoice:[],
                        devis:[],
                    }
                   
                    if(type === "invoice"){
                        data.invoice = orders;
                        
                    }else if(type === "order"){
                        data.order = orders;
                       
                    }else if(type === "devis"){
                        data.devis = orders;
                    }                   
                    dispatch(success(data));
                    return Promise.resolve(orders);
                },
                error =>{
                    dispatch(failure(error.toString()));
                    return Promise.reject(); 
                } 
            );
    };

    function request() { return { type: orderConstants.GETALL_REQUEST } }
    function success(orders) { return { type: orderConstants.GETALL_SUCCESS, orders } }
    function failure(error) { return { type: orderConstants.GETALL_FAILURE, error } }
}

