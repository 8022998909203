import React, { useState ,useEffect} from 'react';
import { TextField, Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function PersonalInformation() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    signup_type: 'normal',
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    general: '',
  });
  useEffect(() => {

    const getUser = JSON.parse(localStorage.getItem('user_pro'));
    if (getUser) {
      // Redirect to the dashboard
      // You can use the 'Navigate' component or any other method to redirect
      // Example using Navigate from react-router-dom
      // Make sure to import 'Navigate' from 'react-router-dom'
      // return <Navigate to="/dashboard" />;
      // navigate("/dashboard");
    }
  }, []); // The empty dependency array makes this effect run only once when the component mounts

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
      general: '',
    }));
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleRegistration = async (e) => {
    e.preventDefault();

    const newErrors = {};

    // Validate non-empty fields
    for (const key in formData) {
      if (formData[key] === '') {
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
      }
    }

    // Validate email
    if (formData.email && !isValidEmail(formData.email)) {
      newErrors.email = 'Invalid email format';
    }

    // Validate password length
    if (formData.password.length > 0 && formData.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters long';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      setLoading(true);
      setErrors({});

      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/prouser/register`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        localStorage.setItem("email", formData.email);
        navigate(`/registrationform/${response.data.insertId}`);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error('Error during registration:', error);
      if (error.response.status === 400 && error.response.data.success === false) {
        setErrors({ general: error.response.data.msg || 'Registration failed' });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className='bg-[#F5F5F5] w-full py-7 3xl:py-12' style={{ background: `linear-gradient(172deg, #64D3B6 60%, white 45%)`, backgroundSize: '100% 50%', backgroundRepeat: 'no-repeat' }}>
        <div className='md:max-w-[1477px] relative w-full h-full   flex items-center justify-center m-auto  '>
          <div className='max-w-[700px] 3xl:max-w-[700px]  w-full h-full bg-white px-5 sm:px-12 py-2 sm:py-5 3xl:p-12 ' style={{ boxShadow: "0px 4px 10px 1px rgba(39, 41, 91, 0.35)", borderRadius: "33px" }}>
            <div className=' px-2 py-2 3xl:py-4'>
              <h1 className='text-xl sm:text-3xl xl:text-[28px] font-extrabold md:leading-[45px] mt-6 text-primary'>  Bienvenue sur Legals pro ! </h1>
              <p className='max-w-[876px] m-auto text-base text-gray mt-0 '> Complétez votre profil entreprise pour être référencé dans notre annuaire des professionnels de votre secteur d’activité.</p>
            </div>
            <Box
              onSubmit={handleRegistration}
              component="form" className='mt-5 sm:mt-8 3xl:mt-12' sx={{ "& .MuiInputLabel-root": { color: '#797979', }, "& fieldset": { border: '1px solid #eee', borderRadius: '5px', color: '#797979', background: 'rgb(39 41 91 / 6%)', }, }}>
              <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                <label className='col-span-12 text-base font-medium text-gray mb-1'>Prénom</label>
                <div className='col-span-12'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    name="firstName"
                    onChange={handleChange}
                    value={formData.firstName}
                    className='w-full outline-secondary focus:outline-secondary'
                  />
                  {errors.firstName && <p className="text-red-500 mt-2" style={{ color: 'red' }}>{errors.firstName}</p>}
                </div>
              </div>
              <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                <label className='col-span-12 text-base font-medium text-gray mb-1'>Nom</label>
                <div className='col-span-12'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    name="lastName"
                    onChange={handleChange}
                    value={formData.lastName}
                    className='w-full outline-secondary focus:outline-secondary'
                  />
                  {errors.lastName && <p className="text-red-500 mt-2" style={{ color: 'red' }}>{errors.lastName}</p>}
                </div>
              </div>
              <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                <label className='col-span-12 text-base font-medium text-gray mb-1'>E-mail</label>
                <div className='col-span-12'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    name="email"
                    onChange={handleChange}
                    value={formData.email}
                    className='w-full outline-secondary focus:outline-secondary'
                    required
                  />
                  {errors.email && <p className="text-red-500 mt-2" style={{ color: 'red' }}>{errors.email}</p>}
                </div>
              </div>
              <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                <label className='col-span-12 text-base font-medium text-gray mb-1'>Mot de passe</label>
                <div className='col-span-12'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    name="password"
                    type="password"
                    onChange={handleChange}
                    value={formData.password}
                    className='w-full outline-secondary focus:outline-secondary'
                  />
                  {errors.password && <p className="text-red-500 mt-2" style={{ color: 'red' }}>{errors.password}</p>}
                </div>
              </div>
              <Stack alignItems='end' sx={{ mt: 2 }}>
                <button
                  type='submit'
                  className='bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white  mt-4 sm:mt-4 3xl:mt-10 px-10   py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white '
                  disabled={loading}
                  onClick={handleRegistration}
                >
                  {loading ? 'En cours...' : 'Suivant'}
                </button>
              </Stack>
              {errors.general && <p className="text-red-500 mt-2" style={{ color: 'red' }}>{errors.general}</p>}
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}