import React, { useEffect, useState } from 'react'

import { Autocomplete, TextField, Box, Stack } from '@mui/material'
import { BsPlusCircle, BsXCircle,BsDashCircle } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { useParams } from 'react-router-dom';

export default function RegistrationForm() {
  let params = useParams();
  const [formData, setFormData] = useState({
    user_id: params.id?params.id:"",
    name: '',
    streetaddress: '',
    complete_address: '',
    postalcode: '',
    addresslocality: '',
    telephone: '',
    email: '',
    people_data:"",
    people_dataArr:[{"name":""}],
    additionalManagers: [],
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    streetaddress: '',
    complete_address: '',
    postalcode: '',
    addresslocality: '',
    telephone: '',
    email: '',
    people_data:"",
    people_dataArr:[{"name":""}],
    additionalManagers: [],
    general: '',
  });


  const [citiesData, setCitiesData] = useState([]);
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
      general: '',
    }));
  };

  const handleChangeMultiple = (e,indexToUpdate) => {
    
    let {name,value} = e.target;
    let formDataGet = {...formData}
    switch(name) {      
      case 'people_data':
        // let people_dataArrget = formDataGet.people_dataArr;            
        formDataGet.people_dataArr[indexToUpdate].name =value;
        setFormData(formDataGet);   
        break;
      default:
          break;
    }
   
  };
  const addrRemove = (name,type,indexToRemove)=>{
    let formDataGet = {...formData}
      console.log("index",indexToRemove)
      switch(name) {        
        case 'people_data':
          let people_dataArrget = formDataGet.people_dataArr;            
          if(type ==="add"){
              people_dataArrget.push({"name":""});
              setFormData({...formData,people_dataArr:people_dataArrget}) 
          }
          if(type ==="remove"){
              let people_dataArrgetRemoved = people_dataArrget.filter((_, index) => index !== indexToRemove)               
              setFormData({...formData,people_dataArr:people_dataArrgetRemoved})            
          } 
          break;
        default:
            break;
        }
       
  }

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleAddManager = () => {
    setFormData((prevData) => ({
      ...prevData,
      additionalManagers: [...prevData.additionalManagers, {}],
    }));
  };

  const handleRemoveManager = (index) => {
    setFormData((prevData) => {
      const updatedManagers = [...prevData.additionalManagers];
      updatedManagers.splice(index, 1);
      return {
        ...prevData,
        additionalManagers: updatedManagers,
      };
    });
  };

  const handleManagerChange = (index, name, value) => {
    setFormData((prevData) => {
      const updatedManagers = [...prevData.additionalManagers];
      updatedManagers[index] = {
        ...updatedManagers[index],
        [name]: value,
      };

      // Check if the changed field is postalcode
      if (name === 'postalcode') {
        const postalCode = value || ''; // Ensure the postal code is not blank
        getAllCities(postalCode, index);
      }

      return {
        ...prevData,
        additionalManagers: updatedManagers,
      };
    });
  };

  const handleRegistration = async (e) => {
    e.preventDefault();

    const newErrors = {};

    // Validate non-empty fields
    // for (const key in formData) {
    //   if (formData[key] === '' && key !== 'additionalManagers') {
    //     newErrors[key] = `${key.split('_').join(' ').charAt(0).toUpperCase() + key.split('_').join(' ').slice(1)} is required`;
    //   }
    // }
    for (const key in formData) {
      if (key !== 'people_data' && key !== 'people_dataArr' &&  key !== 'complete_address' && key !== 'additionalManagers' && formData[key] === '') {
        newErrors[key] = `${key.split('_').join(' ').charAt(0).toUpperCase() + key.split('_').join(' ').slice(1)} is required`;
      }
    }

    // Validate email
    if (formData.email && !isValidEmail(formData.email)) {
      newErrors.email = 'Invalid email format';
    }

    // Validate telephone (10 digits)
    const telephoneRegex = /^\d{10}$/;
    if (formData.telephone && !telephoneRegex.test(formData.telephone)) {
      newErrors.telephone = 'Telephone must be a 10-digit number';
    }


    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Prepare the data to be sent to the API
    const postData = {
      companiesData: [
        {
          ...formData,
        },
        // ...formData.additionalManagers,
      ],
    };
    try {
      setLoading(true);
      setErrors('');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/prouser/socity-register`, postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        navigate(`/propresentation/${params.id}`);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error('Error during API call:', error);
      if (error.response && error.response.status === 400 && error.response.data.success === false) {
        setErrors(error.response.data.msg || 'API call failed');
      }
    } finally {
      setLoading(false);
    }
  };


  const handlePostalCodeChange = async (event, newValue, index) => {
    const postalCode = newValue?.postalcode || '';
    setFormData((prevData) => {
      const updatedManagers = [...prevData.additionalManagers];
      updatedManagers[index] = {
        ...updatedManagers[index],
        postalcode: postalCode,
        addresslocality: newValue?.cityArr && newValue.cityArr.length > 0 ? newValue.cityArr[0] : '',
      };

      return {
        ...prevData,
        additionalManagers: updatedManagers,
      };
    });
  };

  const getAllCities = async (postalCode, index) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}api/getCitiesBycheckzipcode/${postalCode}`);
      console.log(response, "-----------------------")
      if (response.status === 200 && response.data.sucess) {
        const cityData = response.data.data.cityArr;
        console.log("cityData.length",cityData.length)
        /* setFormData((prevData) => {
          const updatedManagers = [...prevData.additionalManagers];
          updatedManagers[0] = {
            ...updatedManagers[0],
            addresslocality:  '',
            postalcode: '',
          };    
          return {
            ...prevData,
            additionalManagers: updatedManagers,
            addresslocality: cityData.length > 0 ? cityData[0] : '',
          };
        }); */
        setFormData((prevData) => ({
          ...prevData,
          addresslocality: cityData.length > 0 ? cityData[0] : '',
        }));
        setCitiesData(cityData);
        
        
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.error('Error during API call:', error);
      if (error.response && error.response.status === 400 && error.response.data.success === false) {
        setErrors(error.response.data.msg || 'API call failed');
      }
    }
  };

  useEffect(() => {
    // getAllCities();
  }, []);

  useEffect(() => {
    console.log("Updated citiesData:------------------------------", citiesData);
    console.log("Updated citiesData:------------------------------", citiesData[0]);
    /* setFormData((prevData) => ({
      ...prevData,
      "addresslocality": citiesData.length>0?citiesData[0]:"",
    })); */
    
  }, [citiesData]);
  useEffect(() => {
    console.log("Updated formData:------------------------------", formData);
  }, [formData]);
  return (
    <>
      <div className='bg-[#F5F5F5] w-full py-7 3xl:py-12' style={{
        background: `linear-gradient(172deg, #64D3B6 60%, white 45%)`,
        backgroundSize: '100% 50%', backgroundRepeat: 'no-repeat'
      }}>
        <div className='md:max-w-[1477px] relative w-full h-full   flex items-center justify-center m-auto  '>

          <div className='max-w-[700px] 3xl:max-w-[700px]  w-full h-full bg-white px-5 sm:px-12 py-2 sm:py-5 3xl:p-12 ' style={{ boxShadow: "0px 4px 10px 1px rgba(39, 41, 91, 0.35)", borderRadius: "33px" }}>
            <div className=' px-2 py-2 3xl:py-4'>
              <h1 className='text-xl sm:text-3xl xl:text-[28px] font-extrabold md:leading-[45px] mt-6 text-primary'>Votre société</h1>
            </div>
            <Box
              onSubmit={handleRegistration}
              component="form" className='mt-5 sm:mt-8 3xl:mt-12'
              sx={{
                "& .MuiInputLabel-root": { color: '#797979', },
                "& fieldset": {
                  border: '1px solid #eee', borderRadius: '5px',
                  color: '#797979',
                  background: 'rgb(39 41 91 / 6%)',
                },
              }}
            >
              <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                <label className='col-span-12 text-base font-medium text-gray mb-1'>Nom de la société</label>
                <div className='col-span-12'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    name="name"
                    onChange={handleChange}
                    value={formData.name}
                    className='w-full outline-secondary focus:outline-secondary'
                  />
                  {errors.name && <p className="text-red-500 mt-2" style={{ color: 'red' }}>{errors.name}</p>}
                </div>
              </div>
              <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                <label className='col-span-12 text-base font-medium text-gray mb-1'>Adresse</label>
                <div className='col-span-12'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    name="streetaddress"
                    onChange={handleChange}
                    value={formData.streetaddress}
                    className='w-full outline-secondary focus:outline-secondary'
                  />
                  {errors.streetaddress && <p className="text-red-500 mt-2" style={{ color: 'red' }}>{errors.streetaddress}</p>}
                </div>
              </div>
              <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                <label className='col-span-12 text-base font-medium text-gray mb-1'>Complément d’adresse <span className='text-xs font-extralight'>{"(Optional)"}</span></label>
                <div className='col-span-12'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    name="complete_address"
                    onChange={handleChange}
                    value={formData.complete_address}
                    className='w-full outline-secondary focus:outline-secondary'
                  />
                  {errors.complete_address && <p className="text-red-500 mt-2" style={{ color: 'red' }}>{errors.complete_address}</p>}
                </div>
              </div>

              <div className='grid grid-cols-12 sm:gap-8 items-center '>
                <div className='col-span-12 sm:col-span-4 sm:grid sm:grid-cols-12 items-cente mt-3 sm:mt-6'>
                  <label className='col-span-12 text-base font-medium text-gray mb-1'>Code postal</label>
                  <div className='col-span-12'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      name="postalcode"
                      onChange={(e) => {
                        // handleChange(e);
                        getAllCities(e.target.value);
                        setFormData((prevData) => ({
                          ...prevData,
                          postalcode: e.target.value || '',
                        }));
                      }}
                      value={formData.postalcode}
                      className='w-full outline-secondary focus:outline-secondary'
                    />
                    {errors.postalcode && <p className="text-red-500 mt-2" style={{ color: 'red' }}>{errors.postalcode}</p>}
                  </div>
                </div>
                <div className='col-span-12 sm:col-span-8 grid grid-cols-12 items-center mt-3 sm:mt-6'>
                  <label className='col-span-12 text-base font-medium text-gray mb-1'>Ville</label>
                  <div className='col-span-12'>
                    <Autocomplete
                      options={citiesData}
                      getOptionLabel={(option) => option}
                      onChange={(event, newValue) => {
                        handlePostalCodeChange(event, newValue, 0);
                        setFormData((prevData) => ({
                          ...prevData,
                          addresslocality: newValue || '',
                        }));
                      }}
                      name="addresslocality"
                      value={formData.addresslocality}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          name="addresslocality"
                          value={formData.addresslocality}
                          onChange={handleChange}
                          className='w-full outline-secondary focus:outline-secondary'
                        />
                      )}
                    />
                  </div>
                </div>
              </div>


              <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                <label className='col-span-12 text-base font-medium text-gray mb-1'>Téléphone <small>(affiché pour la prise de contact) </small> </label>
                <div className='col-span-12'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    name="telephone"
                    onChange={handleChange}
                    value={formData.telephone}
                    className='w-full outline-secondary focus:outline-secondary'
                  />
                  {errors.telephone && <p className="text-red-500 mt-2" style={{ color: 'red' }}>{errors.telephone}</p>}
                </div>
              </div>
              <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                <label className='col-span-12 text-base font-medium text-gray mb-1'>E-mail <small>(adresse e-mail de réception des messages du site Busyplace)</small> </label>
                <div className='col-span-12'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    name="email"
                    onChange={handleChange}
                    value={formData.email}
                    className='w-full outline-secondary focus:outline-secondary'
                  />
                  {errors.email && <p className="text-red-500 mt-2" style={{ color: 'red' }}>{errors.email}</p>}
                </div>
              </div>
              <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                <label className='col-span-12 text-base font-medium text-gray mb-1'>Prénom et Nom du Dirigeant</label>
                {formData.people_dataArr.map((val,people_data_i)=>{
                    return (
                      <div className='col-span-12 inline-flex items-center gap-2 mt-6 ' >
                        <TextField
                          hiddenLabel
                          id="outlined-size-small"
                          size="small"
                          name='people_data'
                          value={val.name}
                          onChange={(e)=>handleChangeMultiple(e,people_data_i)}
                          className='w-full outline-secondary focus:outline-secondary'
                        />
                        {
                          people_data_i !== 0? <BsDashCircle className='text-3xl ' onClick={()=>addrRemove("people_data",'remove',people_data_i)}  />:""}
                     
                    </div>
                   )
                })}         
               
              </div>
              {/* ------------------------------------------------------------------------------------------------------------------ */}
              
              <div
                onClick={()=>addrRemove("people_data",'add',0)}
                className="group flex items-center gap-2 text-primary hover:text-secondary mt-6 cursor-pointer"
              >
                <BsPlusCircle className="text-3xl" />
                <span className="text-sm font-normal">Ajouter un gérant</span>
              </div>
              {/* ---------------------------------------------------------------------------------------------------------- */}
              <Stack alignItems='end' sx={{ mt: 2 }}>
                <button
                    // onSubmit={handleRegistration}
                  type='submit'
                  className='bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white  mt-4 sm:mt-4 3xl:mt-10 px-10   py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white'
                  disabled={loading}
                >
                  {loading ? 'En cours...' : 'Suivant'}
                </button>
              </Stack>
              {errors.general && <p className="text-red-500 mt-2" style={{ color: 'red' }}>{errors.general}</p>}
            </Box>

          </div>
        </div>

      </div>
    </>
  )
}