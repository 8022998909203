import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Link, useNavigate,useParams } from 'react-router-dom';
import axios from 'axios';

export default function ProLogo() {
    const params = useParams();
    const navigate = useNavigate();
    const [profileImage, setProfileImage] = useState(null);
    const [email, setEmail] = useState('');
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState(null);
    const [error, setError] = useState('');

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setProfileImage(selectedFile);

        // Display the selected image
        if (selectedFile) {
            const imageUrl = URL.createObjectURL(selectedFile);
            setSelectedImageUrl(imageUrl);
        }
    };

    const handleCheckboxChange = () => {
        setIsCheckboxChecked(!isCheckboxChecked);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Check if the checkbox is checked
            if (!isCheckboxChecked) {
                // Do not submit if the checkbox is not checked
                return;
            }

            // Check if the profile image is empty
            if (!profileImage) {
                setError('Veuillez sélectionner une image de profil.');
                return;
            }

            const emaildata = localStorage.getItem('email');
            await setEmail(emaildata);
            // Create a FormData object
            const formData = new FormData();
            formData.append('profile_image', profileImage);
            formData.append('email', emaildata);
            formData.append('user_id', params.id);

            const response = await axios.post(`${process.env.REACT_APP_API_URL}api/prouser/add-prouserprofilelogo`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                // Handle success, e.g., redirect or show a success message
                navigate('/login'); // Redirect to the next page on success
                // window.location.reload();
            } else {
                // Handle error, e.g., show an error message
                console.error('Une erreur s\'est produite.'); // Set the error message
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <div className='bg-[#F5F5F5] h-[85vh] flex items-center justify-center'>
                <div className='bg-[#F5F5F5] w-full py-7 3xl:py-12'>
                    <div className='md:max-w-[1477px] relative w-full h-full   flex items-center justify-center m-auto  '>
                        <div className='max-w-[700px] 3xl:max-w-[700px]  w-full h-full bg-white px-5 sm:px-12 py-2 sm:py-5 3xl:p-12 ' style={{ boxShadow: "0px 4px 10px 1px rgba(39, 41, 91, 0.35)", borderRadius: "33px" }}>
                            <div className=' px-2 py-2 3xl:py-4'>
                                <h1 className='text-xl sm:text-3xl xl:text-[28px] font-extrabold md:leading-[45px] mt-6 text-primary'>Importez votre logo ou votre photo<small className='font-light text-base'>(facultatif)</small></h1>
                            </div>
                            <Box
                                component="form"
                                className='mt-5 sm:mt-5 3xl:mt-12'
                                onSubmit={handleSubmit}
                                sx={{
                                    "& .MuiInputLabel-root": { color: '#797979' },
                                    "& fieldset": {
                                        border: '1px solid #eee',
                                        borderRadius: '8px',
                                        color: '#797979',
                                        background: 'rgb(39 41 91 / 6%)',
                                    },
                                }}
                            >
                                <div className='flex flex-col sm:flex-row sm:items-center sm:gap-8'>
                                    <button
                                        variant="contained"
                                        component="label"
                                        className='cursor-pointer relative border-dashed border-[#797979] border-2 rounded-md w-full sm:w-[261px] h-[120px] px-5 sm:h-[150px] flex items-center text-center justify-center'>
                                        {selectedImageUrl ? (
                                            <img src={selectedImageUrl} alt="Selected Logo" className="w-full h-full object-cover" />
                                        ) : (
                                            <p className='text-sm font-light text-black max-w-[290px] m-auto'>Glisser-déposer le logo dans cette zone ou <span className='font-bold text-primary'>cliquez ici pour sélectionner un logo</span> </p>
                                        )}
                                        <input
                                            // accept='image/*'
                                            accept="image/jpeg, image/jpg, image/png"
                                            type="file"
                                            className='absolute w-full h-full opacity-0 cursor-pointer'
                                            onChange={handleFileChange}
                                        />
                                    </button>
                                    <div>
                                        <p className='text-[15px] text-primary font-light'>Format : <span className='font-medium'>jpeg, png </span></p>
                                        <p className='text-[15px] text-primary font-light'>Poids : <span className='font-medium'>5 Mo max. </span></p>
                                        <p className='text-[15px] text-primary font-light'>Dimensions :<span className='font-medium'>500*500px min.</span></p>
                                    </div>
                                </div>
                                <div className='flex items-center mb-5 mt-8 sm:mt-8'>
                                    <div className='flex items-center'>
                                        <label className='flex gap-4'>
                                            <input
                                                type="checkbox"
                                                className="w-8 h-8 rounded-4xl block border-black-light"
                                                onChange={handleCheckboxChange}
                                            />
                                            <span className="text-base  font-normal text-primary">
                                                En cliquant sur “Créer mon compte” vous acceptez nos <Link to="/conditions-generales-vente" className='text-primary font-semibold underline'>conditions générales</Link> et notre <Link to="" className='text-primary font-semibold underline'>politique de confidentialité</Link>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                {error && <p className="text-red-500 mt-2" style={{ color: 'red' }}>{error}</p>}
                                <button
                                    type='submit'
                                    className={`bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white mt-4 sm:mt-4 3xl:mt-10 px-10 py-3 3xl:h-[62px] rounded-lg transition-all duration-300 ${!isCheckboxChecked ? 'opacity-50 cursor-not-allowed' : 'hover:bg-secondary hover:text-white'}`}
                                    disabled={!isCheckboxChecked}
                                >
                                    Suivant
                                </button>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
