import { authHeader } from '../_helpers';

export const announceService = { 
    getAllAnnounce,
    getAll,
    getById,
    create,
    update,
    updatePayment,
    updateSuccessPayment,
    update_user
};

async function getAllAnnounce() {
    try {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        const response = await fetch(process.env.REACT_APP_API_URL + `api/announce/device`, requestOptions);
        const data = await handleResponse(response);
        return data; // Return the data directly
    } catch (error) {
        throw error; // Propagate the error
    }
}

async function getAll(user_id,type) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const response = await fetch(process.env.REACT_APP_API_URL+`api/announce/${user_id}/${type}`, requestOptions);
    return handleResponse(response);
}

async function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const response = await fetch(process.env.REACT_APP_API_URL+`api/announce/${id}`, requestOptions);
    return handleResponse(response);
}

async function create(formData) {

    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(formData)
    };

    const response = await fetch(process.env.REACT_APP_API_URL+`api/announce/create`, requestOptions);
    return handleResponse(response);
}

async function update(formData) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        data:JSON.stringify(formData)
    };

    const response = await fetch(process.env.REACT_APP_API_URL+`/api/announce/update`, requestOptions);
    return handleResponse(response);
}

async function updatePayment(formData) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body:JSON.stringify(formData)
    };

    const response = await fetch(process.env.REACT_APP_API_URL+`api/announce/update_payment`, requestOptions);
    return handleResponse(response);
}
async function updateSuccessPayment(formData) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body:JSON.stringify(formData)
    };

    const response = await fetch(process.env.REACT_APP_API_URL+`api/announce/update_success_payment`, requestOptions);
    return handleResponse(response);
}

async function update_user(formData) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body:JSON.stringify(formData)
    };

    const response = await fetch(process.env.REACT_APP_API_URL+`api/announce/update_user`, requestOptions);
    return handleResponse(response);
}


function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        
        if (!response.ok) {
            // if (response.status === 401) {
            //     // auto logout if 401 response returned from api
            //     logout();
            //     // eslint-disable-next-line no-restricted-globals
            //     location.reload(true);
            // }

            const error = (data && data.msg) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}