import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { administrator } from './admin.reducer';
import { orders } from './orders.reducer';
import { alert } from './alert.reducer';
import { announce } from './announce.reducer';

const rootReducer = combineReducers({
    authentication,
    registration,
    users,
    administrator,
    orders,
    announce,
    alert
});

export default rootReducer;