/* eslint-disable array-callback-return */
import React, {useState, useEffect } from 'react' 
import moment from 'moment';
import dayjs from 'dayjs';
import { BsFillQuestionCircleFill, BsPlusCircle } from 'react-icons/bs'
import { MdEuroSymbol } from 'react-icons/md'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/fr.js';
import { frFR } from '@mui/x-date-pickers/locales';
import Tooltip from '@mui/material/Tooltip';
import { FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select,ListSubheader , TextField ,Divider} from '@mui/material';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';

export  function TransformationSarlEnSas(props) {
 
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const StyledTooltip = ({ title, children, ...props }) => (
    <Tooltip
      {...props}
      title={title}
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            background: '#DEE3E2',
            width: '129px',
            padding: '10px',
            color: '#27295B',
            fontSize: '14px',
            fontWeight: '400'
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );

  return (
    <>
      <div className='' >
        <div className='py-6 px-3 sm:px-6' style={{ borderBottom: '0.5px solid #DEE3E2' }}>
          <h1 className='text-lg sm:text-2xl font-black text-primary'>Annonce légale : Création de {props.adsname}</h1>
        </div>

        <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>La société</h2>
          <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>N° SIREN *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7  mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full outline-secondary focus:outline-secondary'
                name="rcs_societe" 
                value={props.formData.rcs_societe} 
                onChange={props.handleChange} 
                error={!props.formData.rcs_societe  && props.isSubmitted?true:false}
              /> 
            </div>
          </div>        
          <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full outline-secondary focus:outline-secondary'
                name="denomination_societe" 
                value={props.formData.denomination_societe} 
                onChange={props.handleChange}  
                error={!props.formData.denomination_societe  && props.isSubmitted?true:false}
              />
              <StyledTooltip title="Indiquez le nom de la Société tel que mentionné dans les statuts.">
                <div className='p-0 m-0 w-auto'>
                  <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                </div>
              </StyledTooltip>
            </div>
          </div> 

          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-8'>
              
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Choisissez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Choisissez"
                  id="forme_juridique_societe" 
                  name="forme_juridique_societe"
                  value={props.formData.forme_juridique_societe} 
                  onChange={props.handleChange}  
                  error={!props.formData.forme_juridique_societe  && props.isSubmitted?true:false}
                > 
                  <ListSubheader>Constitution de société commerciale</ListSubheader>
                    <MenuItem value="SARLU">SARL Unipersonnelle (EURL)</MenuItem>
                    <MenuItem value="SARL">Société à responsabilité limitée (SARL)</MenuItem>
                    <MenuItem value="SASU">Société par actions simplifiée unipersonnelle (SASU)</MenuItem>
                    <MenuItem value="SAS">Société par actions simplifiée (SAS)</MenuItem>
                    <MenuItem value="SA">Société anonyme (SA)</MenuItem> 
                    <MenuItem value="SNC">Société en nom collectif (SNC)</MenuItem>
                  
                  <ListSubheader>Constitution d'une société civile</ListSubheader>
                    <MenuItem value="SC">Société civile</MenuItem>
                    <MenuItem value="SCI">Société civile immobilière (SCI)</MenuItem>
                    <MenuItem value="SCCV">Société civile immobilière de construction vente (SCCV ou SCICV)</MenuItem>
                    <MenuItem value="SCM">Société civile de moyens (SCM)</MenuItem>
                    <MenuItem value="SCPI">Société civile de placement immobilier (SCPI)</MenuItem>
                    <MenuItem value="SCP">Société civile professionnelle (SCP)</MenuItem>

                  <ListSubheader>Constitution d'une société libéral</ListSubheader>                  
                    <MenuItem value="SELARL">Société d'exercice libéral a responsabilité limitée (SELARL)</MenuItem>
                    <MenuItem value="SELEURL">Société d'exercice libéral ayant un associé Unique (SELEURL)</MenuItem>
                    <MenuItem value="SELAFA">Société d'exercice libéral sous forme anonyme (SELAFA)</MenuItem>
                    <MenuItem value="SELAS">Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                    <MenuItem value="SELASU">Société d'exercice libéral par actions simplifiée unipersonnelle (SELASU)</MenuItem>
                    <MenuItem value="SELCA">Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
                    <MenuItem value="SCEA">Société civile d'exploitation agricole (SCEA)</MenuItem>
                    <MenuItem value="EARL">Exploitation agricole à responsabilité limitée (EARL)</MenuItem>
                    <MenuItem value="GAEC">Groupement agricole d'exploitation en commun (GAEC)</MenuItem>
                    <MenuItem value="GFA">Groupement foncier agricole (GFA)</MenuItem>
                    <MenuItem value="EIRL">Entrepreneur Individuel à Responsabilité Limité</MenuItem>
                    <MenuItem value="SPFPLAS">Société de Participations Financières de Profession Libérale d'Avocats Simplifiée (SPFPLAS)</MenuItem>
                  
                </Select>
              </FormControl>
            </div>
          </div>

          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>

              <FormControl size="small" className='w-full'>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  name="capital_societe"
                  value={props.formData.capital_societe} 
                  onChange={props.handleChange}  
                  error={!props.formData.capital_societe  && props.isSubmitted?true:false}
                />
              </FormControl>

              

            </div>
          </div>
          <div className='grid grid-cols-12 mt-3 sm:mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="address_societe"
                value={props.formData.address_societe} 
                onChange={props.handleChange}  
                error={!props.formData.address_societe  && props.isSubmitted?true:false}
              />
            </div>
          </div>

          <div className='grid grid-cols-12 mt-3 sm:mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse suite</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="address_suite_societe"
                value={props.formData.address_suite_societe} 
                onChange={props.handleChange}  
                // error={!props.formData.address_societe  && props.isSubmitted?true:false}
              />
            </div>
          </div>

          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="code_postal"  maxLength="5"
                value={props.formData.code_postal} 
                onChange={(e)=>{props.handleChange(e)}}  
                error={!props.formData.code_postal  && props.isSubmitted?true:false}
              />
            </div>
          </div>
          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              {/* <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="ville"
                value={props.formData.ville} 
                onChange={props.handleChange}  
                error={!props.formData.ville  && props.isSubmitted?true:false}
              /> */}
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Sélectionnez"
                  name="ville_id"
                  value={props.formData.ville_id}                   
                  onChange={props.handleChange}  
                  error={!props.formData.ville  && props.isSubmitted?true:false}
                  
                >
                  {
                    props.formData.villeArr.map((cityVal,cityIndex)=>{
                      return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                    })
                  }

                </Select>
              </FormControl>
            </div>
          </div>
          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="greffe" 
                value={props.formData.greffe} 
                onChange={props.handleChange} 
              />
            </div>
          </div>
        </div>
        <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Gérant démissionnaire</h2>
          <br />Actuel gérant de la société
          <div className='grid grid-cols-12 items-start mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité </label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Sélectionnez"
                  name="civilite_gerant_physique"
                  value={props.formData.civilite_gerant_physique} 
                  onChange={props.handleChange}   
                  // error={!props.formData.civilite_gerant_physique  && props.isSubmitted?true:false}
                  
                >
                  <MenuItem value={"Mme"}>Madame</MenuItem>
                  <MenuItem value={"M."}>Monsieur</MenuItem>

                </Select>
              </FormControl>
              
            </div>
          </div>

          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="nom_gerant_physique"
                value={props.formData.nom_gerant_physique} 
                onChange={props.handleChange}   
                error={!props.formData.nom_gerant_physique  && props.isSubmitted?true:false}
                
              />
            </div>
          </div>

          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="prenom_gerant_physique"
                value={props.formData.prenom_gerant_physique} 
                onChange={props.handleChange}   
                error={!props.formData.prenom_gerant_physique  && props.isSubmitted?true:false}
              />
            </div>
          </div>
          {/* <div className='grid grid-cols-12 mt-3 sm:mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="adresse_gerant_physique"
                value={props.formData.adresse_gerant_physique} 
                onChange={props.handleChange}  
                error={!props.formData.adresse_gerant_physique  && props.isSubmitted?true:false}
              />
            </div>
          </div>

          <div className='grid grid-cols-12 mt-3 sm:mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse suite</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="adresse_suite_gerant_physique"
                value={props.formData.adresse_suite_gerant_physique} 
                onChange={props.handleChange}  
                // error={!props.formData.address_societe  && props.isSubmitted?true:false}
              />
            </div>
          </div>
          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Résidant à l'étranger?</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-8'>             
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Choisissez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Choisissez"
                  id="gerant_physique_residante" 
                  name="gerant_physique_residante"
                  value={props.formData.gerant_physique_residante} 
                  onChange={props.handleChange}  
                  error={!props.formData.gerant_physique_residante  && props.isSubmitted?true:false}
                > 
                  <MenuItem value="Non">Non</MenuItem>
                  <MenuItem value="Oui">Oui</MenuItem>
                  
                </Select>
              </FormControl>
            </div>
          </div>         
          <div className={props.formData.gerant_physique_residante=="Non"?'grid grid-cols-12 mt-6 items-center':'hidden  grid-cols-12 mt-6 items-center'} >
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="code_postal_gerant_physique"  maxLength="5"
                value={props.formData.code_postal_gerant_physique} 
                onChange={(e)=>{props.handleChange(e)}}  
                error={!props.formData.code_postal_gerant_physique  && props.isSubmitted?true:false}
              />
            </div>
          </div>
          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className={props.formData.gerant_physique_residante=="Oui"?'w-full':'hidden w-full'}
                name="ville_gerant_physique"
                value={props.formData.ville_gerant_physique} 
                onChange={props.handleChange}  
                error={!props.formData.ville_gerant_physique  && props.isSubmitted?true:false}
              />
              <FormControl size="small"  className={props.formData.gerant_physique_residante=="Oui"?'hidden w-full':' w-full'} >
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Sélectionnez"
                  name="ville_gerant_physique_id"
                  value={props.formData.ville_gerant_physique_id} 
                  onChange={props.handleChange}  
                  error={!props.formData.ville_gerant_physique  && props.isSubmitted?true:false}
                  
                >
                  {
                    props.formData.ville_gerant_physiqueArr.map((cityVal,cityIndex)=>{
                      return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                    })
                  }

                </Select>
              </FormControl>
            </div>
          </div>
          <div className={props.formData.gerant_physique_residante=="Oui"?"grid grid-cols-12 mt-6 items-center":"hidden grid-cols-12 mt-6 items-center"} >
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Pays *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="gerant_physique_pays"
                onChange={props.handleChange} 
                value={props.formData.gerant_physique_pays} 
                error={!props.formData.gerant_physique_pays  && props.isSubmitted?true:false}
              />
            </div>
          </div>  */}
        </div>
        
        <div className='py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Transformation SARL - SARLU en SAS</h2>
          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Transformation en *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-8'>
              
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Choisir la forme</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Choisissez"
                  id="type_acte_Transformation" 
                  name="type_acte_Transformation"
                  value={props.formData.type_acte_Transformation} 
                  onChange={props.handleChange}  
                  error={!props.formData.type_acte_Transformation  && props.isSubmitted?true:false}
                > 
                  <MenuItem value="SAS">Société par actions simplifiée (SAS)</MenuItem>
                  <MenuItem value="SAS à capital variable">Société par actions simplifiée à capital variable</MenuItem>
                  <MenuItem value="SASU">Société par actions simplifiée unipersonnelle (SASU)</MenuItem>
                  <MenuItem value="SASU à capital variable">Société par actions simplifiée unipersonnelle à capital variable</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={props.formData.type_acte_Transformation=="SAS à capital variable" || props.formData.type_acte_Transformation=="SASU à capital variable"?"grid grid-cols-12 mt-6 items-center":"hidden grid-cols-12 mt-6 items-center"} >
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital initial *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              name="capital_initial"
              onChange={props.handleChange} 
              value={props.formData.capital_initial} 
              error={!props.formData.capital_initial  && props.isSubmitted?true:false}
            />
          </div>
          </div>
          <div className={props.formData.type_acte_Transformation=="SAS à capital variable" || props.formData.type_acte_Transformation=="SASU à capital variable"?"grid grid-cols-12 mt-6 items-center":"hidden grid-cols-12 mt-6 items-center"} >
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital min. *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="capital_min"
                onChange={props.handleChange} 
                value={props.formData.capital_min} 
                error={!props.formData.capital_min  && props.isSubmitted?true:false}
              />
            </div>
          </div>
          <div className={props.formData.type_acte_Transformation=="SAS à capital variable" || props.formData.type_acte_Transformation=="SASU à capital variable"?"grid grid-cols-12 mt-6 items-center":"hidden grid-cols-12 mt-6 items-center"} >
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital max. *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="capital_max"
                onChange={props.handleChange} 
                value={props.formData.capital_max} 
                error={!props.formData.capital_max  && props.isSubmitted?true:false}
              />
            </div>
          </div>
        </div>
        
        <div className='py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Choisir l'organe et la date de la décision</h2>
          
          <div className='grid grid-cols-12 items-center mt-2 sm:mt-6'>
            <label className='col-span-12 sm:col-span-3 my-2 sm:mb-0 text-base font-normal text-black-light'>Par décision *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Sélectionnez"
                  id="type_acte" name="type_acte"
                  value={props.formData.type_acte} onChange={props.handleChange}
                  error={!props.formData.type_acte && props.isSubmitted?true:false}
                >
                  <MenuItem value={"AGO"}>de L'Assemblée Générale Ordinaire</MenuItem>
                  <MenuItem value={"AGE"}>de L'Assemblée Générale Extraordinaire</MenuItem>
                  <MenuItem value={"AGM"}>de L'Assemblée Générale Mixte</MenuItem>
                  <MenuItem value={"Gerant"}>du Gérant</MenuItem>
                  <MenuItem value={"President"}>du Président</MenuItem>
                  <MenuItem value={"Conseil_Administration"}>du Conseil d'Administration</MenuItem>
                  <MenuItem value={"Associe_Unique"}>de L'Associé Unique</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Date de l'AG *</label>
            <div className='col-span-12 sm:col-span-9 mr-7 md:mr-0 md:col-span-7 lg:col-span-4'>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}  >
                <DemoContainer components={['DatePicker']}  >
                  {
                    props.formData.datelagge?
                    <DatePicker value={dayjs(props.formData.datelagge)}   name="datelagge" onChange={(e)=>{props.handleChangeDate(e,"datelagge")}} format={"DD/MM/YYYY"}                   
                    />
                    :
                    <DatePicker  name="datelagge"  onChange={(e)=>{props.handleChangeDate(e,"datelagge")}} format={"DD/MM/YYYY"}                   
                    />
                  }
                  
                </DemoContainer>
              </LocalizationProvider>
            </div>

          </div>
          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Date d'effet *</label>
            <div className='col-span-12 sm:col-span-9 mr-7 md:mr-0 md:col-span-7 lg:col-span-4'>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}  >
                <DemoContainer components={['DatePicker']}  >
                  {
                    props.formData.date_effect?
                    <DatePicker value={dayjs(props.formData.date_effect)}   name="date_effect"   onChange={(e)=>{props.handleChangeDate(e,"date_effect")}} format={"DD/MM/YYYY"}                   
                    />
                    :
                    <DatePicker  name="date_effect"   onChange={(e)=>{props.handleChangeDate(e,"date_effect")}}   format={"DD/MM/YYYY"}                 
                    />
                  }
                  
                </DemoContainer>
              </LocalizationProvider>
            </div>

          </div>
        </div>
        {/* Les président */}
        <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
        <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Président</h2>
        {props.Les_president.map((val,key)=>{
          return <div key={val.index} index={val.index}  >   
            <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Personne *</label>
              <div className='col-span-12 sm:col-span-9 md:col-span-9 md:mr-10 inline-flex items-center gap-2'>
                <FormControl size="small" className='w-full'>
                  <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id={val.index}
                    label="Sélectionnez"
                    name="type_personne"
                    value={val.type_personne} 
                    onChange={(e)=>{props.handleChangeAddForm(e,val.index,"President")}}  
                    error={!val.type_personne  && props.isSubmitted?true:false}
                    index={val.index}
                  >
                    <MenuItem value={"PERSONNE_PHYSIQUE"}>Personne physique</MenuItem>
                    <MenuItem value={"PERSONNE_MORALE"}>Personne morale</MenuItem>

                  </Select>
                </FormControl>
                
                {/* {val.index >0?
                <div className='p-0 m-0 w-auto'>
                    <RemoveCircleRoundedIcon fontSize='large' className='text-3xl  cursor-pointer  fill-secondary' onClick={(e)=>{props.removeForm(val.index,"Associes")}} />
                </div>
                :""} */}
              </div>
              
            </div>
            <div className={val.type_personne === ""?"hidden":""}>
              <div className={val.type_personne === "PERSONNE_MORALE"?"":"hidden"}>      
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>N° SIREN *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="personne_morale_rcs"
                      value={val.personne_morale_rcs} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"President")}}   
                      error={!val.personne_morale_rcs  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                  </div>
                </div>
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="personne_morale_denomination"
                      value={val.personne_morale_denomination} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"President")}}  
                      error={!val.personne_morale_denomination  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                  </div>
                </div>
                <div className='grid grid-cols-12 items-start mt-6'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                    <FormControl size="small" className='w-full'>
                      <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Sélectionnez"
                        name="personne_morale_forme_juridique"
                        value={val.personne_morale_forme_juridique} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"President")}}    
                        error={!val.personne_morale_forme_juridique  && props.isSubmitted?true:false}
                        index={val.index}
                      >
                        <MenuItem value={"SAS"}>Société par actions simplifiée (SAS)</MenuItem>
                        <MenuItem value={"SAS à capital variable"}>Société par actions simplifiée à capital variable</MenuItem>
                        <MenuItem value={"SASU"}>Société par actions simplifiée unipersonnelle (SASU)</MenuItem>
                        <MenuItem value={"SASU à capital variable"}>Société par actions simplifiée unipersonnelle à capital variable</MenuItem>
                        <MenuItem value={"SARL"}>Société à responsabilité limitée (SARL)</MenuItem>
                        <MenuItem value={"SARL à capital variable"}>Société à responsabilité limitée à capital variable</MenuItem>
                        <MenuItem value={"SARLU"}>Société à responsabilité limitée à associé unique (SARLU)</MenuItem>
                        <MenuItem value={"SARLU à capitale variable"}>Société à responsabilité limitée à associé unique à capitale variable</MenuItem>
                        <MenuItem value={"SCI"}>Société civile immobilière (SCI)</MenuItem>
                        <MenuItem value={"SCI à capital variable"}>Société civile immobilière à capital variable</MenuItem>
                        <MenuItem value={"SC"}>Société civile</MenuItem>
                        <MenuItem value={"SA"}>Société anonyme (SA)</MenuItem>
                        <MenuItem value={"SA à capital variable"}>Société anonyme à capital variable</MenuItem>
                        <MenuItem value={"SNC"}>Société en nom collectif (SNC)</MenuItem>
                        <MenuItem value={"SNC à capital variable"}>Société en nom collectif à capital variable</MenuItem>
                        <MenuItem value={"SCM"}>Société civile de moyens (SCM)</MenuItem>
                        <MenuItem value={"SCP"}>Société civile professionnelle (SCP)</MenuItem>
                        <MenuItem value={"SCPI"}>Société civile de placement immobilier (SCPI)</MenuItem>
                        <MenuItem value={"SCCV"}>Société civile immobilière de construction vente (SCCV ou SCICV)</MenuItem>
                        <MenuItem value={"SELAFA"}>Société d'exercice libéral sous forme anonyme (SELAFA)</MenuItem>
                        <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                        <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                        <MenuItem value={"SELCA"}>Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
                        <MenuItem value={"SELARL"}>Société d'exercice libéral a responsabilité limitée (SELARL)</MenuItem>
                        <MenuItem value={"SELEURL"}>Société d'exercice libéral ayant un associé Unique (SELEURL)</MenuItem>
                        <MenuItem value={"SELASU"}>Société d'exercice libéral par actions simplifiée unipersonnelle (SELASU)</MenuItem>
                        <MenuItem value={"SCEA"}>Société civile d'exploitation agricole (SCEA)</MenuItem>
                        <MenuItem value={"EARL"}>Exploitation agricole à responsabilité limitée (EARL)</MenuItem>
                        <MenuItem value={"GAEC"}>Groupement agricole d'exploitation en commun (GAEC)</MenuItem>
                        <MenuItem value={"GFA"}>Groupement foncier agricole (GFA)</MenuItem>
                        <MenuItem value={"EIRL"}>Entrepreneur Individuel à Responsabilité Limité</MenuItem>
                        <MenuItem value={"SPFPLAS"}>Société de Participations Financières de Profession Libérale d'Avocats Simplifiée (SPFPLAS)</MenuItem>
                      </Select>
                    </FormControl>
                    
                  </div>
                </div>
                <div  className={"grid grid-cols-12 items-center mt-6"} >
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>
                    
                    <FormControl size="small" className='w-full'>
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'weight',
                        }}
                        name="personne_morale_capital"
                        value={val.personne_morale_capital} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"President")}}   
                        error={!val.personne_morale_capital  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </FormControl>


                  </div>
                </div>
              </div> 
              <div className={val.type_personne === "PERSONNE_PHYSIQUE"?"":"hidden"}>
                <div className='grid grid-cols-12 items-start mt-6'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                    <FormControl size="small" className='w-full'>
                      <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Sélectionnez"
                        name="civilite_physique"
                        value={val.civilite_physique} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"President")}}   
                        error={!val.civilite_physique  && props.isSubmitted?true:false}
                        index={val.index}
                        
                      >
                        <MenuItem value={"Mme"}>Madame</MenuItem>
                        <MenuItem value={"M."}>Monsieur</MenuItem>

                      </Select>
                    </FormControl>
                    
                  </div>
                </div>

                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="nom_physique"
                      value={val.nom_physique} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"President")}}   
                      error={!val.nom_physique  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                  </div>
                </div>

                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="prenom_physique" 
                      value={val.prenom_physique} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"President")}}  
                      error={!val.prenom_physique  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                  </div>
                </div>
              </div>
              <div className='grid grid-cols-12 mt-6 items-center'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    className='w-full'
                    name="adresse_physique" 
                    value={val.adresse_physique} 
                    onChange={(e)=>{props.handleChangeAddForm(e,val.index,"President")}}   
                    error={!val.adresse_physique  && props.isSubmitted?true:false}
                    index={val.index}
                  />
                </div>
              </div>
              <div className='grid grid-cols-12 mt-6 items-center'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Complément adresse</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    className='w-full'
                    name="adresse_suite_physique"
                    value={val.adresse_suite_physique} 
                    onChange={(e)=>{props.handleChangeAddForm(e,val.index,"President")}}  
                    index={val.index}
                  />
                </div>
              </div>
              <div className={val.type_personne === "PERSONNE_PHYSIQUE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Résidant à l’étranger</label>
                
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <FormControl size="small" className='w-full'>
                    <InputLabel id="resident_etranger-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="demo-resident_etrangerAssociesphysic-label"
                      id="resident_etranger"
                      label="resident_etranger"
                      name="resident_etranger"
                      value={`${val.resident_etranger}`}                         
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"President")}}   
                      index={val.index}
                    >
                      <MenuItem value={"Non"} >Non</MenuItem>
                      <MenuItem value={"Oui"} >Oui</MenuItem>

                    </Select>
                  </FormControl>
                </div>
              </div>  

              <div className={val.resident_etranger==="Non"||  val.type_personne === "PERSONNE_MORALE"?'grid grid-cols-12 mt-6 items-center':'hidden grid-cols-12 mt-6 items-center'}>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    className='w-full'
                    name="code_postal_physique"
                    value={val.code_postal_physique} 
                    onChange={(e)=>{props.handleChangeAddForm(e,val.index,"President")}}  
                    error={!val.code_postal_physique  && props.isSubmitted?true:false}
                    index={val.index}
                  />
                </div>
              </div>

              <div className='grid grid-cols-12 mt-6 items-center'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    className={val.resident_etranger=="Oui"?'w-full':'hidden w-full'}
                    name="ville_physique"
                    value={val.ville_physique} 
                    onChange={(e)=>{props.handleChangeAddForm(e,val.index,"President")}}  
                    error={!val.ville_physique  && props.isSubmitted?true:false}
                    index={val.index}
                  />
                  <FormControl size="small"  className={val.resident_etranger=="Oui"?'hidden w-full':' w-full'} >
                    <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Sélectionnez"
                      name="ville_physique_id"
                      value={val.ville_physique_id} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"President")}}   
                      error={!val.ville_physique  && props.isSubmitted?true:false}
                      index={val.index}                      
                    >
                      {
                        val.ville_physiqueArr.map((cityVal,cityIndex)=>{
                          return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                        })
                      }

                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className={val.type_personne === "PERSONNE_PHYSIQUE" && val.resident_etranger==="Oui"?'grid grid-cols-12 mt-6 items-center':'hidden grid-cols-12 mt-6 items-center'}>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Pays *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    className='w-full'
                    name="pays_physique"
                    value={val.pays_physique} 
                    onChange={(e)=>{props.handleChangeAddForm(e,val.index,"President")}}  
                    error={!val.pays_physique  && props.isSubmitted?true:false}
                    index={val.index}
                  />
                </div>
              </div>
              <div className={val.type_personne === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe * </label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    className='w-full'
                    name="personne_morale_greffe"
                    value={val.personne_morale_greffe} 
                    onChange={(e)=>{props.handleChangeAddForm(e,val.index,"President")}}   
                    error={!val.personne_morale_greffe  && props.isSubmitted?true:false}
                    index={val.index}
                  />
                </div>
              </div>
              <div className={val.type_personne === "PERSONNE_MORALE"?"":"hidden"}>
                <div className='px-0 group flex items-center gap-2 text-secondary mt-12 '>                   
                  <span className='text-base font-normal'>Représentée par</span>
                </div>
                <div className='grid grid-cols-12 items-start mt-6'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité </label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                    <FormControl size="small" className='w-full'>
                      <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Sélectionnez"
                        name="personne_morale_representation_civilite"
                        value={val.personne_morale_representation_civilite} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"President")}}                            
                        index={val.index}
                        
                      >
                        <MenuItem value={"Mme"}>Madame</MenuItem>
                        <MenuItem value={"M."}>Monsieur</MenuItem>

                      </Select>
                    </FormControl>
                    
                  </div>
                </div>

                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="personne_morale_representation_nom" 
                      value={val.personne_morale_representation_nom} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"President")}}  
                      error={!val.personne_morale_representation_nom  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                  </div>
                </div>

                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="personne_morale_representation_prenom" 
                      value={val.personne_morale_representation_prenom} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"President")}}  
                      error={!val.personne_morale_representation_prenom  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Divider className='mb-6 mt-6' variant="middle" />
          </div> 
        })}  
        {/* <div className='px-0 group flex items-center gap-2 text-primary hover:text-secondary mt-12 cursor-pointer' onClick={(e)=>{props.addForm(e,"Associes")}} >
          <BsPlusCircle className='text-3xl ' />
          <span className='text-base font-normal'>Ajouter un associés</span>
        </div> */}

        </div>  
        {/* Directeur général */}
        <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Directeur général</h2><br /><span>Le Directeur Général</span>
          <div className={"grid grid-cols-12 items-start mt-6"} >            
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Saisir données *</label>
            
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="show_data_direct-label">Sélectionnez</InputLabel>
                <Select
                  labelId="show_data_direct-label"
                  id="show_data_direct"
                  label="Sélectionnez"
                  name="show_data_direct"
                  value={`${props.Directeur_general.show_data_direct}`}                         
                  onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}  
                  error={!props.Directeur_general.show_data_direct  && props.isSubmitted?true:false} 
                >
                  <MenuItem value={"Non"} >Non</MenuItem>
                  <MenuItem value={"Oui"} >Oui</MenuItem>

                </Select>
              </FormControl>
            </div>
          </div>  
          <div className={props.Directeur_general.show_data_direct === "Oui"?"":"hidden"}>
            <div className='Directeur_general'>
              <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Personne *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-9 md:mr-10 inline-flex items-center gap-2'>
                  <FormControl size="small" className='w-full'>
                    <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      // id={val.index}
                      label="Sélectionnez"
                      name="type_personne_direct"
                      value={props.Directeur_general.type_personne_direct} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}  
                      error={!props.Directeur_general.type_personne_direct  && props.isSubmitted?true:false}
                    >
                      <MenuItem value={"PERSONNE_PHYSIQUE"}>Personne physique</MenuItem>
                      <MenuItem value={"PERSONNE_MORALE"}>Personne morale</MenuItem>

                    </Select>
                  </FormControl>
                                   
                </div>                
              </div>
              <div className={props.Directeur_general.type_personne_direct === ""?"hidden":""}>
                <div className={props.Directeur_general.type_personne_direct === "PERSONNE_MORALE"?"":"hidden"}>      
                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>N° SIREN *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="rcs_direct"
                        value={props.Directeur_general.rcs_direct} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}   
                        error={!props.Directeur_general.rcs_direct  && props.isSubmitted?true:false}
                       
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="denomination_direct"
                        value={props.Directeur_general.denomination_direct} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}  
                        error={!props.Directeur_general.denomination_direct  && props.isSubmitted?true:false}
                        
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="forme_juridiquedirect"
                          value={props.Directeur_general.forme_juridiquedirect} 
                          onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}    
                          error={!props.Directeur_general.forme_juridiquedirect  && props.isSubmitted?true:false}
                         
                        >
                          <MenuItem value={"SAS"}>Société par actions simplifiée (SAS)</MenuItem>
                          <MenuItem value={"SAS à capital variable"}>Société par actions simplifiée à capital variable</MenuItem>
                          <MenuItem value={"SASU"}>Société par actions simplifiée unipersonnelle (SASU)</MenuItem>
                          <MenuItem value={"SASU à capital variable"}>Société par actions simplifiée unipersonnelle à capital variable</MenuItem>
                          <MenuItem value={"SARL"}>Société à responsabilité limitée (SARL)</MenuItem>
                          <MenuItem value={"SARL à capital variable"}>Société à responsabilité limitée à capital variable</MenuItem>
                          <MenuItem value={"SARLU"}>Société à responsabilité limitée à associé unique (SARLU)</MenuItem>
                          <MenuItem value={"SARLU à capitale variable"}>Société à responsabilité limitée à associé unique à capitale variable</MenuItem>
                          <MenuItem value={"SCI"}>Société civile immobilière (SCI)</MenuItem>
                          <MenuItem value={"SCI à capital variable"}>Société civile immobilière à capital variable</MenuItem>
                          <MenuItem value={"SC"}>Société civile</MenuItem>
                          <MenuItem value={"SA"}>Société anonyme (SA)</MenuItem>
                          <MenuItem value={"SA à capital variable"}>Société anonyme à capital variable</MenuItem>
                          <MenuItem value={"SNC"}>Société en nom collectif (SNC)</MenuItem>
                          <MenuItem value={"SNC à capital variable"}>Société en nom collectif à capital variable</MenuItem>
                          <MenuItem value={"SCM"}>Société civile de moyens (SCM)</MenuItem>
                          <MenuItem value={"SCP"}>Société civile professionnelle (SCP)</MenuItem>
                          <MenuItem value={"SCPI"}>Société civile de placement immobilier (SCPI)</MenuItem>
                          <MenuItem value={"SCCV"}>Société civile immobilière de construction vente (SCCV ou SCICV)</MenuItem>
                          <MenuItem value={"SELAFA"}>Société d'exercice libéral sous forme anonyme (SELAFA)</MenuItem>
                          <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                          <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                          <MenuItem value={"SELCA"}>Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
                          <MenuItem value={"SELARL"}>Société d'exercice libéral a responsabilité limitée (SELARL)</MenuItem>
                          <MenuItem value={"SELEURL"}>Société d'exercice libéral ayant un associé Unique (SELEURL)</MenuItem>
                          <MenuItem value={"SELASU"}>Société d'exercice libéral par actions simplifiée unipersonnelle (SELASU)</MenuItem>
                          <MenuItem value={"SCEA"}>Société civile d'exploitation agricole (SCEA)</MenuItem>
                          <MenuItem value={"EARL"}>Exploitation agricole à responsabilité limitée (EARL)</MenuItem>
                          <MenuItem value={"GAEC"}>Groupement agricole d'exploitation en commun (GAEC)</MenuItem>
                          <MenuItem value={"GFA"}>Groupement foncier agricole (GFA)</MenuItem>
                          <MenuItem value={"EIRL"}>Entrepreneur Individuel à Responsabilité Limité</MenuItem>
                          <MenuItem value={"SPFPLAS"}>Société de Participations Financières de Profession Libérale d'Avocats Simplifiée (SPFPLAS)</MenuItem>
                        </Select>
                      </FormControl>
                      
                    </div>
                  </div>
                  <div  className={"grid grid-cols-12 items-center mt-6"} >
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>
                      
                      <FormControl size="small" className='w-full'>
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            'aria-label': 'weight',
                          }}
                          name="capital_direct"
                          value={props.Directeur_general.capital_direct} 
                          onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}   
                          error={!props.Directeur_general.capital_direct  && props.isSubmitted?true:false}
                          
                        />
                      </FormControl>


                    </div>
                  </div>
                </div> 
                <div className={props.Directeur_general.type_personne_direct === "PERSONNE_PHYSIQUE"?"":"hidden"}>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="civilite_direct_physique"
                          value={props.Directeur_general.civilite_direct_physique} 
                          onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}   
                          error={!props.Directeur_general.civilite_direct_physique  && props.isSubmitted?true:false}
                        >
                          <MenuItem value={"Mme"}>Madame</MenuItem>
                          <MenuItem value={"M."}>Monsieur</MenuItem>

                        </Select>
                      </FormControl>                      
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="nom_direct_physique"
                        value={props.Directeur_general.nom_direct_physique} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}   
                        error={!props.Directeur_general.nom_direct_physique  && props.isSubmitted?true:false}
                        
                      />
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="prenom_direct_physique" 
                        value={props.Directeur_general.prenom_direct_physique} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}  
                        error={!props.Directeur_general.prenom_direct_physique  && props.isSubmitted?true:false}
                      />
                    </div>
                  </div>
                </div>
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="adresse_direct" 
                      value={props.Directeur_general.adresse_direct} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}   
                      error={!props.Directeur_general.adresse_direct  && props.isSubmitted?true:false}
                      
                    />
                  </div>
                </div>
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Complément adresse</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="adresse_suite_direct"
                      value={props.Directeur_general.adresse_suite_direct} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}  
                      
                    />
                  </div>
                </div>
                

                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="code_postal_direct"
                      value={props.Directeur_general.code_postal_direct} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}  
                      error={!props.Directeur_general.code_postal_direct  && props.isSubmitted?true:false}
                     
                    />
                  </div>
                </div>

                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    
                    {/* <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className={props.Directeur_general.resident_etranger=="Oui"?'w-full':'hidden w-full'}
                      name="ville_direct"
                      value={props.Directeur_general.ville_direct} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}  
                      error={!props.Directeur_general.ville_direct  && props.isSubmitted?true:false}
                    /> */}
                    <FormControl size="small"  className={' w-full'} >
                      <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Sélectionnez"
                        name="ville_direct_id"
                        value={props.Directeur_general.ville_direct_id} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}}     
                        error={!props.Directeur_general.ville_direct  && props.isSubmitted?true:false}
                      >
                        {
                          props.Directeur_general.ville_directArr.map((cityVal,cityIndex)=>{
                            return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                          })
                        }

                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className={props.Directeur_general.type_personne_direct === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe * </label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="greffe_direct"
                      value={props.Directeur_general.greffe_direct} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"Directeur_general")}} 
                      error={!props.Directeur_general.greffe_direct  && props.isSubmitted?true:false}
                      
                    />
                  </div>
                </div>
                
              </div>
            </div>
          
          </div>
        </div>    
        {/* Commissaire aux comptes */}
        <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Commissaire aux comptes</h2>
          <div className={"grid grid-cols-12 items-start mt-6"} >
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Saisir données *</label>
            
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="show_data-label">Sélectionnez</InputLabel>
                <Select
                  labelId="show_data-label"
                  id="show_data_commissaire"
                  label="Sélectionnez"
                  name="show_data_commissaire"
                  value={`${props.CommissaireAuxComptes.show_data_commissaire}`}                         
                  onChange={(e)=>{props.handleChangeAddForm(e,0,"commissaireAuxComptes")}}  
                  error={!props.CommissaireAuxComptes.show_data_commissaire  && props.isSubmitted?true:false} 
                >
                  <MenuItem value={"Non"} >Non</MenuItem>
                  <MenuItem value={"Oui"} >Oui</MenuItem>

                </Select>
              </FormControl>
            </div>
          </div>  
          <div className={props.CommissaireAuxComptes.show_data_commissaire === "Oui"?"":"hidden"}>
            <div className='titulaire'>
              <div className='px-0 group flex items-center gap-2 text-secondary mt-12 '>                   
                <span className='text-base font-normal'>CAC titulaire</span>
              </div>
              <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Personne</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-9 md:mr-10 inline-flex items-center gap-2'>
                  <FormControl size="small" className='w-full'>
                    <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      // id={val.index}
                      label="Sélectionnez"
                      name="type_personne_titulaire"
                      value={props.CommissaireAuxComptes.CAC_titulaire.type_personne_titulaire} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}  
                      error={!props.CommissaireAuxComptes.CAC_titulaire.type_personne_titulaire  && props.isSubmitted?true:false}
                    >
                      <MenuItem value={"PERSONNE_PHYSIQUE"}>Personne physique</MenuItem>
                      <MenuItem value={"PERSONNE_MORALE"}>Personne morale</MenuItem>

                    </Select>
                  </FormControl>
                  <StyledTooltip title="La personne physique désigne un individu. La personne morale, elle, est une entité juridique.">
                    <div className='p-0 m-0 w-auto'>
                      <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer  hover:fill-secondary' />
                    </div>
                  </StyledTooltip>                  
                </div>                
              </div>
              <div className={props.CommissaireAuxComptes.CAC_titulaire.type_personne_titulaire === ""?"hidden":""}>
                <div className={props.CommissaireAuxComptes.CAC_titulaire.type_personne_titulaire === "PERSONNE_MORALE"?"":"hidden"}>      
                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>N° SIREN *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="rcs_titulaire"
                        value={props.CommissaireAuxComptes.CAC_titulaire.rcs_titulaire} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}   
                        error={!props.CommissaireAuxComptes.CAC_titulaire.rcs_titulaire  && props.isSubmitted?true:false}
                       
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="denomination_titulaire"
                        value={props.CommissaireAuxComptes.CAC_titulaire.denomination_titulaire} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}  
                        error={!props.CommissaireAuxComptes.CAC_titulaire.denomination_titulaire  && props.isSubmitted?true:false}
                        
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="forme_juridique_titulaire"
                          value={props.CommissaireAuxComptes.CAC_titulaire.forme_juridique_titulaire} 
                          onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}    
                          error={!props.CommissaireAuxComptes.CAC_titulaire.forme_juridique_titulaire  && props.isSubmitted?true:false}
                         
                        >
                          <MenuItem value={"SAS"}>Société par actions simplifiée (SAS)</MenuItem>
                          <MenuItem value={"SAS à capital variable"}>Société par actions simplifiée à capital variable</MenuItem>
                          <MenuItem value={"SASU"}>Société par actions simplifiée unipersonnelle (SASU)</MenuItem>
                          <MenuItem value={"SASU à capital variable"}>Société par actions simplifiée unipersonnelle à capital variable</MenuItem>
                          <MenuItem value={"SARL"}>Société à responsabilité limitée (SARL)</MenuItem>
                          <MenuItem value={"SARL à capital variable"}>Société à responsabilité limitée à capital variable</MenuItem>
                          <MenuItem value={"SARLU"}>Société à responsabilité limitée à associé unique (SARLU)</MenuItem>
                          <MenuItem value={"SARLU à capitale variable"}>Société à responsabilité limitée à associé unique à capitale variable</MenuItem>
                          <MenuItem value={"SCI"}>Société civile immobilière (SCI)</MenuItem>
                          <MenuItem value={"SCI à capital variable"}>Société civile immobilière à capital variable</MenuItem>
                          <MenuItem value={"SC"}>Société civile</MenuItem>
                          <MenuItem value={"SA"}>Société anonyme (SA)</MenuItem>
                          <MenuItem value={"SA à capital variable"}>Société anonyme à capital variable</MenuItem>
                          <MenuItem value={"SNC"}>Société en nom collectif (SNC)</MenuItem>
                          <MenuItem value={"SNC à capital variable"}>Société en nom collectif à capital variable</MenuItem>
                          <MenuItem value={"SCM"}>Société civile de moyens (SCM)</MenuItem>
                          <MenuItem value={"SCP"}>Société civile professionnelle (SCP)</MenuItem>
                          <MenuItem value={"SCPI"}>Société civile de placement immobilier (SCPI)</MenuItem>
                          <MenuItem value={"SCCV"}>Société civile immobilière de construction vente (SCCV ou SCICV)</MenuItem>
                          <MenuItem value={"SELAFA"}>Société d'exercice libéral sous forme anonyme (SELAFA)</MenuItem>
                          <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                          <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                          <MenuItem value={"SELCA"}>Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
                          <MenuItem value={"SELARL"}>Société d'exercice libéral a responsabilité limitée (SELARL)</MenuItem>
                          <MenuItem value={"SELEURL"}>Société d'exercice libéral ayant un associé Unique (SELEURL)</MenuItem>
                          <MenuItem value={"SELASU"}>Société d'exercice libéral par actions simplifiée unipersonnelle (SELASU)</MenuItem>
                          <MenuItem value={"SCEA"}>Société civile d'exploitation agricole (SCEA)</MenuItem>
                          <MenuItem value={"EARL"}>Exploitation agricole à responsabilité limitée (EARL)</MenuItem>
                          <MenuItem value={"GAEC"}>Groupement agricole d'exploitation en commun (GAEC)</MenuItem>
                          <MenuItem value={"GFA"}>Groupement foncier agricole (GFA)</MenuItem>
                          <MenuItem value={"EIRL"}>Entrepreneur Individuel à Responsabilité Limité</MenuItem>
                          <MenuItem value={"SPFPLAS"}>Société de Participations Financières de Profession Libérale d'Avocats Simplifiée (SPFPLAS)</MenuItem>
                        </Select>
                      </FormControl>
                      
                    </div>
                  </div>
                  <div  className={"grid grid-cols-12 items-center mt-6"} >
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>
                      
                      <FormControl size="small" className='w-full'>
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            'aria-label': 'weight',
                          }}
                          name="capital_titulaire"
                          value={props.CommissaireAuxComptes.CAC_titulaire.capital_titulaire} 
                          onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}   
                          error={!props.CommissaireAuxComptes.CAC_titulaire.capital_titulaire  && props.isSubmitted?true:false}
                          
                        />
                      </FormControl>

                      <StyledTooltip title="Montant du capital mentionné dans les statuts.">
                        <div className='p-0 m-0 w-auto'>
                          <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                        </div>
                      </StyledTooltip>

                    </div>
                  </div>
                </div> 
                <div className={props.CommissaireAuxComptes.CAC_titulaire.type_personne_titulaire === "PERSONNE_PHYSIQUE"?"":"hidden"}>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="civilite_titulaire_physique"
                          value={props.CommissaireAuxComptes.CAC_titulaire.civilite_titulaire_physique} 
                          onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}   
                          error={!props.CommissaireAuxComptes.CAC_titulaire.civilite_titulaire_physique  && props.isSubmitted?true:false}
                         
                          
                        >
                          <MenuItem value={"Mme"}>Madame</MenuItem>
                          <MenuItem value={"M."}>Monsieur</MenuItem>

                        </Select>
                      </FormControl>                      
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="nom_titulaire_physique"
                        value={props.CommissaireAuxComptes.CAC_titulaire.nom_titulaire_physique} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}   
                        error={!props.CommissaireAuxComptes.CAC_titulaire.nom_titulaire_physique  && props.isSubmitted?true:false}
                        
                      />
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="prenom_titulaire_physique" 
                        value={props.CommissaireAuxComptes.CAC_titulaire.prenom_titulaire_physique} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}  
                        error={!props.CommissaireAuxComptes.CAC_titulaire.prenom_titulaire_physique  && props.isSubmitted?true:false}
                        
                      />
                    </div>
                  </div>
                </div>
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="adresse_titulaire" 
                      value={props.CommissaireAuxComptes.CAC_titulaire.adresse_titulaire} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}   
                      error={!props.CommissaireAuxComptes.CAC_titulaire.adresse_titulaire  && props.isSubmitted?true:false}
                      
                    />
                  </div>
                </div>
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Complément adresse</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="adresse_suite_titulaire"
                      value={props.CommissaireAuxComptes.CAC_titulaire.adresse_suite_titulaire} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}  
                      
                    />
                  </div>
                </div>

                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="code_postal_titulaire"
                      value={props.CommissaireAuxComptes.CAC_titulaire.code_postal_titulaire} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}  
                      error={!props.CommissaireAuxComptes.CAC_titulaire.code_postal_titulaire  && props.isSubmitted?true:false}
                     
                    />
                  </div>
                </div>

                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    {/* <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="ville_titulaire"
                      value={props.CommissaireAuxComptes.CAC_titulaire.ville_titulaire} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}  
                      error={!props.CommissaireAuxComptes.CAC_titulaire.ville_titulaire  && props.isSubmitted?true:false}
                      
                    /> */}
                    <FormControl size="small"  className={' w-full'} >
                      <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Sélectionnez"
                        name="ville_titulaire_id"
                        value={props.CommissaireAuxComptes.CAC_titulaire.ville_titulaire_id} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}}  
                        error={!props.CommissaireAuxComptes.CAC_titulaire.ville_titulaire  && props.isSubmitted?true:false}
                        
                      >
                        {
                          props.CommissaireAuxComptes.CAC_titulaire.ville_titulaireArr.map((cityVal,cityIndex)=>{
                            return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                          })
                        }

                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className={props.CommissaireAuxComptes.CAC_titulaire.type_personne_titulaire === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe </label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="greffe_titulaire"
                      value={props.CommissaireAuxComptes.CAC_titulaire.greffe_titulaire} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_titulaire")}} 
                      
                    />
                  </div>
                </div>
                
              </div>
            </div>
            <div className='suppleant mb-3'>
              <div className='px-0 group flex items-center gap-2 text-secondary mt-12 '>                   
                <span className='text-base font-normal'>CAC suppléant</span>
              </div>
              <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Personne</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-9 md:mr-10 inline-flex items-center gap-2'>
                  <FormControl size="small" className='w-full'>
                    <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      // id={val.index}
                      label="Sélectionnez"
                      name="type_personne_suppleant"
                      value={props.CommissaireAuxComptes.CAC_suppleant.type_personne_suppleant} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}  
                      error={!props.CommissaireAuxComptes.CAC_suppleant.type_personne_suppleant  && props.isSubmitted?true:false}
                    >
                      <MenuItem value={"PAS_DE_NOMINATION"}>Pas de nomination de CAC suppléant</MenuItem>
                      <MenuItem value={"PERSONNE_PHYSIQUE"}>Personne physique</MenuItem>
                      <MenuItem value={"PERSONNE_MORALE"}>Personne morale</MenuItem>

                    </Select>
                  </FormControl>
                  <StyledTooltip title="La personne physique désigne un individu. La personne morale, elle, est une entité juridique.">
                    <div className='p-0 m-0 w-auto'>
                      <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer  hover:fill-secondary' />
                    </div>
                  </StyledTooltip>                  
                </div>                
              </div>
              <div className={props.CommissaireAuxComptes.CAC_suppleant.type_personne_suppleant === "" ||props.CommissaireAuxComptes.CAC_suppleant.type_personne_suppleant === "PAS_DE_NOMINATION"?"hidden":""}>
                <div className={props.CommissaireAuxComptes.CAC_suppleant.type_personne_suppleant === "PERSONNE_MORALE"?"":"hidden"}>      
                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>N° SIREN *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="rcs_suppleant"
                        value={props.CommissaireAuxComptes.CAC_suppleant.rcs_suppleant} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}   
                        error={!props.CommissaireAuxComptes.CAC_suppleant.rcs_suppleant  && props.isSubmitted?true:false}
                       
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="denomination_suppleant"
                        value={props.CommissaireAuxComptes.CAC_suppleant.denomination_suppleant} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}  
                        error={!props.CommissaireAuxComptes.CAC_suppleant.denomination_suppleant  && props.isSubmitted?true:false}
                        
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="forme_juridique_suppleant"
                          value={props.CommissaireAuxComptes.CAC_suppleant.forme_juridique_suppleant} 
                          onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}    
                          error={!props.CommissaireAuxComptes.CAC_suppleant.forme_juridique_suppleant  && props.isSubmitted?true:false}
                         
                        >
                          <MenuItem value={"SAS"}>Société par actions simplifiée (SAS)</MenuItem>
                          <MenuItem value={"SAS à capital variable"}>Société par actions simplifiée à capital variable</MenuItem>
                          <MenuItem value={"SASU"}>Société par actions simplifiée unipersonnelle (SASU)</MenuItem>
                          <MenuItem value={"SASU à capital variable"}>Société par actions simplifiée unipersonnelle à capital variable</MenuItem>
                          <MenuItem value={"SARL"}>Société à responsabilité limitée (SARL)</MenuItem>
                          <MenuItem value={"SARL à capital variable"}>Société à responsabilité limitée à capital variable</MenuItem>
                          <MenuItem value={"SARLU"}>Société à responsabilité limitée à associé unique (SARLU)</MenuItem>
                          <MenuItem value={"SARLU à capitale variable"}>Société à responsabilité limitée à associé unique à capitale variable</MenuItem>
                          <MenuItem value={"SCI"}>Société civile immobilière (SCI)</MenuItem>
                          <MenuItem value={"SCI à capital variable"}>Société civile immobilière à capital variable</MenuItem>
                          <MenuItem value={"SC"}>Société civile</MenuItem>
                          <MenuItem value={"SA"}>Société anonyme (SA)</MenuItem>
                          <MenuItem value={"SA à capital variable"}>Société anonyme à capital variable</MenuItem>
                          <MenuItem value={"SNC"}>Société en nom collectif (SNC)</MenuItem>
                          <MenuItem value={"SNC à capital variable"}>Société en nom collectif à capital variable</MenuItem>
                          <MenuItem value={"SCM"}>Société civile de moyens (SCM)</MenuItem>
                          <MenuItem value={"SCP"}>Société civile professionnelle (SCP)</MenuItem>
                          <MenuItem value={"SCPI"}>Société civile de placement immobilier (SCPI)</MenuItem>
                          <MenuItem value={"SCCV"}>Société civile immobilière de construction vente (SCCV ou SCICV)</MenuItem>
                          <MenuItem value={"SELAFA"}>Société d'exercice libéral sous forme anonyme (SELAFA)</MenuItem>
                          <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                          <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                          <MenuItem value={"SELCA"}>Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
                          <MenuItem value={"SELARL"}>Société d'exercice libéral a responsabilité limitée (SELARL)</MenuItem>
                          <MenuItem value={"SELEURL"}>Société d'exercice libéral ayant un associé Unique (SELEURL)</MenuItem>
                          <MenuItem value={"SELASU"}>Société d'exercice libéral par actions simplifiée unipersonnelle (SELASU)</MenuItem>
                          <MenuItem value={"SCEA"}>Société civile d'exploitation agricole (SCEA)</MenuItem>
                          <MenuItem value={"EARL"}>Exploitation agricole à responsabilité limitée (EARL)</MenuItem>
                          <MenuItem value={"GAEC"}>Groupement agricole d'exploitation en commun (GAEC)</MenuItem>
                          <MenuItem value={"GFA"}>Groupement foncier agricole (GFA)</MenuItem>
                          <MenuItem value={"EIRL"}>Entrepreneur Individuel à Responsabilité Limité</MenuItem>
                          <MenuItem value={"SPFPLAS"}>Société de Participations Financières de Profession Libérale d'Avocats Simplifiée (SPFPLAS)</MenuItem>
                        </Select>
                      </FormControl>
                      
                    </div>
                  </div>
                  <div  className={"grid grid-cols-12 items-center mt-6"} >
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>
                      
                      <FormControl size="small" className='w-full'>
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            'aria-label': 'weight',
                          }}
                          name="capital_suppleant"
                          value={props.CommissaireAuxComptes.CAC_suppleant.capital_suppleant} 
                          onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}   
                          error={!props.CommissaireAuxComptes.CAC_suppleant.capital_suppleant  && props.isSubmitted?true:false}
                          
                        />
                      </FormControl>

                      <StyledTooltip title="Montant du capital mentionné dans les statuts.">
                        <div className='p-0 m-0 w-auto'>
                          <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                        </div>
                      </StyledTooltip>

                    </div>
                  </div>
                </div> 
                <div className={props.CommissaireAuxComptes.CAC_suppleant.type_personne_suppleant === "PERSONNE_PHYSIQUE"?"":"hidden"}>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="civilite_suppleant_physique"
                          value={props.CommissaireAuxComptes.CAC_suppleant.civilite_suppleant_physique} 
                          onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}   
                          error={!props.CommissaireAuxComptes.CAC_suppleant.civilite_suppleant_physique  && props.isSubmitted?true:false}
                         
                          
                        >
                          <MenuItem value={"Mme"}>Madame</MenuItem>
                          <MenuItem value={"M."}>Monsieur</MenuItem>

                        </Select>
                      </FormControl>                      
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="nom_suppleant_physique"
                        value={props.CommissaireAuxComptes.CAC_suppleant.nom_suppleant_physique} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}   
                        error={!props.CommissaireAuxComptes.CAC_suppleant.nom_suppleant_physique  && props.isSubmitted?true:false}
                        
                      />
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="prenom_suppleant_physique" 
                        value={props.CommissaireAuxComptes.CAC_suppleant.prenom_suppleant_physique} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}  
                        error={!props.CommissaireAuxComptes.CAC_suppleant.prenom_suppleant_physique  && props.isSubmitted?true:false}
                        
                      />
                    </div>
                  </div>
                </div>
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="adresse_suppleant" 
                      value={props.CommissaireAuxComptes.CAC_titulaire.adresse_suppleant} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}   
                      error={!props.CommissaireAuxComptes.CAC_suppleant.adresse_suppleant  && props.isSubmitted?true:false}
                      
                    />
                  </div>
                </div>
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Complément adresse</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="adresse_suite_suppleant"
                      value={props.CommissaireAuxComptes.CAC_suppleant.adresse_suite_suppleant} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}  
                      
                    />
                  </div>
                </div>
                

                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="code_postal_suppleant"
                      value={props.CommissaireAuxComptes.CAC_suppleant.code_postal_suppleant} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}  
                      error={!props.CommissaireAuxComptes.CAC_suppleant.code_postal_suppleant  && props.isSubmitted?true:false}
                     
                    />
                  </div>
                </div>

                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    {/* <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="ville_suppleant"
                      value={props.CommissaireAuxComptes.CAC_suppleant.ville_suppleant} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}  
                      error={!props.CommissaireAuxComptes.CAC_suppleant.ville_suppleant  && props.isSubmitted?true:false}
                      
                    /> */}
                    <FormControl size="small"  className={' w-full'} >
                      <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Sélectionnez"
                        name="ville_suppleant_id"
                        value={props.CommissaireAuxComptes.CAC_suppleant.ville_suppleant_id} 
                        onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}  
                        error={!props.CommissaireAuxComptes.CAC_suppleant.ville_suppleant  && props.isSubmitted?true:false}
                        
                      >
                        {
                          props.CommissaireAuxComptes.CAC_suppleant.ville_suppleantArr.map((cityVal,cityIndex)=>{
                            return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                          })
                        }

                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className={props.CommissaireAuxComptes.CAC_suppleant.type_personne_suppleant === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe </label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="greffe_suppleant"
                      value={props.CommissaireAuxComptes.CAC_suppleant.greffe_suppleant} 
                      onChange={(e)=>{props.handleChangeAddForm(e,0,"CAC_suppleant")}}   
                     
                      
                    />
                  </div>
                </div>
                
              </div>
            </div>
            
          </div>
          {/* Transmission des actions */}
          <div className={"grid grid-cols-12 items-start mt-6"} >
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'><h2 className='text-lg sm:text-[22px] font-bold text-primary'>Transmission des actions</h2></label>
            
            <div className='col-span-12 sm:col-span-9 md:col-span-8 mr-7 inline-flex items-center gap-2'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="show_data-label">Sélectionnez</InputLabel>
                <Select
                  labelId="show_data-label"
                  id="show_data"
                  label="Sélectionnez"
                  name="show_data"
                  value={`${props.Transmission_des_actions.show_data}`}                         
                  onChange={(e)=>{props.handleChangeAddForm(e,0,"Transmission_des_actions")}}  
                  error={!props.Transmission_des_actions.show_data  && props.isSubmitted?true:false} 
                >
                  <MenuItem value={"Texte libre"} >Texte libre</MenuItem>
                  <MenuItem value={"Actions librement cessibles entre associés uniquement."} >Texte usuel</MenuItem>

                </Select>
              </FormControl>
            </div>
           
          </div>
          <div className={"grid grid-cols-12 items-start mt-6"} >
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'><h2 className='text-lg sm:text-[22px] font-bold text-primary'> </h2></label>
            
            <div className='col-span-12 sm:col-span-9 md:col-span-8 mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                rows="6"
                multiline={true}
                disabled={false}
                helperText="Attention, La mention des Clauses d'Agréments est recommandée, elle est souvent requise par les greffes"
                name="texte"
                placeholder="(Reprendre les clauses figurant dans les statuts, par exemple : Les actions sont librement cessibles entre actionnaires; toutes autres cessions et transmissions sont soumises à l'agrément du conseil d'administration."
                value={props.Transmission_des_actions.texte} 
                onChange={(e)=>{props.handleChangeAddForm(e,0,"Transmission_des_actions")}}                  
              />
            </div>
          </div>
           {/* Admission aux assemblées et droits de votes */}
           <div className={"grid grid-cols-12 items-start mt-6"} >
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'><h2 className='text-lg sm:text-[22px] font-bold text-primary'>Admission aux assemblées et droits de votes</h2></label>
            
            <div className='col-span-12 sm:col-span-9 md:col-span-8 mr-7 inline-flex items-center gap-2'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="show_data-label">Sélectionnez</InputLabel>
                <Select
                  labelId="show_data-label"
                  id="show_data"
                  label="Sélectionnez"
                  name="show_data"
                  value={`${props.Admission_assemblees.show_data}`}                         
                  onChange={(e)=>{props.handleChangeAddForm(e,0,"Admission_assemblees")}}  
                  error={!props.Admission_assemblees.show_data  && props.isSubmitted?true:false} 
                >
                  <MenuItem value={"Texte libre"} >Texte libre</MenuItem>
                  <MenuItem value={"Tout Actionnaire est convoqué aux Assemblées. Chaque action donne droit à une voix."} >Texte usuel</MenuItem>

                </Select>
              </FormControl>
            </div>
           
          </div>
          <div className={"grid grid-cols-12 items-start mt-6"} >
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'><h2 className='text-lg sm:text-[22px] font-bold text-primary'> </h2></label>
            
            <div className='col-span-12 sm:col-span-9 md:col-span-8 mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                rows="6"
                multiline={true}
                disabled={false}
                helperText="Attention, La mention des Admissions aux Assemblées est recommandée, elle est souvent requise par les greffes."
                name="texte"
                value={props.Admission_assemblees.texte} 
                onChange={(e)=>{props.handleChangeAddForm(e,0,"Admission_assemblees")}}                  
              />
            </div>
          </div>                  
        </div> 
           
    </div>
    </>
  )
}
