import React from "react";
import Layout from "./Layout";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import MonEntreprise from "./MonEntreprise";
import MesPaiements from "./MesPaiements";
import MesDocuments from "./MesDocuments";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Parametresentreprise() {
  const [value, setValue] = React.useState("MonEntreprise");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const currentUser = useSelector((state) => state.authentication.user);
  if (!currentUser) {
    return <Navigate to="/" />;
  }

  return (
    <Layout>
      <div className="mb-8 col-span-12 xl:col-span-9">
        <div className="xl:max-w-[1126px] w-full h-full bg-white md:rounded-[20px]">
          <div className="mainHead min-h-[80px] sm:min-h-[187px] flex sm:flex-row  flex-col sm:items-center p-3 sm:p-10 sm:justify-between">
            <div>
            <h1 className="text-lg sm:text-xl md:text-lg xl:text-xl 2xl:text-xl font-extrabold text-white sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0 mt-4 ">
                Parametres entreprise
              </h1>
            </div>
            <div className="w-full sm:w-auto flex justify-end"></div>
          </div>
          <div className="w-full mt-6 sm:-mt-11 sm:px-10 px-2 AnnonTab  ">
            <TabContext value={value}>
              <Box className='paramTabBar'>
                <TabList
                  indicatorColor="none"
                  orientation="horizontal"
                  onChange={handleChange}
                  aria-label="lab API tabs example"
               
                >
                  <Tab
                    label="Mon entreprise"
                    value="MonEntreprise"
                    className="lg:w-[210px] mb-2  sideShadow h-[50px] rounded-[10px] bg-white flex items-center justify-center"
                  />
                  <Tab
                    label="Mes documents"
                    value="MesDocuments"
                    className="lg:w-[210px]  mb-2  sideShadow h-[50px] rounded-[10px] bg-white flex items-center justify-center"
                  />
                  <Tab
                    label="Mes paiements"
                    value="MesPaiements"
                    className="lg:w-[210px] mb-2 sideShadow h-[50px] rounded-[10px] bg-white flex items-center justify-center"
                  />
                </TabList>
              </Box>
              <TabPanel value="MonEntreprise">
                <MonEntreprise />
              </TabPanel>
              <TabPanel value="MesDocuments">
                <MesDocuments />
              </TabPanel>
              <TabPanel value="MesPaiements">
                <MesPaiements />
              </TabPanel>
            </TabContext>
          </div>
        </div>
      </div>
    </Layout>
  );
}
