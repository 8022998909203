export const announceConstants = {
    GETALL_REQUEST: 'ANNOUNCE_GETALL_REQUEST',
    GETALL_SUCCESS: 'ANNOUNCE_GETALL_SUCCESS',
    GETALL_FAILURE: 'ANNOUNCE_GETALL_FAILURE',

    DELETE_REQUEST: 'ANNOUNCE_DELETE_REQUEST',
    DELETE_SUCCESS: 'ANNOUNCE_DELETE_SUCCESS',
    DELETE_FAILURE: 'ANNOUNCE_DELETE_FAILURE' ,
    
    UPDATE_REQUEST: 'ANNOUNCE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ANNOUNCE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ANNOUNCE_UPDATE_FAILURE', 

    CREATE_REQUEST: 'ANNOUNCE_CREATE_REQUEST',
    CREATE_SUCCESS: 'ANNOUNCE_CREATE_SUCCESS',
    CREATE_FAILURE: 'ANNOUNCE_CREATE_FAILURE'
};
