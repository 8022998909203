import React,{useState,useEffect} from 'react'
import { Link,useNavigate,useLocation,Navigate,useParams } from 'react-router-dom'
import Footer from '../component/Footer'
import ToastMessageComp from '../component/ToastMessageComp';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import { alertActions } from '../_actions';

export default function AuthVerify() {
    const [successful, setSuccessful] = useState(false);
    let navigate = useNavigate();
    const location = useLocation();
    const alert = useSelector(state => state.alert);
    const dispatch = useDispatch(); 
    const {email,token} = useParams(); 

    useEffect(()=>{
        
        verifyEmail();

    },[])
    async function verifyEmail() {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}api/auth/verify/${email}/${token}`);
                   
          setSuccessful(true);
          setTimeout(() => {
            if(response.data.sucess === true){
                dispatch(alertActions.success(response.data.msg));
                navigate("/login");  
            }else{
                dispatch(alertActions.error(response.data.msg));
                navigate("/"); 
            }  
          }, 1000);
          

        } catch (error) {
          console.error(error);
          navigate("/");  
        }
    }
  
  /* const currentUser = useSelector(state => state.authentication.user);
  if (currentUser) {
    return <Navigate to="/" />;
  } */
  return (
    <>
      {successful?
      <ToastMessageComp  message={alert.message} type={alert.type} />
      :""}
      
      <Footer />
    </>
  )
}
