import React,{useState,useEffect} from 'react';
import { TextField, Box, FormControl, Select, MenuItem, OutlinedInput, InputAdornment, cardMediaClasses } from '@mui/material'
import { Link } from 'react-router-dom'
import ToastMessageComp from '../component/ToastMessageComp';
import PageLoaderComp from '../component/pageLoaderComp';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions,userActions } from '../_actions';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { userConstants } from '../_constants';
import { formatIBAN, validateIBAN,validateBIC } from '../_helpers';
export default function MesPaiements() {
    const alert = useSelector(state => state.alert);
    const currentUser = useSelector(state => state.authentication.user);
    const {UserBusinessDetails} = useSelector(state => state.authentication.user);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [successfull, setSuccessfull] = useState(false);
    const [ibanError, setIbanError] = useState("");
    const [bicError, setbicError] = useState("");
    const [formData, setFormData] = useState({
        "user_id":currentUser && currentUser.data?currentUser.data.id:"",
        "type":"company",
        "companyArr":{
            "type":"company",
            "entreprise_name":"",
            "adresse":"",
            "statut_juridique":"",
            "userName":"",
            "siret":"",
            "intracommunity_vat":"",
            "naf_code":"",
            "vat":"",
            "created_at":"",
            "updated_at":"",
          
        },
        "documentsArr":{
            "type":"documents",
            "front":"",
            "adresse":"",
            "statut_juridique":"",
            "userName":"",
            "siret":"",
            "intracommunity_vat":"",
            "naf_code":"",
            "vat":"",
            "created_at":"",
            "updated_at":"",
        },
        "paymentsArr":{
            "type":"payments",
            "society_name":"",
            "adresse_bank_acc_holder":"",
            "iban":"",
            "bic":"",
            "created_at":"",
        }
       
    });
    useEffect(() => {
        window.scrollTo(0, 0)        
        if(currentUser && currentUser.data && UserBusinessDetails){
            setFormData({
                "user_id":currentUser.data.id,
                "type":"payments",
                "companyArr":UserBusinessDetails.companyArr,
                "documentsArr":UserBusinessDetails.documentsArr,
                "paymentsArr":UserBusinessDetails.paymentsArr
            });
        }   
    }, [])
    const handleInputChange =async (e) => {
        // console.log("e",e)
        let {name, value} = e.target;
        if(name === "iban"){
            value= formatIBAN(value);
        }
        if(name === "iban" && value.length>0  && !validateIBAN(value)){
            setSubmitted(true);           
            setIbanError("IBAN invalide");
        }else{
            setSubmitted(false); 
            setIbanError("");
        }
        if(name === "bic" && value.length>0  && !validateBIC(value)){
            setSubmitted(true);           
            setbicError("BIC  invalide");
        }else{
            setSubmitted(false); 
            setbicError("");
        }
       
        
        // setIsLoading(true)
        setFormData(prevState => ({
            ...prevState,
            paymentsArr: {
                ...prevState.paymentsArr,
                [name]: value
            }
        }));
    };
    const handleSubmit = (e) => {
        // handleClose()
        setSubmitted(true);  
       
        // console.log("formdata",formData)
        if(formData.paymentsArr.society_name && formData.paymentsArr.adresse_bank_acc_holder  && formData.paymentsArr.iban && validateIBAN(formData.paymentsArr.iban)  && formData.paymentsArr.bic && validateBIC(formData.paymentsArr.bic)){
            setIsLoading(true);            
            fetch(process.env.REACT_APP_API_URL+`api/prouser/user-business-settings`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData),
            })
            .then((res) => res.json())
            .then((response) => {
                if(response.success){
                    dispatch(alertActions.success(response.msg));
                    let UserBusinessDetails = response.UserBusinessDetails;
                    dispatch({ type: userConstants.USER_BUSINESS_SETTINGS, UserBusinessDetails });
                    if(localStorage.getItem('user_pro')!=null){
                        let getLocalUser = JSON.parse(localStorage.getItem('user_pro'));
                        getLocalUser.UserBusinessDetails = UserBusinessDetails;
                        localStorage.setItem('user_pro', JSON.stringify(getLocalUser));
                    }
                }else {
                    dispatch(alertActions.error(response.msg));
                    // navigate(-1)       
                }
                setTimeout(()=>{
                    setIsLoading(false);
                    setSubmitted(false);
                    // setOpen(false);
                },1000)
            });
        }
    };
    return (
        <>
            <h6 className='text-xl font-semibold text-[#414141] mt-8'>Mon compte bancaire</h6>
            <p>Veuillez remplir vos informations bancaires afin de recevoir les gains de vos prestations.</p>
            <Box component="form" className='mt-3 sm:mt-6 3xl:mt-6'
                sx={{
                    "& .MuiInputLabel-root": { color: '#797979', },
                    "& fieldset": {
                        border: '1px solid #eee', borderRadius: '5px',
                        color: '#797979',
                        background: 'rgb(39 41 91 / 6%)',
                    },
                }}
            >
                <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                    <label className='col-span-12 text-base font-medium text-gray mb-1'>Nom de société</label>
                    <div className='col-span-12'>
                        <TextField
                            hiddenLabel
                            className='w-full outline-secondary focus:outline-secondary'
                            name='society_name'
                            value={formData.paymentsArr.society_name}                            
                            onChange={handleInputChange}
                            error={submitted && !formData.paymentsArr.society_name}
                            helperText={submitted && !formData.paymentsArr.society_name?`Le nom de l'entreprise ne doit pas être vide`:""}
                        />
                    </div>
                </div>
                <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                    <label className='col-span-12 text-base font-medium text-gray mb-1'>Adresse du titulaire du compte bancaire</label>
                    <div className='col-span-12'>
                        <TextField
                            hiddenLabel
                            className='w-full outline-secondary focus:outline-secondary'
                            name='adresse_bank_acc_holder'
                            value={formData.paymentsArr.adresse_bank_acc_holder}                            
                            onChange={handleInputChange}
                            error={submitted && !formData.paymentsArr.adresse_bank_acc_holder}
                            helperText={submitted && !formData.paymentsArr.adresse_bank_acc_holder?`Le nom de l'entreprise ne doit pas être vide`:""}
                        />
                    </div>
                </div>
                <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                    <label className='col-span-12 text-base font-medium text-gray mb-1'>IBAN</label>
                    <div className='col-span-12'>
                        <TextField
                            hiddenLabel
                            className='w-full outline-secondary focus:outline-secondary'
                            placeholder='_ _ _ _ I _ _ _ _ I _ _ _ _ I _ _ _ _ I _ _ _ _ I _ _ _ _ I _ _ _'
                            name='iban'
                            value={formData.paymentsArr.iban}                            
                            onChange={handleInputChange}
                            error={submitted && (!formData.paymentsArr.iban || ibanError )}
                            helperText={submitted &&  (!formData.paymentsArr.iban || ibanError )?ibanError?ibanError:`Le nom de l'entreprise ne doit pas être vide`:""}
                        />
                    </div>
                </div>
                <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                    <label className='col-span-12 text-base font-medium text-gray mb-1'>BIC</label>
                    <div className='col-span-12'>
                        <TextField
                            hiddenLabel
                            className='w-full outline-secondary focus:outline-secondary'
                            placeholder='_ _ _ _ _ _ _'
                            name='bic'
                            value={formData.paymentsArr.bic}                            
                            onChange={handleInputChange}
                            error={submitted && (!formData.paymentsArr.bic || bicError )}
                            helperText={submitted &&  (!formData.paymentsArr.bic || bicError )?bicError?bicError:`Le nom de l'entreprise ne doit pas être vide`:""}/* 
                            error={submitted && !formData.paymentsArr.bic}
                            helperText={submitted && !formData.paymentsArr.bic?`Le nom de l'entreprise ne doit pas être vide`:""} */
                        />
                    </div>
                </div>
                {/* <Link to='/' type='submit' className='bg-primary text-base xl:text-xl 3xl:text-xl font-bold text-white  mt-4 sm:mt-4 3xl:mt-10 px-10   py-3   rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white '>Enregistrer</Link> */}
                <LoadingButton
                    className='bg-primary text-base xl:text-xl 3xl:text-xl font-bold text-white  mt-4 sm:mt-4 3xl:mt-10 px-10   py-3   rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white capitalize'
                    size="small"
                    onClick={handleSubmit}
                    endIcon={<SendIcon />}
                    loading={isLoading}
                    loadingPosition="end"
                    variant="contained"                    >
                    <span>Enregistrer</span>
                </LoadingButton>
            </Box>
        </>
    )
}