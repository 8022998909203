import React from 'react'
import logoMastercard from './CardImages/logo-mastercard.png' 
import logoCb from './CardImages/logo-cb 1.png'
import logovisa from './CardImages/logo-visa.png'
import maestro from './CardImages/maestro.png'
import visaElectron from './CardImages/visa-electron.png'
 
export default function PaymentSideBlock() {
  return (
    <div className='mt-7 h-max bg-white rounded-xl w-full px-4 sm:px-5 py-8' style={{ boxShadow: '0px 4px 4px 2px rgba(53, 53, 53, 0.1)' }}>
      <div>
        <div className=' flex gap-3 items-center '>
          <svg width="36" height="44" viewBox="0 0 36 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.9993 0.333252C12.0258 0.333252 7.16602 5.19309 7.16602 11.1666V17.6666H4.99935C3.85008 17.6666 2.74788 18.1231 1.93522 18.9358C1.12256 19.7484 0.666016 20.8506 0.666016 21.9999V39.3332C0.666016 40.4825 1.12256 41.5847 1.93522 42.3974C2.74788 43.21 3.85008 43.6666 4.99935 43.6666H30.9993C32.1486 43.6666 33.2508 43.21 34.0635 42.3974C34.8761 41.5847 35.3327 40.4825 35.3327 39.3332V21.9999C35.3327 20.8506 34.8761 19.7484 34.0635 18.9358C33.2508 18.1231 32.1486 17.6666 30.9993 17.6666H28.8327V11.1666C28.8327 5.19309 23.9728 0.333252 17.9993 0.333252ZM11.4993 11.1666C11.4993 7.58292 14.4157 4.66658 17.9993 4.66658C21.583 4.66658 24.4993 7.58292 24.4993 11.1666V17.6666H11.4993V11.1666ZM20.166 34.3997V39.3332H15.8327V34.3997C15.0752 33.966 14.4669 33.3128 14.0881 32.5264C13.7094 31.7399 13.5778 30.8571 13.7108 29.9944C13.8439 29.1317 14.2352 28.3296 14.8333 27.6938C15.4314 27.058 16.2082 26.6184 17.0612 26.4329C17.6948 26.2928 18.3517 26.2967 18.9835 26.4444C19.6154 26.592 20.2061 26.8796 20.712 27.2859C21.2179 27.6922 21.6262 28.2069 21.9067 28.792C22.1872 29.3771 22.3327 30.0177 22.3327 30.6666C22.3314 31.4244 22.1305 32.1684 21.7501 32.8238C21.3697 33.4792 20.8234 34.0228 20.166 34.3997Z" fill="#1CBE93" />
          </svg>

          <div className=''>
            <p className='text-lg sm:text-xl font-normal text-primary'>Paiement</p>
            <h6 className='text-lg sm:text-xl font-extrabold text-primary'>100 % sécurisé</h6>
          </div>
        </div>
        <div className='mt-2 flex items-center justify-between gap-1 xl:gap-2 py-3'>
          <img src={logovisa} alt="logovisa" className='w-[60px] h-[auto] xl:w-[70px] xl:h-[29px]' />
          <img src={logoMastercard} alt="logo" className='w-[60px] h-[auto] xl:w-[81px] xl:h-[60px]' />
          <img src={maestro} alt="maestro" className='w-[60px] h-[auto] xl:w-[68px] xl:h-[68px]' />
          <img src={logoCb} alt="logoCb" className='w-[60px] h-[auto] xl:w-[60px] xl:h-[33px]' />
          <img src={visaElectron} alt="visaElectron" className='w-[60px] h-[auto] xl:w-[60px] xl:h-[60px]' />
        </div>
      </div>
    </div>
  )
}
