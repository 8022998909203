
import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { ResponsiveChartContainer } from '@mui/x-charts';

const uData = [1, 2, 3, 4, 5, 7, 8, 9, 3, 5, 6, 10,];
const xLabels = [
    'Janv',
    'Févr',
    'Mars',
    'Avr',
    'Mai',
    'Juin',
    'Juil',
    'Août',
    'Sept',
    'Oct',
    'Nov',
    'Déc',
];

export default function DashboardGraph() {
    const valueFormatter = (val) => {
        return val !== null ? val + 'K' : ''
    }
    return (
   
            <BarChart
                borderRadius={20}
                width={1000}
                height={500}
                className='braWidthHeight'
                highlightScope
                series={[
                    { data: uData, color: ['#27295B'] },
                ]}
                xAxis={[{
                    data: xLabels, scaleType: 'band', categoryGapRatio: 0.6,
                    barGapRatio: 0.1,
                    tickFontSize: 16,
                }]}
                yAxis={[{

                    valueFormatter,
                    tickInterval: 1,
                    tickMaxStep: 6,
                    tickLabelStyle: {
                        fontSize: 16
                    },

                }]}

            />
   
    );
}
