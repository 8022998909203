
import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { BsXCircle } from 'react-icons/bs';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers';
import 'dayjs/locale/fr.js';
import { frFR } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';

const Modal = ({ isOpen, onClose, onAddEvent, selectedDate ,setSelectedDate}) => {
  const [title, setTitle] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    if (selectedDate) {
      setEndDate(moment(selectedDate).add(2, 'hours').format('YYYY-MM-DDTHH:mm')); // Example: adds 2 hours for the end date
    }
  }, [selectedDate]);

  const handleAddClick = () => {
    if (!title || !endDate) {
      // Add your validation feedback here
      return;
    }
    onAddEvent({ title, endDate });
    setTitle('');
    setEndDate('');
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={isOpen}>
      <DialogTitle>Ajouter un évènement</DialogTitle>
      <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 4 }}>
        <BsXCircle />
      </IconButton>
      <DialogContent>
        {/* Form inputs for title and end date */}
        <div  >
          <div className="grid grid-cols-12 items-center " >
            <label className="col-span-12 text-base font-medium text-gray mb-4">Titre:</label>
            <div className="col-span-12">
              <TextField
                // margin="dense"
                // id="title"
                // label="Event Title"
                // type="text"
                // fullWidth
                // variant="outlined"
                hiddenLabel
                placeholder='Veuillez saisir vos événements.'
                style={{  background: '#F4F4F4' , width: '100%'}}
                id="outlined-size-small"
                size="small"
                className="outline-secondary focus:outline-secondary w-full"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </div>
          <div className="grid grid-cols-12 items-center" style={{marginTop: '20px'}}>
            <label className="col-span-12 text-base font-medium text-gray mb-4">Date de fin:</label>
            <div className="col-span-12">
              {/* <TextField
                hiddenLabel
                style={{ width: '500px', background: '#F4F4F4' }}
                id="outlined-size-small"
                size="small"
                className="outline-secondary focus:outline-secondary"
                type="datetime-local"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              /> */}
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText} >
                <DemoContainer components={['DateTimePicker', 'DateTimePicker']}  >
                  <DateTimePicker
                   style={{  background: '#F4F4F4' }}  className="outline-secondary focus:outline-secondary"
                    // label="Controlled picker"
                    onChange={(newValue) =>{
                       setEndDate(newValue)
                       setSelectedDate(moment(newValue.$d).format('YYYY-MM-DDTHH:mm'))
                      }
                    }
                    value={dayjs(endDate)}
                    slotProps={{ textField: { size: 'small', width: '100%', minWidth: 'auto' } }}
                  />
                  {/* <DatePicker slotProps={{ textField: { size: 'small' } }}  format={"DD/MM/YYYY"} value={dayjs(props.formData.date_acte)}   name="date_acte"   onChange={props.handleChangeDate}  /> */}
                </DemoContainer>
              </LocalizationProvider>
              {/* <TextField
                hiddenLabel
                style={{ width: '500px', background: '#F4F4F4' }}
                id="outlined-size-small"
                size="small"
                className="outline-secondary focus:outline-secondary"
                type="datetime-local"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              /> */}
            </div>
          </div>

          <button
            autoFocus
            onClick={handleAddClick}
            type="button"
            className="font-semibold p-4 bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white mt-4 sm:mt-4 3xl:mt-10 px-10 py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white"
            style={{
              backgroundColor: '#27295B',
              color: '#FFFFFF',
              padding: '10px',
              font: 'bold',
              fontWeight: 'bold',
              paddingLeft: '30px',
              paddingRight: '30px',
              borderRadius: '10px',
              float: 'right',
              marginTop: "10px",
            }}
          >
            Ajouter un évènement
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddEvent: PropTypes.func.isRequired,
  selectedDate: PropTypes.string,
};

export default Modal;
