import React,  { useState, useEffect } from 'react';
import { FormControl, TextField, FormControlLabel, Radio,Checkbox } from '@mui/material'
import { BsPlusCircle,BsDashCircle  } from 'react-icons/bs'
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom'
import Footer from './Footer';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from "react-router-dom";
import { useLocation,useParams,useNavigate  } from 'react-router-dom';
import axios from "axios";
import no_image from "../assets/no_image.png";
import PageLoaderComp from './pageLoaderComp';
import ToastMessageComp from './ToastMessageComp';
import { alertActions } from '../_actions';

export default function ClaimProAvocatsComp() {
  const alert = useSelector(state => state.alert);
  const dispatch = useDispatch(); 
  const navigate = useNavigate(); 
  const [successful, setSuccessful] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const {type,name, id } = useParams();
  const HorairesData = [
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
  ]
  const [loadingFullpage, setLoadingFullpage] = useState(false);
  const [formData, setFormData] = useState({
    documentId:"",
    avocats_id:id?id:"",
    id:"",
    cbRaisonSociale:"",
    avNom:"",
    avPrenom:"",
    cbAdresse1:"",
    cbAdresse2:"",
    cbVille:"",
    telephone:"",
    email:"",
    people_data:"",
    people_dataArr:[{"name":""}],
    cbCp:"",
    presentation:``,
    professions:"",
    professionsArr:[""],
    skills:"",
    skillsArr:[""],
    activity_area:"",
    activity_areaArr:[""],
    avLang:"",
    languagesArr:[],
    certifications:"",
    certificationsArr:[{certifications_name:"",certifications_number:"",}],    
    youtube_url:"",
    website_url:"",
    openinghoursspecification:"",
    openinghoursspecificationArr:[
      {
        "dayName":"Lundi",
        "openingHours_mrg":{"type":"Matin","time":"9:00 - 18:00","checked":false},
        "openingHours_evg":{"type":"Après-midi","time":"9:00 - 18:00","checked":false}
      },{
        "dayName":"Mardi",
        "openingHours_mrg":{"type":"Matin","time":"9:00 - 18:00","checked":false},
        "openingHours_evg":{"type":"Après-midi","time":"9:00 - 18:00","checked":false}
      },{
        "dayName":"Mercredi",
        "openingHours_mrg":{"type":"Matin","time":"9:00 - 18:00","checked":false},
        "openingHours_evg":{"type":"Après-midi","time":"9:00 - 18:00","checked":false}
      },{
        "dayName":"Jeudi",
        "openingHours_mrg":{"type":"Matin","time":"9:00 - 18:00","checked":false},
        "openingHours_evg":{"type":"Après-midi","time":"9:00 - 18:00","checked":false}
      },{
        "dayName":"Vendredi",
        "openingHours_mrg":{"type":"Matin","time":"9:00 - 18:00","checked":false},
        "openingHours_evg":{"type":"Après-midi","time":"9:00 - 18:00","checked":false}
      },{
        "dayName":"Samedi",
        "openingHours_mrg":{"type":"Matin","time":"9:00 - 18:00","checked":false},
        "openingHours_evg":{"type":"Après-midi","time":"9:00 - 18:00","checked":false}
      }
    ],
    linkedin_url:"",
    viadeo_url:"",
    logo:"",
    oldLogo:[""],
    logo_url:"",
    term_condition:false,
    type:type
  });
  const [responseData, setResponseData] = useState(null);
  const [selectedImage, setSelectedImage] = useState('');
  const currentUser = useSelector(state => state.authentication.user);
  const location = useLocation();
  useEffect(() => {
    if(alert){
      // setSuccessful(true)   

    }else{
      // setSuccessful(false)   
    }
    
  }, [alert]);
  useEffect(() => {
    setLoadingFullpage(true);
    (async () => {      
      await getDirectoryDetails();
      // Use the data for something
    })().catch(console.error); // Make sure to catch any errors    
    
  }, [id]);
  const getDirectoryDetails=async()=>{
    let URL = process.env.REACT_APP_API_URL +"api/admin/get-details-avocats/"+id;
  
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: URL,
      headers: { 
        // 'Authorization': `Bearer ${process.env.REACT_APP_ELK_SEARCH_API_KEY}`,
        // 'Accept': '*/*', 
        // "Cache-Control":"no-cache",
        'Content-Type': 'application/json', 
      },
      // data : data
    };

  axios
    .request(config)
    .then((response) => {
        if((response.data.data).length>0){
            response.data.data.map((valres,i)=>{  
                let val = valres._source;
                // let logoGet = val.logo?JSON.parse(val.logo):""
                let logoGet ="";
                
                let languagesArr = [];
                
                if(val.languages){
                  languagesArr.push((val.languages).split(","))
                }
                val.languagesArr = languagesArr;

                val.people_dataArr =[{"name":""}];
                if(val.people_data){
                    console.log("isJSONString(val.people_data)",isValidJSONString(val.people_data))
                    // let people_data_string = JSON.stringify(val.people_data);
                    // console.log("val.people_data",people_data_string)
                    /* if(isJSONString(val.people_data)){
                        val.people_dataArr = JSON.parse(val.people_data);
                    }else{
                        val.people_dataArr = JSON.parse('`'+val.people_data+'`');
                    } */
                    
                }
                if(val.openinghoursspecification){
                    // console.log("openinghoursspecification",val.openinghoursspecification)
                    // let openingHoursArrGetData = val.openinghoursspecification;
                    /* let openingHoursArrGet = JSON.parse(openingHoursArrGetData);
  
                    openingHoursArrGet.map((valArr,keyArr)=>{
                      valArr.dayOfWeek.map((valWeek,keyWeek)=>{
                        let findIndex = openingHoursArr.findIndex(dayval=>dayval.dayName==valWeek);
                        if(findIndex >=0){
                          let openingHoursArr_get = openingHoursArr[findIndex];
                          let openingHoursGet = openingHoursArr_get.openingHours;
                          let openingHoursGetArr = openingHoursGet.split(",");
                          openingHoursGetArr.push(valArr.opens+"- "+valArr.closes);
                          openingHoursArr[findIndex].openingHours = openingHoursGetArr.join(",")
                        }                        
                      })
                      
                    }) */
                }  
                
                setFormData({
                    documentId:valres._id,
                    avocats_id:id?id:"",
                    id:val.avocats_id,
                    cbRaisonSociale:val.cbRaisonSociale,
                    avNom:val.avNom,
                    avPrenom:val.avPrenom,
                    cbAdresse1:val.cbAdresse1,
                    cbAdresse2:val.cbAdresse2,
                    cbVille:val.cbVille,
                    telephone:val.telephone?val.telephone:"",
                    email:val.email?val.email:"",
                    people_data:val.people_data ?val.people_data:"",
                    people_dataArr:val.people_data && isValidJSONString(val.people_data)?JSON.parse(val.people_data):[{"name":""}],
                    cbCp:val.cbCp,
                    presentation:val.presentation?val.presentation:``,
                    professions:val.professions?val.professions:"",
                    professionsArr:val.professions?val.professions.split(","):[""],
                    skills:val.skills?val.skills:"",
                    skillsArr:val.skills?val.skills.split(","):[""],
                    activity_area:val.activity_area?val.activity_area:"",
                    activity_areaArr:val.activity_area?val.activity_area.split(","):[""],
                    avLang:val.avLang?val.avLang:"",
                    languagesArr:val.avLang?val.avLang.split(","):[""],
                    certifications:val.certifications?val.certifications:"",
                    certificationsArr:val.certifications?JSON.parse(val.certifications):[{certifications_name:"",certifications_number:""}],
                    youtube_url:val.youtube_url?val.youtube_url:"",
                    website_url:val.website_url?val.website_url:"",
                    openinghoursspecification:val.openinghoursspecification,
                    openinghoursspecificationArr:val.openinghoursspecification?JSON.parse(val.openinghoursspecification):[
                      {
                        "dayName":"Lundi",
                        "openingHours_mrg":{"type":"Matin","time":"9:00 - 18:00","checked":false},
                        "openingHours_evg":{"type":"Après-midi","time":"9:00 - 18:00","checked":false}
                      },{
                        "dayName":"Mardi",
                        "openingHours_mrg":{"type":"Matin","time":"9:00 - 18:00","checked":false},
                        "openingHours_evg":{"type":"Après-midi","time":"9:00 - 18:00","checked":false}
                      },{
                        "dayName":"Mercredi",
                        "openingHours_mrg":{"type":"Matin","time":"9:00 - 18:00","checked":false},
                        "openingHours_evg":{"type":"Après-midi","time":"9:00 - 18:00","checked":false}
                      },{
                        "dayName":"Jeudi",
                        "openingHours_mrg":{"type":"Matin","time":"9:00 - 18:00","checked":false},
                        "openingHours_evg":{"type":"Après-midi","time":"9:00 - 18:00","checked":false}
                      },{
                        "dayName":"Vendredi",
                        "openingHours_mrg":{"type":"Matin","time":"9:00 - 18:00","checked":false},
                        "openingHours_evg":{"type":"Après-midi","time":"9:00 - 18:00","checked":false}
                      },{
                        "dayName":"Samedi",
                        "openingHours_mrg":{"type":"Matin","time":"9:00 - 18:00","checked":false},
                        "openingHours_evg":{"type":"Après-midi","time":"9:00 - 18:00","checked":false}
                      }
                    ],
                    linkedin_url:val.linkedin_url?val.linkedin_url:"",
                    viadeo_url:val.viadeo_url?val.viadeo_url:"",
                    logo:"",
                    oldLogo:[""],
                    logoArr:[""],
                    logo_url:logoGet?logoGet[0]:"",
                    term_condition:false,
                    type:type
                })
                // console.log(decodedString);
                return val;
            })
           
            setResponseData(response.data.data[0]._source);
        }else{
            setResponseData(null);
        }
        
      setLoadingFullpage(false);
    })
    .catch((error) => {
      setLoadingFullpage(false);
      console.log(error);
    });
  }
  const addrRemove = (name,type,indexToRemove)=>{
    let formDataGet = {...formData}
      console.log("index",indexToRemove)
      switch(name) {
        case 'certifications':
            let certificationsArrGet = formDataGet.certificationsArr ;
            if(type ==="add"){
                certificationsArrGet.push({certifications_name:"",certifications_number:""});
                setFormData({...formData,certificationsArr:certificationsArrGet})
            }
            if(type ==="remove"){
                let certificationsArrRemoved = certificationsArrGet.filter((_, index) => index !== indexToRemove)               
                setFormData({...formData,certificationsArr:certificationsArrRemoved})            
            }
            break;
        case 'languages':
           let languagesGet = formDataGet.languagesArr;            
            if(type ==="add"){
                languagesGet.push("");
                console.log("languagesGet",languagesGet)
                setFormData({...formData,languagesArr:languagesGet}) 
            }
            if(type ==="remove"){
                let languagesGetArrRemoved = languagesGet.filter((_, index) => index !== indexToRemove)               
                setFormData({...formData,languagesArr:languagesGetArrRemoved})            
            }  
            break;
        case 'activity_area':
            let activity_areaArrget = formDataGet.activity_areaArr;            
            if(type ==="add"){
                activity_areaArrget.push("");
                console.log("activity_areaArrget",activity_areaArrget)
                setFormData({...formData,activity_areaArr:activity_areaArrget}) 
            }
            if(type ==="remove"){
                let activity_areaArrgetRemoved = activity_areaArrget.filter((_, index) => index !== indexToRemove)               
                setFormData({...formData,activity_areaArr:activity_areaArrgetRemoved})            
            } 
            break;
        case 'skills':
             let skillsArrget = formDataGet.skillsArr;            
            if(type ==="add"){
                skillsArrget.push("");
                console.log("skillsArrget",skillsArrget)
                setFormData({...formData,skillsArr:skillsArrget}) 
            }
            if(type ==="remove"){
                let skillsArrgetRemoved = skillsArrget.filter((_, index) => index !== indexToRemove)               
                setFormData({...formData,skillsArr:skillsArrgetRemoved})            
            } 
            break;
        case 'professions':
             let professionsArrget = formDataGet.professionsArr;            
            if(type ==="add"){
                professionsArrget.push("");
                console.log("professionsArrget",professionsArrget)
                setFormData({...formData,professionsArr:professionsArrget}) 
            }
            if(type ==="remove"){
                let professionsArrgetRemoved = professionsArrget.filter((_, index) => index !== indexToRemove)               
                setFormData({...formData,professionsArr:professionsArrgetRemoved})            
            } 
            break;
        case 'people_data':
          let people_dataArrget = formDataGet.people_dataArr;            
          if(type ==="add"){
              people_dataArrget.push({"name":""});
              setFormData({...formData,people_dataArr:people_dataArrget}) 
          }
          if(type ==="remove"){
              let people_dataArrgetRemoved = people_dataArrget.filter((_, index) => index !== indexToRemove)               
              setFormData({...formData,people_dataArr:people_dataArrgetRemoved})            
          } 
          break;
        default:
            break;
        }
       
  }
  /* if (!currentUser) {
    return <Navigate to="/" />;
  }
  console.log(currentUser?.data,"currentUsercurrentUser") */


  // Assume user object is passed as a prop to this component
  // Use state to manage the locally selected image for preview
 
  function isValidJSONString(str) {
    try {
      const parsedData = JSON.parse(str);
      // The data was parsed successfully, but let's ensure it's not a primitive value
      // since JSON.parse can also successfully parse JSON strings containing only numbers, strings, booleans, or null.
      if (parsedData && typeof parsedData === "object") {
        return true; // It's a valid JSON string and it represents an object or an array
      }
      return false; // It's a primitive value, not a JSON object or array
    } catch (e) {
      return false; // It failed to parse, so it's not a valid JSON string
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log("file",file)
    if (file) {
      // Update local state for image preview
      setSelectedImage(URL.createObjectURL(file));
      setFormData({...formData,logo:file,logo_url:URL.createObjectURL(file)})
      
      // Prepare file for upload (not shown here)
    }
  };
  console.log("formData",formData)
  const handleChange = (e) => {
    // console.log("e",e)
    let {name,value} = e.target;
    if(name === "term_condition"){
      setFormData({...formData,[name]:e.target.checked})
    }else{
      setFormData({...formData,[name]:value})
    }
    
  };
  
  const handleChangeOpeningHours = (e,item,index) => {
    console.log("handleChangeOpeningHours",e)
    console.log("item",item )
    console.log("index",index)
    let {name,value} = e.target;
    let openinghoursspecificationArrGet = formData.openinghoursspecificationArr;
    let findIndex = openinghoursspecificationArrGet.findIndex(dayval=>dayval.dayName===item);
    if(name === "mrngtype" && findIndex>=0){
      openinghoursspecificationArrGet[findIndex].openingHours_mrg.checked = e.target.checked;
      
    }
    if(name === "evetype" && findIndex>=0){
      openinghoursspecificationArrGet[findIndex].openingHours_evg.checked = e.target.checked;
      
    }
    if(name === "openingHours_mrg" && findIndex>=0){
      openinghoursspecificationArrGet[findIndex].openingHours_mrg.time = value;
      
    }
    if(name === "openingHours_evg" && findIndex>=0){
      openinghoursspecificationArrGet[findIndex].openingHours_evg.time = value;
      
    }
    setFormData({...formData,"openinghoursspecificationArr":openinghoursspecificationArrGet})
    
  }
  const handleChangeMultiple = (e,indexToUpdate) => {
    
    let {name,value} = e.target;
    let formDataGet = {...formData}
    console.log("index",indexToUpdate)
    switch(name) {
      case 'certifications_name':
          formDataGet.certificationsArr[indexToUpdate].certifications_name =value;
          setFormData(formDataGet);
          break;
      case 'certifications_number':
          formDataGet.certificationsArr[indexToUpdate].certifications_number =value;
          setFormData(formDataGet);
          break;
      case 'languages':         
          formDataGet.languagesArr[indexToUpdate] =value;
          setFormData(formDataGet);
          break;
      case 'activity_area':
          // let activity_areaArrget = formDataGet.activity_areaArr;            
          formDataGet.activity_areaArr[indexToUpdate] =value;
          setFormData(formDataGet);
          break;
      case 'skills':
          //  let skillsArrget = formDataGet.skillsArr;     
          formDataGet.skillsArr[indexToUpdate] =value;
          setFormData(formDataGet);       
          
          break;
      case 'professions':
          //  let professionsArrget = formDataGet.professionsArr;  
          formDataGet.professionsArr[indexToUpdate] =value;
          setFormData(formDataGet);            
          
          break;
      case 'people_data':
        // let people_dataArrget = formDataGet.people_dataArr;            
        formDataGet.people_dataArr[indexToUpdate].name =value;
        setFormData(formDataGet);   
        break;
      default:
          break;
    }
   
  };
  function objectToFormData(obj, form, namespace) {
    const formData = form || new FormData();
    let formKey;

    for(let key in obj) {
        if(obj.hasOwnProperty(key)) {
            if(namespace) {
                formKey = `${namespace}[${key}]`;
            } else {
                formKey = key;
            }

            if (typeof obj[key] === 'object' && !(obj[key] instanceof File)) {
                // For nested objects and arrays, recurse.
                objectToFormData(obj[key], formData, formKey);
            } else {
                // Append the value with the appropriate key name, accounting for arrays and objects
                formData.append(formKey, obj[key]);
            }
        }
    }
    return formData;
}

  const handleSubmit = (e) => {
    // setSuccessful(false)
    if(!formData.term_condition){
      setSuccessful(true)
      dispatch(alertActions.error("Veuillez accepter les termes et conditions"));
      // setSuccessful(false)
      return false;
    }
    setLoadingFullpage(true);
    console.log("formData",formData)
    let URL = process.env.REACT_APP_API_URL +"api/admin/update-details-accountant";
    // let URL = process.env.REACT_APP_API_URL +"api/admin/update-details-accountant-local";
    const formDataSend = objectToFormData(formData);
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: URL,
      headers: { 
        // 'Authorization': `Bearer ${process.env.REACT_APP_ELK_SEARCH_API_KEY}`,
        // 'Accept': '*/*', 
        // "Cache-Control":"no-cache",
        // 'Content-Type': 'application/json', 
        'Content-Type': 'multipart/form-data'
      },
      data : formDataSend
    };

  axios
    .request(config)
    .then((response) => {
      console.log("response",response)   
      setLoadingFullpage(false);
      setSuccessful(true) 
      if(response.data.sucess){
        
       dispatch(alertActions.success(response.data.msg));
       setTimeout(() => {
        navigate(`/fiche-avocats/${name}/${id}`)
       }, 1000);
       

      }else{
        dispatch(alertActions.error(`Une erreur s'est produite. Veuillez réessayer!`));
      }      
      // setSuccessful(false)
    })
    .catch((error) => {
      setLoadingFullpage(false);
      console.log(error);
    });
    /* setSubmitted(true);
    setIsLoading(true);            
    fetch(process.env.REACT_APP_API_URL+`api/announce/create_message`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(formData),
    })
    .then((res) => res.json())
    .then((data) => {
        setTimeout(()=>{
            setIsLoading(false);
            setSubmitted(false);
            setOpen(false);
            props.setIsOpen(false);
        },1000)
    }); */
  };
  

  

  return (
    <>
      {loadingFullpage?
      <PageLoaderComp />
      :""}
      {successful ?
        <ToastMessageComp message={alert.message} type={alert.type} />
      : ""}
      <div className='bg-mainBg h-full w-full'>
        <div className='lg:max-w-[812px] relative w-full h-full m-auto py-4 sm:py-8 px-4 sm:px-14'>
          <div className='rounded-[10px] h-[103px] flex items-center justify-start px-4 sm:px-8' style={{ background: 'linear-gradient(90.26deg, #1CBE93 0%, #602DB4 100%)' }} >
            <h2 className='text-xl md:text-[25px] font-black text-white '>Bienvenue sur Busyplace pro !</h2>
          </div>
          <p className='text-base font-normal text-gray my-4 sm:my-8 max-w-[748px]'>Complétez votre profil entreprise pour être référencé dans notre annuaire des professionnels de votre secteur d’activité.</p>

          <div className='rounded-[10px] border border-[#DEE3E2] bg-white py-6 px-4 sm:px-8'>
            <h3 className='text-xl sm:text-[23px] font-extrabold text-primary'>Société</h3>

            <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
              <label className='col-span-12 text-base font-medium text-gray mb-1'>Nom de la société</label>
              <div className='col-span-12'>
                <TextField
                  hiddenLabel
                  id="outlined-size-small"
                  size="small"
                  className='w-full outline-secondary focus:outline-secondary'
                  name='cbRaisonSociale'
                  value={formData.cbRaisonSociale}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
              <label className='col-span-12 text-base font-medium text-gray mb-1'>Adresse</label>
              <div className='col-span-12'>
                <TextField
                  hiddenLabel
                  id="outlined-size-small"
                  size="small"
                  className='w-full outline-secondary focus:outline-secondary'
                  name="cbAdresse1"
                  onChange={handleChange}
                  value={formData.cbAdresse1}
                />
              </div>
            </div>
            <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
              <label className='col-span-12 text-base font-medium text-gray mb-1'>Complément d’adresse</label>
              <div className='col-span-12'>
                <TextField
                  hiddenLabel
                  id="outlined-size-small"
                  size="small"
                  className='w-full outline-secondary focus:outline-secondary'
                  value={formData.cbAdresse2}
                  name="cbAdresse2"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='grid grid-cols-12 sm:gap-8 items-center '>
              <div className='col-span-12 sm:col-span-4 sm:grid sm:grid-cols-12 items-cente mt-3 sm:mt-6'>
                <label className='col-span-12 text-base font-medium text-gray mb-1'>Code postal</label>
                <div className='col-span-12'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    className='w-full outline-secondary focus:outline-secondary'
                    value={formData.cbCp}
                    name="cbCp"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='col-span-12 sm:col-span-8 grid grid-cols-12 items-center mt-3 sm:mt-6'>
                <label className='col-span-12 text-base font-medium text-gray mb-1'>Ville</label>
                <div className='col-span-12'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    className='w-full outline-secondary focus:outline-secondary'
                    value={formData.cbVille}
                    name="cbVille"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className='grid grid-cols-12 sm:gap-8 items-center mt-3 sm:mt-6'>
              <div className='col-span-12 sm:col-span-9 sm:grid sm:grid-cols-12 items-cente'>
                <label className='col-span-12 text-base font-medium text-gray mb-1'>Téléphone <span className='font-light'>(affiché pour la prise de contact)</span> </label>
                <div className='col-span-5'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    className='w-full outline-secondary focus:outline-secondary'
                    value={formData.telephone}
                    name="telephone"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className='grid grid-cols-12 sm:gap-8 items-center mt-3 sm:mt-6'>
              <div className='col-span-12 sm:col-span-9 sm:grid sm:grid-cols-12 items-cente'>
                <label className='col-span-12 text-base font-medium text-gray mb-1'>E-mail <span className='font-light'>(adresse e-mail de réception des messages du site Busyplace)</span> </label>
                <div className='col-span-8'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    className='w-full outline-secondary focus:outline-secondary'
                    value={formData.email}
                    name="email"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            

            <div className='grid grid-cols-12 sm:gap-8 items-center mt-3 sm:mt-6'>
                <div className='col-span-12 sm:col-span-9  items-cente'>
                    <label className='col-span-12 text-base font-medium text-gray mb-1'>Prénom et Nom du Dirigeant</label>
                   
                    {formData.people_dataArr.map((val,people_data_i)=>{
                        return (
                        <div className='grid grid-cols-12 items-start mt-2 w-full'>
                          <div className='col-span-12 sm:col-span-9 md:col-span-9 inline-flex items-center gap-2'>
                            <TextField
                                variant="outlined"
                                size="small"
                                className='w-full outline-secondary focus:outline-secondary'
                                name='people_data'
                                value={val.name}
                                onChange={(e)=>handleChangeMultiple(e,people_data_i)}
                            />{
                              people_data_i !== 0? <BsDashCircle className='text-3xl ' onClick={()=>addrRemove("people_data",'remove',people_data_i)}  />:""}
                          </div>
                        </div>)
                    })}                   
               
              </div>
            </div>

            <div className=' group flex items-center gap-2 text-primary hover:text-secondary mt-6 cursor-pointer ' onClick={()=>addrRemove("people_data",'add',0)} >
              <BsPlusCircle className='text-3xl ' />
              <span className='text-sm font-normal'>Ajouter un Dirigeant</span>
            </div>
          </div>
          <div className='rounded-[10px] border border-[#DEE3E2] bg-white py-6  px-4 sm:px-8 mt-3'>
            <h3 className='text-xl sm:text-[23px] font-extrabold text-primary'>Présentation</h3>
            <div className='grid grid-cols-12 items-start mt-2'>
              <div className='col-span-12'>
                <TextField
                  hiddenLabel
                  multiline
                  minRows={4}
                  className='w-full'
                  placeholder='Décrivez l’activité de votre entreprise (compétences, caractéristiques, secteur d’activités...)'
                  name='presentation'
                  onChange={handleChange} 
                  value={formData.presentation}
                />

              </div>
            </div>
          </div>

          <div className='rounded-[10px] border border-[#DEE3E2] bg-white py-6  px-4 sm:px-8 mt-3'>
            <h3 className='text-xl sm:text-[23px] font-extrabold text-primary'>Métier</h3>
            {formData.professionsArr.map((professionval,profession_i)=>{   
                return (     
                <div className='grid grid-cols-12 items-start mt-2'>
                <div className='col-span-12 sm:col-span-9 md:col-span-9 inline-flex items-center gap-2'>
                    <TextField
                    label="Saisissez un métier" 
                    variant="outlined"
                    size="small"
                    className='w-full outline-secondary focus:outline-secondary'
                    name='professions'
                    onChange={(e)=>handleChangeMultiple(e,profession_i)}
                    value={professionval}
                    />
                     {profession_i===0?<BsPlusCircle className='text-3xl' onClick={()=>addrRemove("professions",'add',profession_i)} />:<BsDashCircle className='text-3xl ' onClick={()=>addrRemove("professions",'remove',profession_i)}  />}  
                </div>
                </div>
                )
             })}       
            
          </div>

          <div className='rounded-[10px] border border-[#DEE3E2] bg-white py-6  px-4 sm:px-8 mt-3'>
            <h3 className='text-xl sm:text-[23px] font-extrabold text-primary'>Compétences</h3>
            {formData.skillsArr.map((skillsval,skills_i)=>{   
                return (     
                <div className='grid grid-cols-12 items-start mt-2'>
                <div className='col-span-12 sm:col-span-9 md:col-span-9 inline-flex items-center gap-2'>
                    <TextField
                    label="Saisissez une compétence" 
                    variant="outlined"
                    size="small"
                    className='w-full outline-secondary focus:outline-secondary'
                    name='skills'
                    onChange={(e)=>handleChangeMultiple(e,skills_i)} 
                    value={skillsval}
                    />
                     {skills_i===0?<BsPlusCircle className='text-3xl' onClick={()=>addrRemove("skills",'add',skills_i)} />:<BsDashCircle className='text-3xl ' onClick={()=>addrRemove("skills",'remove',skills_i)}  />}  
                </div>
                </div>
                )
             })}   
        
          </div>

          <div className='rounded-[10px] border border-[#DEE3E2] bg-white py-6 px-4 sm:px-8 mt-3'>
            <h3 className='text-xl sm:text-[23px] font-extrabold text-primary'>Secteurs d’activité</h3>
            {formData.activity_areaArr.map((activity_areaval,activity_area_i)=>{   
                return (     
                <div className='grid grid-cols-12 items-start mt-2'>
                <div className='col-span-12 sm:col-span-9 md:col-span-9 inline-flex items-center gap-2'>
                    <TextField
                      label="Saisissez un secteur" variant="outlined"
                      size="small"
                      className='w-full outline-secondary focus:outline-secondary'
                      name='activity_area'
                      onChange={(e)=>handleChangeMultiple(e,activity_area_i)} 
                      value={activity_areaval}
                    />
                     {activity_area_i===0?<BsPlusCircle className='text-3xl' onClick={()=>addrRemove("activity_area",'add',activity_area_i)} />:<BsDashCircle className='text-3xl ' onClick={()=>addrRemove("activity_area",'remove',activity_area_i)}  />}  
                </div>
                </div>
                )
             })}
          </div>


          <div className='rounded-[10px] border border-[#DEE3E2] bg-white py-6 px-4 sm:px-8 mt-3'>
            <h3 className='text-xl sm:text-[23px] font-extrabold text-primary'>Langues</h3>              
                {formData.languagesArr.map((languagesval,languages_i)=>{
                    return (
                        <div className='grid grid-cols-12 items-start mt-2'>
                            <div className='col-span-12 sm:col-span-9 md:col-span-9 inline-flex items-center gap-2'>
                                <TextField
                                    label="Saisissez une langue" variant="outlined"
                                    size="small"
                                    className='w-full outline-secondary focus:outline-secondary'
                                    value={languagesval}
                                    name='languages'   
                                    onChange={(e)=>handleChangeMultiple(e,languages_i)}                  
                                />
                                {languages_i===0?<BsPlusCircle className='text-3xl' onClick={()=>addrRemove("languages",'add',languages_i)} />:<BsDashCircle className='text-3xl ' onClick={()=>addrRemove("languages",'remove',languages_i)}  />}  
                            </div>
                        </div>
                    )
                })}
          </div>

          <div className='rounded-[10px] border border-[#DEE3E2] bg-white py-6 px-4 sm:px-8 mt-3'>
            <h3 className='text-xl sm:text-[23px] font-extrabold text-primary'>Certifications</h3>
            {
                formData.certificationsArr.map((certifications,certificationsIndex)=>{
                    return (
                        <div className='grid grid-cols-12 items-start mt-2 gap-4'>
                            <div className='col-span-7 grid grid-cols-12 items-start mt-2'>
                                <div className='col-span-12 inline-flex items-center gap-2'>
                                <TextField
                                    label="Nom de la certification" 
                                    variant="outlined"
                                    size="small"
                                    className='w-full outline-secondary focus:outline-secondary'
                                    name='certifications_name'
                                    onChange={(e)=>handleChangeMultiple(e,certificationsIndex)}
                                    value={certifications.certifications_name}
                                />
                                </div>
                            </div>
                            <div className='col-span-5 grid grid-cols-12 items-start mt-2'>
                                <div className='col-span-12  inline-flex items-center gap-2'>
                                <TextField
                                    label="Numéro de certification" variant="outlined"
                                    size="small"
                                    className='w-full outline-secondary focus:outline-secondary'
                                    name='certifications_number'
                                    onChange={(e)=>handleChangeMultiple(e,certificationsIndex)}
                                    value={certifications.certifications_number}
                                />
                                {certificationsIndex===0?<BsPlusCircle className='text-3xl' onClick={()=>addrRemove("certifications",'add',certificationsIndex)} />:<BsDashCircle className='text-3xl ' onClick={()=>addrRemove("certifications",'remove',certificationsIndex)}  />}                                
                                
                                </div>
                            </div>
                        </div>
                    )
                })
            }
          </div>

          <div className='rounded-[10px] border border-[#DEE3E2] bg-white py-6 px-4 sm:px-8 mt-3'>
            <h3 className='text-xl sm:text-[23px] font-extrabold text-primary mb-8'>Horaires d’ouverture</h3>
            {
              HorairesData.map((item, index) => {                  
                    let mngHours = "9:00 - 18:00";
                    let eveHours = "9:00 - 18:00";
                    let checkedStatusMrng = false;
                    let checkedStatusEve = false;
                    if(formData && formData.openinghoursspecificationArr){
                        let findIndex = formData.openinghoursspecificationArr.findIndex(dayval=>dayval.dayName===item);/* 
                        let findIndex = responseData.openingHoursArr.findIndex(dayval=>dayval.dayName===item) */
                        if(findIndex>=0){
                          mngHours = formData.openinghoursspecificationArr[findIndex].openingHours_mrg.time;
                          eveHours = formData.openinghoursspecificationArr[findIndex].openingHours_evg.time;
                          checkedStatusMrng = formData.openinghoursspecificationArr[findIndex].openingHours_mrg.checked;
                          checkedStatusEve = formData.openinghoursspecificationArr[findIndex].openingHours_evg.checked;
                        }                    
                    }
                return (
                  <div key={index} className='flex flex-col md:flex-row md:items-center sm:gap-4 my-6 sm:my-2'>
                    <h6 className='min-w-[100px] text-base font-medium text-primary'>{item}</h6>
                    <div className='flex flex-col sm:flex-row sm:items-center justify-between sm:gap-4'>
                      <div className='flex sm:items-center justify-between sm:justify-center'>
                        <FormControl>
                          <FormControlLabel  control={<Checkbox checked={checkedStatusMrng} name='mrngtype' onChange={(e)=>handleChangeOpeningHours(e,item,index)}/>} label="Matin" className='text-sm font-normal text-gray' />
                        </FormControl>
                        <TextField
                          // label="00:00 - 00:00" 
                          variant="outlined"
                          size="small"
                          className='w-full max-w-[133px] outline-secondary focus:outline-secondary'
                          value={mngHours}
                          name="openingHours_mrg"
                          onChange={(e)=>handleChangeOpeningHours(e,item,index)}
                        />
                      </div>
                      <div className='flex sm:items-center justify-between sm:justify-center '>
                        <FormControl>
                          <FormControlLabel  control={<Checkbox  checked={checkedStatusEve} name='evetype' onChange={(e)=>handleChangeOpeningHours(e,item,index)}/>} label="Après-midi"
                            className='text-sm font-normal text-gray' />
                        </FormControl>
                        <TextField
                            value={eveHours}
                            name="openingHours_evg"
                            onChange={(e)=>handleChangeOpeningHours(e,item,index)}
                            // label="00:00 - 00:00" 
                            variant="outlined"
                            size="small"
                            className='w-full max-w-[133px] outline-secondary focus:outline-secondary'
                        />
                      </div>
                    </div>
                  </div>
                )
              })
            }

          </div>

          <div className='rounded-[10px] border border-[#DEE3E2] bg-white py-6  px-4 sm:px-8 mt-3'>
            <h3 className='text-xl sm:text-[23px] font-extrabold text-primary mb-8'>Partager votre profil société</h3>
            <div className='inline-flex items-center gap-6 w-full'>
              <p className='min-w-[80px]'>Site web</p>
              <TextField

                label="Coller le lien url du site web" variant="outlined"
                size="small"
                className='w-2/3 outline-secondary focus:outline-secondary'
                name='website_url'
                value={formData.website_url}
                onChange={handleChange}
              />
            </div>
            <div className='inline-flex items-center gap-6 w-full mt-2'>
              <p className='min-w-[80px]'>YouTube</p>
              <TextField
                name='youtube_url'
                value={formData.youtube_url}
                 onChange={handleChange}
                label="Coller le lien url YouTube" variant="outlined"
                size="small"
                className='w-2/3 outline-secondary focus:outline-secondary'
              />
            </div>
            <div className='inline-flex items-center gap-6 w-full mt-2'>
              <p className='min-w-[80px]'>LinkedIn</p>
              <TextField
                name='linkedin_url'
                value={formData.linkedin_url}
                 onChange={handleChange}
                label="Coller le lien url LinkedIn" variant="outlined"
                size="small"
                className='w-2/3 outline-secondary focus:outline-secondary'
              />
            </div>
            <div className='inline-flex items-center gap-6 w-full mt-2'>
              <p className='min-w-[80px]'>Viadeo</p>
              <TextField
                name='viadeo_url'
                value={formData.viadeo_url}
                 onChange={handleChange}
                label="Coller le lien url Viadeo" variant="outlined"
                size="small"
                className='w-2/3 outline-secondary focus:outline-secondary'
              />
            </div>
          </div>

          {/* <div className='rounded-[10px] border border-[#DEE3E2] bg-white py-6  px-4 sm:px-8 mt-3'>
            <h3 className='text-xl sm:text-[23px] font-extrabold text-primary mb-8'>Importez votre logo</h3>

            <div className='flex flex-col sm:flex-row sm:items-center sm:gap-8'>
              <button
                variant="contained"
                component="label"
                className='cursor-pointer relative border-dashed border-[#797979] border-2 rounded-md w-full sm:w-[361px] h-[120px] px-3 sm:h-[84px] flex items-center text-center justify-center'>
                <p className='text-sm font-light text-black max-w-[290px] m-auto'>Glisser-déposer le logo dans cette zone ou <span className='font-bold text-primary'>cliquez ici pour sélectionner un logo</span> </p>
                <input
                  type="file"
                  className='absolute w-full h-full opacity-0 cursor-pointer'
                />
              </button>
              <div>
                <p className='text-[15px] text-primary font-light'>Format : <span className='font-medium'>jpeg, png </span></p>
                <p className='text-[15px] text-primary font-light'>Poids : <span className='font-medium'>5 Mo max. </span></p>
                <p className='text-[15px] text-primary font-light'>Dimensions :<span className='font-medium'>500*500px min.</span></p>
              </div>
            </div>

          </div> */}
          <div className='rounded-[10px] border border-[#DEE3E2] bg-white py-6 px-4 sm:px-8 mt-3'>
      <h3 className='text-xl sm:text-[23px] font-extrabold text-primary mb-8'>Importez votre logo</h3>
      <div className='flex flex-col sm:flex-row sm:items-center sm:gap-8'>
        <label className='cursor-pointer relative border-dashed border-[#797979] border-2 rounded-md w-full sm:w-[361px] h-[120px] px-3 sm:h-[84px] flex items-center text-center justify-center'>
          {formData.logo_url && (
            <img src={formData.logo_url} alt="Profile" className='absolute w-full h-full object-cover rounded-md' />
          )}
          <input
            type="file"
            accept=".jpg,.jpeg,.png"
            className='absolute w-full h-full opacity-0 cursor-pointer'
            onChange={handleFileChange}
            name='logo'
          />
          <p className='text-sm font-light text-black max-w-[290px] m-auto z-10'>Glisser-déposer le logo dans cette zone ou <span className='font-bold text-primary'>cliquez ici pour sélectionner un logo</span> </p>
        </label>
        <div>
          <p className='text-[15px] text-primary font-light'>Format : <span className='font-medium'>jpeg, png </span></p>
          <p className='text-[15px] text-primary font-light'>Poids : <span className='font-medium'>5 Mo max. </span></p>
          <p className='text-[15px] text-primary font-light'>Dimensions :<span className='font-medium'>500*500px min.</span></p>
        </div>
      </div>
    </div>
          <div className='flex items-center  mb-5 mt-8 sm:mt-8'>
            <div className='flex items-center'>
              <label className='flex gap-4'>
                <input className=" w-8 h-8 rounded-4xl block border-black-light" type="checkbox" checked={formData.term_condition} name='term_condition' onChange={handleChange} />
                <span className="text-base  font-normal text-primary">
                  En cliquant sur “Créer mon compte” vous acceptez nos <Link to="/conditions-generales-vente" className='text-primary font-semibold underline'>conditions générales</Link> et notre <Link to="" className='text-primary font-semibold underline'>politique de confidentialité</Link>
                </span>
              </label>
            </div>
          </div>
          <LoadingButton
            size="small"
            onClick={handleSubmit}
            loading={loading}
            loadingPosition="start"
            variant="contained"
            className='bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white text-capitalize mt-4 sm:mt-4
               3xl:mt-10 px-6 w-full py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white'
          >
            <span className=''>Créer mon compte</span>
          </LoadingButton>
          {/* <button type='button' onClick={handleSubmit} className='bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white  mt-4 sm:mt-4
               3xl:mt-10 px-6 w-full py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white'>Créer mon compte</button> */}

        </div>

      </div>
      <Footer />
    </>




  )
}
