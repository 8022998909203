import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Breadcrumb from '../../component/Breadcrumb'
import Procedures from './FormComponent/Procedures'
import Express from './FormComponent/Express'
import PaymentSideBlock from './FormComponent/PaymentSideBlock'
import { TbPointFilled } from 'react-icons/tb'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Helmet } from 'react-helmet';
import Footer from '../../component/Footer'
import { Link } from 'react-router-dom'

export default function JournauxHabilites() {
  const [departmentList, setDepartmentList] = useState([]);
  const [onError, setOnError] = useState(false);
  const [journalEmpoweredList, setJournalEmpoweredList] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0)
    getAllDepartment();
  }, [])
  async function getAllDepartment() {
    let departementGet = await axios.get(`${process.env.REACT_APP_API_URL}api/announce/departments`);

    setDepartmentList(departementGet.data.data)
  }
  async function getAllJournalEmpoweredByDepartment(event) {

    let department_id = event.target.value;
    let JournalEmpoweredGet = await axios.get(`${process.env.REACT_APP_API_URL}api/announce/JournalEmpowered/${department_id}`);

    setJournalEmpoweredList(JournalEmpoweredGet.data.data)
  }
  return (
    <>
      <Helmet>
        <title>Liste des Journaux Habilités pour Annonces Légales | BusyPlace</title>
        <meta name="description" content="Trouvez facilement le journal officiel adapté à vos besoins pour la publication d'annonces légales avec BusyPlace. Découvrez notre annuaire des journaux habilités dans toute la France pour une publication rapide et conforme." />
        {/* <link rel="canonical" href={`${process.env.REACT_APP_WEB_URL}/journaux-habilites`} /> */}
      </Helmet>
      <div className='md:max-w-[1477px] relative w-full h-full m-auto py-7 px-4 sm:px-14'>
        <Breadcrumb Accueil="Accueil" page="Journaux habilités" />
        <div className='relative my-12'>
          <div className='grid grid-cols-12 gap-5'>
            <div className='mb-8 col-span-12 2lg:col-span-8'>
              <h1 className='text-lg sm:text-[22px] font-black text-primary'>Liste des journaux d’annonces légales</h1>
              <p className='text-base font-normal text-primary mt-6'>Vous trouverez ci-après une liste des journaux d’annonces légales avec lesquels nous collaborons. Quel que soit votre département ou région, vous trouverez forcément ici un journal habilité pour déposer votre annonce légale.</p>
              <p className='text-base font-normal text-primary mt-6'>En sélectionnant l’un de ces JAL, vous pourrez :</p>
              <ul className='mt-3'>
                <li className='flex gap-2 items-center text-primary'><TbPointFilled className='text-secondary text-base' />procéder au dépôt d’une annonce légale, </li>
                <li className='flex gap-2 items-center  text-primary'><TbPointFilled className='text-secondary text-base' />consulter les dernières annonces légales publiées sur chaque support.</li>
              </ul>
              {/* <h6 className='text-base font-semibold text-primary underline my-7'><Link className='text-base font-semibold text-primary underline my-7' target="_blank" to="https://www.busyplace.fr/greffes-de-france">Découvrez également la liste des Greffes de France.</Link></h6> */}
              <h6 className='text-base font-semibold text-primary underline my-7'>
                <a href="https://www.busyplace.fr/greffes-de-france" target="_blank" rel="noopener noreferrer" className='text-base font-semibold text-primary underline my-7'>
                  Découvrez également la liste des Greffes de France.
                </a>
              </h6>

              <FormControl className='w-full' style={{ margin: '40px 0 0', boxShadow: '0px 2px 8px rgba(126, 122, 122, 0.25)' }}>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Sélectionnez"
                  name='department_list'
                  onChange={getAllJournalEmpoweredByDepartment}
                >
                  {
                    departmentList.map((department, index) => {
                      return (
                        <MenuItem value={department.id} key={index}>{department.department_num} - {department.department_name}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>

              <div className='mt-24'>
                <h4 className='text-lg font-semibold text-primary my-4'>Journaux ({journalEmpoweredList.length})</h4>
                {
                  journalEmpoweredList.map((val, key) => {
                    return <div className='border border-primary rounded-[10px] flex flex-col sm:flex-row  items-center justify-between gap-10 px-8 py-6 mb-5'>
                      {/* src={onError?process.env.REACT_APP_WEB_URL+`/no_image.png`:process.env.REACT_APP_API_URL+`/images/journal/${val.image}`} */}
                      <img src={`${val.image}`} onError={(e) => setOnError(true)} alt={val.image} style={{ "width": "200px", "height": "55px", "objectFit": "contain" }} />
                      <div className='flex flex-col gap-6 lg:flex-row'>
                        <div>
                          <h4 className='text-lg font-semibold text-primary'>{val.journal_empowered_name} </h4>
                          <p className='text-[15px] font-normal text-primary'>{val.adresse}</p>
                        </div>
                        <Link className='bg-secondary btnShadow font-bold text-sm text-white text-center py-3 px-2 rounded-lg hover:bg-primary transition-all duration-300' to="/nos-formulaires">Publier dans ce journal</Link>

                      </div>
                    </div>
                  })
                }
              </div>
            </div>
            <div className=' col-span-12 2lg:col-span-4'>
              <Procedures />
              <Express />
              <PaymentSideBlock />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
