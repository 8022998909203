import React from 'react'

export default function Express() {
  return (
    <div className='mt-7 h-max bg-white rounded-xl w-full px-4 sm:px-5 py-8' style={{ boxShadow: '0px 4px 4px 2px rgba(53, 53, 53, 0.1)' }}>
      <div>
        <h3 className='text-lg sm:text-xl font-extrabold text-primary mb-2'>Gagner du temps avec un <span className='text-thColor'>traitement EXPRESS</span>  de vos formalités
        </h3>

        <div className='mt-6 py-3'>
          <h6 className='text-base font-semibold text-primary'>Votre KBIS en un temps record</h6>
          <p className='text-sm font-light text-primary'>Le traitement express de votre formalité auprès du greffe compétant avec un suivi en temps réel nous permet de réagir quasi instantanément en cas d’erreur et d'obtenir votre KBIS en un temps record.</p>
        </div>

        <div className='mt-6 py-3'>
          <h6 className='text-base font-semibold text-primary'>Éviter 70% de rejets des dossiers</h6>
          <p className='text-sm font-light text-primary'>Nous vous garantissons non seulement d’éviter 70% de rejets de dossiers mais aussi d’obtenir un gain de temps et de traitement non négligeables pour vous consacrer pleinement à votre cœur de métier. Pour 99 € HT seulement optez pour la sérénité.</p>
        </div>

      </div>
    </div>
  )
}
