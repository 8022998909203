import React from "react";
import SideBar from "./SideBar";
import PropTypes from "prop-types";

export default function Layout({ children }) {
  return (
    <>
      <div className="bg-mainBg w-full  pt-4 xl:pt-0  ">
 
          <div className="md:max-w-[1477px]  relative w-full h-full m-auto xl:pt-7 pb-16 px-4 md:px-4 sm:px-14">
          <div className="grid grid-cols-12 gap-4">
            <div className="xl:mb-8  xl:col-span-3 col-span-12">
              <SideBar />
            </div>
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
