import React from 'react'
import { Link } from 'react-router-dom'
import { Menu } from '@headlessui/react'
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from '../_actions'

export default function MobileMenu({ handelClick }) {
  const user = useSelector(state => state.authentication.user);
  return (

    <>
      <li onClick={handelClick}><Link className='text-sm sm:text-base font-medium text-primary' to='/Nos-formulaires'> Nos formulaires</Link></li>
      <li onClick={handelClick}><Link className='text-sm sm:text-base font-medium text-primary' to='/Formulaire-inscription-pro'>Journaux habilités</Link></li>
      <li className='relative'>
        <Menu>
          <Menu.Button className='text-sm sm:text-base font-medium text-primary flex items-baseline gap-3'>Annuaires
            <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.347684 0.311086C0.570373 0.111898 0.872364 0 1.18725 0C1.50213 0 1.80412 0.111898 2.02681 0.311086L7.90493 5.57046L13.7831 0.311086C14.007 0.117543 14.307 0.0104483 14.6183 0.0128692C14.9297 0.01529 15.2275 0.127032 15.4477 0.324028C15.6679 0.521025 15.7928 0.787513 15.7955 1.0661C15.7982 1.34468 15.6785 1.61307 15.4622 1.81346L8.7445 7.82402C8.52181 8.02321 8.21982 8.13511 7.90493 8.13511C7.59005 8.13511 7.28806 8.02321 7.06537 7.82402L0.347684 1.81346C0.125062 1.61421 0 1.34401 0 1.06227C0 0.780536 0.125062 0.510334 0.347684 0.311086V0.311086Z" fill="#27295B" />
            </svg>
          </Menu.Button>
          <Menu.Items className="DropItems relative  top-2  text-left w-[250px]  focus:outline-none transform opacity-100 scale-100">
            <Menu.Item className="group flex justify-start w-full items-center rounded-md   py-2  text-sm sm:text-base font-medium text-primary">
              {({ active }) => (
                <Link
                  onClick={handelClick}
                  className={`${active && 'bg-blue-500'}`}
                  to="/Greffes-de-France"
                >
                  Greffes de France
                </Link>
              )}
            </Menu.Item>
            <Menu.Item className="group flex w-full items-center justify-start rounded-md   py-2   text-sm sm:text-base font-medium text-primary">
              {({ active }) => (
                <Link
                  onClick={handelClick}
                  className={`${active && 'bg-blue-500'}`}
                  to="/Experts-comptables"
                >
                  Experts-comptables
                </Link>
              )}
            </Menu.Item>
            <Menu.Item className="group flex w-full items-center justify-start rounded-md   py-2   text-sm sm:text-base font-medium text-primary">
              {({ active }) => (
                <Link
                  onClick={handelClick}
                  className={`${active && 'bg-blue-500'}`}
                  to="/Avocats"
                >
                  Avocats
                </Link>
              )}
            </Menu.Item>
            <Menu.Item className="group flex w-full items-center justify-start rounded-md   py-2   text-sm sm:text-base font-medium text-primary">
              {({ active }) => (
                <Link
                  onClick={handelClick}
                  className={`${active && 'bg-blue-500'}`}
                  to="/Notaires"
                >
                  Notaires
                </Link>
              )}
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </li>
      <li>
        <Link to={process.env.REACT_APP_PRO_WEB_URL} target="_blank"  >
          <button className='text-sm sm:text-base font-medium bg-primary flex flex-col justify-center leading-[18px] items-center px-1 text-center whitespace-nowrap rounded-[10px] w-[249px] h-[59px]'>
            <h2 className='font-bold text-base sm:text-lg  text-white m-0'>Professionnels</h2>
            <p className='font-medium text-xs text-white '>Avocats, Experts-comptables, Notaires</p>
          </button>
        </Link>
      </li>
    </>
  )
}