/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import React, { useEffect } from 'react'
import {useParams } from 'react-router-dom'
import { SarlStepperForm ,EurlStepperForm,SncStepperForm,SasuStepperForm,SasStepperForm,SaStepperForm,ScStepperForm,SciStepperForm,SccvStepperForm,ScmStepperForm,ScpiStepperForm,ScpStepperForm,SeleurlStepperForm,SelarlStepperForm,SelafaStepperForm,SelasStepperForm,SelasuStepperForm,ModificationsMultiplesStepperForm,ChangementGerantStepperForm,ModificationCapitalSocialStepperForm,CessionPartsSocialesStepperForm,ChangementDenominationStepperForm,ChangementObjetSocialStepperForm ,ContinuationActiviteStepperForm,ProrogationDureeStepperForm,TransfertSiegeMemeDepartementStepperForm,TransfertSiegeHorsDepartementDepartStepperForm,TransfertSiegeHorsDepartementArriveeStepperForm,TransformationSaEnSasStepperForm,TransformationSarlEnSasStepperForm,TransformationSasEnSarlStepperForm,DissolutionAnticipeeStepperForm,ClotureLiquidationStepperForm,DissolutionSansLiquidationStepperForm,ApportFondCommerceStepperForm,CessionDroitBailStepperForm,FinLocationGeranceStepperForm,LocationGeranceStepperForm,CessionFondCommerceStepperForm,AdditifStepperForm,RectificatifStepperForm,ModellibreStepperForm} from './stepperForms';


export default function StepperForm() { 
  let { ads,adsname,id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <>
    {
      adsname === "modellibre"?
        <ModellibreStepperForm />
      :""
    } 
    {
      adsname === "sarl"?
        <SarlStepperForm />
      :""
    }
    {
      adsname === "eurl"?
        <EurlStepperForm />
      :""
    }
    {
      adsname === "snc"?
        <SncStepperForm />
      :""
    }
    {
      adsname === "sasu"?
        <SasuStepperForm />
      :""
    }
    {
      adsname === "sas"?
        <SasStepperForm />
      :""
    }  
    {
      adsname === "sa"?
        <SaStepperForm />
      :""
    } 
    {
      adsname === "sc"?
        <ScStepperForm />
      :""
    }
    {
      adsname === "sci"?
        <SciStepperForm />
      :""
    }
    {
      adsname === "sccv"?
        <SccvStepperForm />
      :""
    }  
    {
      adsname === "scm"?
        <ScmStepperForm />
      :""
    } 
     {
      adsname === "scpi"?
        <ScpiStepperForm />
      :""
    } 
    {
      adsname === "scp"?
        <ScpStepperForm />
      :""
    }  
    {
      adsname === "selarl"?
        <SelarlStepperForm />
      :""
    } 
    {
      adsname === "seleurl"?
        <SeleurlStepperForm />
      :""
    } 
     {
      adsname === "selafa"?
        <SelafaStepperForm />
      :""
    }  
    {
      adsname === "selas"?
        <SelasStepperForm />
      :""
    }
     {
      adsname === "selasu"?
        <SelasuStepperForm />
      :""
    }  
    {
      adsname === "modifications-multiples"?
        <ModificationsMultiplesStepperForm />
      :""
    }
    {
      adsname === "changement-gerant"?
        <ChangementGerantStepperForm />
      :""
    }
    {
      adsname === "modification-capital-social"?
        <ModificationCapitalSocialStepperForm />
      :""
    } 
    {
      adsname === "changement-denomination"?
        <ChangementDenominationStepperForm />
      :""
    }
    {
      adsname === "changement-objet-social"?
        <ChangementObjetSocialStepperForm />
      :""
    }
    {
      adsname === "cession-parts-sociales"?
        <CessionPartsSocialesStepperForm />
      :""
    } 
    {
      adsname === "continuation-activite"?
        <ContinuationActiviteStepperForm />
      :""
    } 
    {
      adsname === "prorogation-duree"?
        <ProrogationDureeStepperForm />
      :""
    } 
     {
      adsname === "transfert-siege-meme-departement"?
        <TransfertSiegeMemeDepartementStepperForm />
      :""
    }  
    {
      adsname === "transfert-siege-hors-departement-depart"?
        <TransfertSiegeHorsDepartementDepartStepperForm />
      :""
    } 
     {
      adsname === "transfert-siege-hors-departement-arrivee"?
        <TransfertSiegeHorsDepartementArriveeStepperForm />
      :""
    } 
     {
      adsname === "transformation-sa-en-sas"?
        <TransformationSaEnSasStepperForm />
      :""
    }  
    {
      adsname === "transformation-sarl-en-sas"?
        <TransformationSarlEnSasStepperForm />
      :""
    }       
    {
      adsname === "transformation-sas-en-sarl"?
        <TransformationSasEnSarlStepperForm />
      :""
    }  
    {
      adsname === "dissolution-anticipee"?
        <DissolutionAnticipeeStepperForm />
      :""
    } 
    {
      adsname === "cloture-liquidation"?
        <ClotureLiquidationStepperForm />
      :""
    } 
    {
      adsname === "dissolution-sans-liquidation"?
        <DissolutionSansLiquidationStepperForm />
      :""
    }
    {
      adsname === "apport-fond-commerce"?
        <ApportFondCommerceStepperForm />
      :""
    } 
    {
      adsname === "cession-droit-bail"?
        <CessionDroitBailStepperForm />
      :""
    }  
    {
      adsname === "fin-location-gerance"?
        <FinLocationGeranceStepperForm />
      :""
    } 
     {
      adsname === "location-gerance"?
        <LocationGeranceStepperForm />
      :""
    }  
     {
      adsname === "cession-fond-commerce"?
        <CessionFondCommerceStepperForm />
      :""
    }  
    {
      adsname === "additif"?
        <AdditifStepperForm />
      :""
    }  
    {
      adsname === "rectificatif"?
        <RectificatifStepperForm />
      :""
    } 
    
    </>
  );
}


