import React, { useState } from 'react';
import { TextField, Box, Stack, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { BsPlusCircle, BsXCircle } from 'react-icons/bs';
import { useNavigate,useParams } from 'react-router-dom';
import axios from 'axios';

export default function ProAccountingActivity() {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [formData, setFormData] = useState({
    email: '',
    job_title: '',
    skills: [''], // Start with one field
    domaines: [''], // Start with one field
    specialisations: [''], // Start with one field
    sectors: [''], // Start with one field
  });

  const handleChange = (field, value, index) => {
    setFormData((prevData) => {
      const updatedField = Array.isArray(prevData[field]) ? [...prevData[field]] : value;
      if (index !== undefined && Array.isArray(prevData[field])) {
        updatedField[index] = value;
      }

      return {
        ...prevData,
        [field]: updatedField,
      };
    });
  };

  const handleAddField = (field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: [...prevData[field], ''],
    }));
  };

  const handleRemoveField = (field, index) => {
    setFormData((prevData) => {
      const updatedField = [...prevData[field]];
      updatedField.splice(index, 1);
      return {
        ...prevData,
        [field]: updatedField,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      // Retrieve email from local storage
      const emaildata = localStorage.getItem('email');

      // Validate if job_title is empty
      if (!formData.job_title.trim()) {
        setError('Veuillez sélectionner un métier');
        setLoading(false);
        return;
      }

      // Validate if any skill field is empty
     /*  if (formData.skills.some((skill) => !skill.trim())) {
        setError('Veuillez saisir vos compétences');
        setLoading(false);
        return;
      } */

      // Validate if any sector field is empty
      /* if (formData.sectors.some((sector) => !sector.trim())) {
        setError('Veuillez saisir vos secteurs');
        setLoading(false);
        return;
      } */

      // Create the request data object
      const requestData = {
        user_id:params.id,
        email: emaildata,
        job_title: formData.job_title,
        skills: formData.skills,
        domaines:formData.domaines, // Start with one field
        specialisations: formData.specialisations, // Start with one field
        sectors: formData.sectors,
      };

      // Use Axios to make the API request
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/prouser/add-prouseraccountactivity`, requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        // Handle success, e.g., redirect or show a success message
        
        navigate(`/professionalanguage/${params.id}`);

      } else {
        // Handle error, e.g., show an error message
        setError("Une erreur s'est produite."); // Set the error message
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='bg-[#F5F5F5] h-[85vh] flex items-center justify-center'>
      <div className='bg-[#F5F5F5] w-full py-7 3xl:py-12'>
        <div className='md:max-w-[1477px] relative w-full h-full flex items-center justify-center m-auto'>
          <div className='max-w-[700px] 3xl:max-w-[700px] w-full h-full bg-white px-5 sm:px-12 py-2 sm:py-5 3xl:p-12' style={{ boxShadow: '0px 4px 10px 1px rgba(39, 41, 91, 0.35)', borderRadius: '33px' }}>
            <div className='px-2 py-2 3xl:py-4'>
              <h1 className='text-xl sm:text-3xl xl:text-[28px] font-extrabold md:leading-[45px] mt-6 text-primary'>Votre activité</h1>
            </div>
            <Box
              component='form'
              onSubmit={handleSubmit}
              className='mt-5 sm:mt-5 3xl:mt-12'
              sx={{
                '& .MuiInputLabel-root': { color: '#797979' },
                '& fieldset': {
                  border: '1px solid #eee',
                  borderRadius: '8px',
                  color: '#797979',
                  background: 'rgb(39 41 91 / 6%)',
                },
              }}
            >
              <div className='grid grid-cols-12 items-start mt-2'>
                <label className='col-span-12 text-base font-medium text-gray mb-1'  >Métier</label>
                <div className='col-span-12'>
                  <FormControl className='w-full'>
                    <InputLabel id='demo-simple-select-label'>Sélectionnez un métier</InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      label='Sélectionnez'
                      value={formData.job_title}
                      onChange={(e) => handleChange('job_title', e.target.value)}
                    >
                      <MenuItem value={'avocats'}>Avocats</MenuItem>
                      <MenuItem value={'accountant'}>Experts Comptables</MenuItem>
                      <MenuItem value={'notaires'}>Notaires</MenuItem>
                    </Select>
                  </FormControl>
                  {error && error === 'Veuillez sélectionner un métier' && <p className='text-red-500 mt-2' style={{color:'red'}}>{error}</p>}
                </div>
              </div>

              <div  className={formData.job_title==="avocats"||formData.job_title==="notaires"?`grid grid-cols-12 items-center mt-3 sm:mt-10`: `items-center mt-3 sm:mt-10 hidden`} >
                <label className='col-span-12 text-base font-medium text-gray mb-1'>Domaines</label>
                {formData.domaines.map((domaine, index) => (
                  <div key={index} className={`col-span-12 sm:col-span-12 md:col-span-12 inline-flex items-center gap-2 ${index === 0 ? 'mt-3' : 'mt-8'}`}>
                    <TextField
                      label='Saisissez un domaine'
                      variant='outlined'
                      size='small'
                      className='w-full outline-secondary focus:outline-secondary'
                      value={domaine}
                      onChange={(e) => handleChange('domaines', e.target.value, index)}
                    />
                    {index === 0 ? (
                      <BsPlusCircle className='text-3xl cursor-pointer' onClick={() => handleAddField('domaines')} />
                    ) : (
                      <>
                        <BsXCircle className='text-3xl cursor-pointer' onClick={() => handleRemoveField('domaines', index)} />
                      </>
                    )}
                  </div>
                ))}
                {/* {error && error === 'Veuillez saisir vos compétences' && <p className='text-red-500 mt-2' style={{color:'red'}}>{error}</p>} */}
              </div>

              <div className={formData.job_title==="avocats"?`grid grid-cols-12 items-center mt-3 sm:mt-10`: `items-center mt-3 sm:mt-10 hidden`}>
                <label className='col-span-12 text-base font-medium text-gray mb-1'>Spécialisations</label>
                {formData.specialisations.map((specialisation, index) => (
                  <div key={index} className={`col-span-12 sm:col-span-12 md:col-span-12 inline-flex items-center gap-2 ${index === 0 ? 'mt-3' : 'mt-8'}`}>
                    <TextField
                      label='Saisissez une spécialisation'
                      variant='outlined'
                      size='small'
                      className='w-full outline-secondary focus:outline-secondary'
                      value={specialisation}
                      onChange={(e) => handleChange('specialisations', e.target.value, index)}
                    />
                    {index === 0 ? (
                      <BsPlusCircle className='text-3xl cursor-pointer' onClick={() => handleAddField('specialisations')} />
                    ) : (
                      <>
                        <BsXCircle className='text-3xl cursor-pointer' onClick={() => handleRemoveField('specialisations', index)} />
                      </>
                    )}
                  </div>
                ))}
               {/*  {error && error === 'Veuillez saisir vos secteurs' && <p className='text-red-500 mt-2' style={{color:'red'}}>{error}</p>} */}
              </div>

              <div  className={formData.job_title==="accountant"?`grid grid-cols-12 items-center mt-3 sm:mt-10`: `items-center mt-3 sm:mt-10 hidden`} >
                <label className='col-span-12 text-base font-medium text-gray mb-1'>Compétences</label>
                {formData.skills.map((skill, index) => (
                  <div key={index} className={`col-span-12 sm:col-span-12 md:col-span-12 inline-flex items-center gap-2 ${index === 0 ? 'mt-3' : 'mt-8'}`}>
                    <TextField
                      label='Saisissez une compétence'
                      variant='outlined'
                      size='small'
                      className='w-full outline-secondary focus:outline-secondary'
                      value={skill}
                      onChange={(e) => handleChange('skills', e.target.value, index)}
                    />
                    {index === 0 ? (
                      <BsPlusCircle className='text-3xl cursor-pointer' onClick={() => handleAddField('skills')} />
                    ) : (
                      <>
                        <BsXCircle className='text-3xl cursor-pointer' onClick={() => handleRemoveField('skills', index)} />
                      </>
                    )}
                  </div>
                ))}
                {/* {error && error === 'Veuillez saisir vos compétences' && <p className='text-red-500 mt-2' style={{color:'red'}}>{error}</p>} */}
              </div>

              <div className={formData.job_title==="accountant"?`grid grid-cols-12 items-center mt-3 sm:mt-10`: `items-center mt-3 sm:mt-10 hidden`}>
                <label className='col-span-12 text-base font-medium text-gray mb-1'>Secteurs</label>
                {formData.sectors.map((sector, index) => (
                  <div key={index} className={`col-span-12 sm:col-span-12 md:col-span-12 inline-flex items-center gap-2 ${index === 0 ? 'mt-3' : 'mt-8'}`}>
                    <TextField
                      label='Saisissez un secteur'
                      variant='outlined'
                      size='small'
                      className='w-full outline-secondary focus:outline-secondary'
                      value={sector}
                      onChange={(e) => handleChange('sectors', e.target.value, index)}
                    />
                    {index === 0 ? (
                      <BsPlusCircle className='text-3xl cursor-pointer' onClick={() => handleAddField('sectors')} />
                    ) : (
                      <>
                        <BsXCircle className='text-3xl cursor-pointer' onClick={() => handleRemoveField('sectors', index)} />
                      </>
                    )}
                  </div>
                ))}
               {/*  {error && error === 'Veuillez saisir vos secteurs' && <p className='text-red-500 mt-2' style={{color:'red'}}>{error}</p>} */}
              </div>

              <Stack alignItems='end' sx={{ mt: 2 }}>
                <button
                  type='submit'
                  className='bg-primary text-lg xl:text-xl 3xl:text-2xl font-bold text-white mt-4 sm:mt-4 3xl:mt-10 px-10 py-3 3xl:h-[62px] rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white'
                  disabled={loading}
                >
                  {loading ? 'En cours...' : 'Suivant'}
                </button>
              </Stack>
              {/* {error && error !== 'Veuillez sélectionner un métier' && error !== 'Veuillez saisir vos compétences' && error !== 'Veuillez saisir vos secteurs' && <p className='text-red-500 mt-2' style={{color:'red'}}>{error}</p>} */}
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}




























