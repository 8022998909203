/* eslint-disable array-callback-return */
import React, {useState, useEffect } from 'react' 
import moment from 'moment';
import axios from 'axios';
import dayjs from 'dayjs';
import { BsFillQuestionCircleFill, BsPlusCircle } from 'react-icons/bs'
import { MdEuroSymbol } from 'react-icons/md'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/fr.js';
import { frFR } from '@mui/x-date-pickers/locales';
import Tooltip from '@mui/material/Tooltip';
import { FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select,ListSubheader , TextField ,Divider} from '@mui/material';

import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';

export  function Modellibre(props) {
  console.log("props",props)
  const [Departments, setDepartments] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0)
    getAllDepartment();
  }, [])

  async function getAllDepartment(){
    let departementGet =  await axios.get(`${process.env.REACT_APP_API_URL}api/announce/departments`); 
    
    setDepartments(departementGet.data.data)
  }

  const StyledTooltip = ({ title, children, ...props }) => (
    <Tooltip
      {...props}
      title={title}
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            background: '#DEE3E2',
            width: '129px',
            padding: '10px',
            color: '#27295B',
            fontSize: '14px',
            fontWeight: '400'
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );

  return (
  <>
  <div className='' >
    <div className='py-6 px-3 sm:px-6' style={{ borderBottom: '0.5px solid #DEE3E2' }}>
      <h1 className='text-lg sm:text-2xl font-black text-primary'>Annonce légale : {props.adsname}</h1>
    </div>
    <div className='py-3 sm:py-6 px-3 sm:px-6'>      
      <h2 className='text-lg sm:text-[22px] font-bold text-primary mb-3'>Département de publication</h2>
      <hr />
      
      <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Département *</label>
        <div className='col-span-12 sm:col-span-9 md:col-span-9 md:mr-10 inline-flex items-center gap-2'>
          <FormControl size="small" className='w-full'>
            <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              // id={val.index}
              label="Sélectionnez"
              name="department_name"
              value={props.formData.department_name} 
              onChange={props.handleChange}  
              error={!props.formData.department_name  && props.isSubmitted?true:false}
            >
              {
                Departments.map((department,key)=>{
                  return <MenuItem value={department.id}>{department.department_num} - {department.department_name}</MenuItem>
                })
              }
              
            </Select>
          </FormControl>
                        
        </div>                
      </div>
      <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Type d'annonce *</label>
        <div className='col-span-12 sm:col-span-9 md:col-span-9 md:mr-10 inline-flex items-center gap-2'>
          <FormControl size="small" className='w-full'>
            <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              // id={val.index}
              label="Sélectionnez"
              name="form_type"
              value={props.formData.form_type} 
              onChange={props.handleChange}  
              error={!props.formData.form_type  && props.isSubmitted?true:false}
            >
              <MenuItem value=""> <em>Votre type de formulaire</em></MenuItem>
              <ListSubheader>Constitution de société commerciale</ListSubheader>
                <MenuItem value="eurl"> SARL Unipersonnelle (EURL)</MenuItem>
                <MenuItem value="sarl">Société à Responsabilité Limitée (SARL)</MenuItem>
                <MenuItem value="sasu"> Société par Actions Simplifiée Unipersonnelle (SASU)</MenuItem>
                <MenuItem value="sas">Société par Actions Simplifiée (SAS)</MenuItem>
                <MenuItem value="sa">Société Anonyme (SA)</MenuItem>
                <MenuItem value="snc">Société en Nom Collectif (SNC)</MenuItem>
              <ListSubheader>Constitution d'une société civile</ListSubheader>
                <MenuItem value="sc">Société Civile</MenuItem>
                <MenuItem value="sci">Société Civile Immobilière (SCI)</MenuItem>
                <MenuItem value="sccv">SC de Construction Vente (SCCV ou SCICV)</MenuItem>
                <MenuItem value="scm">Société Civile De Moyens (SCM)</MenuItem>
                <MenuItem value="scpi">Société Civile De Placement Immobilier (SCPI)</MenuItem>
                <MenuItem value="scp">Société Civile Professionnelle (SCP)</MenuItem>
              <ListSubheader>Constitution d'une société libéral</ListSubheader>
                <MenuItem value="selarl">Société d'Exercice Libéral à Responsabilité Limitée (SELARL)</MenuItem>
                <MenuItem value="selca">Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
                <MenuItem value="seleurl">Société d'Exercice Libéral ayant un associé Unique (SELEURL)</MenuItem>
                <MenuItem value="selafa">Société d'Exercice Libéral sous Forme Anonyme (SELAFA)</MenuItem>
                <MenuItem value="selas">Société d'Exercice Libéral par Actions Simplifiée (SELAS)</MenuItem>
                <MenuItem value="selasu">Société d'Exercice Libéral par Actions Simplifiée Unipersonnelle (SELASU)</MenuItem>
                <MenuItem value="selca">Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
              <ListSubheader>Modification de société</ListSubheader>
                <MenuItem value="modifications-multiples"> Modifications multiples </MenuItem>
                <MenuItem value="changement-gerant">Changement Gérant/Président/ Nomination</MenuItem>
                <MenuItem value="transfert-siege-meme-departement">Transfert de siége social dans le département</MenuItem>
                <MenuItem value="transfert-siege-hors-departement-depart"> Transfert de siége social hors département (départ)</MenuItem>
                <MenuItem value="transfert-siege-hors-departement-arrivee">Transfert de siége social hors département (arrivée)</MenuItem>
                <MenuItem value="changement-denomination">Changement de dénomination</MenuItem>
                <MenuItem value="changement-objet-social">Modification d'objet social</MenuItem>
                <MenuItem value="continuation-activite">Poursuite d'activité malgré pertes</MenuItem>
                <MenuItem value="modification-capital-social">Avis de modification de capital</MenuItem>
                <MenuItem value="transformation-sarl-en-sas">Transformation d’une SARL en SAS</MenuItem>
                <MenuItem value="transformation-sas-en-sarl">Transformation d’une SAS en SARL</MenuItem>
                <MenuItem value="cession-parts-sociales">Cession de parts sociales</MenuItem>
                <MenuItem value="transformation-sa-en-sas">Transformation d’une SA en SAS</MenuItem>
                <MenuItem value="prorogation-duree">Prorogation de durée</MenuItem>
              <ListSubheader>Cessation d'activité</ListSubheader>
                <MenuItem value="dissolution-anticipee"> Avis de dissolution anticipée</MenuItem>
                <MenuItem value="cloture-liquidation">Avis de clôture de liquidation</MenuItem>
                <MenuItem value="dissolution-sans-liquidation">Avis de dissolution sans liquidation</MenuItem>
              <ListSubheader>Fonds de Commerce</ListSubheader>
                <MenuItem value="apport-fond-commerce">Apport de Fonds de Commerce</MenuItem>
                <MenuItem value="cession-droit-bail">Cession de Droit au Bail</MenuItem>
                <MenuItem value="fin-location-gerance">Fin de location gérance</MenuItem>
                <MenuItem value="location-gerance">Location gérance</MenuItem>
                <MenuItem value="cession-fond-commerce">Vente de Fonds de Commerce</MenuItem>
              <ListSubheader>Additifs - Rectificatifs</ListSubheader>
                <MenuItem value="additif">Additif</MenuItem>
                <MenuItem value="rectificatif">Annonce rectificative</MenuItem>
            </Select>
          </FormControl>
                        
        </div>                
      </div>
      <div className='grid grid-cols-12 mt-6 items-center'>
        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Reference client</label>
        <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
          <TextField
            hiddenLabel
            id="outlined-size-small"
            size="small"
            className='w-full'
            name="referenceclient"
            value={props.formData.referenceclient} 
            onChange={props.handleChange}  
            // error={!props.formData.referenceclient  && props.isSubmitted?true:false}
          />
        </div>
      </div>
      
      <div className='grid grid-cols-12 mt-6 items-center'>
        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Denomination *</label>
        <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
          <TextField
            hiddenLabel
            id="journal_publication"
            size="small"
            className='w-full'
            name="denomination"
            value={props.formData.denomination} 
            onChange={props.handleChange}  
            error={!props.formData.denomination  && props.isSubmitted?true:false}
          />
        </div>
      </div>
      <div className='grid grid-cols-12 mt-6 items-center'>
        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'><h2 className='text-lg sm:text-[22px] font-bold text-primary'>Zone libre</h2></label>
        <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
          <TextField
            hiddenLabel
            id="outlined-size-small"
            size="small"
            className='w-full'
            rows="6"
            multiline={true}
            disabled={false}
            placeholder="Pour ajouter une information dans le texte initialement publié,saisir ou Copier-Coller la rectification de votre annonce dans le cadre ci-dessous"
            name="zonelibre"
            value={props.formData.zonelibre} 
            onChange={props.handleChange} 
                           
          />
        </div>
      </div> 
    </div>      
  </div>
  </>
  )
}
