/* eslint-disable array-callback-return */
import React, {useState, useEffect } from 'react' 
import moment from 'moment';
import dayjs from 'dayjs';
import { BsFillQuestionCircleFill, BsPlusCircle } from 'react-icons/bs'
import { MdEuroSymbol } from 'react-icons/md'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/fr.js';
import { frFR } from '@mui/x-date-pickers/locales';
import Tooltip from '@mui/material/Tooltip';
import { FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select,ListSubheader , TextField ,Divider} from '@mui/material';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';

export  function DissolutionAnticipee(props) {
 
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const StyledTooltip = ({ title, children, ...props }) => (
    <Tooltip
      {...props}
      title={title}
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            background: '#DEE3E2',
            width: '129px',
            padding: '10px',
            color: '#27295B',
            fontSize: '14px',
            fontWeight: '400'
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );

  return (
    <>
      <div className='' >
        <div className='py-6 px-3 sm:px-6' style={{ borderBottom: '0.5px solid #DEE3E2' }}>
          <h1 className='text-lg sm:text-2xl font-black text-primary'>Annonce légale : Création de {props.adsname}</h1>
        </div>
        <div className='py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Choisir l'organe et la date de la décision</h2>
          
          <div className='grid grid-cols-12 items-center mt-2 sm:mt-6'>
            <label className='col-span-12 sm:col-span-3 my-2 sm:mb-0 text-base font-normal text-black-light'>Par décision *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Sélectionnez"
                  id="type_perdecision_acte" name="type_perdecision_acte"
                  value={props.formData.type_perdecision_acte} onChange={props.handleChange}
                  error={!props.formData.type_perdecision_acte && props.isSubmitted?true:false}
                >
                  <MenuItem value={"AGO"}>de L'Assemblée Générale Ordinaire</MenuItem>
                  <MenuItem value={"AGE"}>de L'Assemblée Générale Extraordinaire</MenuItem>
                  <MenuItem value={"AGM"}>de L'Assemblée Générale Mixte</MenuItem>
                  <MenuItem value={"Gerant"}>du Gérant</MenuItem>
                  <MenuItem value={"President"}>du Président</MenuItem>
                  <MenuItem value={"Conseil_Administration"}>du Conseil d'Administration</MenuItem>
                  <MenuItem value={"Associe_Unique"}>de L'Associé Unique</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Date de l'AG *</label>
            <div className='col-span-12 sm:col-span-9 mr-7 md:mr-0 md:col-span-7 lg:col-span-4'>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}  >
                <DemoContainer components={['DatePicker']}  >
                  {
                    props.formData.date_typeag?
                    <DatePicker value={dayjs(props.formData.date_typeag)}   name="date_typeag" onChange={(e)=>{props.handleChangeDate(e,"date_typeag")}} format={"DD/MM/YYYY"}                   
                    />
                    :
                    <DatePicker  name="date_typeag"  onChange={(e)=>{props.handleChangeDate(e,"date_typeag")}} format={"DD/MM/YYYY"}                   
                    />
                  }
                  
                </DemoContainer>
              </LocalizationProvider>
            </div>

          </div>
          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Date d'effet *</label>
            <div className='col-span-12 sm:col-span-9 mr-7 md:mr-0 md:col-span-7 lg:col-span-4'>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}  >
                <DemoContainer components={['DatePicker']}  >
                  {
                    props.formData.date_type_effet?
                    <DatePicker value={dayjs(props.formData.date_type_effet)}   name="date_type_effet"   onChange={(e)=>{props.handleChangeDate(e,"date_type_effet")}} format={"DD/MM/YYYY"}                   
                    />
                    :
                    <DatePicker  name="date_type_effet"   onChange={(e)=>{props.handleChangeDate(e,"date_type_effet")}}   format={"DD/MM/YYYY"}                 
                    />
                  }
                  
                </DemoContainer>
              </LocalizationProvider>
            </div>

          </div>
        </div>


        <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>La société</h2>
          <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>N° SIREN *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7  mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full outline-secondary focus:outline-secondary'
                name="rcs_societe" 
                value={props.formData.rcs_societe} 
                onChange={props.handleChange} 
                error={!props.formData.rcs_societe  && props.isSubmitted?true:false}
              /> 
            </div>
          </div>        
          <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full outline-secondary focus:outline-secondary'
                name="denomination_societe" 
                value={props.formData.denomination_societe} 
                onChange={props.handleChange}  
                error={!props.formData.denomination_societe  && props.isSubmitted?true:false}
              />
              <StyledTooltip title="Indiquez le nom de la Société tel que mentionné dans les statuts.">
                <div className='p-0 m-0 w-auto'>
                  <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                </div>
              </StyledTooltip>
            </div>
          </div> 

          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-8'>
              {/* <TextField
                hiddenLabel
                size="small"
                className='w-full'
                id="forme_juridique_societe" 
                name="forme_juridique_societe"
                value={props.formData.forme_juridique_societe} 
                onChange={props.handleChange}  
                error={!props.formData.forme_juridique_societe  && props.isSubmitted?true:false}
              /> */}
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Choisissez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Choisissez"
                  id="forme_juridique_societe" 
                  name="forme_juridique_societe"
                  value={props.formData.forme_juridique_societe} 
                  onChange={props.handleChange}  
                  error={!props.formData.forme_juridique_societe  && props.isSubmitted?true:false}
                > 
                  <ListSubheader>Constitution de société commerciale</ListSubheader>
                    <MenuItem value="SARLU">SARL Unipersonnelle (EURL)</MenuItem>
                    <MenuItem value="SARL">Société à responsabilité limitée (SARL)</MenuItem>
                    <MenuItem value="SASU">Société par actions simplifiée unipersonnelle (SASU)</MenuItem>
                    <MenuItem value="SAS">Société par actions simplifiée (SAS)</MenuItem>
                    <MenuItem value="SA">Société anonyme (SA)</MenuItem> 
                    <MenuItem value="SNC">Société en nom collectif (SNC)</MenuItem>
                  
                  <ListSubheader>Constitution d'une société civile</ListSubheader>
                    <MenuItem value="SC">Société civile</MenuItem>
                    <MenuItem value="SCI">Société civile immobilière (SCI)</MenuItem>
                    <MenuItem value="SCCV">Société civile immobilière de construction vente (SCCV ou SCICV)</MenuItem>
                    <MenuItem value="SCM">Société civile de moyens (SCM)</MenuItem>
                    <MenuItem value="SCPI">Société civile de placement immobilier (SCPI)</MenuItem>
                    <MenuItem value="SCP">Société civile professionnelle (SCP)</MenuItem>

                  <ListSubheader>Constitution d'une société libéral</ListSubheader>                  
                    <MenuItem value="SELARL">Société d'exercice libéral a responsabilité limitée (SELARL)</MenuItem>
                    <MenuItem value="SELEURL">Société d'exercice libéral ayant un associé Unique (SELEURL)</MenuItem>
                    <MenuItem value="SELAFA">Société d'exercice libéral sous forme anonyme (SELAFA)</MenuItem>
                    <MenuItem value="SELAS">Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                    <MenuItem value="SELASU">Société d'exercice libéral par actions simplifiée unipersonnelle (SELASU)</MenuItem>
                    <MenuItem value="SELCA">Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
                    <MenuItem value="SCEA">Société civile d'exploitation agricole (SCEA)</MenuItem>
                    <MenuItem value="EARL">Exploitation agricole à responsabilité limitée (EARL)</MenuItem>
                    <MenuItem value="GAEC">Groupement agricole d'exploitation en commun (GAEC)</MenuItem>
                    <MenuItem value="GFA">Groupement foncier agricole (GFA)</MenuItem>
                    <MenuItem value="EIRL">Entrepreneur Individuel à Responsabilité Limité</MenuItem>
                    <MenuItem value="SPFPLAS">Société de Participations Financières de Profession Libérale d'Avocats Simplifiée (SPFPLAS)</MenuItem>
                  
                </Select>
              </FormControl>
            </div>
          </div>

          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>

              <FormControl size="small" className='w-full'>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  name="capital_societe"
                  value={props.formData.capital_societe} 
                  onChange={props.handleChange}  
                  error={!props.formData.capital_societe  && props.isSubmitted?true:false}
                />
              </FormControl>

              <StyledTooltip title="Montant du capital mentionné dans les statuts.">
                <div className='p-0 m-0 w-auto'>
                  <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                </div>
              </StyledTooltip>

            </div>
          </div>
          <div className='grid grid-cols-12 mt-3 sm:mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="address_societe"
                value={props.formData.address_societe} 
                onChange={props.handleChange}  
                error={!props.formData.address_societe  && props.isSubmitted?true:false}
              />
            </div>
          </div>

          <div className='grid grid-cols-12 mt-3 sm:mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse suite</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="address_suite_societe"
                value={props.formData.address_suite_societe} 
                onChange={props.handleChange}  
                // error={!props.formData.address_societe  && props.isSubmitted?true:false}
              />
            </div>
          </div>

          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="code_postal"  maxLength="5"
                value={props.formData.code_postal} 
                onChange={(e)=>{props.handleChange(e)}}  
                error={!props.formData.code_postal  && props.isSubmitted?true:false}
              />
            </div>
          </div>
          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              {/* <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="ville"
                value={props.formData.ville} 
                onChange={props.handleChange}  
                error={!props.formData.ville  && props.isSubmitted?true:false}
              /> */}
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Sélectionnez"
                  name="ville_id"
                  value={props.formData.ville_id}                   
                  onChange={(e)=>{props.handleChange(e)}}  
                  error={!props.formData.code_postal  && props.isSubmitted?true:false}
                  
                >
                  {
                    props.formData.villeArr.map((cityVal,cityIndex)=>{
                      return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                    })
                  }

                </Select>
              </FormControl>
            </div>
          </div>
          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe </label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="greffe" 
                value={props.formData.greffe} 
                onChange={props.handleChange} 
              />
            </div>
          </div>
        </div>
        {/* Liquidateur */}          
        <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>         
          {props.Liquidateur.map((val,key)=>{
           return <div>
            <div className="flex flex-row ">
              <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Liquidateur</h2> 
              {val.index >0?
              <div className='p-0 m-0 w-auto'>
                <RemoveCircleRoundedIcon fontSize='large' className='text-3xl  cursor-pointer  fill-secondary' onClick={(e)=>{props.removeForm(val.index,"Liquidateur")}} />
              </div>
              :""}
            </div>    
                  
            <div className='grid grid-cols-12 items-center mt-6'>
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité *</label>
              <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-8'>             
                <FormControl size="small" className='w-full'>
                  <InputLabel id="demo-simple-select-label">Choisissez</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Choisissez"
                    id="civilite_liquidature" 
                    name="civilite_liquidature" 
                    value={val.civilite_liquidature} 
                    onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Liquidateur")}}  
                    error={!val.civilite_liquidature  && props.isSubmitted?true:false}
                    index={val.index}
                  > 
                    <MenuItem value="M.">Monsieur</MenuItem>
                    <MenuItem value="Mme">Madame</MenuItem>
                    
                  </Select>
                </FormControl>
                
              </div>
            </div>
            <div className='grid grid-cols-12 mt-6 items-center'>
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom * </label>
              <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                <TextField
                  hiddenLabel                  
                  size="small"
                  className='w-full'
                  id="nom_liquidature" 
                  name="nom_liquidature" 
                  value={val.nom_liquidature} 
                  onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Liquidateur")}}  
                  error={!val.nom_liquidature  && props.isSubmitted?true:false}
                  index={val.index}
                />
              </div>
            </div>        
            <div className='grid grid-cols-12 items-center mt-6'>
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
              <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>

                <FormControl size="small" className='w-full'>
                  <OutlinedInput
                    
                    // endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                    id="prenom_liquidature" 
                    name="prenom_liquidature" 
                    value={val.prenom_liquidature} 
                    onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Liquidateur")}}  
                    error={!val.prenom_liquidature  && props.isSubmitted?true:false}
                    index={val.index}
                  />
                </FormControl>


              </div>
            </div>
            <div className='grid grid-cols-12 mt-3 sm:mt-6 items-center'>
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
              <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                <TextField
                  hiddenLabel                 
                  size="small"
                  className='w-full'
                  id="adresse_liquidature" 
                  name="adresse_liquidature" 
                  value={val.adresse_liquidature} 
                  onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Liquidateur")}}  
                  error={!val.adresse_liquidature  && props.isSubmitted?true:false}
                  index={val.index}
                />
              </div>
            </div>

            <div className='grid grid-cols-12 mt-3 sm:mt-6 items-center'>
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse suite</label>
              <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                <TextField
                  hiddenLabel                  
                  size="small"
                  className='w-full'
                  id="adresse_suite_liquidature" 
                  name="adresse_suite_liquidature" 
                  value={val.adresse_suite_liquidature} 
                  onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Liquidateur")}}  
                  // error={!val.adresse_suite_liquidature  && props.isSubmitted?true:false}
                  index={val.index}
                />
              </div>
            </div>
            <div className='grid grid-cols-12 items-center mt-6'>
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Résidant à l'étranger? *</label>
              <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-8'>             
                <FormControl size="small" className='w-full'>
                  <InputLabel id="demo-simple-select-label">Choisissez</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Choisissez"
                    id="resident_etranger_gerance" 
                    name="resident_etranger_gerance" 
                    value={val.resident_etranger_gerance} 
                    onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Liquidateur")}}  
                    error={!val.resident_etranger_gerance  && props.isSubmitted?true:false}
                    index={val.index}
                  > 
                    <MenuItem value="Non">Non</MenuItem>
                    <MenuItem value="Oui">Oui</MenuItem>
                    
                  </Select>
                </FormControl>
              </div>
            </div>         
            <div className={val.resident_etranger_gerance==="Non"?'grid grid-cols-12 mt-6 items-center':'hidden grid-cols-12 mt-6 items-center'} >
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
              <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                <TextField
                  hiddenLabel
                  size="small"
                  className='w-full'
                  id="code_postal_liquidature" 
                  name="code_postal_liquidature" 
                  value={val.code_postal_liquidature} 
                  onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Liquidateur")}}  
                  error={!val.code_postal_liquidature  && props.isSubmitted?true:false}
                  index={val.index}
                />
                
              </div>
            </div>
            <div className='grid grid-cols-12 mt-6 items-center'>
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
              <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                <TextField
                  hiddenLabel
                  size="small"
                  className={val.resident_etranger_gerance==="Non"?'hidden w-full':' w-full'}
                  id="ville_liquidature" 
                  name="ville_liquidature" 
                  value={val.ville_liquidature} 
                  onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Liquidateur")}}  
                  error={!val.ville_liquidature  && props.isSubmitted?true:false}
                  index={val.index}
                />
                <FormControl size="small" className={val.resident_etranger_gerance==="Non"?' w-full':'hidden w-full'} >
                  <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Sélectionnez"
                    id="ville_liquidature_id" 
                    name="ville_liquidature_id" 
                    value={val.ville_liquidature_id} 
                    onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Liquidateur")}}  
                    error={!val.ville_liquidature  && props.isSubmitted?true:false}
                    index={val.index}
                    
                  >
                    {
                      val.ville_liquidatureArr.map((cityVal,cityIndex)=>{
                        return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                      })
                    }

                  </Select>
                </FormControl>
              </div>
            </div>
            <div className={val.resident_etranger_gerance==="Oui"?'grid grid-cols-12 mt-6 items-center':'hidden grid-cols-12 mt-6 items-center'}>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Pays *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    className='w-full'
                    name="pays_liquidature"
                    value={val.pays_liquidature} 
                    onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Liquidateur")}}  
                    error={!val.pays_liquidature  && props.isSubmitted?true:false}
                    index={val.index}
                  />
                </div>
              </div>
          </div> 
          })}   
          <div className='px-0 group flex items-center gap-2 text-primary hover:text-secondary mt-12 cursor-pointer' onClick={(e)=>{props.addForm(e,"Liquidateur")}} >
            <BsPlusCircle className='text-3xl ' />
            <span className='text-base font-normal'>Ajouter un</span>
          </div>         
        </div> 
        {/* Siège de liquidation */}      
        <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Siège de liquidation</h2>
          <div className={"grid grid-cols-12 items-start mt-6"} >
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
            
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="type_personne_lagrance-label"> Choisir le siège de liquidation</InputLabel>
                <Select
                  labelId="type_personne_lagrance-label"
                  id="type_personne_lagrance"
                  label="Sélectionnez"
                  name="type_personne_lagrance"
                  value={`${props.formData.type_personne_lagrance}`}                         
                  onChange={props.handleChange}  
                  error={!props.formData.type_personne_lagrance  && props.isSubmitted?true:false} 
                >
                  <MenuItem value={"siege_social"} >Au siège social</MenuItem>
                  <MenuItem value={"Chez_liquidateur"} >Chez le liquidateur</MenuItem>
                  <MenuItem value={"Autre_adresse"} >Autre adresse</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>  
         
        </div>    
    </div>
    </>
  )
}
