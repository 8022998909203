import React, { useState, useEffect, useRef } from "react";
import Footer from "../../component/Footer";
import Breadcrumb from "./Breadcrumb";
import NeedHelp from "./NeedHelp";
import { TbPointFilled } from "react-icons/tb";
import { Link } from "react-router-dom";
import axios from "axios";
import { decodedStringValue } from "../../_helpers";
import { Helmet } from 'react-helmet';
import VerifiedIcon from '@mui/icons-material/Verified';

export default function ExpertsComptables() {
  const [breadcrumbsData, setBreadcrumbsData] = useState({
    "breadCrumbs": [
      { "pagename": "Annuaires", "path": "/" }
    ],
    "main": { "pagename": "Experts-comptables", "path": "/" }
  });
  /* =====================|Get Default Values|================== */
  const searchRef = useRef(null);
  const [search, setSearch] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [paginateData, setPaginateData] = useState({
    "current": 1,
    "total_pages": 0,
    "total_results": 0,
    "size": 100
  });
  // Search Input data
  function getInputSearchData(input) {
    setSearchKey(input);
    if (input) {
      /* let data = JSON.stringify({
        "query": input,
        "group": {
          "field": "postalcode"
        }
      }); */
      // let URL = 'https://enterprise-search.kifwat.net/api/as/v1/engines/directory-accountant/search';    
      let URL = process.env.REACT_APP_API_URL + "api/admin/search-directory-accountant";
      let data = { search: input, size: 10, currentPage: 1, filter_data: [{ "competences": [], "secteur_dactivite": [], "languages": ["Français"], "accessibilites": [] }] };
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: URL,
        headers: {
          // 'Authorization': `Bearer ${process.env.REACT_APP_ELK_SEARCH_API_KEY}`,
          // 'Accept': '*/*', 
          // "Cache-Control":"no-cache",
          'Content-Type': 'application/json',
        },
        data: data
      };
      let getRequest = setTimeout(async () => {
        axios.request(config)
          .then((response) => {
            if ((response.data.data).length > 0) {
              /* let resultList  = response.data.data.filter((val,i)=>val._source.addresslocality && val._source.postalcode)
              setSearch(resultList); */
              setSearch(response.data.data);
              setCheckList(true);
            } else {
              setSearch(response.data.data);
            }
          })
          .catch((error) => {
            // Handle any errors that occurred during the request
            console.error(error);
            setCheckList(false);
          });
      }, 1000);
      return () => clearTimeout(getRequest);

      /* axios
        .post(
          process.env.REACT_APP_API_URL +
            `api/admin/search-directory-accountant`,
          { search: input }
        )
        .then((response) => {
          // Handle a successful response
          setSearch(response.data.data);
          setCheckList(true);
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          console.error(error);
          setCheckList(false);
        }); */
    } else {
      setCheckList(false);
    }
  }
  // ========================================
  const [checkList, setCheckList] = useState(false);
  useEffect(() => {

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [search, checkList]);

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setCheckList(false);
    }
  };
  return (
    <>
      <Helmet>
        <title>Trouvez votre Expert-Comptable en France | Annuaire BusyPlace</title>
        <meta name="description" content="Explorez notre annuaire complet pour trouver un expert-comptable en France adapté à vos besoins. BusyPlace facilite votre recherche par localité, spécialisation et disponibilité, vous connectant avec des professionnels qualifiés en quelques clics." />
        {/* <link rel="canonical" href={`${process.env.REACT_APP_WEB_URL}/experts-comptables`} /> */}
      </Helmet>
      <div className="w-full h-full AnnuaireBg  pb-6 py-2 md:py-0 md:min-h-[359px]">
        <div className="md:max-w-[1477px] relative w-full h-full m-auto py-0 md:py-8 px-4 sm:px-14">
          <div className="flex items-center justify-start sm:gap-4 w-full m-auto">
            <Breadcrumb page="Experts-comptables" breadcrumbsData={breadcrumbsData} />
          </div>
          <div className="max-w-[1277px] m-auto mt-8 md:mt-16 flex justify-center items-center flex-col gap-4">
            <h1 className=" text-2xl text-center lg:text-3xl 3xl:text-[35px] font-black text-primary">
              L’annuaire des Experts-comptables{" "}
            </h1>
            <div
              className=" max-w-[725px]"
              style={{
                position: "relative",
                width: "100%",
                margin: 'o auto'
              }}
            >
              <form
                id="searchForm"
                style={{ zIndex: "9" }}
                className="bg-white border-0 mx-auto  px-5 py-4 input-box-shadow rounded flex justify-between w-full"
              >
                <input
                  onInput={(e) => getInputSearchData(e.target.value)}
                  type="text"
                  className=" w-full outline-none text-[#797979] text-base font-light"
                  placeholder="Nom de la ville ou du département"
                />
                <Link>
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M33 33L25.6807 25.6677M29.7368 15.8684C29.7368 19.5466 28.2757 23.074 25.6749 25.6749C23.074 28.2757 19.5466 29.7368 15.8684 29.7368C12.1903 29.7368 8.6628 28.2757 6.06197 25.6749C3.46113 23.074 2 19.5466 2 15.8684C2 12.1903 3.46113 8.6628 6.06197 6.06197C8.6628 3.46113 12.1903 2 15.8684 2C19.5466 2 23.074 3.46113 25.6749 6.06197C28.2757 8.6628 29.7368 12.1903 29.7368 15.8684V15.8684Z"
                      stroke="#27295B"
                      strokeWidth="3"
                      strokeLinecap="round"
                    />
                  </svg>
                </Link>
              </form>
              <div
                style={{ display: checkList ? "block" : "none" }}
                className="searchListBox "
                ref={searchRef}
              >
                <ul>
                  {search.length === 0 ? (
                    <div
                      className="containertype"
                      style={{ textAlign: "left", paddingTop: "9px 19px" }}
                    >
                      <span>No result found!</span>
                      {/* <div className="dottype"></div>
                      <div className="dottype"></div>
                      <div className="dottype"></div> */}
                    </div>
                  ) : (
                    ""
                  )}
                  {search.map((listdata) => {
                    let list = listdata._source;
                    return (
                      <li>
                        <Link
                          className="ctmref"
                          to={"/fiche-experts-comptables/" + decodedStringValue(list.slug) + "/" + list.id}

                        >
                          {decodedStringValue(list.name)}  ({decodedStringValue(list.addresslocality)}, {list.postalcode})
                        </Link>
                        {
                          list.partner_status ==="approved"?
                          <span style={{ color: "green", marginLeft: "8px" }}><VerifiedIcon color="success" /></span>
                          : list.partner_status ==="gold" ?<span style={{ color: "green", marginLeft: "8px" }}><VerifiedIcon style={{"color":"gold"}}  /></span>:""
                        }
                        
                      </li>
                    );
                  })}
                  <li className={search.length > 0 ? "" : "hidden"} style={{ "fontWeight": "700" }}>
                    <Link
                      onClick={(e) => {
                        setCheckList(false)
                        setPaginateData({
                          "current": 1,
                          "total_pages": 0,
                          "total_results": 0,
                          "size": 100
                        })
                      }}
                      className="ctmref"
                      // to={`/directory_search_result/accountant/${searchKey}`}
                      to={`/annuaire-des-experts-comptable-selection/accountants/${searchKey}`}
                    >
                      {"Voir tous les résultats de la recherche"}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full min-h-[359px]  px-4 sm:px-14">
        <div className="max-w-[1057px] relative w-full h-full m-auto mt-8 sm:mt-20">
          <h2 className="font-semibold text-xl md:text-3xl text-primary">
            Quel est le rôle de l’Expert-comptable dans votre entreprise ?
          </h2>
          <p className="text-[17px] font-normal text-primary mt-3">
            L'activité principale de l'expert-comptable est le conseil aux entreprises. Au-delà de la comptabilité, il apporte son expertise en matière financière, fiscale ou juridique. Partenaire clé du dirigeant, il l'accompagne dans ses prises de décisions.
          </p>

          <p className="text-[17px] font-normal text-primary mt-7">
            Les prestations de l'Expert-comptable varient selon les besoins de ses clients et peuvent être très variées :
          </p>
          <ul className=" ">
            <li className="flex gap-2 items-center text-primary">
              <TbPointFilled className="text-primary text-[6px]" />
              La comptabilité de l'entreprise.
            </li>
            <li className="flex gap-2 items-center text-primary">
              <TbPointFilled className="text-primary text-[6px]" />
              La gestion de l'entreprise.
            </li>
            <li className="flex gap-2 items-center text-primary">
              <TbPointFilled className="text-primary text-[6px]" />
              Les obligations légales de l'entreprise.{" "}
            </li>
            <li className="flex gap-2 items-center text-primary">
              <TbPointFilled className="text-primary text-[6px]" />
              Droit du travail et gestion sociale.{" "}
            </li>
            <li className="flex gap-2 items-center text-primary">
              <TbPointFilled className="text-primary text-[6px]" />
              Droit fiscal.{" "}
            </li>
            <li className="flex gap-2 items-center text-primary">
              <TbPointFilled className="text-primary text-[6px]" />
              Suivi juridique de l'entreprise.{" "}
            </li>
            <li className="flex gap-2 items-center text-primary">
              <TbPointFilled className="text-primary text-[6px]" />
              Information de l'entreprise.
            </li>
          </ul>

          <p className="text-[17px] font-normal text-primary mt-7">
            Depuis le 1er janvier 2020, les experts-comptables peuvent procéder de deux manières pour publier une annonce légale pour le compte de leurs clients : la publication dans un journal papier ou sur internet.{" "}
          </p>
        </div>
      </div>
      <NeedHelp />
      <Footer />
    </>
  );
}
